import axios from "axios";

const { SERVER_URL } = window._env_;

// const SERVER_URL = "https://ag-energie-admin-backend.int.at-digital.fr";


export const uploadImage = async (file) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const result = await axios.post(
    `${SERVER_URL}/api/v1/uploadImage`,
    file,
    config
  );
  return result.data;
};

export const createTechnicalReport = async (newBusinessId, report) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/createTechnicalReport/${newBusinessId}`, report);

  return result.data;
}

export const updateTechnicalReport = async (reportId, report) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/updateTechnicalReport/${reportId}`, report);

  return result.data;
}
