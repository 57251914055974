import axios from 'axios';

const { SERVER_URL } = window._env_;

export const addSiteSupervisionConstructionSite = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-site-supervision-construction-site`, data);

  return result.data;
}

export const addSiteSupervisionIntervention = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-site-supervision-intervention`, data);

  return result.data;
}

export const addSiteSupervisionTroubleShoot = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-site-supervision-trouble-shoot`, data);

  return result.data;
}

export const addSiteSupervisionOrder = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-site-supervision-order`, data);

  return result.data;
}

export const updateSiteSupervision = async (id, data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/update-site-supervision/${id}`, data);

  return result.data;
}

export const getSiteSupervisionById = async (id) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/get-site-supervision/${id}`);

  return result.data;
}

export const deleteSiteSupervision = async (id,) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/delete-site-supervision/${id}`);

  return result.data;
}
