import axios from 'axios';

const { SERVER_URL } = window._env_;

export const addTechnicianReportConstructionSite = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-technician-report-construction-site`, data);

  return result.data;
}

export const addTechnicianReportIntervention = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-technician-report-intervention`, data);

  return result.data;
}

export const addTechnicianReportTroubleShoot = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-technician-report-trouble-shoot`, data);

  return result.data;
}

export const getTechniciansReport = async (id,) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/get-technician-report/${id}`);

  return result.data;
}

export const updateTechniciansReport = async (id, data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/update-technician-report/${id}`, data);

  return result.data;
}

export const deleteTechniciansReport = async (id, data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/delete-technician-report/${id}`);

  return result.data;
}


export const addTechnicianReportMaintenanceContract = async (data) => {
  const result = await axios
    .post(`${SERVER_URL}/api/v1/add-technician-report-maintenance-contract`, data);

  return result.data;
}

