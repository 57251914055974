import {
  cumulAmount,
  handleFilterOrdersTodo,
  handleFilterOrdersPurchased,
  handleFilterOrdersDelivered,
  handleFilterOrdersPlanified,
  handleFilterOrdersOfMonth,
  handleFilterOrdersOf3Monthes,
  handleFilterOrdersOfYear,
  handleFilterOrdersOf2Years,
} from "service/functions";

export const handleSetHeaderStatCards = (
  interventions,
  advStatus,
  [
    headerstatcardsinterventions,
    setheaderstatcardsinterventions,
  ],
) => {
  if (!advStatus || advStatus === ":running") {
    const interventionsTodo = handleFilterOrdersTodo(
      interventions,
    );
    const interventionsPurchased = handleFilterOrdersPurchased(
      interventions,
    );
    const interventionsDelivered = handleFilterOrdersDelivered(
      interventions,
    );
    const interventionsPlanified = handleFilterOrdersPlanified(
      interventions,
    );

    setheaderstatcardsinterventions({
      color: "accentBlue",
      cards: [
        {
          statSubtitle: "à planifier / à commander",
          statTitle: `${interventionsTodo.length} 
            / 
            ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(cumulAmount(interventionsTodo))}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "far fa-chart-bar",
          statIconColor: "bg-red-500",
        },

        {
          statSubtitle: "à planifier / mat. commandé",
          statTitle: `${
            interventionsPurchased.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(cumulAmount(interventionsPurchased))}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-users",
          statIconColor: "bg-pink-500",
        },
        {
          statSubtitle: "à planifier / matériel reçu",
          statTitle: `${
            interventionsDelivered.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(cumulAmount(interventionsDelivered))}`,
          statArrow: "down",
          statPercent: "",
          statPercentColor: "text-white-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-chart-pie",
          statIconColor: "bg-agOrange-regular",
        },
        {
          statSubtitle: "planifié / reçu",
          statTitle: `${
            interventionsPlanified.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(cumulAmount(interventionsPlanified))}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le début de l'année",
          statIconName: "fas fa-percent",
          statIconColor: "bg-lightBlue-500",
        },
      ],
    });
  } else if (advStatus === ":finished") {
    setheaderstatcardsinterventions({
      color: "accentBlue",
      cards: [
        {
          statSubtitle: "Interventions terminées (mois)",
          statTitle: `${
            handleFilterOrdersOfMonth(interventions).length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersOfMonth(interventions),
              ),
            )}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le début de l'année",
          statIconName: "fas fa-percent",
          statIconColor: "bg-lightBlue-500",
        },
        {
          statSubtitle: "Interventions terminées (3 mois)",
          statTitle: `${
            handleFilterOrdersOf3Monthes(interventions)
              .length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersOf3Monthes(interventions),
              ),
            )}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-users",
          statIconColor: "bg-pink-500",
        },
        {
          statSubtitle: "Interventions terminées (année)",
          statTitle: `${
            handleFilterOrdersOfYear(interventions).length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersOfYear(interventions),
              ),
            )}`,
          statArrow: "down",
          statPercent: "",
          statPercentColor: "text-white-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-chart-pie",
          statIconColor: "bg-agOrange-regular",
        },
        {
          statSubtitle: "Interventions terminées (2 ans)",
          statTitle: `${
            handleFilterOrdersOf2Years(interventions).length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersOf2Years(interventions),
              ),
            )}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "far fa-chart-bar",
          statIconColor: "bg-red-500",
        },
      ],
    });
  }
};

export default handleSetHeaderStatCards;