import { cumulAmount, handleFilterCreated, handleFilterHot, handleFilterSent } from "service/functions";

export const handleSetHeaderStatCardNewBusiness = (
  newBusiness, [headerstatcardnewbusiness, setheaderstatcardsnewbusiness]
) => {

  setheaderstatcardsnewbusiness({
    color: "accentBlue",
    cards: [
      {
        statSubtitle: "AFFAIRES en retard",

        statTitle: `${handleFilterHot(newBusiness).length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(
            cumulAmount(handleFilterHot(newBusiness)),
          )}`,
        statArrow: "up",
        statPercent: "",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Depuis le mois dernier",
        statIconName: "far fa-chart-bar",
        statIconColor: "bg-red-500",
      },

      {
        statSubtitle: "AFFAIRES Créées",
        statTitle: `${handleFilterCreated(newBusiness).length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(
            cumulAmount(handleFilterCreated(newBusiness)),
          )}`,
        statArrow: "up",
        statPercent: "",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Depuis le mois dernier",
        statIconName: "fas fa-users",
        statIconColor: "bg-pink-500",
      },
      {
        statSubtitle: "AFFAIRES envoyées",
        statTitle: `${handleFilterSent(newBusiness).length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(
            cumulAmount(handleFilterSent(newBusiness)),
          )}`,
        statArrow: "down",
        statPercent: "",
        statPercentColor: "text-white-500",
        statDescripiron: "Depuis le mois dernier",
        statIconName: "fas fa-chart-pie",
        statIconColor: "bg-agOrange-regular",
      },
      {
        statSubtitle: "Total AFFAIRES",
        statTitle: `${newBusiness.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(cumulAmount(newBusiness))}`,
        statArrow: "up",
        statPercent: "",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Depuis le début de l'année",
        statIconName: "fas fa-euro-sign",
        statIconColor: "bg-lightBlue-500",
      },
    ],
  });
};

