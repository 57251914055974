import { addSiteSupervisionConstructionSite, addSiteSupervisionIntervention, addSiteSupervisionOrder, addSiteSupervisionTroubleShoot, deleteSiteSupervision, getSiteSupervisionById, updateSiteSupervision } from 'api/siteSupervision';
import Button from 'components/Elements/Button';
import Images from 'components/Elements/Images';
import HeaderStatCards from 'components/Headers/Admin/HeaderStatCards';
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import AppContext from 'Contexts/AppContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { imageHandler } from 'utils/common';
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
import Input from "../../../components/Elements/Input";


export default function AddUpdateSiteSupervision() {
  const { siteSupervisionId } = useParams();
  const [siteSupervision, setSiteSupervision] = useState({});
  const history = useHistory();
  const { state: { type = "", id = "" } = {} } = useLocation()
  const { user, displayErrorMessage, isAdmin, isSuperAdmin } = useContext(AppContext);

  const isAllowedToDelete = isAdmin || isSuperAdmin;


  const isAdd = !siteSupervisionId;
  const title = isAdd ? "Ajouter suivi de chantier" : "Mis à jour suivi de chantier"

  const isDisabled = false;

  useEffect(() => {
    if (siteSupervisionId)
      handleGetSiteSupervision()

  }, [])

  const handleDeleteSiteSupervision = async (e) => {
    e.preventDefault();

    try {
      await deleteSiteSupervision(siteSupervisionId);
    } catch (err) {
      console.log("err", err);
      displayErrorMessage("Une erreur est survenu dans la suppresion du suivi de chantier.")
    } finally {
      history.push("/")

    }
  }


  const validateSiteSupervision = () => {
    const { name } = siteSupervision;

    if (!name) {
      displayErrorMessage("Veuillez entrez un nom pour ce suivi chantier")
      return false
    }
    return true;
  }

  const handleAddSiteSupervision = async (e) => {
    e.preventDefault();

    if (!validateSiteSupervision())
      return false;

    try {
      if (!siteSupervisionId) {
        if (type === "ORDERS") {
          const data = {
            orderId: id,
            siteSupervision
          }
          await addSiteSupervisionOrder(data)
        }
        else if (type === "CONSTRUCTION_SITE") {
          const data = {
            constructionSiteId: id,
            siteSupervision
          }
          await addSiteSupervisionConstructionSite(data)
        }
        else if (type === "INTERVENTIONS") {
          const data = {
            interventionId: id,
            siteSupervision
          }
          await addSiteSupervisionIntervention(data)
        }
        else if (type === "TROUBLESHOOTS") {
          const data = {
            troubleShootId: id,
            siteSupervision
          }
          await addSiteSupervisionTroubleShoot(data)
        }
      } else {
        await updateSiteSupervision(siteSupervisionId, siteSupervision);
      }

    } catch (err) {
      displayErrorMessage("Une erreur est survenue.")
    } finally {
      history.push("/")
    }

  }

  const handleGetSiteSupervision = async () => {
    try {
      const result = await getSiteSupervisionById(siteSupervisionId);
      setSiteSupervision(result);
    } catch (err) {
      console.log("err", err);
      displayErrorMessage("Une erreur est survenue dans la récuperation du suivi chantier.")
    }
  }

  const handleAddNewImage = (imageUrl) => {
    setSiteSupervision(vals => {
      const images = vals?.images || []
      return {
        ...vals,
        images: [...images, { url: imageUrl }]
      }
    })
  }


  const handleRemoveOfNewImage = (imageUrl) => {
    setSiteSupervision(vals => {
      return {
        ...vals,
        images: vals?.images?.filter(({ url }) => url !== imageUrl)
      }
    })
  }


  const handleChangeOfImage = (existingImageUrl, newImageUrl) => {
    setSiteSupervision(vals => {
      return {
        ...vals,
        images: vals?.images?.map(image => {
          const { url, ...args } = image;
          if (url === existingImageUrl)
            return { ...args, url: newImageUrl }
          return image;
        })
      }
    })
  }

  const onChangeText = (existingImageUrl, text) => {
    setSiteSupervision(vals => {
      return {
        ...vals,
        images: vals?.images?.map(image => {
          const { url, } = image;
          if (url === existingImageUrl)
            return { ...image, description: text }
          return image;
        })
      }
    })
  }


  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="flex flex-wrap">
            <div className="w-full  px-4">
              <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">

                <div className="mb-3 p-6 pb-0">
                  <div className="text-center flex justify-between items-center">
                    <h6 className="text-xl font-bold mb-0">
                      {title?.toUpperCase()}
                    </h6>
                  </div>
                </div>
                <div className="flex-auto px-6 pb-6 pt-0">
                  <form >

                    <div className="flex flex-wrap -mx-4">
                      <div
                        className={`w-full px-4 lg:w-6/12 `}
                      >
                        <div className="relative w-full mb-10">
                          <label
                            className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                          >
                            Nom
                          </label>
                          <Input {...{
                            border: !siteSupervision.name
                              ? "borderwarning"
                              : "border",
                            placeholder: "",
                            type: "text",
                            defaultValue: siteSupervision.name,
                            value: siteSupervision.name,
                            onChange: (e) => {
                              setSiteSupervision((vals) => ({
                                ...vals,
                                name: e.target.value,
                              }));
                            },
                          }} />
                        </div>
                        <Images
                          {...{
                            images: siteSupervision.images || [],
                            onChange: async (existingImageUrl, file) => {
                              try {
                                const url = await imageHandler(file);

                                handleChangeOfImage(existingImageUrl, url);

                              } catch (err) {

                              }
                            },
                            onRemove: (existingImageUrl) => {
                              handleRemoveOfNewImage(existingImageUrl)
                            },
                            onAddNew: async (_placeholder, file) => {
                              try {
                                const url = await imageHandler(file);

                                handleAddNewImage(url);

                              } catch (err) {

                              }
                            },
                            onRemoveNew: (existingImageUrl) => {
                              handleRemoveOfNewImage(existingImageUrl)

                            },
                            onChangeText,
                            changeButton: {
                              disabled: isDisabled,
                              children: "Ajouter/changer",
                              size: "sm",
                              color: "lightBlue",
                              onClick: () => alert("add/change"),
                            },
                            removeButton: {
                              disabled: isDisabled,
                              children: "Supprimer",
                              size: "sm",
                              color: "lightBlue",
                              onClick: () => alert("remove"),
                            }
                          }}
                        />


                        <div className="mt-10 ml-6">

                          <Button {...{
                            children: "Sauvegarder",
                            size: "sm",
                            color: "lightBlue",
                            onClick: handleAddSiteSupervision
                          }} />

                          {
                            (isAllowedToDelete && !isAdd) && (
                              <Button {...{
                                children: "Supprimer",
                                size: "sm",
                                color: "red",
                                onClick: handleDeleteSiteSupervision
                              }} />
                            )
                          }
                        </div>

                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

