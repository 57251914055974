// src/components/Cards/Admin/CardSettingsLarge.js

import Checkbox from "components/Elements/Checkbox.js";
import ReactGoogleAutocomplete from "components/Elements/GooglePlaceAutoComplete.js";
import ImageUpload from "components/Elements/ImageUpload";
import PropTypes from "prop-types";
import React from "react";
import SelectSearch from "react-select-search";
// components
import Button from "../../../components/Elements/Button.js";
import Images from "../../../components/Elements/Images";
import Incrementer from "../../../components/Elements/Incrementer.js";
import Input from "../../../components/Elements/Input.js";
import Radio from "../../../components/Elements/Radio.js";
import Select from "../../../components/Elements/Select.js";
import Incrementers from "../../../components/TechnicalReportItem/Incrementers";

export default function CardSettingsLarge({
  title,
  button,
  deleteButton,
  button2,
  button3,
  button4,
  button5,
  forms,
}) {
  const widths = {
    1: "lg:w-1/12",
    2: "lg:w-2/12",
    3: "lg:w-3/12",
    4: "lg:w-4/12",
    5: "lg:w-5/12",
    6: "lg:w-6/12",
    7: "lg:w-7/12",
    8: "lg:w-8/12",
    9: "lg:w-9/12",
    10: "lg:w-10/12",
    11: "lg:w-11/12",
    12: "lg:w-12/12",
  };

  return (
    <>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 p-6 pb-0">
          <div className="text-center flex justify-between items-center">
            <h6 className="text-xl font-bold mb-0">{title?.toUpperCase()}</h6>
            <div>
              {button5 && <Button {...button5} />}
              {button && <Button {...button} />}
              {deleteButton && <Button {...deleteButton} />}
            </div>
          </div>
        </div>
        <div className="mb-0 p-6 pb-0 flex justify-between">
          <div className="text-center flex justify-start items-center mb-3">
            {button2 && <Button {...button2} />}
            {button3 && <Button {...button3} />}
            {button4 && <Button {...button4} />}
          </div>
          {/* <Breadcrumb location={location} pageName={capitalizeFirstLetter(title)} color="lightBlue" /> */}
        </div>

        <div className="flex-auto px-6 pb-6 pt-0">
          <form>
            {forms?.map((prop, key) => (
              <div key={key}>
                {prop.title && (
                  <div className="flex flex-center flex-1 w-full flex-grow">
                    <h6 className="mt-6 mb-2 font-bold flex-grow flex-1">
                      {prop.title}
                    </h6>
                    <hr className="mb-6 border-b-1 border-blueGray-200" />
                  </div>
                )}

                {prop.titles && (
                  <div className="flex justify-around min-w-full">
                    {prop.titles &&
                      prop.titles.map((title, key) => (
                        <h6
                          key={key}
                          className="mt-6 mb-2 font-bold flex-1 flex-grow"
                        >
                          {title}
                        </h6>
                      ))}
                    <hr className="mb-6 border-b-1 border-blueGray-200" />
                  </div>
                )}
                <div className="flex flex-wrap -mx-4">
                  {prop.inputs &&
                    prop.inputs.map((inputProp, inputKey) => (
                      <div
                        key={inputKey}
                        className={`w-full px-4 ${widths[inputProp?.width]} ${
                          inputProp?.className
                        } `}
                      >
                        {inputProp.checkbox ? (
                          <div className="relative w-full mb-3 checkbox">
                            {inputProp?.label && (
                              <label
                                className="block uppercase text-blueGray-500 text-xs font-bold  ml-1"
                                htmlFor="grid-password"
                              >
                                {inputProp?.label}
                              </label>
                            )}
                            {inputProp.checkbox && (
                              <Checkbox {...inputProp.checkbox} />
                            )}
                          </div>
                        ) : (
                          <>
                            <div className="relative w-full mb-3">
                              {inputProp?.label && (
                                <label
                                  className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                                  htmlFor="grid-password"
                                >
                                  {inputProp?.label}
                                </label>
                              )}
                              {inputProp?.input && (
                                <Input {...inputProp?.input} />
                              )}
                              {inputProp?.addressAutoComplete && (
                                <ReactGoogleAutocomplete
                                  {...inputProp?.addressAutoComplete}
                                />
                              )}
                              {inputProp?.selectSearch && (
                                <SelectSearch
                                  style={{
                                    border: "1px solid black",
                                  }}
                                  {...inputProp?.selectSearch}
                                />
                              )}
                              {inputProp?.select && (
                                <Select {...inputProp?.select} />
                              )}
                              {inputProp.radio && (
                                <Radio {...inputProp.radio} />
                              )}
                              {inputProp.incrementer && (
                                <Incrementer {...inputProp.incrementer} />
                              )}
                              {inputProp.incrementers && (
                                <Incrementers {...inputProp.incrementers} />
                              )}

                              {inputProp.checkbox && (
                                <Checkbox {...inputProp.checkbox} />
                              )}
                              {inputProp.imageUpload && (
                                <ImageUpload {...inputProp.imageUpload} />
                              )}
                              {inputProp.images && (
                                <Images {...inputProp.images} />
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </form>
        </div>
      </div>
    </>
  );
}

const inputsObject = {
  // NOTE: this width only applies on large devices
  width: PropTypes.oneOf([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]),
  // if you wish, you can send somthing like
  // <span className="mr-2">Label Name</span><span className="text-red-500">*</span>
  // NOTE: the label tag will be auto generated
  label: PropTypes.node,
};

const inputsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Input component
  input: PropTypes.object,
});

const selectsShape = PropTypes.shape({
  ...inputsObject,
  // props to pass to the Select component
  select: PropTypes.object,
});

const inputsTypes = PropTypes.arrayOf(
  PropTypes.oneOfType([inputsShape, selectsShape])
);

CardSettingsLarge.defaultProps = {
  button: {},
  forms: [],
};

CardSettingsLarge.propTypes = {
  title: PropTypes.string,
  // props to pass to the Button component
  button: PropTypes.object,

  forms: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      titles: PropTypes.arrayOf(PropTypes.string),

      inputs: inputsTypes,
    })
  ),
};
