import axios from "axios";

const { SERVER_URL } = window._env_;


export const getAllAgencies = async () => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getAgencies`);

  return result.data;
}

export const getAgencyById = async (agencyId) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getAgency/${agencyId}`);

  return result.data;
}
export const getAgenciesForFranchiseOwner = async (userPrincipalName) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/getAgenciesForFranchiseOwner`, { userPrincipalName });

  return result.data;
}

export const addAgency = async (agency) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/addAgency`, { agency });

  return result.data;
}

export const updateAgency = async (agencyId, updatedAgency) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/updateAgency/${agencyId}`, { agency: updatedAgency });

  return result.data;
}

export const deleteAgency = async (agencyId) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/deleteAgency/${agencyId}`);

  return result.data;
}

