import axios from "axios";

const { SERVER_URL } = window._env_;


export const getAllUsers = async () => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getUsers`);

  return result.data;
}

export const getAllTechnicalUsers = async (agency) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/getTechnicalUsers`, { agency });

  return result.data;
}

export const getAllEstimateManagers = async (agency) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/getEstimateManagers`, { agency });

  return result.data;
}

export const getAllSiteManagers = async (agency) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/getSiteManagers`, { agency });

  return result.data;
}
export const getFranchiseOwners = async () => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getFranchiseOwners`);

  return result.data;
}

export const disableUserAccount = async (userPrincipalName, enabled) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/blockUser`, { userPrincipalName, enabled });

  return result.data;
}

