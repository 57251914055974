// components
import CardLoginAgPlus from "components/Cards/Auth/CardLoginAgPlus.js";
import FooterAuth from "components/Footers/Auth/FooterAuth.js";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Role } from "utils/common";
import cardloginagplus from "_texts/auth/cards/cardloginagplus.js";
import footerauth from "_texts/auth/footers/footerauth.js";
import AppContext from "../../Contexts/AppContext";
// texts
export default function Login() {
  const history = useHistory();
  const location = useLocation();
  const context = useContext(AppContext);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);


  const redirectUserAfterSuccessfulLogin = () => {
    if (!context?.user?.id) return

    if (context?.user?.department?.toLowerCase() === Role.SERVICE_TECHNIQUE?.toLowerCase()) {
      history.replace("/list-sites-or-trouble-shoots/:sites")
    } else {
      history.replace("/");
    }
  }

  React.useEffect(() => {
    redirectUserAfterSuccessfulLogin()
    // history.push("/");
  }, [context.user]);

  const login = (user) => {
    localStorage.setItem("isLoggedIn", true);
    // history.replace("/");
    context.setUser(user);
    redirectUserAfterSuccessfulLogin()
  };

  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div className="absolute top-0 w-full h-full bg-accentBlue-regular bg-no-repeat bg-full"></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <CardLoginAgPlus onPress={login} {...cardloginagplus} />
              </div>
            </div>
          </div>
          <FooterAuth {...footerauth} />
        </section>
      </main>
    </>
  );
}
