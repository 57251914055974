import { updateIntervention } from 'api/interventions';
import { addNewBusinessFromConstructionSite, addNewBusinessFromIntervention, addNewBusinessFromMaintenanceContract, addNewBusinessFromTroubleShoot } from 'api/newBusiness';
import { updateConstructionSite, updateTroubleShoot } from 'api/orders';
import { addTechnicianReportConstructionSite, addTechnicianReportIntervention, addTechnicianReportMaintenanceContract, addTechnicianReportTroubleShoot, deleteTechniciansReport, getTechniciansReport, updateTechniciansReport } from 'api/techniciansReport';
import CardSettingsLarge from 'components/Cards/Admin/CardSettingsLarge';
import HeaderStatCards from 'components/Headers/Admin/HeaderStatCards';
import NavbarSearchUser from 'components/Navbars/NavbarSearchUser';
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import AppContext from 'Contexts/AppContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { imageHandler } from 'utils/common';
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
import { updateMaintenanceContract } from '../../../api/maintenanceContract';
import { MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE, SITE_STATUSES } from '../../../types/baseTypes';

export default function AddUpdateTechniciansReport() {
  const [technicianReport, setTechnicianReport] = useState({});
  const [initialTechnicianReport, setInitialTechnicianReport] = useState({})
  const history = useHistory();
  const { user, selectedAgency, displayErrorMessage, displaySuccessMessage, isSuperAdmin, isAdmin } = useContext(AppContext);
  const { state: { type = "", id = "" } = {} } = useLocation()
  let { technicianReportId, disabled } = useParams();
  const isDisabled = disabled === "disabled";
  const userAgency = selectedAgency

  const isAdd = !technicianReportId;
  const title = isAdd ? "Ajouter compte rendu" : "Mis à jour compte rendu"

  const wasComplete = initialTechnicianReport?.isComplete;

  const invoiceSentAlready = initialTechnicianReport?.newInvoiceNeeded;


  useEffect(() => {
    if (technicianReportId)
      handleGetTechnicianReport()
    else {
      setTechnicianReport({ date: new Date() })
    }
  }, [])


  const handleGetTechnicianReport = async () => {
    try {
      const result = await getTechniciansReport(technicianReportId);
      setInitialTechnicianReport(result);
      setTechnicianReport(result);
    } catch (err) {
      console.log("err", err)
      displayErrorMessage("Une erreur est survenue")
    }
  }

  const validateTechniciansReport = () => {
    const { startTime, endTime, breakTime, date, isComplete, newInvoiceNeeded, images = [], zone } = technicianReport;

    if (moment(endTime, "HH:mm").isBefore(moment(startTime, "HH:mm"), "minutes")) {
      displayErrorMessage("L'heure de fin ne peut pas etre avant l'heure de début.")
      return false
    }

    if (images?.length < 1) {
      displayErrorMessage("Veuillez inclure au moins 1 document.")
      return false
    }


    if (
      (typeof breakTime !== "number" || breakTime < 0) ||
      !date ||
      typeof isComplete !== "boolean" ||
      typeof newInvoiceNeeded !== "boolean" ||
      !zone
    ) {
      displayErrorMessage("Veuillez compléter le formulaire correctement.")
      return false
    }

    return true;
  };

  const consolidateTechnicianReport = () => {
    const { startTime, endTime, breakTime } = technicianReport;
    const start = moment(startTime, "HH:mm");
    const end = moment(endTime, "HH:mm");
    const duration = moment.duration(end.diff(start));

    const timeSpent = duration.asMinutes() - breakTime;

    return { ...technicianReport, timeSpent };
  }

  const handleAddTechnicianReport = async (e) => {
    e.preventDefault()


    if (!validateTechniciansReport())
      return;
    try {

      const consolidatedReport = consolidateTechnicianReport();

      if (!technicianReportId) {
        if (type === "CONSTRUCTION_SITE") {
          const data = {
            constructionSiteId: id,
            technicianReport: {
              ...consolidatedReport,
              technician: user.userPrincipalName
            },
          }
          await addTechnicianReportConstructionSite(data)
        }
        else if (type === "MAINTENANCE_CONTRACT") {
          const data = {
            maintenanceContractId: id,
            technicianReport: {
              ...consolidatedReport,
              technician: user.userPrincipalName
            },
          }
          await addTechnicianReportMaintenanceContract(data)
        }
        else if (type === "INTERVENTIONS") {
          const data = {
            interventionId: id,
            technicianReport: {
              ...consolidatedReport,
              technician: user.userPrincipalName
            },
          }
          await addTechnicianReportIntervention(data)
        }
        else if (type === "TROUBLESHOOTS") {
          const data = {
            troubleShootId: id,
            technicianReport: {
              ...consolidatedReport,
              technician: user.userPrincipalName
            },
          }
          await addTechnicianReportTroubleShoot(data)
        }
      }
      else {
        await updateTechniciansReport(technicianReportId, consolidatedReport)
      }



      const data = {
        siteStatus: technicianReport.isComplete
          ? SITE_STATUSES.TO_BE_INVOICED
          : SITE_STATUSES.NOT_FINISHED_TO_PLANIFY
      }
      if (type === "CONSTRUCTION_SITE") {
        await updateConstructionSite(id, data)
      }
      else if (type === "INTERVENTIONS") {
        await updateIntervention(id, data)
      }
      else if (type === "TROUBLESHOOTS") {
        await updateTroubleShoot(id, data)
      }
      else if (type === "MAINTENANCE_CONTRACT") {
        const status = technicianReport.isComplete
          ? MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.TO_BE_INVOICED
          : MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.NOT_FINISHED_TO_PLANIFY
        await updateMaintenanceContract(id, { status })
      }

      if ((!invoiceSentAlready && consolidatedReport.newInvoiceNeeded) || consolidatedReport.newInvoiceNeeded) {
        if (type === "CONSTRUCTION_SITE") {
          const data = {
            constructionSiteId: id,
            userAgency,
            comment: consolidatedReport.comment || ""
          }
          await addNewBusinessFromConstructionSite(data)
        }
        else if (type === "INTERVENTIONS") {
          const data = {
            interventionId: id,
            userAgency,
            comment: consolidatedReport.comment || ""
          }
          await addNewBusinessFromIntervention(data)
        }
        else if (type === "TROUBLESHOOTS") {
          const data = {
            troubleShootId: id,
            userAgency,
            comment: consolidatedReport.comment || ""
          }
          await addNewBusinessFromTroubleShoot(data)
        }
        else if (type === "MAINTENANCE_CONTRACT") {
          const data = {
            maintenanceContractId: id,
            userAgency,
            comment: consolidatedReport.comment || ""
          }
          await addNewBusinessFromMaintenanceContract(data)

        }
      }

    } catch (err) {
      displayErrorMessage("Une erreur est survenue")
    } finally {
      displaySuccessMessage()
      history.push("/")
    }
  }

  const handleDeleteTechnicianReport = async (e) => {
    e.preventDefault()

    try {
      await deleteTechniciansReport(technicianReportId);
    } catch (err) {
      console.log("err", err)
      displayErrorMessage("Une erreur est survenue")
    } finally {
      history.push("/")
    }
  }

  const handleAddNewImage = (imageUrl) => {
    setTechnicianReport(vals => {
      const images = vals?.images || []
      return {
        ...vals,
        images: [...images, { url: imageUrl }]
      }
    })
  }


  const handleRemoveOfNewImage = (imageUrl) => {
    setTechnicianReport(vals => {
      return {
        ...vals,
        images: vals?.images?.filter(({ url }) => url !== imageUrl)
      }
    })
  }


  const handleChangeOfImage = (existingImageUrl, newImageUrl) => {
    setTechnicianReport(vals => {
      return {
        ...vals,
        images: vals?.images?.map(image => {
          const { url, ...args } = image;
          if (url === existingImageUrl)
            return { ...args, url: newImageUrl }
          return image;
        })
      }
    })
  }


  const onChangeText = (existingImageUrl, text) => {
    setTechnicianReport(vals => {
      return {
        ...vals,
        images: vals?.images?.map(image => {
          const { url, } = image;
          if (url === existingImageUrl)
            return { ...image, description: text }
          return image;
        })
      }
    })
  }


  const buildForm = () => {

    const deleteButton = ((isAdmin || isSuperAdmin) && technicianReportId)
      ? {
        children: "SUPPRIMER",
        size: "sm",
        color: "red",
        onClick: handleDeleteTechnicianReport
      }
      : null;



    return {
      title,
      deleteButton,
      button: {
        children: "Sauvegarder",
        size: "sm",
        color: "lightBlue",
        onClick: handleAddTechnicianReport,
      },

      forms: [
        {
          inputs: [
            {
              label: "Date *",
              width: 12,
              input: {
                disabled: isDisabled,
                type: "date",
                border: !technicianReport.date ? "borderwarning" : "border",
                defaultValue: moment(technicianReport.date).format("yyyy-MM-DD"),
                value: moment(technicianReport.date).format("yyyy-MM-DD"),
                onChange: (e) => {
                  setTechnicianReport((vals) => ({
                    ...vals,
                    date: e.target.value,
                  }));
                },
              },
            },

            {
              label: `Heure du début*`,
              width: 6,
              input: {
                disabled: isDisabled,
                border: !technicianReport.startTime ? "borderwarning" : "border",
                type: "time",
                step: 300,
                defaultValue: technicianReport.startTime,
                value: technicianReport.startTime,
                onChange: (e) => {
                  setTechnicianReport((vals) => ({
                    ...vals,
                    startTime: e.target.value,
                  }));
                },
              },
            },
            {
              label: `Heure de fin *`,
              width: 6,
              input: {
                disabled: isDisabled,
                border: !technicianReport.endTime ? "borderwarning" : "border",
                type: "time",
                step: 300,
                defaultValue: technicianReport.endTime,
                value: technicianReport.endTime,
                onChange: (e) => {
                  setTechnicianReport((vals) => ({
                    ...vals,
                    endTime: e.target.value
                  }));
                },
              },
            },
            {
              label: `Temps de pause (en minutes)*`,
              width: 6,
              input: {
                disabled: isDisabled,
                border: (+technicianReport.breakTime < 0 || typeof technicianReport.breakTime === "undefined") ? "borderwarning" : "border",
                type: "number",
                defaultValue: technicianReport.breakTime,
                value: technicianReport.breakTime,
                onChange: (e) => {
                  setTechnicianReport((vals) => ({
                    ...vals,
                    breakTime: +e.target.value
                  }));
                },
              },
            },
            {
              label: `Fini? *`,
              width: 6,
              select: {
                disabled: isDisabled,
                items: ["OUI", "NON"],
                border: typeof technicianReport.isComplete === "undefined" ? "borderwarning" : "border",
                type: "text",
                defaultValue: typeof technicianReport.isComplete === "undefined"
                  ? ""
                  : technicianReport.isComplete ? "OUI" : "NON",
                value: typeof technicianReport.isComplete === "undefined"
                  ? ""
                  : technicianReport.isComplete ? "OUI" : "NON",
                onChange: (e) => {
                  setTechnicianReport((vals) => ({
                    ...vals,
                    isComplete: e === "OUI",
                  }));
                },
              },
            },
            {
              label: `Nouveau devis requis? *`,
              width: 6,
              select: {
                disabled: isDisabled,
                border:
                  typeof technicianReport.newInvoiceNeeded === "undefined"
                    ? "borderwarning"
                    : "border",
                placeholder: "Catégorie",
                value: typeof technicianReport.newInvoiceNeeded === "undefined"
                  ? ""
                  : technicianReport.newInvoiceNeeded ? "OUI" : "NON",
                defaultValue: typeof technicianReport.newInvoiceNeeded === "undefined"
                  ? ""
                  : technicianReport.newInvoiceNeeded ? "OUI" : "NON",
                items: ["OUI", "NON"],
                onChange: (e) => {
                  setTechnicianReport((vals) => ({
                    ...vals,
                    newInvoiceNeeded: e === "OUI"
                  }));
                },
              },
            },

            {
              label: "Zone de déplacement",
              width: 6,
              select: {
                disabled: isDisabled,
                border: typeof technicianReport.zone === "undefined"
                  ? "borderwarning"
                  : "border",
                placeholder: "Zone de déplacement",
                defaultValue: technicianReport.zone,
                items: [
                  "Z1a",
                  "Z1b",
                  "Z2",
                  "Z3",
                  "Z4",
                  "Z5",
                ],
                value: technicianReport.zone,
                onChange: (e) => {
                  if (typeof e === "string") {
                    setTechnicianReport((vals) => ({
                      ...vals,
                      zone: e
                    }))
                  }
                },
              },
            }

          ]

        },
        {
          // title: "images",
          inputs: [
            {
              label: `Remarques *`,
              width: 12,
              input: {
                disabled: isDisabled,
                border:
                  !technicianReport.comment ? "borderwarning" : "border",
                placeholder: "Remarques",
                type: "textarea",
                defaultValue: technicianReport.comment,
                rows: 2,
                value: technicianReport.comment,
                onChange: (e) =>
                  setTechnicianReport((vals) => ({
                    ...vals,
                    comment: e.target.value,
                  })),
              },
            },
            {
              label: "Ajouter une image",
              width: 3,
              images: {
                disabled: isDisabled,
                images: technicianReport.images,
                onChange: async (existingImageUrl, file) => {
                  try {
                    const url = await imageHandler(file);

                    handleChangeOfImage(existingImageUrl, url);

                  } catch (err) {

                  }
                },
                onRemove: (existingImageUrl) => {
                  handleRemoveOfNewImage(existingImageUrl)
                },
                onAddNew: async (_placeholder, file) => {
                  try {
                    const url = await imageHandler(file);

                    handleAddNewImage(url);

                  } catch (err) {

                  }
                },
                onRemoveNew: (existingImageUrl) => {
                  handleRemoveOfNewImage(existingImageUrl)

                },
                onChangeText,
                changeButton: {
                  disabled: isDisabled,
                  children: "Ajouter/changer",
                  size: "sm",
                  color: "lightBlue",
                  onClick: () => alert("add/change"),
                },
                removeButton: {
                  disabled: isDisabled,
                  children: "Supprimer",
                  size: "sm",
                  color: "lightBlue",
                  onClick: () => alert("remove"),
                }

              },
            },

          ],
        },
      ]
    }
  }



  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="flex flex-wrap">
            <div className="w-full  px-4">


              <CardSettingsLarge {...buildForm()} />


            </div >
          </div >
        </div >
      </div >
    </>
  )
}
