import {
  cumulAmount,
  handleFilterOrdersToStart,
  handleFilterOrdersOfMonth,
  handleFilterOrdersOf3Monthes,
  handleFilterOrdersOfYear,
  handleFilterOrdersOf2Years,
  handleFilterOrdersRunning,
} from "service/functions";

export const handleSetHeaderStatCardsOrder = (
  orders,
  advStatus,
  [headerstatcardsorder, setheaderstatcardsorders],
) => {
  advStatus === ":running"
    ? setheaderstatcardsorders({
        color: "accentBlue",
        cards: [
          {
            statSubtitle: "Commandes à démarrer",
            statTitle: `${
              handleFilterOrdersToStart(orders).length
            } / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersToStart(orders),
              ),
            )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le début de l'année",
            statIconName: "fas fa-percent",
            statIconColor: "bg-lightBlue-500",
          },
          {
            statSubtitle: "Commande en cours",
            statTitle: `${
              handleFilterOrdersRunning(orders).length
            } / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersRunning(orders),
              ),
            )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "fas fa-users",
            statIconColor: "bg-pink-500",
          },
          {
            statSubtitle: "COMMANDES du mois",
            statTitle: `${
              handleFilterOrdersOfMonth(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOfMonth(orders),
                ),
              )}`,
            statArrow: "down",
            statPercent: "",
            statPercentColor: "text-white-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "fas fa-chart-pie",
            statIconColor: "bg-agOrange-regular",
          },
          {
            statSubtitle: "Commandes de l'année",
            statTitle: `${
              handleFilterOrdersOfYear(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOfYear(orders),
                ),
              )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "far fa-chart-bar",
            statIconColor: "bg-red-500",
          },
        ],
      })
    : advStatus === ":finished"
    ? setheaderstatcardsorders({
        color: "accentBlue",
        cards: [
          {
            statSubtitle: "CMd terminées (mois)",
            statTitle: `${
              handleFilterOrdersOfMonth(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOfMonth(orders),
                ),
              )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le début de l'année",
            statIconName: "fas fa-percent",
            statIconColor: "bg-lightBlue-500",
          },
          {
            statSubtitle: "Cmd terminées (3 mois)",
            statTitle: `${
              handleFilterOrdersOf3Monthes(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOf3Monthes(orders),
                ),
              )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "fas fa-users",
            statIconColor: "bg-pink-500",
          },
          {
            statSubtitle: "Cmd terminées (année) ",
            statTitle: `${
              handleFilterOrdersOfYear(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOfYear(orders),
                ),
              )}`,
            statArrow: "down",
            statPercent: "",
            statPercentColor: "text-white-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "fas fa-chart-pie",
            statIconColor: "bg-agOrange-regular",
          },
          {
            statSubtitle: "cmd terminées (2 ans)",
            statTitle: `${
              handleFilterOrdersOf2Years(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOf2Years(orders),
                ),
              )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "far fa-chart-bar",
            statIconColor: "bg-red-500",
          },
        ],
      })
    : setheaderstatcardsorders({
        color: "accentBlue",
        cards: [
          {
            statSubtitle: "Affaires KO du mois",
            statTitle: `${
              handleFilterOrdersOfMonth(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOfMonth(orders),
                ),
              )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le début de l'année",
            statIconName: "fas fa-percent",
            statIconColor: "bg-lightBlue-500",
          },
          {
            statSubtitle: "Affaires KO (3 mois)",
            statTitle: `${
              handleFilterOrdersOf3Monthes(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOf3Monthes(orders),
                ),
              )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "fas fa-users",
            statIconColor: "bg-pink-500",
          },
          {
            statSubtitle: "Affaires KO de l'année",
            statTitle: `${
              handleFilterOrdersOfYear(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOfYear(orders),
                ),
              )}`,
            statArrow: "down",
            statPercent: "",
            statPercentColor: "text-white-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "fas fa-chart-pie",
            statIconColor: "bg-agOrange-regular",
          },
          {
            statSubtitle: "Affaires KO (2 années)",
            statTitle: `${
              handleFilterOrdersOf2Years(orders).length
            } 
              / ${new Intl.NumberFormat("fr-FR", {
                style: "currency",
                currency: "EUR",
              }).format(
                cumulAmount(
                  handleFilterOrdersOf2Years(orders),
                ),
              )}`,
            statArrow: "up",
            statPercent: "",
            statPercentColor: "text-emerald-500",
            statDescripiron: "Depuis le mois dernier",
            statIconName: "far fa-chart-bar",
            statIconColor: "bg-red-500",
          },
        ],
      });
};
