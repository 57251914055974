import {
  cumulAmount,
  handleFilterOrdersTodo,
  handleFilterOrdersPurchased,
  handleFilterOrdersDelivered,
  handleFilterOrdersPlanified,
  handleFilterOrdersOfMonth,
  handleFilterOrdersOf3Monthes,
  handleFilterOrdersOfYear,
  handleFilterOrdersOf2Years,
} from "service/functions";

export const handleSetHeaderStatCards = (
  orders,
  advStatus,
  typeOfOrder,
  [
    headerstatcardssitesortroubleshoots,
    setheaderstatcardssitesortroubleshoots,
  ],
) => {
  if (!advStatus) {
    const ordersTodo = handleFilterOrdersTodo(orders);
    const ordersPurchased = handleFilterOrdersPurchased(
      orders,
    );
    const ordersDelivered = handleFilterOrdersDelivered(
      orders,
    );
    const ordersPlanified = handleFilterOrdersPlanified(
      orders,
    );

    setheaderstatcardssitesortroubleshoots({
      color: "accentBlue",
      cards: [
        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "à planifier / à commander"
              : "à planifier / à commander",
          statTitle: `${ordersTodo.length} 
            / 
            ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(cumulAmount(ordersTodo))}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "far fa-chart-bar",
          statIconColor: "bg-red-500",
        },

        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "à planifier / mat. commandé"
              : "à planifier / mat. commandé",
          statTitle: `${
            ordersPurchased.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(cumulAmount(ordersPurchased))}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-users",
          statIconColor: "bg-pink-500",
        },
        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "à planifier / matériel reçu"
              : "à planifier / matériel reçu",
          statTitle: `${
            ordersDelivered.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(cumulAmount(ordersDelivered))}`,
          statArrow: "down",
          statPercent: "",
          statPercentColor: "text-white-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-chart-pie",
          statIconColor: "bg-agOrange-regular",
        },
        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "planifié / reçu"
              : "planifié / reçu",
          statTitle: `${
            ordersPlanified.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
          }).format(cumulAmount(ordersPlanified))}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le début de l'année",
          statIconName: "fas fa-percent",
          statIconColor: "bg-lightBlue-500",
        },
      ],
    });
  } else if (advStatus === ":finished") {
    setheaderstatcardssitesortroubleshoots({
      color: "accentBlue",
      cards: [
        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "Chantiers terminés (mois)"
              : "Dépannages terminé (mois)",
          statTitle: `${
            handleFilterOrdersOfMonth(orders).length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersOfMonth(orders),
              ),
            )}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le début de l'année",
          statIconName: "fas fa-percent",
          statIconColor: "bg-lightBlue-500",
        },
        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "Chantiers terminés (3 mois)"
              : "Dépannages terminé (3 mois)",
          statTitle: `${
            handleFilterOrdersOf3Monthes(orders).length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersOf3Monthes(orders),
              ),
            )}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-users",
          statIconColor: "bg-pink-500",
        },
        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "Chantiers terminés (année)"
              : "Dépannages terminé (année)",
          statTitle: `${
            handleFilterOrdersOfYear(orders).length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(handleFilterOrdersOfYear(orders)),
            )}`,
          statArrow: "down",
          statPercent: "",
          statPercentColor: "text-white-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "fas fa-chart-pie",
          statIconColor: "bg-agOrange-regular",
        },
        {
          statSubtitle:
            typeOfOrder === ":sites"
              ? "Chantiers terminés (2 ans)"
              : "Dépannages terminé (2 ans)",
          statTitle: `${
            handleFilterOrdersOf2Years(orders).length
          } 
            / ${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(
              cumulAmount(
                handleFilterOrdersOf2Years(orders),
              ),
            )}`,
          statArrow: "up",
          statPercent: "",
          statPercentColor: "text-emerald-500",
          statDescripiron: "Depuis le mois dernier",
          statIconName: "far fa-chart-bar",
          statIconColor: "bg-red-500",
        },
      ],
    });
  }
};
export default handleSetHeaderStatCards;
