
import { countByYearNMonth, turnoverByYearNMonth } from "service/functions";
export const handleSetCardChartJS = (orders, [cardchartjsdashboardtotalorders, setCardchartjsdashboardtotalorders], [cardchartjsdashboardturnover, setCardchartjsdashboardturnover]) => {
  setCardchartjsdashboardtotalorders({
    title: "Nombre total commandes",
    subtitle: "Performance",
    chart: {
      type: "bar",
      data: {
        labels: [
          "Janvier",
          "Fevrier",
          "Mars",
          "April",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        datasets: [
          {
            label: new Date().getFullYear() - 1,
            fill: false,
            backgroundColor: "#4c51bf",
            borderColor: "#4c51bf",
            // data: countGroupedOrdersYearLess1,
            data: countByYearNMonth(orders)[1],

            barThickness: 8,
          },
          {
            label: new Date().getFullYear(),
            backgroundColor: "#ed64a6",
            borderColor: "#ed64a6",
            data: countByYearNMonth(orders)[0],
            fill: false,
            barThickness: 8,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            labels: {
              color: "rgba(0,0,0,.4)",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            position: "average",
          },
        },
        scales: {
          x: {
            display: false,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(0,0,0,.4)",
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(0,0,0,.4)",
            },
            grid: {
              drawBorder: false,
              borderDash: [2],
              color: "rgba(33, 37, 41, 0.3)",
            },
          },
        },
      },
    },
  });

  setCardchartjsdashboardturnover({
    title: "Chiffre d'affaires €",
    subtitle: "Overview",
    color: "blueGray",
    chart: {
      type: "line",
      data: {
        labels: [
          "Janv",
          "Fev",
          "Mars",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Août",
          "Sept",
          "Oct",
          "Nov",
          "Déc",
        ],
        datasets: [
          {
            label: new Date().getFullYear() - 1,
            backgroundColor: "#4c51bf",
            borderColor: "#4c51bf",
            data: turnoverByYearNMonth(orders)[1],
            fill: false,
            tension: 0.4,
          },
          {
            label: new Date().getFullYear(),
            backgroundColor: "#ed64a6",
            borderColor: "#ed64a6",
            data: turnoverByYearNMonth(orders)[0],
            fill: false,
            tension: 0.4,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        interaction: {
          intersect: false,
          mode: "index",
        },
        plugins: {
          legend: {
            labels: {
              color: "#FFF",
            },
            align: "end",
            position: "bottom",
          },
          tooltips: {
            position: "average",
          },
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(255,255,255,.7)",
            },
            grid: {
              display: false,
            },
          },
          y: {
            display: true,
            title: {
              display: true,
            },
            ticks: {
              color: "rgba(255,255,255,.7)",
            },
            grid: {
              drawBorder: false,
              borderWidth: 2,
              borderDash: [3],
              borderDashOffset: [3],
              color: "rgba(255, 255, 255, 0.15)",
            },
          },
        },
      },
    },
  });
};

export default handleSetCardChartJS;