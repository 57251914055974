import {
	deleteConstructionSite,
	deleteTroubleShoot,
	getConstructionSiteById,
	getTroubleShootById,
	updateConstructionSite,
	updateOrder,
	updateTroubleShoot,
} from "api/orders";
import {
	getAllEstimateManagers,
	getAllSiteManagers,
	getAllTechnicalUsers,
} from "api/users";
import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge.js";
// import CardDashboardProfile from "components/Cards/Admin/CardDashboardProfile.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
// components
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	ADVANCEMENT_STATUSES,
	siteStatusCorres,
	siteStatusReverseCorres,
	SITE_STATUSES,
	typeOfBusinessReverseCorres,
} from "types/baseTypes";
// import headerstatcards from "_texts/admin/headers/headerstatcards.js";
// import cardsettingslarge from "_texts/admin/cards/cardsettingslarge.js";
// import carddashboardprofile from "_texts/admin/cards/carddashboardprofile.js";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import AppContext from "../../Contexts/AppContext";

export default function UpdateRunningOrder() {
	const location = useLocation();
	const history = useHistory();
	let { id, typeOfOrder, disabled } = useParams();
	const {
		user,
		isAdmin,
		isSuperAdmin,
		selectedAgency,
		displayErrorMessage,
		displaySuccessMessage,
	} = useContext(AppContext);

	const [orderPlus, setOrderPlus] = useState({});
	const [newSiteStatus, setNewSiteStatus] = useState();
	const [isLoaded, setIsLoaded] = useState(false);
	const [dateCompleteSite, setDateCompleteSite] = useState(null);
	const [estimateManagers, setEstimateManagers] = useState([]);
	const [technicalUsers, setTechnicalUsers] = useState([]);
	const [siteManagers, setSiteManagers] = useState([]);

	const isAllowedDelete = isAdmin || isSuperAdmin;
	const isDisabled = !!disabled;

	const handleGetEstimateManagers = async () => {
		try {
			const users = await getAllEstimateManagers(selectedAgency);
			setEstimateManagers(users);
		} catch (err) {
			console.log("err estimate users", err);
		}
	};

	const handleGetTechnicalUsers = async () => {
		try {
			const users = await getAllTechnicalUsers(selectedAgency);
			setTechnicalUsers(users);
		} catch (err) {
			console.log("err estimate users", err);
		}
	};
	const handleGetSiteManagers = async () => {
		try {
			const users = await getAllSiteManagers(selectedAgency);
			setSiteManagers(users);
		} catch (err) {
			console.log("err estimate users", err);
		}
	};

	useEffect(() => {
		handleGetEstimateManagers();
		handleGetTechnicalUsers();
		handleGetSiteManagers();
	}, [selectedAgency]);

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	const handleGetOrderById = async () => {
		let dataPlus = {};
		try {
			if (typeOfOrder === ":sites") {
				dataPlus = await getConstructionSiteById(id);
			} else {
				dataPlus = await getTroubleShootById(id);
			}
			setOrderPlus({
				...dataPlus,
				materialOrderDate:
					dataPlus.materialOrderDate &&
					moment(dataPlus.materialOrderDate).format("YYYY-MM-DD"),
				supplier: dataPlus.supplier,
				deliveryDate:
					dataPlus.deliveryDate &&
					moment(dataPlus.deliveryDate).format("YYYY-MM-DD"),
				objectifDateStart:
					dataPlus.objectifDateStart &&
					moment(dataPlus.objectifDateStart).format("YYYY-MM-DD"),
				objectifDateEnd:
					dataPlus.objectifDateEnd &&
					moment(dataPlus.objectifDateEnd).format("YYYY-MM-DD"),
			});
			setNewSiteStatus(dataPlus.siteStatus || 0);
			setDateCompleteSite(
				dataPlus.orderId?.dateCompleteSite
					? moment(dataPlus.orderId?.dateCompleteSite).format("YYYY-MM-DD")
					: null,
			);

			setIsLoaded(true);
		} catch (err) {
			displayErrorMessage(
				"Impossible de récuperer les information liés à cette commande.",
			);
		}
	};

	useEffect(() => {
		handleGetOrderById();
	}, []);

	const isStarting = newSiteStatus === SITE_STATUSES.TO_DO;

	const isPurchased = newSiteStatus === SITE_STATUSES.PURCHASED;
	const isDelivered = newSiteStatus === SITE_STATUSES.DELIVERED;

	const toBePlannedAndMaterialToBeOrdered =
		newSiteStatus === SITE_STATUSES.PLANIFIED_MATERIAL_TO_ORDER;

	const toBePlannedAndMaterialOrdered =
		newSiteStatus === SITE_STATUSES.PLANIFIED_MATERIAL_ORDERED;

	const isOk = newSiteStatus === SITE_STATUSES.PLANIFIED;

	const isFinished = newSiteStatus === SITE_STATUSES.FINISHED;

	const wasStarting = orderPlus.siteStatus === SITE_STATUSES.TO_DO;

	const wasPurchased = orderPlus.siteStatus === SITE_STATUSES.PURCHASED;

	const toBeInvoiced = orderPlus.siteStatus === SITE_STATUSES.TO_BE_INVOICED;

	// const wasDelivered =
	// orderPlus.siteStatus === SITE_STATUSES.DELIVERED;

	const wasOk = orderPlus.siteStatus === SITE_STATUSES.PLANIFIED;

	const wasFinished = orderPlus.siteStatus === SITE_STATUSES.FINISHED;

	const validateUpdateOrderPlus = (msg, display = true) => {
		const {
			bt,
			technicians,
			objectifDateStart,
			objectifDateEnd,
			materialOrderDate,
			deliveryDate,
			supplier,
		} = { ...orderPlus };
		function disPlay() {
			if (display) displayErrorMessage(msg);
		}
		if (!bt || isNaN(bt) || bt.toString().length !== 4) {
			disPlay();
			return false;
		}

		if (
			(isPurchased || isDelivered || isOk || isFinished) &&
			(!materialOrderDate || !supplier)
		) {
			disPlay();
			return false;
		}
		if ((isDelivered || isOk || isFinished) && !deliveryDate) {
			disPlay();
			return false;
		}
		if (
			(isOk || isFinished) &&
			(!objectifDateEnd || !objectifDateStart || !technicians)
		) {
			disPlay();
			return false;
		}
		if (isFinished && !dateCompleteSite) {
			disPlay();

			return false;
		}
		return true;
	};

	const handleUpdateOrderPlus = async () => {
		const changedSiteStatus = handleChangeSiteStatus();

		try {
			const msg =
				"Veuillez completer tous les champs obligatoires avant de modifier cette commande.";

			if (!validateUpdateOrderPlus(msg)) return;

			const consolidatedOrderPlus = {
				...orderPlus,
				siteStatus: !!changedSiteStatus ? changedSiteStatus : newSiteStatus,
			};
			consolidatedOrderPlus.orderId = orderPlus?.orderId?._id;

			if (typeOfOrder === ":sites") {
				await updateConstructionSite(orderPlus._id, consolidatedOrderPlus);
			}
			await updateTroubleShoot(orderPlus._id, consolidatedOrderPlus);
			if (isFinished) {
				const newOrder = {
					...orderPlus.orderId,
					advancementStatus: ADVANCEMENT_STATUSES.FINISHED,
					dateCompleteSite,
				};
				await updateOrder(orderPlus.orderId._id, newOrder);
			} else if (orderPlus.objectifDateEnd) {
				const dateCompleteSite = orderPlus.objectifDateEnd;
				const newOrder = {
					...orderPlus.orderId,
					dateCompleteSite,
				};
				await updateOrder(orderPlus.orderId._id, newOrder);
			}

			displaySuccessMessage();
			history.push(`/list-sites-or-trouble-shoots/${typeOfOrder}`);
		} catch (err) {
			displayErrorMessage();
		}
	};
	const handleChangeSiteStatus = () => {
		let toChangeSiteStatus = null;
		const {
			technicians,
			objectifDateStart,
			objectifDateEnd,
			materialOrderDate,
			deliveryDate,
			supplier,
			dateCompleteSite,
		} = orderPlus;

		if (
			materialOrderDate &&
			supplier &&
			deliveryDate &&
			objectifDateStart &&
			objectifDateEnd &&
			technicians &&
			dateCompleteSite &&
			newSiteStatus < SITE_STATUSES.FINISHED
		) {
			toChangeSiteStatus = SITE_STATUSES.FINISHED;
		} else if (
			materialOrderDate &&
			supplier &&
			deliveryDate &&
			objectifDateStart &&
			objectifDateEnd &&
			technicians &&
			newSiteStatus < SITE_STATUSES.PLANIFIED
		) {
			toChangeSiteStatus = SITE_STATUSES.PLANIFIED;
		} else if (
			materialOrderDate &&
			supplier &&
			deliveryDate &&
			newSiteStatus < SITE_STATUSES.DELIVERED
		) {
			toChangeSiteStatus = SITE_STATUSES.DELIVERED;
		} else if (
			materialOrderDate &&
			supplier &&
			newSiteStatus < SITE_STATUSES.PURCHASED
		) {
			toChangeSiteStatus = SITE_STATUSES.PURCHASED;
		} else {
			return;
		}
	};
	const isBtWarning =
		!orderPlus.bt ||
		isNaN(orderPlus.bt) ||
		orderPlus.bt.toString().length !== 4;
	const isButtonDisabled = !validateUpdateOrderPlus("", false);

	const handleDeleteSite = async () => {
		try {
			await deleteConstructionSite(id);
			history.push(`/list-sites-or-trouble-shoots/${typeOfOrder}`);
		} catch (err) {
			console.log("err delting new business", err);
		}
	};

	const handleDeleteTroubleShoot = async () => {
		try {
			await deleteTroubleShoot(id);
		} catch (err) {
			console.log("err delting new business", err);
		}
	};

	const handleDeletion = async () => {
		try {
			if (typeOfOrder === ":sites") {
				await handleDeleteSite();
			} else {
				await handleDeleteTroubleShoot();
			}
		} catch (err) {
		} finally {
			history.push(`/list-sites-or-trouble-shoots/${typeOfOrder}`);
		}
	};

	const buildForm = () => {
		const buttonTitle = "Modifier";

		const title =
			typeOfOrder === ":sites" && !isFinished
				? "Éditer le chantier"
				: typeOfOrder === ":sites" && isFinished
				? "Chantier terminé"
				: typeOfOrder === ":trouble-shoots" && !isFinished
				? "Éditer l'intervention"
				: "Intervention terminé";

		const deleteButton =
			isAllowedDelete && id && !isDisabled
				? {
						children: "SUPPRIMER",
						size: "sm",
						color: "red",
						onClick: handleDeletion,
				  }
				: null;

		const technicalReportButton = {
			children: "Relevé technique",
			size: "sm",
			color: "lightBlue",
			onClick: () =>
				history.push(
					`/list-technical-reports/${orderPlus?.orderId?.newBusinessId}/disabled`,
				),
		};

		const techniciansReportButton =
			orderPlus.siteStatus >= SITE_STATUSES.PLANIFIED
				? {
						children: "Compte rendu",
						size: "sm",
						color: "lightBlue",
						onClick: () => {
							// const suffix = order.advancementStatus >= ADVANCEMENT_STATUSES.RUNNING
							//   ? "/disabled"
							//   : ""
							history.push({
								pathname: `/list-technicians-report`,
								state: {
									type:
										typeOfOrder === ":sites"
											? "CONSTRUCTION_SITE"
											: "TROUBLESHOOTS",
									id: id,
									techniciansReports: orderPlus?.techniciansReports || [],
								},
							});
						},
				  }
				: null;

		const siteSupervisionButton = {
			children: "Suivi de chantier",
			size: "sm",
			color: "lightBlue",
			onClick: () => {
				// const suffix = order.advancementStatus >= ADVANCEMENT_STATUSES.RUNNING
				//   ? "/disabled"
				//   : ""
				history.push({
					pathname: `/list-site-supervisions`,
					state: {
						type:
							typeOfOrder === ":sites" ? "CONSTRUCTION_SITE" : "TROUBLESHOOTS",
						id: id,
						siteSupervisions: orderPlus?.siteSupervisions || [],
					},
				});
			},
		};

		return {
			title,
			deleteButton,
			button: !isDisabled && {
				children: buttonTitle,
				disabled: isButtonDisabled,
				size: "sm",
				color: !isButtonDisabled ? "lightBlue" : "darkGrey",
				onClick: handleUpdateOrderPlus,
			},
			button2: technicalReportButton,
			button3: siteSupervisionButton,
			button4: techniciansReportButton,
			forms: [
				{
					title: "",
					inputs: [
						{
							label:
								typeOfOrder === ":sites"
									? `Chantier (4 chiffres)`
									: `BT (4 chiffres)`,
							width: 6,
							input: {
								// disabled: isDisabled || (!wasStarting && !isStarting),
								disabled: isDisabled || toBeInvoiced,
								border:
									!orderPlus.bt ||
									isNaN(orderPlus.bt) ||
									orderPlus.bt.toString().length !== 4
										? "borderwarning"
										: "border",
								placeholder: "",
								type: "text",
								defaultValue: orderPlus.bt,
								value: orderPlus.bt,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										bt: e.target.value.toString().slice(0, 4),
									})),
							},
						},
						{
							label: "Statut",
							width: 6,
							select: {
								defaultValue: siteStatusReverseCorres[orderPlus.siteStatus],
								// siteStatusReverseCorres[newSiteStatus],
								// value:
								//   siteStatusReverseCorres[newSiteStatus],
								disabled: isDisabled || wasFinished,
								items: Object.keys(siteStatusCorres).filter((value) => {
									return true;

									// const siteStatusNb =
									//   orderPlus.siteStatus ||
									//   SITE_STATUSES.TO_DO;

									// return (
									//   SITE_STATUSES[
									//   siteStatusCorres[value]
									//   ] >= siteStatusNb
									// );
								}),
								onChange: (e) =>
									setNewSiteStatus(SITE_STATUSES[siteStatusCorres[e]]),
							},
						},

						{
							label: `Date de Commande${
								isPurchased && !orderPlus.materialOrderDate ? "*" : ""
							}`,
							width: 6,
							input: {
								// disabled: isDisabled || (!wasStarting && !isStarting),
								disabled: isDisabled || toBeInvoiced,
								border:
									!isStarting &&
									!toBePlannedAndMaterialToBeOrdered &&
									!orderPlus.materialOrderDate
										? "borderwarning"
										: "border",
								placeholder: "",
								type: "date",
								defaultValue: orderPlus.materialOrderDate,
								value: orderPlus.materialOrderDate,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										materialOrderDate: e.target.value,
									})),
							},
						},
						{
							label: `Fournisseur ${
								isPurchased && !orderPlus.supplier ? "*" : ""
							}`,
							width: 6,
							input: {
								// disabled: isDisabled || (!wasStarting && !isStarting),
								disabled: isDisabled || toBeInvoiced,
								placeholder: "",
								border:
									!isStarting &&
									!toBePlannedAndMaterialToBeOrdered &&
									!orderPlus.supplier
										? "borderwarning"
										: "border",
								type: "text",
								defaultValue: orderPlus.supplier,
								value: orderPlus.supplier,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										supplier: e.target.value,
									})),
							},
						},
						{
							label: `Livraison ${
								isDelivered && !orderPlus.deliveryDate ? "*" : ""
							}`,

							width: 6,
							input: {
								// disabled:
								// 	isDisabled ||
								// 	(!(wasStarting || wasPurchased) &&
								// 		!(isStarting || isPurchased)),
								disabled: isDisabled || toBeInvoiced,
								border:
									!(isStarting || isPurchased) &&
									!toBePlannedAndMaterialToBeOrdered &&
									!orderPlus.deliveryDate
										? "borderwarning"
										: "border",
								placeholder: "",
								type: "date",
								defaultValue: orderPlus.deliveryDate,
								value: orderPlus.deliveryDate,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										deliveryDate: e.target.value,
									})),
							},
						},
						{
							label: `Planification début chantier ${
								isOk && !orderPlus.objectifDateStart ? "*" : ""
							}`,

							width: 6,
							input: {
								// disabled:
								// 	isDisabled ||
								// 	((wasOk || wasFinished) && (isOk || isFinished)),
								disabled: isDisabled || toBeInvoiced,
								border:
									(isOk || isFinished || toBePlannedAndMaterialToBeOrdered) &&
									!orderPlus.objectifDateStart
										? "borderwarning"
										: "border",
								placeholder: "",
								type: "date",
								defaultValue: orderPlus.objectifDateStart,
								value: orderPlus.objectifDateStart,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										objectifDateStart: e.target.value,
									})),
							},
						},
						{
							label: `Planification fin chantier ${
								isOk && !orderPlus.objectifDateEnd ? "*" : ""
							}`,

							width: 6,
							input: {
								// disabled:
								// isDisabled ||
								// ((wasOk || wasFinished) && (isOk || isFinished)),
								disabled: isDisabled || toBeInvoiced,
								border:
									(isOk || isFinished || toBePlannedAndMaterialToBeOrdered) &&
									!orderPlus.objectifDateEnd
										? "borderwarning"
										: "border",
								placeholder: "",
								type: "date",
								defaultValue: orderPlus.objectifDateEnd,
								value: orderPlus.objectifDateEnd,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										objectifDateEnd: e.target.value,
									})),
							},
						},
						{
							label: `Techniciens ${isOk && !orderPlus.technicians ? "*" : ""}`,
							width: 6,
							select: {
								disabled: isDisabled,
								multi: true,
								placeholder: "",
								border: "border",
								items: technicalUsers.map((value) => value.userPrincipalName),
								value: orderPlus.technicians,
								defaultValue: orderPlus.technicians,
								onChange: (e) => {
									if (typeof e === "string") {
										setOrderPlus((vals) => ({
											...vals,
											technicians: e,
										}));
									}
								},
							},
						},
						isFinished &&
							!wasFinished && {
								label: `Date fin chantier ${!dateCompleteSite ? "*" : ""}`,
								width: 6,
								input: {
									disabled: isDisabled,
									placeholder: "",
									border: !dateCompleteSite ? "borderwarning" : "border",
									type: "date",
									defaultValue: dateCompleteSite,
									value: dateCompleteSite,
									onChange: (e) => setDateCompleteSite(e.target.value),
								},
							},
						{
							label: "Problèmes",
							width: 6,
							input: {
								disabled: isDisabled || wasFinished,
								placeholder: "",
								type: "textarea",
								defaultValue: orderPlus.problem,
								value: orderPlus.problem,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										problem: e.target.value,
									})),
							},
						},

						{
							label: "Remarques",
							width: 12,
							input: {
								disabled: isDisabled,
								placeholder: "",
								type: "textarea",
								defaultValue: orderPlus?.orderId?.comment,
								rows: 2,
								value: orderPlus?.orderId?.comment,
								onChange: (e) =>
									setOrderPlus((vals) => {
										const { orderId = {} } = vals;

										return {
											...vals,
											comment: e.target.value,
											orderId: {
												...orderId,
												comment: e.target.value,
											},
										};
									}),
							},
						},
						wasFinished && {
							label: "Date fin chantier",
							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								type: "date",
								value: dateCompleteSite,
							},
						},
						{
							label: "Type de client",
							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								value: orderPlus?.clientId?.typeOfClient,
							},
						},
						{
							label: "Responsable chantier",
							width: 6,
							select: {
								disabled: isDisabled,
								placeholder: "",
								items: siteManagers.map((value) => value.userPrincipalName),
								value: orderPlus?.orderId?.siteManager,
								defaultValue: orderPlus?.orderId?.siteManager,
								onChange: (e) => {
									if (typeof e === "string") {
										setOrderPlus((vals) => ({
											...vals,
											orderId: {
												...(vals?.orderId || {}),
												siteManager: e,
											},
										}));
									}
								},
							},
							// input: {
							//   placeholder: "",
							//   disabled: true,
							//   type: "text",
							//   defaultValue:
							//     orderPlus?.orderId?.siteManager,

							//   value: orderPlus?.orderId?.siteManager,
							// },
						},

						orderPlus?.clientId?.typeOfClient === "Entreprise" && {
							label: "Nom de l'entreprise",

							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								type: "text",
								defaultValue: "",
								value: orderPlus?.clientId?.companyName,
							},
						},
						{
							label: "Nom du contact",
							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								type: "text",
								defaultValue: "",
								value: orderPlus?.clientId?.clientLastName,
							},
						},
						{
							label: "Prénom du contact",
							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								type: "text",
								defaultValue: "",
								value: orderPlus?.clientId?.clientFirstName,
							},
						},

						!isDisabled && {
							label: "Catégorie",
							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								value:
									typeOfBusinessReverseCorres[
										orderPlus?.orderId?.typeOfBusiness
									],
							},
						},
						!isDisabled && {
							label: "Origine de l'affaire",
							width: 6,
							background: "grey",
							input: {
								disabled: true,
								placeholder: "",
								value: orderPlus?.clientId?.origin,
							},
						},
					],
				},
				{
					title: "",
					inputs: [
						{
							label: "Responsable devis",
							width: 6,
							select: {
								disabled: isDisabled,
								placeholder: "",
								items: estimateManagers.map((value) => value.userPrincipalName),
								value: orderPlus?.orderId?.responsableOfInvoice,
								defaultValue: orderPlus?.orderId?.responsableOfInvoice,
								onChange: (e) =>
									setOrderPlus((vals) => ({
										...vals,
										orderId: {
											...(vals?.orderId || {}),

											responsableOfInvoice: e,
										},
									})),
								// onChange: (e) => {
								//   setNewBusiness((vals) => ({
								//     ...vals,
								//     responsableOfInvoice: e
								//   }));
								// },
							},
							// input: {
							//   disabled: true,
							//   placeholder: "",
							//   type: "text",
							//   // defaultValue: "",
							//   value:
							//     orderPlus?.orderId?.responsableOfInvoice,

							// },
						},
						!isDisabled && {
							label: "Date de signature",
							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								type: "date",
								defaultValue:
									orderPlus?.orderId?.dateOfSignature &&
									moment(orderPlus?.orderId?.dateOfSignature).format(
										"YYYY-MM-DD",
									),
								value:
									orderPlus?.orderId?.dateOfSignature &&
									moment(orderPlus?.orderId?.dateOfSignature).format(
										"YYYY-MM-DD",
									),
							},
						},
					],
				},
				{
					title: "",
					inputs: [
						!isDisabled && {
							label: "Montant HT €",
							width: 6,
							input: {
								disabled: true,
								placeholder: "",
								type: "number",
								defaultValue: "",
								value: orderPlus?.orderId?.amount,
							},
						},
						{
							label: "Status",
							width: 6,
							select: {
								placeholder: "",
								disabled: true,
								items: ["Devis accepté"],
								defaultValue: "Devis Accepté",
								value: "Devis Accepté",
							},
						},
					],
				},
			],
		};
	};

	return (
		<>
			<AuthSidebar />
			<div className="relative 2xl:ml-64 bg-blueGray-100">
				{/* <NavbarSearchUser {...navbarsearchusersettings2} /> */}
				<HeaderStatCards {...{ color: "accentBlue" }} />
				<div className="px-4 md:px-6 mx-auto w-full -mt-24">
					<div className="flex flex-wrap">
						<div className="w-full  px-4">
							{Object.keys(orderPlus)?.length > 0 && (
								<CardSettingsLarge {...buildForm()} />
							)}
						</div>
					</div>
					<FooterAdmin {...footeradmin} />
				</div>
			</div>
		</>
	);
}
