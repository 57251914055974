import { Role } from "utils/common";

const textProps = {
  image: require("assets/img/team-1-800x800.jpg").default,
  items: [
    {
      href: "#/profile",
      children: "Profil",
    },
    {
      href: "#/site-stats-agency",
      children: "Statistique agence",
      permissions: [Role.ADMIN, Role.SUPER_ADMIN]
    },
    {
      href: "#/logout",
      children: "Déconnexion",
    },
    // {
    //   divider: true,
    // },
    // {
    //   href: "#pablo",
    //   children: "Seprated link",
    // },
  ],
};
export default textProps;
