/* eslint-disable react-hooks/exhaustive-deps */
import { getInterventions } from "api/interventions";
import { getNewBusiness } from "api/newBusiness";
import CardFullTable from "components/Cards/Admin/CardFullTable.js";
// import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
// components
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import AppContext from "Contexts/AppContext";
import React, { useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
// service
import { handleSetHeaderStatCards } from "service/dashboard/HanldeSetHeaderStatCards";
import { sortData } from "service/functions";
import {
  advancementStatusReverseCorres, ADVANCEMENT_STATUSES, MAINTENANCE_CONTRACTS_STATUS, siteStatusReverseCorres, SITE_STATUSES, STATUSES, statusReverseCorres
} from "types/baseTypes";
import { Role } from "utils/common";
// import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
import headerstatcards from "_texts/admin/headers/headerstatcards.js";
import { getAllMaintenanceContracts } from '../../api/maintenanceContract';
import {
  getConstructionSites,
  getOrders,
  getTroubleShoots
} from "../../api/orders";
import Input from "../../components/Elements/Input";
// import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import { Breadcrumb } from "./BreadCrumb";

;


export default function ListingOrders() {
  const location = useLocation();
  const [data, setData] = useState([]);
  const { user, selectedAgency } = useContext(AppContext)
  // eslint-disable-next-line no-unused-vars
  const [concatedData, setConcatedData] = useState([]);
  const [transformedData, setTransformedData] = useState(
    [],
  );
  const [
    headerstatcardsdashboard,
    setheaderstatcardsdashboard,
  ] = useState(headerstatcards);
  const { advStatus } = useParams();
  const { queryParams } = useParams();
  const isTechnicalPersonnel = user?.department?.toLowerCase() === Role.SERVICE_TECHNIQUE?.toLowerCase();

  const isOver =
    advStatus === ":ko" || advStatus === ":finished";
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [
    entryOfDataToSortOn,
    setEntryOfDataToSortOn,
  ] = useState("dateOfSignature");
  const [typeToSort, setTypeToSort] = useState("date");
  const [isSortPositif, setIsSortPositif] = useState(true);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [rerender, setRerender] = useState(false);

  const cardfulltablesearch = {
    title: "Recherche",
    color: "white",
    head: [
      {
        onClick: () => { },
        string: "",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("clientLastName");
          setIsSortPositif(!isSortPositif);
        },
        string: "Nom",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("status");
          setIsSortPositif(!isSortPositif);
        },
        string: "Etat",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("typeOfCategory");
          setIsSortPositif(!isSortPositif);
        },
        string: "Typologie",
      },
    ],
    body: [],
  };
  const dropDownConfig = (typeOfCategory, elemId) => {
    const suffix = isTechnicalPersonnel ? "/disabled" : "";
    let url = "";
    if (typeOfCategory === "new-business")
      url = `#/update-new-business/${elemId}`
    else if (typeOfCategory === "order")
      url = `#/update-order/${elemId}`;
    else if (typeOfCategory === "site")
      url = `#/update-site-or-trouble-shoot/${elemId}/:sites${suffix}`;
    else if (typeOfCategory === "troubleshoot")
      url = `#/update-site-or-trouble-shoot/${elemId}/:trouble-shoots${suffix}`;
    else if (typeOfCategory === "intervention")
      url = `#/update-intervention/${elemId}`
    else if (typeOfCategory === "maintenanceContract")
      url = `#/add-maintenance-contracts/${elemId}`

    return {
      icon: isOver ? "fas fa-eye" : "fas fa-edit",
      button: {
        color: "white",
        size: "sm",
        href: url
      },
    }
  };

  const getStatusColor = (status) => {
    let color = "";

    switch (status) {
      case STATUSES.CREATED:
        color = "amber";
        break;
      case STATUSES.SENT:
        color = "lightBlue";
        break;
      case STATUSES.ACCEPTED:
        color = "emerald";
        break;
      case STATUSES.REFUSED:
        color = "red";
        break;

      default:
        color = "blueGray";
        break;
    }
    return color;
  };

  const getAdvancementStatusColor = (advancementStatus) => {
    let color = "";

    switch (advancementStatus) {
      case ADVANCEMENT_STATUSES.TO_START:
        color = "amber";
        break;
      case ADVANCEMENT_STATUSES.RUNNING:
        color = "lightBlue";
        break;
      case ADVANCEMENT_STATUSES.FINISHED:
        color = "emerald";
        break;
      case ADVANCEMENT_STATUSES.REFUSED:
        color = "red";
        break;

      default:
        color = "blueGray";
        break;
    }
    return color;
  };

  const getSiteStatusColor = (siteStatus) => {
    let color = "";

    switch (siteStatus) {
      case SITE_STATUSES.TO_DO:
        color = "red";
        break;
      case SITE_STATUSES.PURCHASED:
        color = "orange";
        break;
      case SITE_STATUSES.DELIVERED:
        color = "amber";
        break;
      case SITE_STATUSES.PLANIFIED:
        color = "lightBlue";
        break;
      case SITE_STATUSES.FINISHED:
        color = "emerald";
        break;
      default:
        color = "blueGray";
        break;
    }
    return color;
  };


  const transformDataIndividual = (data) => {
    const type = data.typeOfCategory;
    if (type === "maintenanceContract") {
      return [
        data?.clientId?.typeOfClient === "Entreprise"
          ? data?.clientId?.companyName
          : `${data?.clientId?.clientLastName} ${data?.clientId?.clientFirstName}`,
        {
          color: getStatusColor(data.status),
          text: MAINTENANCE_CONTRACTS_STATUS[data.status],
        },
        "Contrat d'entretien"
      ]
    }
    else {
      return [
        data?.clientId?.typeOfClient === "Entreprise"
          ? data?.clientId?.companyName
          : `${data?.clientId?.clientLastName} ${data?.clientId?.clientFirstName}`,
        typeof data.status === "number"
          ? {
            color: getStatusColor(data.status),
            text: statusReverseCorres[data.status],
          }
          : typeof data.advancementStatus === "number"
            ? {
              color: getAdvancementStatusColor(
                data.advancementStatus,
              ),
              text:
                advancementStatusReverseCorres[
                data.advancementStatus
                ],
            }
            : typeof data.siteStatus === "number" && {
              color: getSiteStatusColor(
                data.siteStatus || SITE_STATUSES.TO_DO,
              ),
              text:
                siteStatusReverseCorres[data.siteStatus] ||
                siteStatusReverseCorres[
                SITE_STATUSES.TO_DO
                ],
            },

        data.typeOfCategory === "new-business"
          ? "Affaire Nouvelle"
          : data.typeOfCategory === "order" &&
            data.status === STATUSES.ACCEPTED
            ? "Commande"
            : data.typeOfCategory === "order" &&
              data.status === STATUSES.REFUSED
              ? "Affaire KO"
              : data.typeOfCategory === "site"
                ? "Chantier"
                : data.typeOfCategory === "troubleshoot"
                  ? "Intervention"
                  : data.typeOfCategory === "intervention" &&
                  "Dépannage",
      ]
    }
  }

  const transformData = (data, index) => {
    return {
      rowProps: {
        bg: " ",
        // data.advancementStatus ===
        // ADVANCEMENT_STATUSES.PAUSED
        //   ? "bg-blueGray-200"
        //   : " ",
      },
      colProps: [
        {
          dropdown: {
            ...dropDownConfig(
              data.typeOfCategory,
              data._id,
            ),
          },
        },
        ...(transformDataIndividual(data) || [])
      ],
    };
  };

  const handleGetData = async () => {
    try {
      const newBusiness = await getNewBusiness(selectedAgency);
      newBusiness.forEach(
        (elt) => (elt.typeOfCategory = "new-business"),
      );
      let orders = await getOrders(selectedAgency);
      orders.forEach(
        (elt) => (elt.typeOfCategory = "order"),
      );
      let sites = await getConstructionSites(selectedAgency);
      sites.forEach((elt) => (elt.typeOfCategory = "site"));
      let troubleShoots = await getTroubleShoots(selectedAgency);
      troubleShoots.forEach(
        (elt) => (elt.typeOfCategory = "troubleshoot"),
      );
      let interventions = await getInterventions(selectedAgency);
      interventions.forEach(
        (elt) => (elt.typeOfCategory = "intervention"),
      );
      let maintenanceContracts = await getAllMaintenanceContracts(selectedAgency);
      maintenanceContracts.forEach(elt => elt.typeOfCategory = "maintenanceContract");

      const data = [
        newBusiness,
        orders,
        sites,
        troubleShoots,
        interventions,
        maintenanceContracts
      ];
      setData(data);

      const filteredData = filterData(data, queryParams?.toLowerCase());
      const concatedData = concatData(filteredData);

      const sortedData = sortData(
        concatedData,
        entryOfDataToSortOn,
        isSortPositif,
        typeToSort,
      );
      const transformedData = transformConcatedData(
        sortedData,
      );

      setTransformedData(transformedData);

      setIsLoaded(true);

      handleSetHeaderStatCards(
        orders,
        newBusiness,
        interventions,
        [
          headerstatcardsdashboard,
          setheaderstatcardsdashboard,
        ],
      );

      setIsReady(true);
      (!isLoaded || !isReady) && setRerender(!rerender);
    } catch (err) {
      console.log("err", err);
    }
  };

  const filterData = (data, query) => {
    const filterQuery = query?.toLowerCase();

    if (!filterQuery || filterQuery.length === 0) {
    } else {

      const filteredData = data.map((data) =>
        data.filter(
          (elt) =>
            elt?.clientId?.clientLastName
              ?.toLowerCase()
              .includes(filterQuery) ||
            elt?.clientId?.companyName
              ?.toLowerCase()
              .includes(filterQuery) ||
            elt?.clientId?.clientFirstName
              ?.toLowerCase()
              .includes(filterQuery),
        ),
      );
      return filteredData;
    }
  };
  const concatData = (filteredData) => {
    const concatedData = filteredData?.reduce(
      (acc, curr) => acc.concat(curr),
      [],
    );
    setConcatedData(concatedData);
    return concatedData;
  };

  const transformConcatedData = (concatedData) => {
    const transformedData = concatedData?.map(
      transformData,
    );
    return transformedData;
  };

  const filterConcatAndTransformData = (data, query) => {
    const filteredData = filterData(data, query);
    const concatedData = concatData(filteredData);
    const sortedData = sortData(
      concatedData,
      entryOfDataToSortOn,
      isSortPositif,
      typeToSort,
    );
    const transformedData = transformConcatedData(
      sortedData,
    );
    setTransformedData(transformedData);
    return transformedData;
  };

  React.useEffect(() => {
    handleGetData();
  }, [advStatus, rerender, isSortPositif, queryParams]);


  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        {/* <NavbarSearchUser {...navbarsearchusersettings2} /> */}
        {
          isTechnicalPersonnel
            ? (
              <div style={{ marginTop: "6rem" }} ></div>
            )
            : <HeaderStatCards {...headerstatcardsdashboard} />
        }
        <div className="w-full px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="w-full flex flex-wrap">
            <div className="px-4 flex flex-nowrap items-center justify-between w-full">
              <Input
                {...{
                  placeholder: "Recherche",
                  type: "text",
                  onChange: (e) =>
                    filterConcatAndTransformData(
                      data,
                      e.target.value,
                    ),
                }}
              />
              <Breadcrumb
                location={location}
                pageName={cardfulltablesearch.title}
              />
            </div>
            <div className="w-full px-4">
              <CardFullTable
                {...cardfulltablesearch}
                body={transformedData}
              />
            </div>
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  );
}
