import PropTypes from "prop-types";
import React from "react";
// import { Link } from "react-router-dom";
// components
import MicrosoftCard from "../Auth/CardMicrosoftLogin";

export default function CardLoginAgPlus({ title, logo, button, onPress }) {
  return (
    <>
      <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
        <div className="mb-0 px-6 py-6">
          <div className="text-center mb-3"></div>
          <div className="text-center">
            <img src={logo.src} alt={logo.alt}></img>
            <p className="font-bold" >{ logo.text}</p>
          </div>
          <hr className="mt-6 border-b-1 border-darkGrey-200" />
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <div className="text-darkGrey-500 text-center mb-3 font-bold">
            <small>{title}</small>
          </div>
          <form>
            <div className="flex justify-center mt-5">
              <MicrosoftCard onClick={onPress} />
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

CardLoginAgPlus.defaultProps = {
  inputs: [],
  button: {},
};

CardLoginAgPlus.propTypes = {
  logo: PropTypes.object,
  title: PropTypes.string,
  button: PropTypes.object,
};
