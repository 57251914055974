import AppContext from "Contexts/AppContext";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
import { getAllAgencies } from "api/agency";
import {
	addMaintenanceContract,
	deleteMaintenanceContract,
	getMaintenanceContractsById,
	updateMaintenanceContract,
} from "api/maintenanceContract";
import { getClients } from "api/newBusiness";
import { getAllTechnicalUsers } from "api/users";
import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from "components/Sidebar/AuthSidebar";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { capitalizeFirstLetter } from "service/functions";
import {
	MAINTENANCE_CONTRACTS_STATUS,
	MAINTENANCE_CONTRACTS_STATUS_REVERSE,
	TYPE_OF_BUSINESS,
	statusReverseCorres,
	typeOfBusinessCorres,
	typeOfBusinessReverseCorres,
} from "types/baseTypes";
import { getPostalCode } from "utils/common";

export default function AddUpdateMaintenanceContract() {
	const [maintenanceContract, setMaintenanceContract] = useState({
		clientId: {},
		dates: [],
	});
	const [technicalUsers, setTechnicalUsers] = useState([]);
	const history = useHistory();
	const {
		user,
		selectedAgency,
		isSuperAdmin,
		displayErrorMessage,
		displaySuccessMessage,
		isAdmin,
	} = useContext(AppContext);
	let { id, disabled } = useParams();

	const isAdd = !id;
	const [agencies, setAgencies] = useState([]);
	const [chosenAgency, setChosenAgency] = useState("");
	const [initialAgencies, setInitialAgencies] = useState([]);
	const [existingClient, setExistingClient] = useState(isAdd ? false : true);
	const [initClients, setInitClients] = useState([]);
	const [existingClients, setExistingClients] = useState([]);
	const [existingClientIdx, setExistingClientIdx] = useState(0);
	const isDisabled = !!disabled;

	const title = isAdd
		? "Ajouter contrat d'entretien"
		: "Mis à jour contrat d'entretien";

	const isAllowedDelete = isAdmin || isSuperAdmin;

	const isAllowedShowAgencySelection =
		!isAdd && !maintenanceContract?.agencyFound && isSuperAdmin;

	useEffect(() => {
		const agencyFound = initialAgencies.find(
			(a) => a._id === maintenanceContract?.agencyId,
		);

		setChosenAgency(agencyFound?.name);
	}, [maintenanceContract, initialAgencies]);

	const handleGetAllAgencies = async () => {
		try {
			const data = await getAllAgencies();
			setInitialAgencies(data);
			setAgencies(data.map((d) => d.name));
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleAgencySelect = (sAgency) => {
		const agencyFound = initialAgencies.find((a) =>
			a.name.toLowerCase().includes(sAgency.toLowerCase()),
		);

		if (agencyFound) {
			setMaintenanceContract((vals) => ({
				...vals,
				agencyId: agencyFound?._id,
			}));

			setChosenAgency(sAgency);
		}
	};

	const handleValidationOfBusiness = async () => {
		try {
			if (
				!maintenanceContract?.agencyId ||
				!maintenanceContract?.clientId?.address
			) {
				displayErrorMessage("Veuillez compléter le formulaire");
				return;
			}

			await updateMaintenanceContract(maintenanceContract._id, {
				...maintenanceContract,
				needsValidation: false,
			});
			history.push("/");
		} catch (err) {
			console.log("err", err);
			displayErrorMessage();
		}
	};

	useEffect(() => {
		handleGetAllAgencies();
	}, []);

	useEffect(() => {
		handleGetTechnicians();
		if (id) {
			handleGetMaintenanceContract();
		} else initMaintenanceContract();
	}, []);

	useEffect(() => {
		handleGetExisingClients();
	}, [selectedAgency]);

	const handleGetExisingClients = async () => {
		try {
			let data = (await getClients(selectedAgency)) || [];
			setInitClients(data);

			if (isAdd) {
				const first = data[0];
				setMaintenanceContract((vals) => ({ ...vals, clientId: { ...first } }));
			}

			data = data.map((d, idx) => ({
				name: `${d.typeOfClient} | ${
					d.typeOfClient === "Entreprise"
						? d.companyName
						: `${d.clientLastName} ${d.clientFirstName}`
				}`,
				value: idx,
			}));
			setExistingClients(data);
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleSetExistingClient = (idx) => {
		setMaintenanceContract((vals) => ({
			...vals,
			clientId: {
				typeOfClient: "",
				companyName: "",
				clientLastName: "",
				clientFirstName: "",
				address: "",
			},
			agencyId: "",
		}));

		const data = initClients[idx] || {};
		setExistingClientIdx(idx);

		setMaintenanceContract((vals) => ({ ...vals, clientId: { ...data } }));
	};

	const handleGetTechnicians = async () => {
		try {
			const users = await getAllTechnicalUsers(selectedAgency);

			setTechnicalUsers(users);
		} catch (err) {
			console.log("err", err);
			displayErrorMessage(
				"Une erreur est survenue dans la récuperation des techniciens",
			);
		}
	};

	const initMaintenanceContract = () => {
		const dates = [];
		for (let idx = 0; idx < 3; idx++) {
			const date = moment().subtract(idx, "years").toDate();
			dates.push({ year: date, date: null });
		}
		dates.push({ year: moment().add(1, "year").format("YYYY"), date: null });

		setMaintenanceContract((vals) => ({ ...vals, dates }));
	};

	const handleGetMaintenanceContract = async () => {
		try {
			const result = await getMaintenanceContractsById(id);
			setMaintenanceContract(result);
		} catch (err) {
			console.log("err", err);
			displayErrorMessage("Une erreur est survenue");
		}
	};

	const validateMaintenanceContract = () => {
		const { clientId, status, amountHT, typeOfBusiness } = maintenanceContract;
		const {
			typeOfClient,
			clientFirstName,
			clientLastName,
			companyName,
		} = clientId;

		if (
			typeof status !== "number" ||
			status < 0 ||
			typeof typeOfBusiness !== "number" ||
			typeOfBusiness < 0 ||
			typeof amountHT !== "number" ||
			amountHT <= 0
		) {
			displayErrorMessage("Veuillez compléter le formulaire complétement");
			return false;
		}

		if (
			!clientFirstName ||
			!clientLastName ||
			(typeOfClient === "Entreprise" && !companyName)
		) {
			displayErrorMessage("Veuillez compléter les informations du client");
			return false;
		}
		return true;
	};

	const handleAddMaintenanceContract = async (e) => {
		e.preventDefault();

		if (!validateMaintenanceContract()) return;

		try {
			if (id) {
				await updateMaintenanceContract(id, maintenanceContract);
			} else {
				const consolidatedMaintenanceContract = {
					...maintenanceContract,
				};

				if (!consolidatedMaintenanceContract.postalCode) {
					const postalCode = getPostalCode(
						maintenanceContract?.clientId?.address,
					);
					consolidatedMaintenanceContract.postalCode = postalCode;
				}

				await addMaintenanceContract({
					userAgency: selectedAgency,
					maintenanceContract: consolidatedMaintenanceContract,
				});
				displaySuccessMessage();
			}
		} catch (err) {
			console.log("err", err);
			displayErrorMessage("Une erreur est survenue");
		} finally {
			history.push("/");
		}
	};

	const handleDeleteMaintenanceContract = async (e) => {
		e.preventDefault();

		try {
			await deleteMaintenanceContract(id);
			displaySuccessMessage();
		} catch (err) {
			console.log("err", err);
			displayErrorMessage("Une erreur est survenue");
		} finally {
			history.push("/");
		}
	};

	const handleAddAmount = (value) => {
		const amountHT = +value || 0;
		// const amountHT = maintenanceContract?.amountHT || 0;
		const addAmt =
			maintenanceContract?.clientId?.typeOfClient === "Entreprise"
				? amountHT * 0.2
				: amountHT * 0.1;

		const amountTTC = amountHT + addAmt;

		setMaintenanceContract((vals) => ({ ...vals, amountTTC, amountHT }));
	};

	useEffect(() => {
		handleAddAmount(maintenanceContract?.amountHT || 0);
	}, [maintenanceContract?.clientId?.typeOfClient]);

	const getPlace = (place) => {
		const { address_components, formatted_address } = place;

		const postalCodeObj = address_components.find((a) => {
			return a.types.includes("postal_code");
		});

		const postalCode = postalCodeObj?.long_name;

		setMaintenanceContract((vals) => ({
			...vals,
			postalCode,
			clientId: {
				...vals?.clientId,
				address: formatted_address,
			},
		}));
	};

	const handleUpdateYear = (year, date) => {
		if (!year || !date) return;

		const formattedYear = moment(year, "YYYY").startOf("year").toISOString();

		if (
			maintenanceContract?.dates?.find((d) => moment(d.year).year() === year)
		) {
			setMaintenanceContract((vals) => ({
				...vals,
				dates: vals.dates.map((d) => {
					if (moment(d.year).year() === year) {
						return {
							...d,
							date,
						};
					}
					return d;
				}),
			}));
		} else {
			setMaintenanceContract((vals) => ({
				...vals,
				dates: [
					...vals.dates,
					{
						year: formattedYear,
						date,
					},
				],
			}));
		}
	};

	const buildForm = () => {
		const deleteButton =
			!isDisabled && isAllowedDelete && id
				? {
						children: "SUPPRIMER",
						size: "sm",
						color: "red",
						onClick: handleDeleteMaintenanceContract,
				  }
				: null;

		let dates = [];

		const currentYear = new Date().getFullYear();
		const nextYear = moment().add(1, "year").year();

		let consolidatedDates = maintenanceContract?.dates || [];

		const earliestYear = consolidatedDates.reduce((acc, curr) => {
			const currYear = moment(curr.year).year();
			return currYear < acc ? currYear : acc;
		}, currentYear);

		for (let a = earliestYear; a <= nextYear; a++) {
			const value = consolidatedDates.find((d) => moment(d.year).year() === a)
				?.date;

			dates.push({
				label: a,
				width: 6,
				input: {
					disabled: isDisabled,
					border: "border",
					defaultValue: value ? moment(value).format("yyyy-MM-DD") : null,
					type: "date",
					value: value ? moment(value).format("yyyy-MM-DD") : null,
					onChange: (e) => {
						handleUpdateYear(
							a,
							moment(e.target.value).set("year", a).toISOString(),
						);
					},
				},
			});
		}

		//     dates = maintenanceContract?.dates?.map(({ year, date }) => ({
		// 	label: moment(year).format("YYYY"),
		// 	width: 6,
		// 	input: {
		// 		disabled: isDisabled,
		// 		border: "border",
		// 		defaultValue: moment(date).format("yyyy-MM-DD"),
		// 		type: "date",
		// 		value: moment(date).format("yyyy-MM-DD"),
		// 		onChange: (e) => {
		// 			setMaintenanceContract((vals) => {
		// 				return {
		// 					...vals,
		// 					dates: vals.dates.map((d) => {
		// 						if (moment(d.year).isSame(year, "year")) {
		// 							const formattedYear = moment(year, "YYYY").format("YYYY");
		// 							return {
		// 								...d,
		// 								date: moment(e.target.value)
		// 									.set("year", formattedYear)
		// 									.format("yyyy-MM-DD"),
		// 							};
		// 						}
		// 						return d;
		// 					}),
		// 				};
		// 			});
		// 		},
		// 	},
		// }));

		// const nextYearInput = new Date().getFullYear() + 1;
		// let isNextYear = false;

		// dates.map((date) => {
		// 	if (date.label === nextYearInput.toString()) isNextYear = true;
		// });

		// if (!isNextYear) {
		// 	dates.push({
		// 		label: nextYearInput,
		// 		width: 6,
		// 		input: {
		// 			disabled: isDisabled,
		// 			border: "border",
		// 			defaultValue: null,
		// 			type: "date",
		// 			value: null,
		// 			onChange: (e) => {
		// 				setMaintenanceContract({
		// 					...maintenanceContract,
		// 					dates: [
		// 						...maintenanceContract.dates,
		// 						{
		// 							year: moment(e.target.value)
		// 								.set("year", nextYearInput)
		// 								.format("yyyy-MM-DD"),
		// 							date: moment(e.target.value)
		// 								.set("year", nextYearInput)
		// 								.format("yyyy-MM-DD"),
		// 						},
		// 					],
		// 				});
		// 			},
		// 		},
		// 	});
		// }

		dates = dates.sort((a, b) =>
			moment(a.label, "YYYY").isBefore(moment(b.label, "YYYY"), "years")
				? -1
				: 1,
		);

		const techniciansReportButton = !isAdd
			? {
					children: "Compte rendu",
					size: "sm",
					color: "lightBlue",
					onClick: () => {
						// const suffix = order.advancementStatus >= ADVANCEMENT_STATUSES.RUNNING
						//   ? "/disabled"
						//   : ""
						history.push({
							pathname: `/list-technicians-report`,
							state: {
								type: "MAINTENANCE_CONTRACT",
								id: id,
								techniciansReports:
									maintenanceContract?.techniciansReports || [],
							},
						});
					},
			  }
			: null;

		return {
			title,
			deleteButton,
			button: !isDisabled && {
				children: "Sauvegarder",
				size: "sm",
				color: "lightBlue",
				onClick: handleAddMaintenanceContract,
			},
			button2: !isAdd &&
				maintenanceContract.needsValidation && {
					children: "VALIDER",
					size: "sm",
					color: "orange",
					onClick: handleValidationOfBusiness,
				},
			button3: techniciansReportButton,
			forms: [
				{
					inputs: [
						isAdd && {
							label: "Nouveau client",
							width: 6,
							select: {
								border: "border",
								items: ["Oui", "Non"],
								defaultValue: existingClient ? "Oui" : "Non",
								value: existingClient ? "Oui" : "Non",
								onChange: (e) => {
									setExistingClient(e === "Oui");
									if (e === "Oui") {
										setMaintenanceContract((vals) => ({
											...vals,
											clientId: {
												typeOfClient: "",
												companyName: "",
												clientLastName: "",
												clientFirstName: "",
												address: "",
											},
											agencyId: "",
										}));
									} else {
										handleSetExistingClient(0);
									}
								},
							},
						},
						(existingClient || existingClients?.length === 0) && {
							label: "Type de client *",
							width: 6,
							select: {
								disabled: isDisabled,
								border: !maintenanceContract?.clientId?.typeOfClient
									? "borderwarning"
									: "border",
								placeholder: "Type de client",
								defaultValue: maintenanceContract?.clientId?.typeOfClient,
								items: ["Particulier", "Entreprise"],
								value: maintenanceContract?.clientId?.typeOfClient,
								onChange: (e) => {
									setMaintenanceContract((vals) => ({
										...vals,
										clientId: {
											...vals?.clientId,
											typeOfClient: e,
										},
									}));
								},
							},
						},
						(existingClient || existingClients?.length === 0) &&
							maintenanceContract?.clientId?.typeOfClient === "Entreprise" && {
								label: `Nom de l'entreprise *`,
								width: 6,
								input: {
									disabled: isDisabled,
									border: !maintenanceContract?.clientId?.companyName
										? "borderwarning"
										: "border",
									placeholder: "Entreprise",
									type: "text",
									defaultValue: maintenanceContract?.clientId?.companyName,
									value: maintenanceContract?.clientId?.companyName,
									onChange: (e) => {
										setMaintenanceContract((vals) => ({
											...vals,
											clientId: {
												...vals?.clientId,
												companyName: e.target.value?.toUpperCase(),
											},
										}));
									},
								},
							},
						(existingClient || existingClients?.length === 0) && {
							label: `Nom du contact *`,
							width: 6,
							input: {
								disabled: isDisabled,
								required: true,
								border: !maintenanceContract?.clientId?.clientLastName
									? "borderwarning"
									: "border",
								placeholder: "Nom",
								type: "text",
								defaultValue: maintenanceContract?.clientId?.clientLastName,
								value: maintenanceContract?.clientId?.clientLastName,
								onChange: (e) => {
									setMaintenanceContract((vals) => ({
										...vals,
										clientId: {
											...vals?.clientId,
											clientLastName: e.target.value?.toUpperCase(),
										},
									}));
								},
							},
						},
						(existingClient || existingClients?.length === 0) && {
							label: `Prénom du contact *`,
							width: 6,
							input: {
								disabled: isDisabled,
								required: true,
								border: !maintenanceContract?.clientId?.clientFirstName
									? "borderwarning"
									: "border",
								placeholder: "Prénom",
								type: "text",
								defaultValue: maintenanceContract?.clientId?.clientFirstName,
								value: maintenanceContract?.clientId?.clientFirstName,
								onChange: (e) => {
									setMaintenanceContract((vals) => ({
										...vals,
										clientId: {
											...vals?.clientId,
											clientFirstName: capitalizeFirstLetter(e.target.value),
										},
									}));
								},
							},
						},
						!existingClient &&
							existingClients?.length > 0 && {
								label: `Choix du client *`,
								width: 6,
								selectSearch: {
									search: true,
									options: existingClients,
									autoComplete: "on",
									placeholder: "",
									required: true,
									disabled: isDisabled,
									value: existingClientIdx,
									filterOptions: () => {
										return (value) => {
											if (value.length === 0) return existingClients;
											return existingClients?.filter((c) =>
												c?.name?.toLowerCase()?.includes(value?.toLowerCase()),
											);
										};
									},
									onChange: (idx) => {
										handleSetExistingClient(idx);
									},
								},
							},
						{
							label: `Adresse *`,
							width: 12,
							addressAutoComplete: {
								disabled: isDisabled,
								defaultValue: maintenanceContract?.clientId?.address,
								value: maintenanceContract?.clientId?.address,
								language: "fr",
								types: ["geocode"],
								componentRestrictions: { country: "fr" },
								style: {
									width: "100%",
									border: "1px solid black",
									borderColor: !maintenanceContract?.clientId?.address
										? "red"
										: "black",
									borderRadius: "5px",
									padding: "10px",
								},
								onChange: (e) => {
									setMaintenanceContract((vals) => ({
										...vals,
										clientId: {
											...vals?.clientId,
											address: e.target.value,
										},
									}));
								},
								onPlaceSelected: (e) => {
									getPlace(e);
								},
							},
						},
						isAllowedShowAgencySelection && {
							label: `Agence`,
							width: 6,
							select: {
								border: !maintenanceContract.agencyId
									? "borderwarning"
									: "border",
								items: agencies,
								defaultValue: chosenAgency,
								value: chosenAgency,
								onChange: handleAgencySelect,
							},
						},
						{
							label: "Statut",
							width: 6,
							select: {
								border:
									maintenanceContract.status === undefined
										? "borderwarning"
										: "border",
								placeholder: "",
								defaultValue:
									MAINTENANCE_CONTRACTS_STATUS[maintenanceContract?.status],
								items: Object.keys(MAINTENANCE_CONTRACTS_STATUS).map(
									(key) => MAINTENANCE_CONTRACTS_STATUS[key],
								),
								value:
									MAINTENANCE_CONTRACTS_STATUS[maintenanceContract?.status],
								onChange: (e) => {
									if (typeof e === "string") {
										setMaintenanceContract((vals) => ({
											...vals,
											status: MAINTENANCE_CONTRACTS_STATUS_REVERSE[e],
										}));
									}
								},
							},
						},
						{
							label: `Catégorie *`,
							width: 6,
							select: {
								disabled: isDisabled,
								border:
									maintenanceContract.typeOfBusiness === undefined
										? "borderwarning"
										: "border",
								placeholder: "Catégorie",
								defaultValue:
									typeOfBusinessReverseCorres[
										maintenanceContract.typeOfBusiness
									],
								items: Object.keys(typeOfBusinessCorres),
								value:
									typeOfBusinessReverseCorres[
										maintenanceContract.typeOfBusiness
									],
								onChange: (e) => {
									setMaintenanceContract((vals) => ({
										...vals,
										typeOfBusiness: TYPE_OF_BUSINESS[typeOfBusinessCorres[e]],
									}));
								},
							},
						},
					],
				},
				{
					inputs: [
						!isDisabled && {
							label: `Montant HT € *`,
							width: 6,
							input: {
								disabled: isDisabled,
								border:
									!maintenanceContract.amountHT ||
									maintenanceContract.amountHT <= 0
										? "borderwarning"
										: "border",
								placeholder: "",
								type: "number",
								defaultValue: maintenanceContract.amountHT,
								value: maintenanceContract.amountHT,
								onChange: (e) => {
									handleAddAmount(e.target.value);
								},
							},
						},
						!isDisabled && {
							label: `Montant TTC €`,
							width: 6,
							input: {
								disabled: true,
								border: "border",
								placeholder: "",
								type: "number",
								defaultValue: maintenanceContract.amountTTC,
								value: maintenanceContract.amountTTC,
							},
						},
						{
							label: "Technicien",
							width: 6,
							select: {
								disabled: isDisabled,
								placeholder: "",
								defaultValue: maintenanceContract.technician,
								items: technicalUsers.map((u) => u?.userPrincipalName),
								value: statusReverseCorres[maintenanceContract.status],
								onChange: (e) => {
									if (typeof e === "string") {
										setMaintenanceContract((vals) => ({
											...vals,
											technician: e,
										}));
									}
								},
							},
						},
						{
							label: `Remarques`,
							width: 12,
							input: {
								disabled: isDisabled,
								border: "border",
								placeholder: "Remarques",
								type: "textarea",
								defaultValue: maintenanceContract.comment,
								rows: 2,
								value: maintenanceContract.comment,
								onChange: (e) =>
									setMaintenanceContract((vals) => ({
										...vals,
										comment: e.target.value,
									})),
							},
						},
						...dates,
					],
				},
			],
		};
	};

	return (
		<>
			<AuthSidebar />
			<div className="relative 2xl:ml-64 bg-blueGray-100">
				<NavbarSearchUser {...navbarsearchusersettings2} />
				<HeaderStatCards {...{ color: "accentBlue" }} />
				<div className="px-4 md:px-6 mx-auto w-full -mt-24">
					<div className="flex flex-wrap">
						<div className="w-full  px-4">
							<CardSettingsLarge {...buildForm()} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
