import PropTypes from "prop-types";
import React from "react";
// components
import Button from "../../components/Elements/Button.js";

export default function ImageUpload({
	placeholder,
	changeButton,
	removeButton,
	onChange,
	onRemove,
	disabled,
}) {
	// eslint-disable-next-line
	const [file, setFile] = React.useState(null);
	const [imagePreviewUrl, setImagePreviewUrl] = React.useState(placeholder);
	const fileInput = React.useRef(null);
	const handleImageChange = (e) => {
		e.preventDefault();
		try {
			let reader = new FileReader();
			let file = e.target.files[0];
			reader.onloadend = () => {
				setFile(file);
				onChange(placeholder, file, reader.result);
			};
			if (file) {
				reader.readAsDataURL(file);
			}
		} catch (err) {
			console.log("err handleImageChange", err);
		}
	};
	const handleClick = (e) => {
		e.preventDefault();
		try {
			fileInput?.current?.click();
		} catch (err) {
			console.log("err, handle click", err);
		}
	};
	const handleRemove = () => {
		try {
			setFile(null);
			setImagePreviewUrl(placeholder);
			onRemove(placeholder);
			fileInput.current.value = null;
		} catch (err) {
			console.log("err handleremove", err);
		}
	};

	const openDocument = (link) => {
		if (!link) return;

		window.open(link, "_blank");
	};

	const displayMedia = (link) => {
		if (link.includes(".pdf")) {
			return (
				<iframe
					onClick={() => openDocument(link)}
					title="Document"
					style={{ minHeight: "500px" }}
					disabled={disabled}
					src={imagePreviewUrl}
					frameBorder="0"></iframe>
			);
		}
		return (
			<img
				onClick={() => openDocument(link)}
				disabled={disabled}
				className=""
				src={imagePreviewUrl}
				alt="..."
			/>
		);
	};

	return (
		<>
			<div className="text-center">
				<input
					// accept="image/*"
					className="hidden"
					type="file"
					onChange={handleImageChange}
					ref={fileInput}
				/>
				<div className=" mx-auto shadow-lg rounded max-w-500-px mb-4">
					{imagePreviewUrl ? (
						displayMedia(imagePreviewUrl)
					) : (
						<h5>Ajouter une image</h5>
					)}
				</div>
				<div>
					{changeButton && <Button {...changeButton} onClick={handleClick} />}
					{removeButton && <Button {...removeButton} onClick={handleRemove} />}
				</div>
			</div>
		</>
	);
}

ImageUpload.defaultProps = {
	changeButton: {},
	removeButton: {},
	onChange: () => {},
	onRemove: () => {},
};

ImageUpload.propTypes = {
	// this is the default image that
	// appears when no image is uploaded
	placeholder: PropTypes.string,
	// props to pass to the change Button component
	changeButton: PropTypes.object,
	// props to pass to the remove Button component
	removeButton: PropTypes.object,
	// this function will get called when
	// clicking the changeButton
	// and it has two parameters, the image, and the image preview
	// example of usage: onChange={(image,imagePreviewUrl) => {console.log("image is :",image);console.log("image preview url is: ",imagePreviewUrl);}}
	onChange: PropTypes.func,
	// this function will get called when
	// clicking the removeButton, and does not have any params
	// example of usage: onRemove={() => console.log("remove happened")}
	onRemove: PropTypes.func,
};
