import CardFullTable from "components/Cards/Admin/CardFullTable";
import ListingActions from "components/Elements/ListingActions";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
// components
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import CsvDownload from "react-json-to-csv";
import { useLocation, useParams } from "react-router-dom";
import { handleSetHeaderStatCards } from "service/sitesOrTroubleShoots/HandleSetHeaderStatCards";
import {
  ADVANCEMENT_STATUSES,
  siteStatusReverseCorres,
  SITE_STATUSES,
} from "types/baseTypes";
import { Role } from "utils/common";
import headerstatcards from "_texts/admin/headers/headerstatcards.js";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
// import footeradmin from "_texts/admin/footers/footeradmin.js";
import { getConstructionSites, getTroubleShoots } from "../../api/orders";
// import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import Input from "../../components/Elements/Input";
import Appcontext from "../../Contexts/AppContext";
import { Breadcrumb } from "./BreadCrumb";
const SORT_FILTER_TYPE = {
  CLIENT_LAST_NAME: "CLIENT_LAST_NAME"
}

export default function ListingSitesOrTS() {
  const { user, selectedAgency } = useContext(Appcontext);
  const [isTechnicalPersonnel] = useState(
    user?.department?.toLowerCase() === Role.SERVICE_TECHNIQUE.toLowerCase()
  );

  const location = useLocation();
  let { typeOfOrder } = useParams();
  let { advStatus } = useParams();

  const [entryOfDataToSortOn, setEntryOfDataToSortOn] = useState(
    "objectifInvoiceDate"
  );
  const [typeToSort, setTypeToSort] = useState("date");
  const [isSortPositif, setIsSortPositif] = useState(true);
  const [sortParams, setSortParams] = useState({
    lastname: {
      sortCriteria: "lastname",
      sortType: "string",
      sortState: "disabled"
    },
    firstName: {
      sortCriteria: "firstname",
      sortType: "string",
      sortState: "disabled"
    }
  });

  const [listSitesOrTS, setListSitesOrTS] = useState([]);
  const [query, setQuery] = useState(null);

  const [filteredSitesOrTS, setFilteredSitesOrTS] = useState([]);
  const [
    headerstatcardssitesortroubleshoots,
    setheaderstatcardssitesortroubleshoots,
  ] = useState(headerstatcards);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const ascendingReturn = isSortPositif ? -1 : 1;
    const data = listSitesOrTS.sort((a, b) => {
      if (entryOfDataToSortOn === "clientLastName") {
        return a?.clientId?.clientFirstName?.toLowerCase() <
          b?.clientId?.clientFirstName?.toLowerCase()
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "siteStatus") {
        return a.siteStatus < b.siteStatus
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "bt") {
        return a.bt < b.bt ? ascendingReturn : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "siteManager") {
        return a.siteManager < b.siteManager
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "technicians") {
        return a.technicians < b.technicians
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "materialOrderDate") {
        return moment(a.materialOrderDate).isBefore(b.materialOrderDate)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "deliveryDate") {
        return moment(a.deliveryDate).isBefore(b.deliveryDate)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "objectifDateStart") {
        return moment(a.objectifDateStart).isBefore(b.objectifDateStart)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "objectifDateEnd") {
        return moment(a.objectifDateEnd).isBefore(b.objectifDateEnd)
          ? ascendingReturn
          : ascendingReturn * -1;
      }
      if (entryOfDataToSortOn === "amount") {
        return (a.amount || 0) < (b.amount || 0)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "problem") {
        return a.problem < b.problem ? ascendingReturn : ascendingReturn * -1;
      }
      return 1;
    });

    const transformedData = data
      .map(transformData)

    if (data) setFilteredSitesOrTS(transformedData);
  }, [entryOfDataToSortOn, isSortPositif, typeToSort]);

  const TABLE_CONFIG = {
    title:
      typeOfOrder === ":sites"
        ? advStatus && advStatus === ":finished"
          ? "Chantiers terminés"
          : "Chantiers en cours"
        : advStatus && advStatus === ":finished"
        ? "Interventions terminés"
        : "Interventions en cours",
    color: "white",
    head: [
      {
        onClick: () => {},
        string: "",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("clientLastName");
          setIsSortPositif(!isSortPositif);
        },
        string: "Nom",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("siteStatus");
          setIsSortPositif(!isSortPositif);
        },
        string: "Statut",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("bt");
          setIsSortPositif(!isSortPositif);
        },
        string: typeOfOrder === ":sites" ? "chantier" : "bt",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("siteManager");
          setIsSortPositif(!isSortPositif);
        },
        string: "RC",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("technicians");
          setIsSortPositif(!isSortPositif);
        },
        string: "Techniciens",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("materialOrderDate");
          setIsSortPositif(!isSortPositif);
        },
        string: "Commande",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("deliveryDate");
          setIsSortPositif(!isSortPositif);
        },
        string: "Livraison",
      },

      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("objectifDateStart");
          setIsSortPositif(!isSortPositif);
        },
        string: "Début",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("objectifDateEnd");
          setIsSortPositif(!isSortPositif);
        },
        string: "Fin",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("supplier");
          setIsSortPositif(!isSortPositif);
        },
        string: "Fournisseur",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("problem");
          setIsSortPositif(!isSortPositif);
          switchSortParams("lastname");
        },
        string: "Problèmes",
      },
    ],
  };

  const switchSortParams = (criteria) => {
    console.log(sortParams.criteria)
    if(sortParams.criteria.sortState === "ascending") {
      setSortParams({...sortParams, criteria: {...criteria, sortState: "descending"}})
    } else if(sortParams.criteria.sortState === "descending") {
      setSortParams({...sortParams, criteria: {...criteria, sortState: "disabled"}})
    } else if(sortParams.criteria.sortState === "disabled") {
      setSortParams({...sortParams, criteria: {...criteria, sortState: "ascending"}})
    }
    console.log(sortParams)
  }

  const dropDownConfig = (orderId, order, typeOfOrder) => {
    // const technicalReportUrl = order?.newBusinessId
    //   ? `#/list-technical-reports/${order?.newBusinessId}/disabled`
    //   : "#"
    const technicalReportUrl = `#/update-site-or-trouble-shoot/${orderId}/${typeOfOrder}/disabled`;
    const href = isTechnicalPersonnel
      ? technicalReportUrl
      : `#/update-site-or-trouble-shoot/${orderId}/${typeOfOrder}`;

    return {
      icon:
        advStatus && advStatus === ":finished" ? "fas fa-eye" : "fas fa-edit",
      button: {
        color: "white",
        size: "sm",
        href,
      },
    };
  };

  const getSiteStatusColor = (siteStatus) => {
    let color = "";

    switch (siteStatus) {
      case SITE_STATUSES.TO_DO:
        color = "red";
        break;
      case SITE_STATUSES.PURCHASED:
        color = "pink";
        break;
      case SITE_STATUSES.DELIVERED:
        color = "teal";
        break;
      case SITE_STATUSES.PLANIFIED:
        color = "lightBlue";
        break;
      case SITE_STATUSES.TO_BE_INVOICED:
        color = "purple";
        break;
      case SITE_STATUSES.NOT_FINISHED_TO_PLANIFY:
        color = "amber";
        break;
      case SITE_STATUSES.FINISHED:
        color = "emerald";
        break;
      default:
        color = "blueGray";
        break;
    }
    return color;
  };

  const transformData = (data) => {
    return {
      rowProps: {
        bg:
          data.orderId?.advancementStatus === ADVANCEMENT_STATUSES.PAUSED
            ? " bg-blueGray-200"
            : " ",
      },

      colProps: [
        {
          dropdown: {
            ...dropDownConfig(data._id, data.orderId, typeOfOrder),
          },
        },
        data?.clientId?.typeOfClient === "Entreprise"
          ? data?.clientId?.companyName
          : `${data?.clientId?.clientLastName} ${data.clientId?.clientFirstName}`,
        {
          color: getSiteStatusColor(data.siteStatus || SITE_STATUSES.TO_DO),
          text:
            siteStatusReverseCorres[data.siteStatus] ||
            siteStatusReverseCorres[SITE_STATUSES.TO_DO],
        },
        data.bt || "-",
        data?.orderId?.siteManager || "-",
        data.technicians || "-",
        data.materialOrderDate
          ? moment(data.materialOrderDate).format("DD/MM/YYYY")
          : "-",
        data.deliveryDate
          ? moment(data.deliveryDate).format("DD/MM/YYYY")
          : "-",
        data.objectifDateStart
          ? moment(data.objectifDateStart).format("DD/MM/YYYY")
          : "-",
        data.objectifDateEnd
          ? moment(data.objectifDateEnd).format("DD/MM/YYYY")
          : "-",
        data.supplier || "-",
        data.problem || "-",
      ],
    };
  };

  const buildConsolidatedData = (data) => {
    const todoSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.TO_DO)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const purchasedSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.PURCHASED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const deliveredSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.DELIVERED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const plannedSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.PLANIFIED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const plannedSitesMaterialToOrder = data
      .filter(
        ({ siteStatus }) =>
          siteStatus === SITE_STATUSES.PLANIFIED_MATERIAL_TO_ORDER
      )
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const plannedSitesMaterialOrdered = data
      .filter(
        ({ siteStatus }) =>
          siteStatus === SITE_STATUSES.PLANIFIED_MATERIAL_ORDERED
      )
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const toBeInvoiced = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.TO_BE_INVOICED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const notFinishedToPlanify = data
      .filter(
        ({ siteStatus }) => siteStatus === SITE_STATUSES.NOT_FINISHED_TO_PLANIFY
      )
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const finishedSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.FINISHED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);

    let consolidatedData = [];

    if (advStatus && advStatus === ":finished") {
      consolidatedData = [...finishedSites];
    } else {
      consolidatedData = [
        ...todoSites,
        ...purchasedSites,
        ...deliveredSites,
        ...notFinishedToPlanify,
        ...plannedSitesMaterialToOrder,
        ...plannedSitesMaterialOrdered,
        ...plannedSites,
        ...toBeInvoiced,
      ];
    }

    return consolidatedData;
  };

  const handleGetSitesOrTS = async () => {
    let data = [];
    try {
      if (typeOfOrder === ":sites") {
        data = await getConstructionSites(selectedAgency);
      } else {
        data = await getTroubleShoots(selectedAgency);
      }

      // data = sortData(
      //   data,
      //   entryOfDataToSortOn,
      //   isSortPositif,
      //   typeToSort,
      // );

      // if (advStatus && advStatus === ":finished") {
      //   data = data.filter(
      //     (elt) => elt.siteStatus === SITE_STATUSES.FINISHED,
      //   );
      // } else {
      //   data = data.filter(
      //     (elt) => elt.siteStatus !== SITE_STATUSES.FINISHED,
      //   );

      // }

      const allData = buildConsolidatedData(data);

      setListSitesOrTS(data);
      setFilteredSitesOrTS(allData);

      handleSetHeaderStatCards(data, advStatus, typeOfOrder, [
        headerstatcardssitesortroubleshoots,
        setheaderstatcardssitesortroubleshoots,
      ]);
    } catch (err) {
      console.log("err", err);
    }
  };

  const filterSitesOrTS = (query) => {
    const filterQuery = query?.toLowerCase();

    const filtered = buildConsolidatedData(listSitesOrTS).filter((siteOrTS) => {
      let textValues = siteOrTS.colProps
        .map((value) => {
          if (typeof value === "string") return value;
          if (typeof value === "object" && value?.color) return value.text;
        })
        .filter((value) => typeof value === "string");
      textValues = textValues.filter((value) =>
        value?.toLowerCase().includes(filterQuery)
      );
      return textValues.length > 0;
    });

    if (filterQuery.length === 0) {
      setFilteredSitesOrTS(buildConsolidatedData(listSitesOrTS));
      setQuery(null);
    } else {
      setFilteredSitesOrTS(filtered);
      setQuery(filterQuery);
    }
  };

  React.useEffect(() => {
    handleGetSitesOrTS();
  }, [advStatus, typeOfOrder, location, selectedAgency]);

  return (
    <>
      <AuthSidebar />
      {/* 2xl:ml-64 */}
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards
          {...{
            ...headerstatcardssitesortroubleshoots,
            isAllowedToView: !isTechnicalPersonnel,
          }}
        />
        <div className="w-full h-full px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="w-full flex flex-wrap">
            <ListingActions
              {...{
                fileName: "listing_sites.csv",
                onInputSearch: filterSitesOrTS,
                downloadData: listSitesOrTS,
                title: TABLE_CONFIG.title,
                location,
              }}
            />
            <div className="w-full px-4">
              <CardFullTable {...TABLE_CONFIG} body={filteredSitesOrTS} />
            </div>
            {/* <div className="w-full px-4">
              <CardFullTable {...cardfulltable} color="blueGray" />
            </div> */}
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  );
}
