/* eslint-disable react-hooks/exhaustive-deps */
import { getAllAgencies } from "api/agency";
import { addIntervention, deleteInterventionById, getInterventionById, updateIntervention } from "api/interventions";
import { getClients } from "api/newBusiness";
import { getAllSiteManagers, getAllTechnicalUsers } from "api/users";
import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
// components
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import GeneralForm from "forms/intervention/GeneralForm";
import moment from "moment";
import React, {
  useContext, useEffect, useState
} from "react";
import {
  useHistory,
  useLocation,
  useParams
} from "react-router-dom";
import { SITE_STATUSES } from "types/baseTypes";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
import AppContext from "../../Contexts/AppContext";




export default function AddUpdateIntervention() {
  let location = useLocation();
  const history = useHistory();
  const [newSiteStatus, setNewSiteStatus] = useState(
    SITE_STATUSES.TO_DO,
  );
  let { interventionId, disabled } = useParams();
  const {
    user,
    selectedAgency,
    displayErrorMessage,
    displaySuccessMessage,
    isAdmin,
    isSuperAdmin
  } = useContext(AppContext);
  const [isAdd] = useState(!!!interventionId);
  const [isLoaded, setIsLoaded] = useState(false);

  const [intervention, setIntervention] = useState({
    siteStatus: SITE_STATUSES.TO_DO,
    clientId: {}
  });
  const [technicalUsers, setTechnicalUsers] = useState([]);
  const [siteManagers, setSiteManagers] = useState([])
  const [existingClient, setExistingClient] = useState(isAdd ? false : true);
  const [initClients, setInitClients] = useState([])
  const [existingClients, setExistingClients] = useState([]);
  const [existingClientIdx, setExistingClientIdx] = useState(0)
  const [agencies, setAgencies] = useState([]);
  const [chosenAgency, setChosenAgency] = useState("");
  const [initialAgencies, setInitialAgencies] = useState([])

  const isAllowedDelete = isAdmin || isSuperAdmin;
  const isDisabled = !!disabled;

  const isAllowedShowAgencySelection = !isAdd && isSuperAdmin;




  // const isStarting =
  // newSiteStatus ===
  //   SITE_STATUSES.TO_DO;
  // const isPurchased =
  //   newSiteStatus === SITE_STATUSES.PURCHASED;
  // const isDelivered =
  //   newSiteStatus === SITE_STATUSES.DELIVERED;

  // const isOk = newSiteStatus === SITE_STATUSES.PLANIFIED;

  // const isFinished =
  //   newSiteStatus === SITE_STATUSES.FINISHED;

  // const wasStarting =
  // intervention.siteStatus ===
  //   SITE_STATUSES.TO_DO;

  // const wasPurchased =
  // intervention.siteStatus ===
  //   SITE_STATUSES.PURCHASED;

  // const wasDelivered =
  // intervention.siteStatus=== SITE_STATUSES.DELIVERED;

  // const wasOk =
  // intervention.siteStatus ===
  //   SITE_STATUSES.PLANIFIED;

  // const wasFinished =
  //   intervention.siteStatus === SITE_STATUSES.FINISHED;


  const handleGetAllAgencies = async () => {
    try {
      const data = await getAllAgencies();
      setInitialAgencies(data);
      setAgencies(data.map(d => d.name))
    } catch (err) {
      console.log("err", err)
    }
  }

  const handleAgencySelect = (sAgency) => {
    const agencyFound = initialAgencies.find(a => a.name.toLowerCase().includes(sAgency.toLowerCase())  );

    if (agencyFound) {
      setIntervention(vals => ({ ...vals, agencyId: agencyFound?._id }));
      setChosenAgency(sAgency)
    }

  }

  useEffect(() => {
    handleGetAllAgencies();
  }, [])




  useEffect(() => {
    handleGetExisingClients();
  }, [selectedAgency])

  const handleGetExisingClients = async () => {
    try {
      let data = await getClients(selectedAgency) || [];
      setInitClients(data);

      if (isAdd) {
        const first = data[0];
        setIntervention(vals => ({
          ...vals,
          clientId: {
            ...first,
          }
        }));
      }

      data = data.map((d, idx) => ({
        name: `${d?.typeOfClient} | ${d?.typeOfClient === "Entreprise" ? d?.companyName : `${d?.clientLastName} ${d?.clientFirstName}`}`,
        value: idx
      }))
      setExistingClients(data);

    } catch (err) {
      console.log("err", err);
    }
  }

  const handleSetExistingClient = (idx) => {

    setIntervention(vals => ({
      ...vals,
      clientId: {
        typeOfClient: "",
        companyName: "",
        clientLastName: "",
        clientFirstName: "",
        address: "",
        origin: "",
      },
      agencyId: "",
    }))

    const data = initClients[idx] || {};
    setExistingClientIdx(idx);


    setIntervention(vals => ({ ...vals, clientId: { ...data } }));
  }


  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleGetTechnicalUsers = async () => {
    try {
      const users = await getAllTechnicalUsers(selectedAgency);
      setTechnicalUsers(users);
    } catch (err) {
      console.log("err estimate users", err)
    }
  }
  const handleGetSiteManagers = async () => {
    try {
      const users = await getAllSiteManagers(selectedAgency);
      setSiteManagers(users);
    } catch (err) {
      console.log("err estimate users", err)
    }
  }

  useEffect(() => {
    handleGetTechnicalUsers()
    handleGetSiteManagers()
  }, [selectedAgency])


  const handleGetInterventionById = async () => {
    try {
      const data = await getInterventionById(
        interventionId,
      );
      setIntervention({
        ...data,
        materialOrderDate:
          data.materialOrderDate &&
          moment(data.materialOrderDate).format(
            "YYYY-MM-DD",
          ),
        supplier: data.supplier,
        deliveryDate:
          data.deliveryDate &&
          moment(data.deliveryDate).format("YYYY-MM-DD"),
        objectifDateStart:
          data.objectifDateStart &&
          moment(data.objectifDateStart).format(
            "YYYY-MM-DD",
          ),
        objectifDateEnd:
          data.objectifDateEnd &&
          moment(data.objectifDateEnd).format("YYYY-MM-DD"),
        dateCompleteSite:
          data.dateCompleteSite &&
          moment(data.dateCompleteSite).format(
            "YYYY-MM-DD",
          ),
      });

      setNewSiteStatus(
        data.siteStatus
          ? data.siteStatus
          : SITE_STATUSES.TO_DO,
      );

      setIsLoaded(true);
    } catch (err) {
      console.log("err", err);
      displayErrorMessage(
        "Impossible de récuperer les information liés à cette intervention.",
      );
    }
  };

  useEffect(() => {
    if (!isAdd) {
      handleGetInterventionById();
    }
  }, []);

  const setInitialChosenAgency = () => {
    const { agencyId = "" } = intervention || {};
    const agencyFound = initialAgencies?.find(a => a._id === agencyId);

    if (agencyFound) {
      setChosenAgency(agencyFound.name);
    }

  }

  useEffect(() => {
    if (!isAdd)
      setInitialChosenAgency()
  }, [initialAgencies, intervention])

  const validateAddIntervention = (msg, display = true) => {
    const {
      bt,
      typeOfBusiness,
      clientId
    } = intervention;

    const {
      typeOfClient,
      clientFirstName,
      clientLastName,
      origin,
      companyName,
    } = clientId;

    if (
      !typeOfClient ||
      !clientFirstName ||
      !clientLastName ||
      typeOfBusiness === undefined ||
      !origin ||
      (typeOfClient === "Entreprise" && !companyName) ||
      !bt ||
      isNaN(bt) ||
      bt.toString().length !== 4
    ) {
      if (display) displayErrorMessage(msg);
      return false;
    }

    return true;
  };

  // const validateAddUpdateIntervention = (
  //   msg,
  //   display = true,
  // ) => {
  //   const {
  //     objectifDateStart,
  //     objectifDateEnd,
  //     materialOrderDate,
  //     deliveryDate,
  //     supplier,
  //     dateCompleteSite,
  //     technicians,
  //   } = intervention;

  //   // if (
  //   //   ((isPurchased || isDelivered || isOk || isFinished) &&
  //   //     (!materialOrderDate || !supplier)) ||
  //   //   ((isDelivered || isOk || isFinished) &&
  //   //     !deliveryDate) ||
  //   //   // ((isOk || isFinished) &&
  //   //   //   (!objectifDateEnd ||
  //   //   //     !objectifDateStart ||
  //   //   //     !technicians)) ||
  //   //   (isFinished && !dateCompleteSite)
  //   // ) {
  //   //   if (display) displayErrorMessage(msg);
  //   //   return false;
  //   // }

  //   return false;
  // };

  // const handleChangeSiteStatus = () => {
  //   let toChangeSiteStatus = null;
  //   const {
  //     technicians,
  //     objectifDateStart,
  //     objectifDateEnd,
  //     materialOrderDate,
  //     deliveryDate,
  //     supplier,
  //     dateCompleteSite,
  //   } = intervention;

  //   if (
  //     materialOrderDate &&
  //     supplier &&
  //     deliveryDate &&
  //     objectifDateStart &&
  //     objectifDateEnd &&
  //     technicians &&
  //     dateCompleteSite &&
  //     newSiteStatus < SITE_STATUSES.FINISHED
  //   ) {
  //     toChangeSiteStatus = SITE_STATUSES.FINISHED;
  //   } else if (
  //     materialOrderDate &&
  //     supplier &&
  //     deliveryDate &&
  //     objectifDateStart &&
  //     objectifDateEnd &&
  //     technicians &&
  //     newSiteStatus < SITE_STATUSES.PLANIFIED
  //   ) {
  //     toChangeSiteStatus = SITE_STATUSES.PLANIFIED;
  //   } else if (
  //     materialOrderDate &&
  //     supplier &&
  //     deliveryDate &&
  //     newSiteStatus < SITE_STATUSES.DELIVERED
  //   ) {
  //     toChangeSiteStatus = SITE_STATUSES.DELIVERED;
  //   } else if (
  //     materialOrderDate &&
  //     supplier &&
  //     newSiteStatus < SITE_STATUSES.PURCHASED
  //   ) {
  //     toChangeSiteStatus = SITE_STATUSES.PURCHASED;
  //   } else {
  //     return;
  //   }

  //   // if (
  //   //   window.confirm(
  //   //     `Souhaitez vous changer le statut de l'intervention en "${siteStatusReverseCorres[toChangeSiteStatus]}" `,
  //   //   )
  //   // )
  //   return toChangeSiteStatus;
  // };

  const validateUpdateIntervention = (msg) => {
    const {
      amount,
      objectifDateStart,
      objectifDateEnd,
      technicians,
      dateCompleteSite
    } = intervention;

    const isFinished = newSiteStatus === SITE_STATUSES.FINISHED;

    if (moment(objectifDateEnd).isBefore(objectifDateStart)) {
      displayErrorMessage("La date fin intervention ne peut pas etre avant la date début intervention.")
      return false
    }


    if (
      (
        !amount ||
        +amount <= 0 ||
        !objectifDateEnd ||
        !objectifDateStart ||
        !dateCompleteSite ||
        technicians?.trim()?.length <= 0
      ) && isFinished
    ) {
      displayErrorMessage(msg);
      return false;
    }

    return true;
  }

  const handleAddIntervention = async () => {

    // const changedSiteStatus = handleChangeSiteStatus();

    const consolidatedIntervention = {
      ...intervention,
      siteStatus: newSiteStatus,
    };

    try {
      if (isAdd) {
        const msg =
          "Veuillez completer tous les champs obligatoires avant d'ajouter une nouvelle intervention.";

        if (!validateAddIntervention(msg)) return;
        // if (!validateAddUpdateIntervention(msg)) return;

        await addIntervention({
          userAgency: selectedAgency,
          intervention: consolidatedIntervention,
        });
        // console.log("addIntervention consolidatedIntervention", consolidatedIntervention)
      } else {
        const msg =
          "Veuillez completer tous les champs obligatoires avant de modifier cette intervention.";

        if (!validateUpdateIntervention(msg)) return;

        await updateIntervention(
          intervention._id,
          consolidatedIntervention,
        );

      }

      displaySuccessMessage();
      history.push("/list-interventions/:running");
    } catch (err) {
      console.log(err);
      let msg = "Une erreur est survenue.";

      displayErrorMessage(msg);
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  const handleDeleteIntervention = async () => {
    try {
      await deleteInterventionById(interventionId)
      history.push("/list-interventions/:running");
    } catch (err) {
      console.log("err delting new business", err)
    }
  }


  const buildForm = () => {
    const buttonTitle = isAdd ? "Ajouter" : "Modifier";

    const title = isAdd
      ? "AJOUTER UN DÉPANNAGE"
      : "ÉDITER LE DÉPANNAGE";

    // const isDisabled = wasFinished;

    // const isButtonDisabled = isDisabled || wasFinished;

    const deleteButton = (isAllowedDelete && interventionId)
      ? {
        children: "SUPPRIMER",
        size: "sm",
        color: "red",
        onClick: handleDeleteIntervention
      }
      : null;

    const siteSupervisionButton = {
      children: "Suivi de chantier",
      size: "sm",
      color: "lightBlue",
      onClick: () => {
        // const suffix = order.advancementStatus >= ADVANCEMENT_STATUSES.RUNNING
        //   ? "/disabled"
        //   : ""
        history.push({
          pathname: `/list-site-supervisions`,
          state: {
            type: "INTERVENTIONS",
            id: interventionId,
            siteSupervisions: intervention.siteSupervisions || []
          }
        })
      },
    }

    const techniciansReportButton =
      intervention.siteStatus >= SITE_STATUSES.PLANIFIED
        ? {
          children: "Compte rendu",
          size: "sm",
          color: "lightBlue",
          onClick: () => {
            // const suffix = order.advancementStatus >= ADVANCEMENT_STATUSES.RUNNING
            //   ? "/disabled"
            //   : ""
            history.push({
              pathname: `/list-technicians-report`,
              state: {
                type: "INTERVENTIONS",
                id: interventionId,
                techniciansReports: intervention?.techniciansReports || []
              }
            })
          },
        }
        : null;


    return {
      title,
      deleteButton,
      button: {
        children: buttonTitle,
        // disabled: isButtonDisabled,
        size: "sm",
        color: "lightBlue",
        onClick: handleAddIntervention,
      },
      button2: siteSupervisionButton,
      button3: techniciansReportButton,

      forms: GeneralForm(
        [intervention, setIntervention],
        [newSiteStatus, setNewSiteStatus],
        capitalizeFirstLetter,
        isAdd,
        {
          technicalUsers: technicalUsers.map(u => u?.userPrincipalName),
          siteManagers: siteManagers.map(u => u?.userPrincipalName),
          isDisabled,
          existingClients,
          existingClient,
          existingClientIdx,
          setExistingClient,
          handleSetExistingClient,
          agencies,
          agency: chosenAgency,
          selectAgency: handleAgencySelect,
          isAllowedShowAgencySelection
        }
      ),
    };
  };

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="flex flex-wrap">
            <div className="w-full  px-4">
              {isAdd || isLoaded ? (
                <CardSettingsLarge {...buildForm()} />
              ) : (
                <h1>...Loading</h1>
              )}
            </div>
            {/* <div className="w-full xl:w-4/12 px-4">
              <CardDashboardProfile {...carddashboardprofile} />
            </div> */}
          </div>
          <FooterAdmin {...footeradmin} />
        </div>
      </div>
    </>
  );
}
