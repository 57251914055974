import { getInterventions } from 'api/interventions';
import { getNewBusiness } from 'api/newBusiness';
import { getConstructionSites, getOrders, getTroubleShoots } from 'api/orders';
import CardChartJS from 'components/Cards/Admin/CardChartJS';
import FooterAdmin from 'components/Footers/Admin/FooterAdmin';
import HeaderStatCards from 'components/Headers/Admin/HeaderStatCards';
import NavbarSearchUser from 'components/Navbars/NavbarSearchUser';
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import AppContext from 'Contexts/AppContext';
import React, { useContext, useEffect, useState } from 'react';
import { handleSetCardChartJS } from "service/dashboard/HandleSetCardChartJS";
import { handleSetHeaderStatCards } from "service/dashboard/HanldeSetHeaderStatCards";
import { handleSetHeaderStatCards as handleSetHeaderStatCardsInterventions } from "service/interventions/HandleSetHeaderStatCards";
import { handleSetHeaderStatCardNewBusiness } from "service/newBusiness/HandleSetHeaderStatCards";
import { handleSetHeaderStatCardsOrder } from "service/orders/HandleSetHeaderStatCardsOrder";
import { handleSetHeaderStatCards as handleSetHeaderStatCardsForSiteTroubleShoot } from "service/sitesOrTroubleShoots/HandleSetHeaderStatCards";
import { ADVANCEMENT_STATUSES } from "types/baseTypes";
import cardchartjsdashboard1 from "_texts/admin/cards/cardchartjsdashboard1";
import cardchartjsdashboard2 from "_texts/admin/cards/cardchartjsdashboard2";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import headerstatcards from "_texts/admin/headers/headerstatcards.js";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";



export default function SiteAgency() {
  const { selectedAgency } = useContext(AppContext)
  const [
    cardchartjsdashboardturnover,
    setCardchartjsdashboardturnover,
  ] = useState(cardchartjsdashboard1);
  const [
    cardchartjsdashboardtotalorders,
    setCardchartjsdashboardtotalorders,
  ] = useState(cardchartjsdashboard2);
  const [
    headerstatcardsorders,
    setheaderstatcardsorders,
  ] = useState(headerstatcards);

  const [
    headerstatcardssitesortroubleshoots,
    setheaderstatcardssitesortroubleshoots,
  ] = useState(headerstatcards);

  const [
    headerstatcardssitesortConstructionSites,
    setheaderstatcardssitesortConstructionSites,
  ] = useState(headerstatcards);

  const [headerstatcardsdashboard, setheaderstatcardsdashboard] = useState(
    headerstatcards
  );
  const [
    headerstatcardsinterventions,
    setheaderstatcardsinterventions,
  ] = useState(headerstatcards);

  const [
    headerstatcardsnewbusiness,
    setheaderstatcardsnewbusiness,
  ] = useState(headerstatcards);

  const handleFilterOrdersNotRefused = (data) => {
    if (data && data.length) {
      return data.filter(
        (data) => data.advancementStatus !== ADVANCEMENT_STATUSES.REFUSED
      );
    } else return {};
  };



  const handleGetData = async () => {
    try {
      let orders = await getOrders(selectedAgency);

      const newBusiness = await getNewBusiness(selectedAgency);
      const interventions = await getInterventions(selectedAgency);
      const troubleShoots = await getTroubleShoots(selectedAgency);
      const constructionSites = await getConstructionSites(selectedAgency);

      if (orders.length) {
        orders = handleFilterOrdersNotRefused(orders);
      } else {
        console.log("NO ORDERS");
      }

      handleSetHeaderStatCardsOrder(orders, ":running", [
        headerstatcardsorders,
        setheaderstatcardsorders,
      ]);


      handleSetHeaderStatCards(orders, newBusiness, interventions, [
        headerstatcardsdashboard,
        setheaderstatcardsdashboard,
      ]);

      handleSetHeaderStatCardsInterventions(
        interventions,
        null,
        [
          headerstatcardsinterventions,
          setheaderstatcardsinterventions,
        ],
      );

      handleSetHeaderStatCardNewBusiness(newBusiness, [
        headerstatcardsnewbusiness,
        setheaderstatcardsnewbusiness,
      ]);
      handleSetCardChartJS(
        orders,
        [cardchartjsdashboardtotalorders, setCardchartjsdashboardtotalorders],
        [cardchartjsdashboardturnover, setCardchartjsdashboardturnover]
      );

      handleSetHeaderStatCardsForSiteTroubleShoot(
        constructionSites,
        null,
        ":sites",
        [
          headerstatcardssitesortConstructionSites,
          setheaderstatcardssitesortConstructionSites,
        ],
      );
      handleSetHeaderStatCardsForSiteTroubleShoot(
        troubleShoots,
        null,
        ":trouble-shoots",
        [
          headerstatcardssitesortroubleshoots,
          setheaderstatcardssitesortroubleshoots,
        ],
      );


    } catch (err) {
      console.log("err", err);
    }
  };


  useEffect(() => {
    handleGetData();
  }, [selectedAgency]);

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-accentBlue-regular">
        <NavbarSearchUser {...navbarsearchusersettings2} />


        <div style={{ paddingTop: "4rem" }} className="px-2 md:px-2 mx-auto w-full mt-5">
          <div className="flex flex-wrap">
            <div className="w-full md:w-8/12 px-2">
              <CardChartJS {...cardchartjsdashboardturnover} />
            </div>
            <div className="w-full md:w-4/12 px-2">
              <CardChartJS {...cardchartjsdashboardtotalorders} />
            </div>
          </div>
        </div>
        {/* <NavbarSearchUser {...navbarsearchusersettings2} /> */}
        {/* <HeaderStatCards {...headerstatcardsdashboard} /> */}

        <h1 className="bg-accentBlue-regular font-bold pl-3 text-white" >NOUVELLES AFFAIRES</h1>
        <HeaderStatCards {...headerstatcardsnewbusiness} />
        <h1 className="bg-accentBlue-regular font-bold pl-3 text-white" >COMMANDES</h1>
        <HeaderStatCards {...headerstatcardsorders} />
        <h1 className="bg-accentBlue-regular font-bold pl-3 text-white" >CHANTIERS</h1>
        <HeaderStatCards
          {...{
            ...headerstatcardssitesortConstructionSites,
            isAllowedToView: true
          }}
        />
        <h1 className="bg-accentBlue-regular font-bold pl-3 text-white" >INTERVENTIONS</h1>
        <HeaderStatCards
          {...{
            ...headerstatcardssitesortroubleshoots,
            isAllowedToView: true
          }}
        />
        <h1 className="bg-accentBlue-regular font-bold pl-3 text-white" >DÉPANNAGES</h1>
        <HeaderStatCards
          {...{
            ...headerstatcardsinterventions,
            isAllowedToView: true
          }}
        />



        <FooterAdmin {...footeradmin} />
      </div>
    </>
  )
}
