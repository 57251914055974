import CardFullTable from 'components/Cards/Admin/CardFullTable';
import Button from 'components/Elements/Button';
import HeaderStatCards from 'components/Headers/Admin/HeaderStatCards';
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import AppContext from 'Contexts/AppContext';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Role } from 'utils/common';
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";


export default function ListTechniciansReports() {
  const [query, setQuery] = useState("");
  const { user, isSuperAdmin, isAdmin } = useContext(AppContext)

  const history = useHistory();
  const params = useParams();
  const { state: { techniciansReports = [], type = "", id = "" } = {} } = useLocation()
  const userRole = user?.department?.toLowerCase() || "";

  const addPermissions = [
    Role.SUPER_ADMIN,
    Role.ADMIN,
    Role.SERVICE_TECHNIQUE
  ]
  const isAddAllowed = addPermissions.map(p => p.toLowerCase()).includes(userRole);

  useEffect(() => {

    if (!id) history.goBack();

  }, [])

  const dropDownConfig = (technicianReport, isComplete) => {
    const { _id: technicianReportId, technician } = technicianReport;
    const { userPrincipalName } = user;

    const permissions = [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.SERVICE_DIRECTION,
      Role.SERVICE_TECHNIQUE
    ]

    const isDisabledForUser =
      (userRole === Role.SERVICE_TECHNIQUE.trim().toLowerCase() &&
        isComplete) || (userPrincipalName !== technician && (!isSuperAdmin && !isAdmin));

    let suffix = permissions
      .map(p => p.toLowerCase())
      .includes(userRole)
      ? ""
      : "/disabled"

    suffix = isDisabledForUser ? "/disabled" : suffix;

    return ({
      icon: "fas fa-edit",
      button: {
        color: "white",
        size: "sm",
        onClick: () => {
          history.push({
            pathname: `add-technicians-report/${technicianReportId}${suffix}`,
            state: {
              type,
              id,
            }
          })
        }
      },
    })
  };


  const transformData = (technicianReport) => {
    const displayTimeSpent = (technicianReport) => {
      if (!technicianReport) return "-"

      const { startTime, endTime, breakTime } = technicianReport;
      const start = moment(startTime, "HH:mm");
      const end = moment(endTime, "HH:mm");
      const duration = moment.duration(end.diff(start)).subtract(breakTime, "minutes")

      // const timeSpent = duration.asMinutes() - breakTime;
      // const duration = moment.duration(minutes, "minutes");

      return `${duration.hours()}:${duration.minutes()}`
    }
    return {
      rowProps: {
        bg: " ",
      },
      colProps: [
        {
          dropdown: { ...dropDownConfig(technicianReport, technicianReport?.isComplete) },
        },
        moment(technicianReport.date).format("DD/MM/YYYY"),
        technicianReport.technician || "-",
        technicianReport.startTime || "-",
        technicianReport.endTime || "-",
        technicianReport.breakTime || "-",
         displayTimeSpent(technicianReport),
        technicianReport.comment || "-"
      ],
    };
  };


  const LIST_TABLE_HEADER = {
    title: "Liste des comptes rendus",
    color: "white",
    head: [
      {
        onClick: () => { },
        string: "Editer",
      },
      {
        string: "date",
      },
      {
        string: "technicien",
      },
      {
        string: "Heure début",
      },
      {
        string: "Heure fin",
      },
      {
        string: "Pause",
      },
      {
        string: "Durée (H:m)",
      },
      {
        string: "Commentaire",
      },

    ],
    body: techniciansReports.map(transformData),
  }

  const handleTechniciansReport = () => {
    history.push({
      pathname: `/add-technicians-report`,
      state: {
        type,
        id,
      }
    })
  }

  return (
    <>

      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="w-full h-full px-4 md:px-6 mx-auto -mt-24">
          <div className="w-full h-full hello flex flex-wrap">
            <div className="px-4 flex flex-nowrap items-center justify-between w-full">
              <div className="flex">

                {/* <Input
                  {...{
                    placeholder: "Recherche",
                    type: "text",
                    onChange: (e) =>
                      setQuery(e.target.value),
                  }}
                /> */}
                {
                  isAddAllowed &&
                  <Button {...{
                    style: {
                      zIndex: 1,
                      marginLeft: "10px"
                    },
                    children: "Ajouter un compte de rendu",
                    size: "sm",
                    color: "lightBlue",
                    onClick: handleTechniciansReport
                  }} />
                }

              </div>

              {/* <Breadcrumb
                location={location}
                pageName={cardfulltablenewbusiness.title}
              /> */}
            </div>
            <div className="w-full h-full px-2">
              <CardFullTable
                {...LIST_TABLE_HEADER}
              // body={filteredSupervisions}
              />
            </div>
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  )
}
