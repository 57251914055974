import {
  advancementStatusReverseCorres, ADVANCEMENT_STATUSES, siteStatusReverseCorres, SITE_STATUSES, STATUSES, statusReverseCorres, typeOfBusinessReverseCorres
} from "types/baseTypes";

export const handleFilterSites = (data) => {
  if (data && data.length) {
    return data?.filter(
      (elt) => elt.amount && elt.amount >= 1000,
    );
  } else return [];
};

export const handleFilterTroubleShoots = (data) => {
  if (data && data.length) {
    return data?.filter(
      (elt) => elt.amount && elt.amount < 1000,
    );
  } else return [];
};

export const handleFilterOrdersOfMonth = (orders) => {
  if (orders && orders.length) {
    return orders
      ?.filter(
        (order) =>
          new Date(
            order.dateOfSignature
              ? order.dateOfSignature
              : order.createdAt,
          ).getUTCFullYear() === new Date().getFullYear(),
      )
      .filter(
        (order) =>
          new Date(
            order.dateOfSignature
              ? order.dateOfSignature
              : order.createdAt,
          ).getMonth() === new Date().getMonth(),
      );
  } else return [];
};

export const handleFilterOrdersOfYear = (orders) => {
  if (orders && orders?.length > 0) {
    return orders?.filter(
      (order) =>
        new Date(
          order.dateOfSignature
            ? order.dateOfSignature
            : order.createdAt,
        ).getUTCFullYear() === new Date().getFullYear(),
    );
  } else return [];
};

export const handleFilterOrdersOfLastMonthUntilDDay = (
  bizz,
) => {
  if (bizz && bizz.length > 0) {
    return bizz
      ?.filter(
        (order) =>
          new Date(
            order.dateOfSignature
              ? order.dateOfSignature
              : order.createdAt,
          ).getFullYear() === new Date().getFullYear(),
      )
      .filter(
        (order) =>
          new Date(
            order.dateOfSignature
              ? order.dateOfSignature
              : order.createdAt,
          ).getMonth() ===
          new Date().getMonth() - 1,
      )
      .filter(
        (order) =>
          new Date(
            order.dateOfSignature
              ? order.dateOfSignature
              : order.createdAt,
          ).getDate() <= new Date().getDate(),
      );
  } else return [];
};

export const cumulAmount = (bizz) => {
  if (bizz && bizz.length) {
    return bizz?.reduce(
      (acc, curr) =>
        curr.amount
          ? (acc += curr.amount)
          : curr.orderId?.amount
            ? (acc += curr.orderId?.amount)
            : (acc += 0),
      0,
    );
  } else return [];
};

export const handleFilterOrdersOfLastYearUntilMMonth = (
  bizz,
) => {
  if (bizz && bizz.lenth > 0) {
    return bizz
      ?.filter(
        (order) =>
          new Date(
            order.dateOfSignature
              ? order.dateOfSignature
              : order.createdAt,
          ).getFullYear() ===
          new Date().getFullYear() - 1,
      )
      .filter(
        (order) =>
          new Date(
            order.dateOfSignature
              ? order.dateOfSignature
              : order.createdAt,
          ).getMonth() <= new Date().getMonth(),
      );
  } else return [];
};

export const handleFilterCreated = (newB) => {
  return newB?.filter(
    (elt) => elt.status === STATUSES.CREATED,
  );
};

export const handleFilterSent = (newB) => {
  return newB.filter((elt) => elt.status === STATUSES.SENT);
};

export const handleFilterHot = (newB) => {
  return newB
    .filter(
      (elt) =>
        new Date(elt.objectifDateInvoice) < new Date(),
    )
    .filter((elt) => elt.status === STATUSES.CREATED)
    .filter((elt) => !elt.invoiceDate);
};

export const handleFilterOrdersNotRefused = (data) => {
  return data?.filter(
    (data) =>
      data.advancementStatus !==
      ADVANCEMENT_STATUSES.REFUSED,
  );
};
export const handleFilterOrdersNotFinished = (data) => {
  return data?.filter(
    (data) =>
      data.advancementStatus !==
      ADVANCEMENT_STATUSES.FINISHED ||
      data.siteStatus !== SITE_STATUSES.FINISHED,
  );
};

export const handleFilterOrdersToStart = (data) => {
  return data?.filter(
    (data) =>
      data.advancementStatus ===
      ADVANCEMENT_STATUSES.TO_START,
  );
};
export const handleFilterOrdersRunning = (data) => {
  return data.filter(
    (data) =>
      data.advancementStatus ===
      ADVANCEMENT_STATUSES.RUNNING,
  );
};

export const handleFilterOrdersFinished = (data) => {
  return data?.filter(
    (data) =>
      data.advancementStatus ===
      ADVANCEMENT_STATUSES.FINISHED ||
      data.siteStatus === SITE_STATUSES.FINISHED,
  );
};

export const handleFilterOrdersRefused = (data) => {
  return data?.filter(
    (data) =>
      data.advancementStatus ===
      ADVANCEMENT_STATUSES.REFUSED,
  );
};

export const handleFilterOrdersTodo = (data) => {
  return data?.filter(
    (data) => data.siteStatus === SITE_STATUSES.TO_DO,
  );
};

export const handleFilterOrdersPurchased = (data) => {
  return data?.filter(
    (data) => data.siteStatus === SITE_STATUSES.PURCHASED,
  );
};

export const handleFilterOrdersDelivered = (data) => {
  return data?.filter(
    (data) => data.siteStatus === SITE_STATUSES.DELIVERED,
  );
};

export const handleFilterOrdersPlanified = (data) => {
  return data?.filter(
    (data) => data.siteStatus === SITE_STATUSES.PLANIFIED,
  );
};

export const handleFilterOrdersOf3Monthes = (orders) => {
  return orders
    ?.filter(
      (order) =>
        new Date(
          order.dateOfSignature || order.createdAt,
        ).getUTCFullYear() === new Date().getFullYear(),
    )
    .filter(
      (order) =>
        new Date(
          order.dateOfSignature || order.createdAt,
        ).getMonth() >=
        new Date().getMonth() - 2,
    );
};

export const handleFilterOrdersOf2Years = (orders) => {
  return orders?.filter(
    (order) =>
      new Date(
        order.dateOfSignature || order.createdAt,
      ).getUTCFullYear() >=
      new Date().getFullYear() - 1,
  );
};

export const countByYearNMonth = (bizz) => {
  if (bizz && bizz.length > 0) {
    return bizz?.reduce((acc, curr) => {
      const K =
        new Date().getFullYear() -
        new Date(
          curr.dateOfSignature
            ? curr.dateOfSignature
            : curr.createdAt,
        ).getFullYear();
      const k = new Date(
        curr.dateOfSignature
          ? curr.dateOfSignature
          : curr.createdAt,
      ).getMonth();
      if (!acc[K]) {
        acc[K] = [];
        for (let i = 0; i <= 11; i++) {
          if (!acc[K][i]) {
            acc[K][i] = 0;
          }
        }
      }
      acc[K][k] += 1;
      return acc;
    }, {});
  } else return [];
};

export const turnoverByYearNMonth = (bizz) => {
  if (bizz && bizz.length > 0) {
    return bizz?.reduce((acc, curr) => {
      const K =
        new Date().getFullYear() -
        new Date(
          curr.dateOfSignature
            ? curr.dateOfSignature
            : curr.createdAt,
        ).getFullYear();
      const k = new Date(
        curr.dateOfSignature
          ? curr.dateOfSignature
          : curr.createdAt,
      ).getMonth();

      if (!acc[K]) {
        acc[K] = [];
        for (let i = 0; i <= 11; i++) {
          if (!acc[K][i]) {
            acc[K][i] = 0;
          }
        }
      }
      curr.amount
        ? (acc[K][k] += curr.amount)
        : console.log(
          "this business has no amount: ",
          curr,
        );
      return acc;
    }, {});
  } else return [];
};

export function capitalizeFirstLetter(string = "") {
  return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
}

export const sortData = (
  data,
  entryOfDataToSortOn,
  isSortPositif = true,
  typeToSort = "date",
) => {
  return data?.sort((a, b) => {
    let valueA = a[entryOfDataToSortOn];
    let valueB = b[entryOfDataToSortOn];

    if (
      entryOfDataToSortOn === "clientLastName" ||
      entryOfDataToSortOn === "companyName" ||
      entryOfDataToSortOn === "clientFirstName"
    ) {
      if (a.typeOfClient !== "Particulier") {
        valueA = a.companyName;
      }
      if (b.typeOfClient !== "Particulier") {
        valueB = b.companyName;
      }
    }
    if (
      typeToSort === "string" &&
      (entryOfDataToSortOn === "status" ||
        entryOfDataToSortOn === "siteStatus" ||
        entryOfDataToSortOn === "advancementStatus")
    ) {
      if (typeof a.status === "number") {
        valueA = statusReverseCorres[a.status];
      } else if (typeof a.advancementStatus === "number") {
        valueA =
          advancementStatusReverseCorres[
          a.advancementStatus
          ];
      } else if (typeof a.siteStatus === "number") {
        valueA = siteStatusReverseCorres[a.siteStatus];
      }
      if (typeof b.status === "number") {
        valueB = statusReverseCorres[b.status];
      } else if (typeof b.advancementStatus === "number") {
        valueB =
          advancementStatusReverseCorres[
          b.advancementStatus
          ];
      } else if (typeof b.siteStatus === "number") {
        valueB = siteStatusReverseCorres[b.siteStatus];
      }
    }

    if (entryOfDataToSortOn === "typeOfBusiness") {
      valueA =
        typeOfBusinessReverseCorres[a.typeOfBusiness];
      valueB =
        typeOfBusinessReverseCorres[b.typeOfBusiness];
    }

    if (typeToSort === "string") {
      valueA = valueA ? valueA.toUpperCase() : "A";
      valueB = valueB ? valueB.toUpperCase() : "A";
      if (isSortPositif === true) {
        if (valueA < valueB) {
          return -1;
        }
        if (valueA > valueB) {
          return 1;
        }
      } else {
        if (valueA < valueB) {
          return 1;
        }
        if (valueA > valueB) {
          return -1;
        }
      }
    } else if (typeToSort === "date") {
      valueA = valueA ? new Date(valueA) : new Date();
      valueB = valueB ? new Date(valueB) : new Date();
      if (isSortPositif) {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    } else {
      if (valueA === undefined) {
        valueA = 0;
      }
      if (valueB === undefined) {
        valueB = 0;
      }
      if (valueA === valueB) {
        return 1;
      }

      if (isSortPositif) {
        return valueA - valueB;
      } else return valueB - valueA;
    }
    return 1
  });
};
