
const textProps = {
  color: "accentBlue",
  cards: [
    { 
      statSubtitle: "NOUVELLES AFFAIRES",
      statTitle: "350,897",
      statArrow: "up",
      statPercent: "3.48",
      statPercentColor: "text-emerald-500",
      statDescripiron: "Depuis le mois dernier",
      statIconName: "far fa-chart-bar",
      statIconColor: "bg-red-500",
    },
    
    {
      statSubtitle: "NOUV AFFAIRES A ENVOYER",
      statTitle: "49,65%",
      statArrow: "up",
      statPercent: "12",
      statPercentColor: "text-emerald-500",
      statDescripiron: "Depuis le mois dernier",
      statIconName: "fas fa-users",
      statIconColor: "bg-pink-500",
    },
    {
      statSubtitle: "NOUV AFFAIRES URGENTES",
      statTitle: "924",
      statArrow: "down",
      statPercent: "1.10",
      statPercentColor: "text-white-500",
      statDescripiron: "Depuis le mois dernier",
      statIconName: "fas fa-chart-pie",
      statIconColor: "bg-agOrange-regular",
    },
    {
      statSubtitle: "CA NOUVELLES AFFAIRES",
      statTitle: "49,65%",
      statArrow: "up",
      statPercent: "12",
      statPercentColor: "text-emerald-500",
      statDescripiron: "Depuis le début de l'année",
      statIconName: "fas fa-percent",
      statIconColor: "bg-lightBlue-500",
    },
  ],
};
export default textProps;
