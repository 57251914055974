// src/forms/newBusiness/ClimatisationHeatTechnicalReportForm.js

import {
	buildPeriodCorres,
	buildPeriodReverseCorres,
	BUILD_PERIOD,
	elecHeatRegulationTypeCorres,
	elecHeatRegulationTypeReverseCorres,
	electricityTypeCorres,
	electricityTypeReverseCorres,
	ELECTRICITY_TYPE,
	ELEC_HEAT_REGULATION_TYPE,
	emeterTypeCorres,
	emeterTypeReverseCorres,
	EMETER_TYPE,
	heatTypeCorres,
	heatTypeReverseCorres,
	HEAT_TYPE,
	hotWaterDeportedTypeCorres,
	hotWaterDeportedTypeReverseCorres,
	HOT_WATER_DEPORTED_TYPE,
	houseSizeCorres,
	houseSizeReverseCorres,
	HOUSE_SIZE,
	hydroHeatTypeCorres,
	hydroHeatTypeReverseCorres,
	HYDRO_HEAT_TYPE,
	newClimActionChoiceCorres,
	newClimActionChoiceReverseCorres,
	newClimActionRepriseCorres,
	newClimActionRepriseReverseCorres,
	newClimMouthTypeCorres,
	newClimMouthTypeReverseCorres,
	newHeatTypeCorres,
	newHeatTypeReverseCorres,
	newLowConsoHeatTypeCorres,
	newLowConsoHeatTypeReverseCorres,
	newPacActionCorres,
	newPacActionReverseCorres,
	NEW_CLIM_ACTION_CHOICE,
	NEW_CLIM_ACTION_REPRISE,
	NEW_CLIM_MOUTH_TYPE,
	NEW_HEAT_TYPE,
	NEW_LOW_CONSO_HEAT_TYPE,
	NEW_PAC_ACTION,
	partOfHouseCorres,
	partOfHouseReverseCorres,
	PART_OF_HOUSE,
	roofIsolationCorres,
	roofIsolationReverseCorres,
	roofTypeCorres,
	roofTypeReverseCorres,
	ROOF_ISOLATION,
	ROOF_TYPE,
	smokeSysTypeCorres,
	smokeSysTypeReverseCorres,
	SMOKE_SYS_TYPE,
	thermostatTypeCorres,
	thermostatTypeReverseCorres,
	THERMOSTAT_TYPE,
	typeOfWallsCorres,
	typeOfWallsReverseCorres,
	TYPE_OF_WALLS,
	wallsIsolationCorres,
	wallsIsolationReverseCorres,
	WALLS_ISOLATION,
	wireClimSysTypeCorres,
	wireClimSysTypeReverseCorres,
	WIRE_CLIM_SYS_TYPE,
} from "types/technicalReportTypes";
import { imageHandler } from "../../utils/common";

export default function ClimatisationHeatTechnicalReportForm(
	onGoingTechnicalReport,
	setOnGoingTechnicalReport,
	isDisabled = false,
	technicalType = "HEAT", // "HEAT" or "CLIM"
) {
	const handleAddNewImage = (imageUrl) => {
		setOnGoingTechnicalReport((vals) => {
			const images = vals?.images || [];
			return {
				...vals,
				images: [...images, { url: imageUrl }],
			};
		});
	};

	const handleRemoveOfNewImage = (imageUrl) => {
		setOnGoingTechnicalReport((vals) => {
			return {
				...vals,
				images: vals?.images?.filter(({ url }) => url !== imageUrl),
			};
		});
	};

	const handleChangeOfImage = (existingImageUrl, newImageUrl) => {
		setOnGoingTechnicalReport((vals) => {
			return {
				...vals,
				images: vals?.images?.map((image) => {
					const { url, ...args } = image;
					if (url === existingImageUrl) return { ...args, url: newImageUrl };
					return image;
				}),
			};
		});
	};

	const onChangeText = (existingImageUrl, text) => {
		setOnGoingTechnicalReport((vals) => {
			return {
				...vals,
				images: vals?.images?.map((image) => {
					const { url } = image;
					if (url === existingImageUrl) return { ...image, description: text };
					return image;
				}),
			};
		});
	};

	const setBathroomsArea = (idx, area) => {
		let rooms = Array.isArray(onGoingTechnicalReport?.bathroomArea)
			? onGoingTechnicalReport?.bathroomArea
			: [area];
		rooms = rooms.map((roomArea, roomAreaIdx) => {
			if (idx === roomAreaIdx) return area;
			return roomArea;
		});
		if (rooms.length === 0) rooms.push(area);

		setOnGoingTechnicalReport((vals) => ({ ...vals, bathroomArea: rooms }));
	};

	const addNewBathroomArea = () => {
		try {
			let rooms = Array.isArray(onGoingTechnicalReport?.bathroomArea)
				? onGoingTechnicalReport?.bathroomArea
				: [];

			setOnGoingTechnicalReport((vals) => ({
				...vals,
				bathroomArea: [...rooms, 0],
			}));
		} catch (err) {
			console.log("err add new bathroom", err);
		}
	};
	const removeNewBathroomArea = (idx) => {
		try {
			let rooms = Array.isArray(onGoingTechnicalReport?.bathroomArea)
				? onGoingTechnicalReport?.bathroomArea
				: [];

			rooms = rooms.filter((r, i) => idx !== i);

			setOnGoingTechnicalReport((vals) => ({
				...vals,
				bathroomArea: [...rooms],
			}));
		} catch (err) {
			console.log("err add new bathroom", err);
		}
	};

	const getBathroomsArea = () => {
		let rooms =
			onGoingTechnicalReport?.bathroomArea &&
			Array.isArray(onGoingTechnicalReport?.bathroomArea)
				? onGoingTechnicalReport?.bathroomArea
				: [onGoingTechnicalReport?.bathroomArea || 0];

		if (rooms.length === 0) return [0];

		return rooms;
	};

	const setRoomsArea = (idx, area) => {
		let rooms = Array.isArray(onGoingTechnicalReport?.roomsArea)
			? onGoingTechnicalReport?.roomsArea
			: [area];

		rooms = rooms.map((roomArea, roomAreaIdx) => {
			if (idx === roomAreaIdx) return area;
			return roomArea;
		});
		if (rooms.length === 0) rooms = [area];

		setOnGoingTechnicalReport((vals) => ({ ...vals, roomsArea: rooms }));
	};

	const addNewRoomArea = () => {
		// console.log(
		// 	"onGoingTechnicalReport?.roomsArea",
		// 	onGoingTechnicalReport,
		// 	onGoingTechnicalReport?.roomsArea,
		// );
		// let rooms = Array.isArray(onGoingTechnicalReport?.roomsArea)
		// 	? onGoingTechnicalReport?.roomsArea
		// 	: [];

		setOnGoingTechnicalReport((vals) => ({
			...vals,
			roomsArea: [...(vals.roomsArea || []), 0],
		}));
	};

	const removeNewRoomArea = (idx) => {
		let rooms = Array.isArray(onGoingTechnicalReport?.roomsArea)
			? onGoingTechnicalReport?.roomsArea
			: [];

		rooms = rooms.filter((r, i) => idx !== i);

		setOnGoingTechnicalReport((vals) => ({
			...vals,
			roomsArea: [...rooms],
		}));
	};

	const getRoomsArea = () => {
		const rooms = onGoingTechnicalReport?.roomsArea || [];

		if (rooms.length === 0) return [0];

		return rooms;
	};

	return [
		// {
		//   title: `Relevé technique ${typeOfBusinessReverseCorres[typeOfBusiness]}`,

		//   inputs: [],
		// },
		{
			titles: ["Plan fournis par le client"],
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.hasMap === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.hasMap === undefined
								? false
								: onGoingTechnicalReport.hasMap,
						checked:
							onGoingTechnicalReport.hasMap === undefined
								? false
								: onGoingTechnicalReport.hasMap,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasMap:
									onGoingTechnicalReport.hasMap === undefined
										? true
										: !!!onGoingTechnicalReport.hasMap,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 6,
					className: "half",
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.hasMap === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.hasMap === undefined
								? false
								: !onGoingTechnicalReport.hasMap,
						checked:
							onGoingTechnicalReport.hasMap === undefined
								? false
								: !onGoingTechnicalReport.hasMap,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasMap:
									onGoingTechnicalReport.hasMap === undefined
										? false
										: !!!onGoingTechnicalReport.hasMap,
							}));
						},
					},
				},
			],
		},
		{
			titles: ["Appartement ou maison individuelle"],
			inputs: [
				{
					label: "Appartement",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isHouse === undefined
								? "borderwarning"
								: "border",
						defaultValue:
							onGoingTechnicalReport.isHouse === undefined ||
							onGoingTechnicalReport.isHouse === null
								? false
								: !onGoingTechnicalReport.isHouse,
						checked:
							onGoingTechnicalReport.isHouse === undefined ||
							onGoingTechnicalReport.isHouse === null
								? false
								: !onGoingTechnicalReport.isHouse,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isHouse:
									onGoingTechnicalReport.isHouse === undefined ||
									onGoingTechnicalReport.isHouse === null
										? false
										: !onGoingTechnicalReport.isHouse,
							}));
						},
					},
				},
				{
					label: "Maison",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isHouse === undefined
								? "borderwarning"
								: "border",
						defaultValue:
							onGoingTechnicalReport.isHouse === undefined
								? false
								: onGoingTechnicalReport.isHouse,
						checked:
							onGoingTechnicalReport.isHouse === undefined
								? false
								: onGoingTechnicalReport.isHouse,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isHouse:
									onGoingTechnicalReport.isHouse === undefined ||
									onGoingTechnicalReport.isHouse === null
										? true
										: !onGoingTechnicalReport.isHouse,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isHouse === false && {
					label: "Nombre d'étages",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.nbOfFloors === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 99,
						changeValue: 1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.nbOfFloors,
						value: onGoingTechnicalReport.nbOfFloors,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								nbOfFloors: e,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isHouse === true && {
			titles: ["Mitoyenne"],
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isTerracedHouse === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isTerracedHouse === undefined
								? false
								: onGoingTechnicalReport.isTerracedHouse,
						checked:
							onGoingTechnicalReport.isTerracedHouse === undefined
								? false
								: onGoingTechnicalReport.isTerracedHouse,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isTerracedHouse:
									onGoingTechnicalReport.isTerracedHouse === undefined
										? true
										: !onGoingTechnicalReport.isTerracedHouse,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isTerracedHouse === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isTerracedHouse === undefined
								? false
								: !onGoingTechnicalReport.isTerracedHouse,
						checked:
							onGoingTechnicalReport.isTerracedHouse === undefined
								? false
								: !onGoingTechnicalReport.isTerracedHouse,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isTerracedHouse:
									onGoingTechnicalReport.isTerracedHouse === undefined
										? false
										: !onGoingTechnicalReport.isTerracedHouse,
							}));
						},
					},
				},
				(onGoingTechnicalReport?.isHouse ||
					onGoingTechnicalReport?.isTerracedHouse) && {
					label: "Nombre de murs",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.nbOfWalls === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 3,
						color: "lightBlue",
						placeholder: "Nombre de murs",
						defaultValue: onGoingTechnicalReport.nbOfWalls,
						value: onGoingTechnicalReport.nbOfWalls,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								nbOfWalls: e,
							}));
						},
					},
				},
			],
		},
		{
			titles: ["Type de logement"],
			inputs: [
				{
					label: "Neuf",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isNewProperty === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isNewProperty === undefined
								? false
								: !onGoingTechnicalReport.isNewProperty,
						checked:
							onGoingTechnicalReport.isNewProperty === undefined
								? false
								: onGoingTechnicalReport.isNewProperty,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isNewProperty:
									onGoingTechnicalReport.isNewProperty === undefined
										? true
										: !onGoingTechnicalReport.isNewProperty,
							}));
						},
					},
				},
				{
					label: "Rénovation",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isNewProperty === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isNewProperty === undefined
								? false
								: !onGoingTechnicalReport.isNewProperty,
						checked:
							onGoingTechnicalReport.isNewProperty === undefined
								? false
								: !onGoingTechnicalReport.isNewProperty,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isNewProperty:
									onGoingTechnicalReport.isNewProperty === undefined
										? false
										: !onGoingTechnicalReport.isNewProperty,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isNewProperty === true && {
			inputs: [
				{
					label: "Déperditions du bureau technique",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.hasDeperditions === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.hasDeperditions,
						checked: onGoingTechnicalReport.hasDeperditions,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasDeperditions: !!!onGoingTechnicalReport.hasDeperditions,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isNewProperty === false && {
			titles: [
				"Année de Construction",
				// "Traveaux d'isolation",
			],
			inputs: [
				{
					label: "",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							!onGoingTechnicalReport.buildYear ||
							isNaN(onGoingTechnicalReport?.buildYear) ||
							onGoingTechnicalReport?.buildYear?.toString().length !== 4 ||
							parseInt(onGoingTechnicalReport?.buildYear) < 1900
								? "borderwarning"
								: "border",
						// placeholder: new Date().getFullYear(),,
						color: "lightBlue",
						min: 0,
						max: new Date().getFullYear(),
						defaultValue: onGoingTechnicalReport?.buildYear,
						value: onGoingTechnicalReport?.buildYear,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								buildYear: e,
							}));
						},
					},
				},
				// {
				//   label: "Oui",
				//   width: 3,
				//   checkbox: {
				//     disabled: isDisabled,
				//     border:
				//       onGoingTechnicalReport.hasIsolationWorks ===
				//         undefined
				//         ? "borderwarning"
				//         : "border",
				//     placeholder: "",
				//     defaultValue:
				//       onGoingTechnicalReport.hasIsolationWorks ===
				//         undefined
				//         ? false
				//         : onGoingTechnicalReport.hasIsolationWorks,
				//     checked:
				//       onGoingTechnicalReport.hasIsolationWorks ===
				//         undefined
				//         ? false
				//         : onGoingTechnicalReport.hasIsolationWorks,
				//     onChange: (e) => {
				//       setOnGoingTechnicalReport((vals) => ({
				//         ...vals,
				//         hasIsolationWorks:
				//           onGoingTechnicalReport.hasIsolationWorks ===
				//             undefined
				//             ? true
				//             : !onGoingTechnicalReport.hasIsolationWorks,
				//       }))

				//     },
				//   },
				// },
				// {
				//   label: "Non",
				//   width: 3,
				//   checkbox: {
				//     disabled: isDisabled,
				//     placeholder: "",
				//     defaultValue:
				//       onGoingTechnicalReport.hasIsolationWorks ===
				//         undefined
				//         ? false
				//         : !onGoingTechnicalReport.hasIsolationWorks,
				//     checked:
				//       onGoingTechnicalReport.hasIsolationWorks ===
				//         undefined
				//         ? false
				//         : !onGoingTechnicalReport.hasIsolationWorks,
				//     onChange: (e) => {
				//       setOnGoingTechnicalReport((vals) => ({
				//         ...vals,
				//         hasIsolationWorks:
				//           onGoingTechnicalReport.hasIsolationWorks ===
				//             undefined
				//             ? false
				//             : !onGoingTechnicalReport.hasIsolationWorks,
				//       }))

				//     },
				//   },
				// },
			],
		},
		{
			titles: ["Isolation"],
			inputs: [
				{
					label: "Type de murs",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.typeOfWalls === undefined
								? "borderwarning"
								: "border",
						placeholder: "Type de murs",
						defaultValue:
							typeOfWallsReverseCorres[onGoingTechnicalReport.typeOfWalls],
						items: ["Brique", "Placo", "Pierre"],
						value: typeOfWallsReverseCorres[onGoingTechnicalReport.typeOfWalls],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								typeOfWalls: TYPE_OF_WALLS[typeOfWallsCorres[e]],
							}));
						},
					},
				},

				{
					label: "Isolation des Murs",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.wallsIsolation === undefined
								? "borderwarning"
								: "border",
						placeholder: "Isolation des Murs",
						defaultValue:
							wallsIsolationReverseCorres[
								onGoingTechnicalReport.wallsIsolation
							],
						items: ["Air", "Laine de verre", "Polystyrenne", "Inexistant"],
						value:
							wallsIsolationReverseCorres[
								onGoingTechnicalReport.wallsIsolation
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								wallsIsolation: WALLS_ISOLATION[wallsIsolationCorres[e]],
							}));
						},
					},
				},
				{
					label: "Période de construction",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.buildPeriod === undefined
								? "borderwarning"
								: "border",
						placeholder: "Isolation des Murs",
						defaultValue:
							buildPeriodReverseCorres[onGoingTechnicalReport.buildPeriod],
						items: Object.keys(buildPeriodCorres),
						value: buildPeriodReverseCorres[onGoingTechnicalReport.buildPeriod],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								buildPeriod: BUILD_PERIOD[buildPeriodCorres[e]],
							}));
						},
					},
				},
				{
					label: "Type de plafond",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.roofType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Type de Plafond",
						defaultValue:
							roofTypeReverseCorres[onGoingTechnicalReport.roofType],

						items: ["Placo", "Hourdi", "Plafonette"],
						value: roofTypeReverseCorres[onGoingTechnicalReport.roofType],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								roofType: ROOF_TYPE[roofTypeCorres[e]],
							}));
						},
					},
				},
			],
		},
		{
			titles: ["Isolation toiture"],
			inputs: [
				{
					label: "Type",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.ceilingType === undefined
								? "borderwarning"
								: "border",
						placeholder: "type de toiture",
						defaultValue:
							roofTypeReverseCorres[onGoingTechnicalReport.ceilingType],
						items: ["Comble perdu", "Aménagé"],
						value: roofTypeReverseCorres[onGoingTechnicalReport.ceilingType],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								ceilingType: ROOF_TYPE[roofTypeCorres[e]],
							}));
						},
					},
				},

				{
					label: "Isolation des comble",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.roofIsolation === undefined
								? "borderwarning"
								: "border",
						placeholder: "Isolation des combles",
						defaultValue:
							roofIsolationReverseCorres[onGoingTechnicalReport.roofIsolation],
						items: ["Soufflés", "Déroulés"],
						value:
							roofIsolationReverseCorres[onGoingTechnicalReport.roofIsolation],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								roofIsolation: ROOF_ISOLATION[roofIsolationCorres[e]],
							}));
						},
					},
				},
				// onGoingTechnicalReport?.hasIsolationWorks && onGoingTechnicalReport?.roofIsolation &&
				{
					label: "épaisseur en cm",
					width: 10,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.roofIsolationThickness === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 200,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.roofIsolationThickness,
						value: onGoingTechnicalReport.roofIsolationThickness,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								roofIsolationThickness: e,
							}));
						},
					},
				},
				{
					label: "Date de l'isolation",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.roofIsolationPeriod === undefined
								? "borderwarning"
								: "border",
						placeholder: "Isolation des Murs",
						defaultValue:
							buildPeriodReverseCorres[
								onGoingTechnicalReport.roofIsolationPeriod
							],
						items: Object.keys(buildPeriodCorres),
						value:
							buildPeriodReverseCorres[
								onGoingTechnicalReport.roofIsolationPeriod
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								roofIsolationPeriod: BUILD_PERIOD[buildPeriodCorres[e]],
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isNewProperty === false && {
			titles: ["Types de fenêtres"],
			inputs: [
				{
					label: "Bois",
					width: 2,
					className: "third",
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasWoodWindow &&
							!!!onGoingTechnicalReport.hasPvcWindow &&
							!!!onGoingTechnicalReport.hasAluWindow
								? "borderwarning"
								: "border",
						defaultValue:
							onGoingTechnicalReport.hasWoodWindow === undefined
								? false
								: onGoingTechnicalReport.hasWoodWindow,
						checked:
							onGoingTechnicalReport.hasWoodWindow === undefined
								? false
								: onGoingTechnicalReport.hasWoodWindow,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasWoodWindow:
									onGoingTechnicalReport.hasWoodWindow === undefined ||
									onGoingTechnicalReport.hasWoodWindow === null
										? true
										: !onGoingTechnicalReport.hasWoodWindow,
							}));
						},
					},
				},
				{
					label: "PVC",
					width: 2,
					className: "third",
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasWoodWindow &&
							!!!onGoingTechnicalReport.hasPvcWindow &&
							!!!onGoingTechnicalReport.hasAluWindow
								? "borderwarning"
								: "border",
						defaultValue:
							onGoingTechnicalReport.hasPvcWindow === undefined
								? false
								: onGoingTechnicalReport.hasPvcWindow,
						checked:
							onGoingTechnicalReport.hasPvcWindow === undefined
								? false
								: onGoingTechnicalReport.hasPvcWindow,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasPvcWindow:
									onGoingTechnicalReport.hasPvcWindow === undefined ||
									onGoingTechnicalReport.hasPvcWindow === null
										? true
										: !onGoingTechnicalReport.hasPvcWindow,
							}));
						},
					},
				},
				{
					label: "Alu",
					width: 2,
					className: "third",
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasWoodWindow &&
							!!!onGoingTechnicalReport.hasPvcWindow &&
							!!!onGoingTechnicalReport.hasAluWindow
								? "borderwarning"
								: "border",
						defaultValue:
							onGoingTechnicalReport.hasAluWindow === undefined
								? false
								: onGoingTechnicalReport.hasAluWindow,
						checked:
							onGoingTechnicalReport.hasAluWindow === undefined
								? false
								: onGoingTechnicalReport.hasAluWindow,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasAluWindow:
									onGoingTechnicalReport.hasAluWindow === undefined ||
									onGoingTechnicalReport.hasAluWindow === null
										? true
										: !onGoingTechnicalReport.hasAluWindow,
							}));
						},
					},
				},

				{
					label: "Années des fenêtres",

					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport?.windowWorksPeriod === undefined
								? "borderwarning"
								: "border",
						placeholder: "Rénovation des fenêtres",
						defaultValue:
							buildPeriodReverseCorres[
								onGoingTechnicalReport?.windowWorksPeriod
							],
						items: Object.keys(buildPeriodCorres),
						value:
							buildPeriodReverseCorres[
								onGoingTechnicalReport.windowWorksPeriod
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								windowWorksPeriod: BUILD_PERIOD[buildPeriodCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport?.windowWorksPeriod ===
					BUILD_PERIOD.AFTER_2000 && {
					label: "Double vitrage",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.doubleWindows === undefined
								? "borderwarning"
								: "border",
						placeholder: "Double vitrage",
						defaultValue:
							partOfHouseReverseCorres[onGoingTechnicalReport.doubleWindows],
						items: Object.keys(partOfHouseCorres),
						value:
							partOfHouseReverseCorres[onGoingTechnicalReport.doubleWindows],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								doubleWindows: PART_OF_HOUSE[partOfHouseCorres[e]],
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport.isNewProperty === false && {
			inputs: [
				{
					label: "Température d’ambiance actuelle dans le logement (°C)",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.ambiantTemperature === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 40,
						changeValue: 1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport?.ambiantTemperature,
						value: onGoingTechnicalReport?.ambiantTemperature,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								ambiantTemperature: e,
							}));
						},
					},
				},
				{
					label: "Alimentation électrique",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.electricityType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Alimentation électrique",
						defaultValue:
							electricityTypeReverseCorres[
								onGoingTechnicalReport.electricityType
							],
						items: ["Monophasée", "Triphasée"],
						value:
							electricityTypeReverseCorres[
								onGoingTechnicalReport.electricityType
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								electricityType: ELECTRICITY_TYPE[electricityTypeCorres[e]],
							}));
						},
					},
				},
				{
					label: "Puissance abonnement EDF ( Ampère )",
					width: 10,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.powerElec === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 100,
						color: "lightBlue",
						placeholder: "puissance",
						defaultValue: onGoingTechnicalReport.powerElec,
						value: onGoingTechnicalReport.powerElec,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								powerElec: e,
							}));
						},
					},
				},
			],
		},

		onGoingTechnicalReport.isNewProperty === false && {
			title: "Surfaces en m²",
			inputs: [
				{
					label: "Taille du logement",
					width: 10,

					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.houseSize === undefined
								? "borderwarning"
								: "border",
						placeholder: "Taille du logement",
						defaultValue:
							houseSizeReverseCorres[onGoingTechnicalReport.houseSize],
						items: Object.keys(houseSizeCorres),
						value: houseSizeReverseCorres[onGoingTechnicalReport.houseSize],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								houseSize: HOUSE_SIZE[houseSizeCorres[e]],
							}));
						},
					},
				},
				{
					label: "Surface totale ( m² )",
					width: 12,

					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.totalArea === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 1000,
						changeValue: 0.1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.totalArea,
						value: onGoingTechnicalReport.totalArea,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								totalArea: e,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isNewProperty === false && {
			titles: ["Coin jour", "Coin nuit"],
			inputs: [
				{
					label: "Surface séjour ( m² )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.livingRoomArea === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 127,
						changeValue: 0.1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.livingRoomArea,
						value: onGoingTechnicalReport.livingRoomArea,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								livingRoomArea: e,
							}));
						},
					},
				},

				{
					label: "Surface dégagement nuit  ( m² )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.clearanceNightArea === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 127,
						changeValue: 0.1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.clearanceNightArea,
						value: onGoingTechnicalReport.clearanceNightArea,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								clearanceNightArea: e,
							}));
						},
					},
				},
				{
					label: "Surface cuisine ( m² )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.kitchenArea === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 127,
						changeValue: 0.1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.kitchenArea,
						value: onGoingTechnicalReport.kitchenArea,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								kitchenArea: e,
							}));
						},
					},
				},
				{
					label: "Chambres ( m² )",
					width: 6,
					incrementers: {
						values: getRoomsArea(),
						onAddNewItem: addNewRoomArea,
						onRemoveNewItem: removeNewRoomArea,
						setValues: setRoomsArea,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport?.roomsArea?.length === 0
									? "borderwarning"
									: "border",
							min: 0,
							max: 255,
							changeValue: 0.1,
							color: "lightBlue",
							placeholder: "",
							// defaultValue: onGoingTechnicalReport.roomsArea,
							// value: onGoingTechnicalReport.roomsArea,
							// onChange: (e) => {
							//   setOnGoingTechnicalReport((vals) => ({
							//     ...vals,
							//     roomsArea: e,
							//   }))

							// },
						},
					},
				},
				{
					label: "Dégagement jour( m² )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.clearanceDayArea === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 127,
						changeValue: 0.1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.clearanceDayArea,
						value: onGoingTechnicalReport.clearanceDayArea,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								clearanceDayArea: e,
							}));
						},
					},
				},
				{
					label: "Salle de bain ( m² )",
					width: 6,
					incrementers: {
						values: getBathroomsArea(),
						onAddNewItem: addNewBathroomArea,
						onRemoveNewItem: removeNewBathroomArea,
						setValues: setBathroomsArea,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport?.bathroomArea?.length === 0
									? "borderwarning"
									: "border",
							min: 0,
							max: 127,
							changeValue: 0.1,
							color: "lightBlue",
							placeholder: "",
							// defaultValue: onGoingTechnicalReport.bathroomArea,
							// value: onGoingTechnicalReport.bathroomArea,
							// onChange: (e) => {
							//   setOnGoingTechnicalReport((vals) => ({
							//     ...vals,
							//     bathroomArea: e,
							//   }))

							// },
						},
					},
				},
			],
		},
		onGoingTechnicalReport.isNewProperty === false && {
			title: "Hauteur sous plafond ( cm )",
			titles: ["Coin jour ( cm )", "Coin nuit ( cm )"],
			inputs: [
				{
					label: "Coin jour ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.heightDaySpace === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 5,
						changeValue: 0.1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.heightDaySpace,
						value: onGoingTechnicalReport.heightDaySpace,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								heightDaySpace: e,
							}));
						},
					},
				},
				{
					label: "Coin nuit ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.heightNightSpace === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 5,
						changeValue: 0.1,
						color: "lightBlue",
						placeholder: "",
						defaultValue: onGoingTechnicalReport.heightNightSpace,
						value: onGoingTechnicalReport.heightNightSpace,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								heightNightSpace: e,
							}));
						},
					},
				},
			],
		},
		technicalType === "HEAT" &&
			onGoingTechnicalReport.isNewProperty === false && {
				title: "Chauffage actuel",
				inputs: [
					{
						label: "Diamètre du départ du réseau de chauffage ( mm )",
						width: 6,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.diametreStartHeatNetwork === undefined
									? "borderwarning"
									: "border",

							min: 0,
							max: 999,
							changeValue: 1,
							color: "lightBlue",
							placeholder: "puissance (W)",
							defaultValue: onGoingTechnicalReport.diametreStartHeatNetwork,
							value: onGoingTechnicalReport.diametreStartHeatNetwork,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									diametreStartHeatNetwork: e,
								}));
							},
						},
					},
					technicalType === "HEAT" && {
						label: "Type de chauffage",
						width: 6,
						select: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.heatType === undefined
									? "borderwarning"
									: "border",
							placeholder: "Chauffage actuel",
							defaultValue:
								heatTypeReverseCorres[onGoingTechnicalReport.heatType],
							items: Object.keys(heatTypeCorres),
							value: heatTypeReverseCorres[onGoingTechnicalReport.heatType],
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									heatType: HEAT_TYPE[heatTypeCorres[e]],
								}));
							},
						},
					},
				],
			},
		technicalType === "HEAT" &&
			onGoingTechnicalReport?.isNewProperty === false && {
				titles: ["Types de matériaux du réseau"],
				inputs: [
					{
						label: "Cuivre",
						width: 2,
						checkbox: {
							disabled: isDisabled,
							border:
								!!!onGoingTechnicalReport.hasMultilayerInNetwork &&
								!!!onGoingTechnicalReport.hasPerInNetwork &&
								!!!onGoingTechnicalReport.hasIronInNetwork &&
								!!!onGoingTechnicalReport.hasBrassInNetwork &&
								!!!onGoingTechnicalReport.hasGalvaInNetwork
									? "borderwarning"
									: "border",
							defaultValue:
								onGoingTechnicalReport.hasBrassInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasBrassInNetwork,
							checked:
								onGoingTechnicalReport.hasBrassInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasBrassInNetwork,

							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									hasBrassInNetwork:
										onGoingTechnicalReport.hasBrassInNetwork === undefined ||
										onGoingTechnicalReport.hasBrassInNetwork === null
											? true
											: !onGoingTechnicalReport.hasBrassInNetwork,
								}));
							},
						},
					},
					{
						label: "Fer",
						width: 2,
						checkbox: {
							disabled: isDisabled,
							border:
								!!!onGoingTechnicalReport.hasMultilayerInNetwork &&
								!!!onGoingTechnicalReport.hasPerInNetwork &&
								!!!onGoingTechnicalReport.hasIronInNetwork &&
								!!!onGoingTechnicalReport.hasBrassInNetwork &&
								!!!onGoingTechnicalReport.hasGalvaInNetwork
									? "borderwarning"
									: "border",
							defaultValue:
								onGoingTechnicalReport.hasIronInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasIronInNetwork,
							checked:
								onGoingTechnicalReport.hasIronInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasIronInNetwork,

							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									hasIronInNetwork:
										onGoingTechnicalReport.hasIronInNetwork === undefined ||
										onGoingTechnicalReport.hasIronInNetwork === null
											? true
											: !onGoingTechnicalReport.hasIronInNetwork,
								}));
							},
						},
					},
					{
						label: "Per",
						width: 2,
						checkbox: {
							disabled: isDisabled,
							border:
								!!!onGoingTechnicalReport.hasMultilayerInNetwork &&
								!!!onGoingTechnicalReport.hasPerInNetwork &&
								!!!onGoingTechnicalReport.hasIronInNetwork &&
								!!!onGoingTechnicalReport.hasBrassInNetwork &&
								!!!onGoingTechnicalReport.hasGalvaInNetwork
									? "borderwarning"
									: "border",
							defaultValue:
								onGoingTechnicalReport.hasPerInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasPerInNetwork,
							checked:
								onGoingTechnicalReport.hasPerInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasPerInNetwork,

							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									hasPerInNetwork:
										onGoingTechnicalReport.hasPerInNetwork === undefined ||
										onGoingTechnicalReport.hasPerInNetwork === null
											? true
											: !onGoingTechnicalReport.hasPerInNetwork,
								}));
							},
						},
					},
					{
						label: "Multicouche",
						width: 2,
						checkbox: {
							disabled: isDisabled,
							border:
								!!!onGoingTechnicalReport.hasMultilayerInNetwork &&
								!!!onGoingTechnicalReport.hasPerInNetwork &&
								!!!onGoingTechnicalReport.hasIronInNetwork &&
								!!!onGoingTechnicalReport.hasBrassInNetwork &&
								!!!onGoingTechnicalReport.hasGalvaInNetwork
									? "borderwarning"
									: "border",
							defaultValue:
								onGoingTechnicalReport.hasMultilayerInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasMultilayerInNetwork,
							checked:
								onGoingTechnicalReport.hasMultilayerInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasMultilayerInNetwork,

							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									hasMultilayerInNetwork:
										onGoingTechnicalReport.hasMultilayerInNetwork ===
											undefined ||
										onGoingTechnicalReport.hasMultilayerInNetwork === null
											? true
											: !onGoingTechnicalReport.hasMultilayerInNetwork,
								}));
							},
						},
					},
					{
						label: "Galva",
						width: 2,
						checkbox: {
							disabled: isDisabled,
							border:
								!!!onGoingTechnicalReport.hasMultilayerInNetwork &&
								!!!onGoingTechnicalReport.hasPerInNetwork &&
								!!!onGoingTechnicalReport.hasIronInNetwork &&
								!!!onGoingTechnicalReport.hasBrassInNetwork &&
								!!!onGoingTechnicalReport.hasGalvaInNetwork
									? "borderwarning"
									: "border",
							defaultValue:
								onGoingTechnicalReport.hasGalvaInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasGalvaInNetwork,
							checked:
								onGoingTechnicalReport.hasGalvaInNetwork === undefined
									? false
									: onGoingTechnicalReport.hasGalvaInNetwork,

							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									hasGalvaInNetwork:
										onGoingTechnicalReport.hasGalvaInNetwork === undefined ||
										onGoingTechnicalReport.hasGalvaInNetwork === null
											? true
											: !onGoingTechnicalReport.hasGalvaInNetwork,
								}));
							},
						},
					},
				],
			},
		onGoingTechnicalReport.isNewProperty === false &&
			(onGoingTechnicalReport?.heatType === HEAT_TYPE.PAC_AIR_WATER ||
				onGoingTechnicalReport?.heatType === HEAT_TYPE.FUEL ||
				onGoingTechnicalReport?.heatType === HEAT_TYPE.GAZ) && {
				titles: ["", "Avec ECS"],
				inputs: [
					{
						label: "Puissance ( kW )",
						width: 6,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.powerHeat === undefined
									? "borderwarning"
									: "border",
							min: 0,
							max: 999,
							color: "lightBlue",
							placeholder: "",
							defaultValue: onGoingTechnicalReport.powerHeat,
							value: onGoingTechnicalReport.powerHeat,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									powerHeat: e,
								}));
							},
						},
					},
					{
						label: "Oui",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.withECS === undefined
									? "borderwarning"
									: "border",
							placeholder: "",
							defaultValue:
								onGoingTechnicalReport.withECS === undefined
									? false
									: onGoingTechnicalReport.withECS,
							checked:
								onGoingTechnicalReport.withECS === undefined
									? false
									: onGoingTechnicalReport.withECS,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									withECS:
										onGoingTechnicalReport.withECS === undefined
											? true
											: !onGoingTechnicalReport.withECS,
								}));
							},
						},
					},
					{
						label: "Non",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.withECS === undefined
									? "borderwarning"
									: "border",
							placeholder: "",
							defaultValue:
								onGoingTechnicalReport.withECS === undefined
									? false
									: !onGoingTechnicalReport.withECS,
							checked:
								onGoingTechnicalReport.withECS === undefined
									? false
									: !onGoingTechnicalReport.withECS,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									withECS:
										onGoingTechnicalReport.withECS === undefined
											? false
											: !onGoingTechnicalReport.withECS,
								}));
							},
						},
					},
					{
						label: "Nombre de départ",
						width: 6,
						select: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.startNumber === undefined
									? "borderwarning"
									: "border",
							placeholder: "Nombre de départ",
							defaultValue: onGoingTechnicalReport.startNumber,
							items: [1, 2],
							value: onGoingTechnicalReport.startNumber,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									startNumber: e,
								}));
							},
						},
					},
					{
						label: "Type de thermostat",
						width: 6,
						select: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.thermostatType === undefined
									? "borderwarning"
									: "border",
							placeholder: "Type de thermostat",
							defaultValue:
								thermostatTypeReverseCorres[
									onGoingTechnicalReport.thermostatType
								],
							items: Object.keys(thermostatTypeCorres),
							value:
								thermostatTypeReverseCorres[
									onGoingTechnicalReport.thermostatType
								],
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									thermostatType: THERMOSTAT_TYPE[thermostatTypeCorres[e]],
								}));
							},
						},
					},
					{
						label: "Type d'émetteurs",
						width: 6,
						select: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.emeterType === undefined
									? "borderwarning"
									: "border",
							placeholder: "Type d'émetteurs",
							defaultValue:
								emeterTypeReverseCorres[onGoingTechnicalReport.emeterType],
							items: Object.keys(emeterTypeCorres),
							value: emeterTypeReverseCorres[onGoingTechnicalReport.emeterType],
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									emeterType: EMETER_TYPE[emeterTypeCorres[e]],
								}));
							},
						},
					},
					onGoingTechnicalReport?.emeterType === EMETER_TYPE.HYDRO && {
						label: "Type de radiateur hydraulique",
						width: 6,
						select: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.hydroHeatType === undefined
									? "borderwarning"
									: "border",
							placeholder: "Taille du logement",
							defaultValue:
								hydroHeatTypeReverseCorres[
									onGoingTechnicalReport.hydroHeatType
								],
							items: Object.keys(hydroHeatTypeCorres),
							value:
								hydroHeatTypeReverseCorres[
									onGoingTechnicalReport.hydroHeatType
								],
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									hydroHeatType: HYDRO_HEAT_TYPE[hydroHeatTypeCorres[e]],
								}));
							},
						},
					},
					{
						label: "Température de depart de l'eau ( °C )",
						width: 6,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.temperatureStartWater === undefined
									? "borderwarning"
									: "border",

							min: 0,
							max: 100,
							color: "lightBlue",
							placeholder: "Nombre de salle de bains",
							defaultValue: onGoingTechnicalReport.temperatureStartWater,
							value: onGoingTechnicalReport.temperatureStartWater,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									temperatureStartWater: e,
								}));
							},
						},
					},
				],
			},
		(onGoingTechnicalReport.heatType === HEAT_TYPE.FUEL ||
			onGoingTechnicalReport.heatType === HEAT_TYPE.GAZ) && {
			inputs: [
				{
					label: "Fumisterie",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.smokeSysType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Fumisterie",
						defaultValue:
							smokeSysTypeReverseCorres[onGoingTechnicalReport.smokeSysType],
						items: Object.keys(smokeSysTypeCorres),
						value:
							smokeSysTypeReverseCorres[onGoingTechnicalReport.smokeSysType],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								smokeSysType: SMOKE_SYS_TYPE[smokeSysTypeCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport?.smokeSysType === SMOKE_SYS_TYPE.CHIMNEY && {
					label: "Longueur cheminée ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.heightChimney === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 500,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.heightChimney,
						value: onGoingTechnicalReport.heightChimney,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								heightChimney: e,
							}));
						},
					},
				},
				onGoingTechnicalReport?.smokeSysType === SMOKE_SYS_TYPE.SUCKER && {
					label: "Hauteur ventouse verticale ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.heightSucker === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 500,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.heightSucker,
						value: onGoingTechnicalReport.heightSucker,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								heightSucker: e,
							}));
						},
					},
				},
				onGoingTechnicalReport?.smokeSysType === SMOKE_SYS_TYPE.SUCKER && {
					label: "largeur ventouse horizontale ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.widthSucker === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 500,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.widthSucker,
						value: onGoingTechnicalReport.widthSucker,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								widthSucker: e,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.heatType === HEAT_TYPE.ELEC && {
			title: "Puissance radiateur électrique",
			inputs: [
				{
					label: "Puissance radiateurs séjour ( Watts )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.powerElecHeatLivingRoom === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 5000,
						changeValue: 10,
						color: "lightBlue",
						placeholder: "puissance (W)",
						defaultValue: onGoingTechnicalReport.powerElecHeatLivingRoom,
						value: onGoingTechnicalReport.powerElecHeatLivingRoom,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								powerElecHeatLivingRoom: e,
							}));
						},
					},
				},
				{
					label: "Puissance radiateurs cuisine ( Watts )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.powerElecHeatKitchen === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 5000,
						changeValue: 10,
						color: "lightBlue",
						placeholder: "puissance (W)",
						defaultValue: onGoingTechnicalReport.powerElecHeatKitchen,
						value: onGoingTechnicalReport.powerElecHeatKitchen,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								powerElecHeatKitchen: e,
							}));
						},
					},
				},
				{
					label: "Puissance radiateurs chambre 1 ( Watts )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.powerElecHeatRoom1 === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 5000,
						changeValue: 10,
						color: "lightBlue",
						placeholder: "puissance (W)",
						defaultValue: onGoingTechnicalReport.powerElecHeatRoom1,
						value: onGoingTechnicalReport.powerElecHeatRoom1,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								powerElecHeatRoom1: e,
							}));
						},
					},
				},
				{
					label: "Puissance radiateurs chambre 2 ( Watts )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.powerElecHeatRoom2 === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 5000,
						changeValue: 10,
						color: "lightBlue",
						placeholder: "puissance (W)",
						defaultValue: onGoingTechnicalReport.powerElecHeatRoom2,
						value: onGoingTechnicalReport.powerElecHeatRoom2,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								powerElecHeatRoom2: e,
							}));
						},
					},
				},
				{
					label: "Puissance radiateurs chambre 3 ( Watts )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.powerElecHeatRoom3 === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 5000,
						changeValue: 10,
						color: "lightBlue",
						placeholder: "puissance (W)",
						defaultValue: onGoingTechnicalReport.powerElecHeatRoom3,
						value: onGoingTechnicalReport.powerElecHeatRoom3,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								powerElecHeatRoom3: e,
							}));
						},
					},
				},
				{
					label: "Puissance radiateurs chambre 4 ( Watts )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.powerElecHeatRoom4 === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 5000,
						changeValue: 10,
						color: "lightBlue",
						placeholder: "puissance (W)",
						defaultValue: onGoingTechnicalReport.powerElecHeatRoom4,
						value: onGoingTechnicalReport.powerElecHeatRoom4,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								powerElecHeatRoom4: e,
							}));
						},
					},
				},
			],
		},
		technicalType === "CLIM" && {
			inputs: [
				{
					label: "Type de climatisation",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.wireClimSysType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Climatisation",
						defaultValue:
							wireClimSysTypeReverseCorres[
								onGoingTechnicalReport.wireClimSysType
							],
						items: Object.keys(wireClimSysTypeCorres),
						value:
							wireClimSysTypeReverseCorres[
								onGoingTechnicalReport.wireClimSysType
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								wireClimSysType: WIRE_CLIM_SYS_TYPE[wireClimSysTypeCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport.wireClimSysType ===
					WIRE_CLIM_SYS_TYPE.SHEATHABLE && {
					label: "Régulation",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newClimActionChoice === undefined
								? "borderwarning"
								: "border",
						placeholder: "Action",
						defaultValue:
							newClimActionChoiceReverseCorres[
								onGoingTechnicalReport.newClimActionChoice
							],
						items: Object.keys(newClimActionChoiceCorres),
						value:
							newClimActionChoiceReverseCorres[
								onGoingTechnicalReport.newClimActionChoice
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newClimActionChoice:
									NEW_CLIM_ACTION_CHOICE[newClimActionChoiceCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport.wireClimSysType ===
					WIRE_CLIM_SYS_TYPE.SHEATHABLE && {
					label: "Bouche",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newClimMouthType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Type",
						defaultValue:
							newClimMouthTypeReverseCorres[
								onGoingTechnicalReport.newClimMouthType
							],
						items: Object.keys(newClimMouthTypeCorres),
						value:
							newClimMouthTypeReverseCorres[
								onGoingTechnicalReport.newClimMouthType
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newClimMouthType:
									NEW_CLIM_MOUTH_TYPE[newClimMouthTypeCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport.wireClimSysType ===
					WIRE_CLIM_SYS_TYPE.SHEATHABLE && {
					label: "Reprise",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newClimActionReprise === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							newClimActionRepriseReverseCorres[
								onGoingTechnicalReport.newClimActionReprise
							],
						items: Object.keys(newClimActionRepriseCorres),
						value:
							newClimActionRepriseReverseCorres[
								onGoingTechnicalReport.newClimActionReprise
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newClimActionReprise:
									NEW_CLIM_ACTION_REPRISE[newClimActionRepriseCorres[e]],
							}));
						},
					},
				},
			],
		},

		technicalType === "HEAT" && {
			title: "Choix du nouveaux système de chauffage",
			inputs: [
				{
					label: "Nouveau système de chauffage",
					width: 10,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newHeatType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Nouveau système de chauffage",
						defaultValue:
							newHeatTypeReverseCorres[onGoingTechnicalReport.newHeatType],
						items: Object.keys(newHeatTypeCorres),
						value: newHeatTypeReverseCorres[onGoingTechnicalReport.newHeatType],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newHeatType: NEW_HEAT_TYPE[newHeatTypeCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport.newHeatType === NEW_HEAT_TYPE.PAC && {
					label: "Action à effectuer",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newPacAction === undefined
								? "borderwarning"
								: "border",
						placeholder: "Action à effectuer",
						defaultValue:
							newPacActionReverseCorres[onGoingTechnicalReport.newPacAction],
						items: Object.keys(newPacActionCorres),
						value:
							newPacActionReverseCorres[onGoingTechnicalReport.newPacAction],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newPacAction: NEW_PAC_ACTION[newPacActionCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport.newHeatType === NEW_HEAT_TYPE.LOW_CONSO && {
					label: "Type d'énergie",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newLowConsoHeatType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Type d'énergie",
						defaultValue:
							newLowConsoHeatTypeReverseCorres[
								onGoingTechnicalReport.newLowConsoHeatType
							],
						items: Object.keys(newLowConsoHeatTypeCorres),
						value:
							newLowConsoHeatTypeReverseCorres[
								onGoingTechnicalReport.newLowConsoHeatType
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newLowConsoHeatType:
									NEW_LOW_CONSO_HEAT_TYPE[newLowConsoHeatTypeCorres[e]],
							}));
						},
					},
				},
				// memes choix que basse conso
				onGoingTechnicalReport.newHeatType === NEW_HEAT_TYPE.PAC_HYBRIDE && {
					label: "Type d'énergie",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newPacHybridHeatType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Chauffage actuel",
						defaultValue:
							newLowConsoHeatTypeReverseCorres[
								onGoingTechnicalReport.newPacHybridHeatType
							],
						items: Object.keys(newLowConsoHeatTypeCorres),
						value:
							newLowConsoHeatTypeReverseCorres[
								onGoingTechnicalReport.newPacHybridHeatType
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newPacHybridHeatType:
									NEW_LOW_CONSO_HEAT_TYPE[newLowConsoHeatTypeCorres[e]],
							}));
						},
					},
				},

				// onGoingTechnicalReport?.newHeatType === NEW_HEAT_TYPE.CLIM && {
				// 	label: "Type",
				// 	width: 6,
				// 	select: {
				// 		disabled: isDisabled,
				// 		border:
				// 			onGoingTechnicalReport.newWireClimSysType === undefined
				// 				? "borderwarning"
				// 				: "border",
				// 		placeholder: "Type",
				// 		defaultValue:
				// 			wireClimSysTypeReverseCorres[
				// 				onGoingTechnicalReport.newWireClimSysType
				// 			],
				// 		items: Object.keys(wireClimSysTypeCorres),
				// 		value:
				// 			wireClimSysTypeReverseCorres[
				// 				onGoingTechnicalReport.newWireClimSysType
				// 			],
				// 		onChange: (e) => {
				// 			setOnGoingTechnicalReport((vals) => ({
				// 				...vals,
				// 				newWireClimSysType:
				// 					WIRE_CLIM_SYS_TYPE[wireClimSysTypeCorres[e]],
				// 			}));
				// 		},
				// 	},
				// },
				(onGoingTechnicalReport.newHeatType === NEW_HEAT_TYPE.PAC_HYBRIDE ||
					onGoingTechnicalReport.newHeatType === NEW_HEAT_TYPE.LOW_CONSO) && {
					label: "Fumisterie",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newSmokeSysType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Fumisterie",
						defaultValue:
							smokeSysTypeReverseCorres[onGoingTechnicalReport.newSmokeSysType],
						items: Object.keys(smokeSysTypeCorres),
						value:
							smokeSysTypeReverseCorres[onGoingTechnicalReport.newSmokeSysType],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newSmokeSysType: SMOKE_SYS_TYPE[smokeSysTypeCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport?.newSmokeSysType === SMOKE_SYS_TYPE.CHIMNEY && {
					label: "Longueur cheminée ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newHeightChimney === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 500,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.newHeightChimney,
						value: onGoingTechnicalReport.newHeightChimney,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newHeightChimney: e,
							}));
						},
					},
				},
				onGoingTechnicalReport?.newSmokeSysType === SMOKE_SYS_TYPE.SUCKER && {
					label: "Hauteur ventouse verticale ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newHeightSucker === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 500,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.newHeightSucker,
						value: onGoingTechnicalReport.newHeightSucker,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newHeightSucker: e,
							}));
						},
					},
				},
				onGoingTechnicalReport?.newSmokeSysType === SMOKE_SYS_TYPE.SUCKER && {
					label: "largeur ventouse horizontale ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.newWidthSucker === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 500,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.newWidthSucker,
						value: onGoingTechnicalReport.newWidthSucker,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								newWidthSucker: e,
							}));
						},
					},
				},
				// onGoingTechnicalReport?.newWireClimSysType ===
				// 	WIRE_CLIM_SYS_TYPE.SHEATHABLE && {
				// 	label: "Type",
				// 	width: 6,
				// 	select: {
				// 		disabled: isDisabled,
				// 		border:
				// 			onGoingTechnicalReport.newClimAction === undefined
				// 				? "borderwarning"
				// 				: "border",
				// 		placeholder: "Action",
				// 		defaultValue:
				// 			newClimActionReverseCorres[onGoingTechnicalReport.newClimAction],
				// 		items: Object.keys(newClimActionCorres),
				// 		value:
				// 			newClimActionReverseCorres[onGoingTechnicalReport.newClimAction],
				// 		onChange: (e) => {
				// 			setOnGoingTechnicalReport((vals) => ({
				// 				...vals,
				// 				newClimAction: NEW_CLIM_ACTION[newClimActionCorres[e]],
				// 			}));
				// 		},
				// 	},
				// },
				// (onGoingTechnicalReport?.newClimAction === NEW_CLIM_ACTION.RESHAPE ||
				// 	onGoingTechnicalReport?.newClimAction ===
				// 		NEW_CLIM_ACTION.REGULATION) && {
				// 	label: "Type",
				// 	width: 6,
				// 	select: {
				// 		disabled: isDisabled,
				// 		border:
				// 			onGoingTechnicalReport.newClimActionChoice === undefined
				// 				? "borderwarning"
				// 				: "border",
				// 		placeholder: "Action",
				// 		defaultValue:
				// 			newClimActionChoiceReverseCorres[
				// 				onGoingTechnicalReport.newClimActionChoice
				// 			],
				// 		items: Object.keys(newClimActionChoiceCorres),
				// 		value:
				// 			newClimActionChoiceReverseCorres[
				// 				onGoingTechnicalReport.newClimActionChoice
				// 			],
				// 		onChange: (e) => {
				// 			setOnGoingTechnicalReport((vals) => ({
				// 				...vals,
				// 				newClimActionChoice:
				// 					NEW_CLIM_ACTION_CHOICE[newClimActionChoiceCorres[e]],
				// 			}));
				// 		},
				// 	},
				// },
				// onGoingTechnicalReport?.newClimAction === NEW_CLIM_ACTION.MOUTH && {
				// 	label: "Type",
				// 	width: 6,
				// 	select: {
				// 		disabled: isDisabled,
				// 		border:
				// 			onGoingTechnicalReport.newClimMouthType === undefined
				// 				? "borderwarning"
				// 				: "border",
				// 		placeholder: "Type",
				// 		defaultValue:
				// 			newClimMouthTypeReverseCorres[
				// 				onGoingTechnicalReport.newClimMouthType
				// 			],
				// 		items: Object.keys(newClimMouthTypeCorres),
				// 		value:
				// 			newClimMouthTypeReverseCorres[
				// 				onGoingTechnicalReport.newClimMouthType
				// 			],
				// 		onChange: (e) => {
				// 			setOnGoingTechnicalReport((vals) => ({
				// 				...vals,
				// 				newClimMouthType:
				// 					NEW_CLIM_MOUTH_TYPE[newClimMouthTypeCorres[e]],
				// 			}));
				// 		},
				// 	},
				// },
				onGoingTechnicalReport.newHeatType === NEW_HEAT_TYPE.ELEC && {
					label: "Régulation centralisée",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.elecHeatRegulationType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Régulation centralisé",
						defaultValue:
							elecHeatRegulationTypeReverseCorres[
								onGoingTechnicalReport.elecHeatRegulationType
							],
						items: Object.keys(elecHeatRegulationTypeCorres),
						value:
							elecHeatRegulationTypeReverseCorres[
								onGoingTechnicalReport.elecHeatRegulationType
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								elecHeatRegulationType:
									ELEC_HEAT_REGULATION_TYPE[elecHeatRegulationTypeCorres[e]],
							}));
						},
					},
				},
			],
		},
		technicalType === "HEAT" && {
			title: "Eau chaude sanitaire",

			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isHotWater === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isHotWater === undefined
								? false
								: onGoingTechnicalReport.isHotWater,
						checked:
							onGoingTechnicalReport.isHotWater === undefined
								? false
								: onGoingTechnicalReport.isHotWater,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isHotWater:
									onGoingTechnicalReport.isHotWater === undefined
										? true
										: !onGoingTechnicalReport.isHotWater,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isHotWater === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isHotWater === undefined
								? false
								: !onGoingTechnicalReport.isHotWater,
						checked:
							onGoingTechnicalReport.isHotWater === undefined
								? false
								: !onGoingTechnicalReport.isHotWater,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isHotWater:
									onGoingTechnicalReport.isHotWater === undefined
										? false
										: !onGoingTechnicalReport.isHotWater,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isHotWater === true && {
					label: "Intégré",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isHotWaterIntegrated === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isHotWaterIntegrated === undefined
								? false
								: !onGoingTechnicalReport.isHotWaterIntegrated,
						checked:
							onGoingTechnicalReport.isHotWaterIntegrated === undefined
								? false
								: onGoingTechnicalReport.isHotWaterIntegrated,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isHotWaterIntegrated:
									onGoingTechnicalReport.isHotWaterIntegrated === undefined
										? true
										: !onGoingTechnicalReport.isHotWaterIntegrated,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isHotWater === true && {
					label: "Déporté",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isHotWaterIntegrated === undefined
								? "borderwarning"
								: "border",
						placeholder: "",
						defaultValue:
							onGoingTechnicalReport.isHotWaterIntegrated === undefined
								? false
								: onGoingTechnicalReport.isHotWaterIntegrated,
						checked:
							onGoingTechnicalReport.isHotWaterIntegrated === undefined
								? false
								: !onGoingTechnicalReport.isHotWaterIntegrated,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isHotWaterIntegrated:
									onGoingTechnicalReport.isHotWaterIntegrated === undefined
										? false
										: !onGoingTechnicalReport.isHotWaterIntegrated,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isHotWater === true && {
					label: "Litrage ( L )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.hotWaterLiters === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 2000,
						changeValue: 10,
						color: "lightBlue",
						placeholder: "puissance (W)",
						defaultValue: onGoingTechnicalReport.hotWaterLiters,
						value: onGoingTechnicalReport.hotWaterLiters,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hotWaterLiters: e,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isHotWaterIntegrated === false && {
					label: "Système",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.hotWaterDeportedType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Système",
						defaultValue:
							hotWaterDeportedTypeReverseCorres[
								onGoingTechnicalReport.hotWaterDeportedType
							],
						items: Object.keys(hotWaterDeportedTypeCorres),
						value:
							hotWaterDeportedTypeReverseCorres[
								onGoingTechnicalReport.hotWaterDeportedType
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hotWaterDeportedType:
									HOT_WATER_DEPORTED_TYPE[hotWaterDeportedTypeCorres[e]],
							}));
						},
					},
				},
			],
		},
		{
			// title: "images",
			inputs: [
				{
					label: "Ajouter une image",
					width: 3,
					images: {
						images: onGoingTechnicalReport.images,
						onChange: async (existingImageUrl, file) => {
							try {
								const url = await imageHandler(file);

								handleChangeOfImage(existingImageUrl, url);
							} catch (err) {}
						},
						onRemove: (existingImageUrl) => {
							handleRemoveOfNewImage(existingImageUrl);
						},
						onAddNew: async (_placeholder, file) => {
							try {
								const url = await imageHandler(file);

								handleAddNewImage(url);
							} catch (err) {}
						},
						onRemoveNew: (existingImageUrl) => {
							handleRemoveOfNewImage(existingImageUrl);
						},
						onChangeText,
						changeButton: {
							disabled: isDisabled,
							children: "Ajouter/changer",
							size: "sm",
							color: "lightBlue",
							onClick: () => alert("add/change"),
						},
						removeButton: {
							disabled: isDisabled,
							children: "Supprimer",
							size: "sm",
							color: "lightBlue",
							onClick: () => alert("remove"),
						},
					},
				},
			],
		},
	];
}
