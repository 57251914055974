export const STATUSES = {
	CREATED: 0,
	SENT: 5,
	ACCEPTED: 10,
	REFUSED: 15,
};
export const statusCorres = {
	"Créé": "CREATED",
	"Devis envoyé": "SENT",
	"Devis accepté": "ACCEPTED",
	"Devis refusé": "REFUSED",
};
export const statusReverseCorres = {
	0: "Créé",
	5: "Devis envoyé",
	10: "Devis accepté",
	15: "Devis refusé",
};

export const ADVANCEMENT_STATUSES = {
	TO_START: 0,
	RUNNING: 5,
	PAUSED: 10,
	REFUSED: 15,
	FINISHED: 20,
};
export const advancementStatusCorres = {
	"A démarrer": "TO_START",
	"En cours": "RUNNING",
	"Suspendu": "PAUSED",
	"Devis refusé": "REFUSED",
	"Terminé": "FINISHED",
};
export const advancementStatusReverseCorres = {
	0: "A démarrer",
	5: "En cours",
	10: "Suspendu",
	15: "Devis refusé",
	20: "Terminé",
};

export const SITE_STATUSES = {
	TO_DO: 0,
	PURCHASED: 5,
	DELIVERED: 10,
	PLANIFIED_MATERIAL_TO_ORDER: 14.1,
	PLANIFIED_MATERIAL_ORDERED: 14.2,
	PLANIFIED: 15,
	TO_BE_INVOICED: 16,
	NOT_FINISHED_TO_PLANIFY: 17,
	FINISHED: 20,
};
export const siteStatusCorres = {
	"A planifier / A commander": "TO_DO",
	"A planifer / Matériel commandé": "PURCHASED",
	"A planifier / Matériel reçu": "DELIVERED",
	"Planifié / Matériel a commander": "PLANIFIED_MATERIAL_TO_ORDER",
	"Planifié / Matériel commandé": "PLANIFIED_MATERIAL_ORDERED",
	"Planifié / Reçu": "PLANIFIED",
	"À facturer": "TO_BE_INVOICED",
	"Non fini / A replanifier": "NOT_FINISHED_TO_PLANIFY",
	"Terminé": "FINISHED",
};
export const siteStatusReverseCorres = {
	0: "A planifier / A commander",
	5: "A planifer / Matériel commandé",
	10: "A planifier / Matériel reçu",
	14.1: "Planifié / Matériel a commander",
	14.2: "Planifié / Matériel commandé",
	15: "Planifié / Reçu",
	16: "À facturer",
	17: "Non fini / A replanifier",
	20: "Terminé",
};

export const TYPE_OF_BUSINESS = {
	HEAT: 0,
	VENTILATION: 5,
	CLIMATISATION: 10,
	PLUMBING: 15,
	BATHROOM: 20,
	SITE: 25,
};
export const typeOfBusinessCorres = {
	Chauffage: "HEAT",
	Ventilation: "VENTILATION",
	Climatisation: "CLIMATISATION",
	Plomberie: "PLUMBING",
	Sanitaire: "BATHROOM",
	Chantier: "SITE",
};

export const typeOfBusinessReverseCorres = {
	0: "Chauffage",
	5: "Ventilation",
	10: "Climatisation",
	15: "Plomberie",
	20: "Sanitaire",
	25: "Chantier",
};

export const MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE = {
	CREATED: 0,
	SENT: 5,
	ACCEPTED: 10,
	NOT_FINISHED_TO_PLANIFY: 11,
	TO_BE_INVOICED: 12,
	REFUSED: 15,
};

export const MAINTENANCE_CONTRACTS_STATUS = {
	0: "Crée",
	5: "Envoyé",
	10: "Accepté",
	11: "Non fini / A replanifier",
	12: "A facturer",
	15: "Refusé",
};

export const MAINTENANCE_CONTRACTS_STATUS_REVERSE = {
	"Crée": 0,
	"Envoyé": 5,
	"Accepté": 10,
	"Non fini / A replanifier": 11,
	"A facturer": 12,
	"Refusé": 15,
};
