import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/index.css";
import "assets/styles/tailwind.min.css";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import {
  HashRouter,
  Redirect,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import { ToastProvider, useToasts } from "react-toast-notifications";
import { Role } from "utils/common";
import AddUpdateIntervention from "views/admin/AddUpdateIntervention";
import AddUpdateNewBusiness from "views/admin/AddUpdateNewBusiness";
import AddUpdateAgency from "views/admin/agency/AddUpdateAgency";
import ListAgencies from "views/admin/agency/ListAgencies";
import SiteAgency from "views/admin/agency/SiteAgency";
import Dashboard from "views/admin/Dashboard.js";
import ListingInterventions from "views/admin/ListingInterventions";
import ListingNewBusiness from "views/admin/ListingNewBusiness";
import ListingOrders from "views/admin/ListingOrders";
import ListingSearch from "views/admin/ListingSearch";
import ListingSitesOrTroubleShoots from "views/admin/ListingSitesOrTroubleShoots";
import AddUpdateMaintenanceContract from "views/admin/MaintenanceContracts/AddUpdateMaintenanceContract";
import ListMaintenanceContracts from "views/admin/MaintenanceContracts/ListMaintenanceContracts";
import AddUpdateSiteSupervision from "views/admin/SiteSupervisions/AddUpdateSiteSupervision";
import ListSupervisions from "views/admin/SiteSupervisions/ListSupervisions";
import AddUpdateTechniciansReport from "views/admin/TechniciansReport/AddUpdateTechniciansReport";
import ListTechniciansReports from "views/admin/TechniciansReport/ListTechniciansReports";
import ListUsers from "views/auth/ListUsers";
import Login from "views/auth/Login";
import Logout from "views/auth/Logout";
import Profile from "views/auth/Profile";
import Error404 from "views/error/Error404";
import AppContext from "./Contexts/AppContext";
import AddTechnicalReport from "./views/admin/AddTechnicalReport";
import ListTechnicalReports from "./views/admin/ListTechnicalReports";
import UpdateOrder from "./views/admin/UpdateOrder";
import UpdateSiteOrTS from "./views/admin/UpdateSiteOrTS";



function PrivateRoute({ children, permissions = [], ...rest }) {
  const location = useLocation();

  const { user } = useContext(AppContext);

  if (!user || Object.keys(user)?.length === 0) return <Redirect to="/login" />

  const isAllowed = permissions?.length === 0
    ? true
    : user && permissions
      .map((item = "") => item?.toLowerCase())
      .includes(user?.department?.toLowerCase());

  if (
    location.pathname === "/" &&
    user?.department?.toLowerCase() === Role.SERVICE_TECHNIQUE?.toLowerCase()
  ) {
    return (
      <Redirect to={{
        pathname: '/list-sites-or-trouble-shoots/:sites',
      }} />
    )
  }

  if (!isAllowed)
    return (
      <Redirect to={{
        pathname: '/',
      }} />
    )

  return (
    <Route {...rest} render={({ location }) => {
      return isAllowed
        ? children
        : <Redirect to={{
          pathname: '/',
          state: { from: location }
        }}
        />
    }} />
  )
}

function App() {
  const { addToast } = useToasts();
  const [user, setUser] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState("");
  const [logoutHandler, setLogoutHandler] = useState(() => { })


  const displayErrorMessage = (
    msg = "Une Erreur est survenu. Veuillez réesayer plus tard."
  ) => {
    addToast(msg, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const displaySuccessMessage = (msg = "Action compléter avec succéss") => {
    addToast(msg, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        selectedAgency,
        setSelectedAgency,
        displayErrorMessage,
        displaySuccessMessage,
        setLogoutHandler,
        logoutHandler,
        isSuperAdmin,
        setIsSuperAdmin,
        isAdmin,
        setIsAdmin
      }}
    >
      <HashRouter>
        <Switch>
          <Route path="/login" exact component={Login} />
          <PrivateRoute path="/profile" exact component={Profile} />
          <Route path="/logout" exact component={Logout} />
          <PrivateRoute path="/" exact component={Dashboard} />
          <PrivateRoute path="/add-agency/:agencyId?" exact component={AddUpdateAgency} />
          <PrivateRoute path="/list-agencies" exact component={ListAgencies} />
          <PrivateRoute path="/list-site-supervisions" exact component={ListSupervisions} />
          <PrivateRoute path="/add-site-supervisions/:siteSupervisionId?" exact component={AddUpdateSiteSupervision} />

          <PrivateRoute path="/list-maintenance-contracts/:type?" exact component={ListMaintenanceContracts} />
          <PrivateRoute path="/add-maintenance-contracts/:id?/:disabled?" exact component={AddUpdateMaintenanceContract} />

          <PrivateRoute path="/list-technicians-report/:type?" exact component={ListTechniciansReports} />
          <PrivateRoute path="/add-technicians-report/:technicianReportId?/:disabled?" exact component={AddUpdateTechniciansReport} />

          <PrivateRoute
            path="/site-stats-agency"
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
            ]}
            exact
            component={SiteAgency}
          />

          <PrivateRoute
            path="/manage-personnel"
            exact
            component={ListUsers}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
            ]}
          />
          <PrivateRoute
            path="/"
            exact
            component={Dashboard}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_COMMERCIAL,
              Role.SERVICE_MAGASIN,
              // Role.SERVICE_TECHNIQUE,
            ]}
          />

          <PrivateRoute
            path="/add-new-business"
            exact
            component={AddUpdateNewBusiness}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_COMMERCIAL,
            ]}
          />

          <PrivateRoute
            path="/add-new-technical-report/:businessId"
            exact
            component={AddTechnicalReport}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_COMMERCIAL,
            ]}
          />
          <PrivateRoute
            path="/list-technical-reports/:businessId/:disabled?"
            exact
            component={ListTechnicalReports}
          // permissions={[
          //   Role.ADMIN,
          //   Role.SERVICE_DIRECTION,
          //   Role.SERVICE_ADMINISTRATION,
          //   Role.SERVICE_COMMERCIAL,
          // ]}
          />
          <PrivateRoute
            path="/update-new-business/:businessId"
            exact
            component={AddUpdateNewBusiness}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_COMMERCIAL,
            ]}
          />
          <PrivateRoute
            path="/update-order/:orderId"
            exact
            component={UpdateOrder}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_COMMERCIAL,
              Role.SERVICE_MAGASIN,
            ]}
          />

          <PrivateRoute
            path="/update-site-or-trouble-shoot/:id/:typeOfOrder/:disabled?"
            exact
            component={UpdateSiteOrTS}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_COMMERCIAL,
              Role.SERVICE_MAGASIN,
              Role.SERVICE_TECHNIQUE,
            ]}
          />
          <PrivateRoute
            path="/list-search/:queryParams"
            exact
            component={ListingSearch}
          />
          <PrivateRoute
            path="/list-new-business/:ko?"
            exact
            component={ListingNewBusiness}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_COMMERCIAL,

            ]}
          />
          <PrivateRoute
            path="/list-orders/:advStatus"
            exact
            component={ListingOrders}
          />
          <PrivateRoute
            path="/list-sites-or-trouble-shoots/:typeOfOrder"
            exact
            component={ListingSitesOrTroubleShoots}
          />
          <PrivateRoute
            path="/list-sites-or-trouble-shoots/:typeOfOrder/:advStatus"
            exact
            component={ListingSitesOrTroubleShoots}
          />
          <PrivateRoute
            path="/add-intervention"
            exact
            component={AddUpdateIntervention}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_MAGASIN,
              Role.SERVICE_TECHNIQUE,
            ]}
          />
          <PrivateRoute
            path="/update-intervention/:interventionId/:disabled?"
            exact
            component={AddUpdateIntervention}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_MAGASIN,
              Role.SERVICE_TECHNIQUE,
            ]}
          />
          <PrivateRoute
            path="/list-interventions"
            exact
            component={ListingInterventions}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_TECHNIQUE,
              Role.SERVICE_MAGASIN,
            ]}
          />
          <PrivateRoute
            path="/list-interventions/:advStatus"
            exact
            component={ListingInterventions}
            permissions={[
              Role.SUPER_ADMIN,
              Role.ADMIN,
              Role.SERVICE_DIRECTION,
              Role.SERVICE_ADMINISTRATION,
              Role.SERVICE_TECHNIQUE,
              Role.SERVICE_MAGASIN,

            ]}
          />

          <Route path="/error-404" exact component={Error404} />
          {/* <Redirect from="*" to="/error-404" /> */}
        </Switch>
      </HashRouter>
    </AppContext.Provider>
  );
}

const AppWrapper = () => (
  <ToastProvider placement="top-center">
    <App />
  </ToastProvider>
);

ReactDOM.render(<AppWrapper />, document.getElementById("root"));