import Appcontext from "Contexts/AppContext";
import React, { useContext } from "react";
import { Role } from "utils/common";
import Sidebar from "../../components/Sidebar/Sidebar";

const textProps = {
  brand: {
    text: "APPLI AG+ ENERGIES",
    image: require("assets/img/brand/Logo_AG+.svg").default,
    href: "#/",
    link: {
      href: "#/",
    },
  },
  input: {
    placeholder: "Recherche",
  },
  button: {
    color: "accentBlue",
    size: "sm",
    pathname: "#/list-search/",
    roundedFull: true,
    mb2: true,
    children: [<i className="fas fa-search" />],
  },
  activeColor: "lightBlue",
  items: [
    {
      divider: true,
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
      ],
    },
    {
      title: "Affaires nouvelles ",
      image: require("assets/img/illustrations/nouvelle-affaire.svg").default,
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
      ],
    },
    {
      text: "Ajouter une affaire",
      link: {
        href: "#/add-new-business",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
      ],
    },
    {
      text: "Listing des affaires",
      link: {
        href: "#/list-new-business",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
      ],
    },
    {
      text: "Listing des affaires en attente de validation",
      link: {
        href: "#/list-new-business/needs-validation",
      },
      roles: [
        Role.SUPER_ADMIN
      ],
    },
    {
      // icon: "fas fa-eye",
      text: "Affaires KO",
      link: {
        href: "#/list-new-business/:ko",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,

      ],
    },
    {
      divider: true,
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,

      ],
    },

    {
      title: "Commandes",
      image: require("assets/img/illustrations/commande.svg").default,
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
        Role.SERVICE_MAGASIN
      ],
    },
    {
      text: "Listing des commandes",
      link: {
        href: "#/list-orders/:running",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
        Role.SERVICE_MAGASIN

      ],
    },
    {
      // icon: "fas fa-eye",

      text: "Commandes terminées",
      link: {
        href: "#/list-orders/:finished",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
        Role.SERVICE_MAGASIN
      ],
    },

    {
      divider: true,
    },
    {
      title: "Chantiers",
      image: require("assets/img/illustrations/chantier.svg").default,
    },
    {
      text: "Listing des chantiers",
      link: {
        href: "#/list-sites-or-trouble-shoots/:sites",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
        Role.SERVICE_TECHNIQUE,
        Role.SERVICE_MAGASIN,
      ],
    },
    {
      text: "Chantiers terminés",
      link: {
        href: "#/list-sites-or-trouble-shoots/:sites/:finished",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
        Role.SERVICE_TECHNIQUE,
        Role.SERVICE_MAGASIN,
      ],
    },
    {
      divider: true,
    },
    {
      title: "Interventions",
      image: require("assets/img/illustrations/depannage.svg")
        .default,
    },
    {
      text: "Listing des interventions",
      link: {
        href:
          "#/list-sites-or-trouble-shoots/:trouble-shoots",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
        Role.SERVICE_TECHNIQUE,
        Role.SERVICE_MAGASIN,
      ],
    },
    {
      text: "Interventions terminés",
      link: {
        href:
          "#/list-sites-or-trouble-shoots/:trouble-shoots/:finished",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_COMMERCIAL,
        Role.SERVICE_TECHNIQUE,
        Role.SERVICE_MAGASIN,
      ],
    },
    {
      title: "Dépannages",
      image: require("assets/img/illustrations/depannages.svg")
        .default,
    },
    {
      text: "Ajouter un dépannage",
      link: {
        href: "#/add-intervention",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_MAGASIN,

      ],
    },
    {
      text: "Listing des dépannages",
      link: {
        href: "#/list-interventions",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,

        Role.SERVICE_TECHNIQUE,
      ],
    },
    {
      text: "Dépannages terminées",
      link: {
        href: "#/list-interventions/:finished",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_TECHNIQUE,
      ],
    },
    {
      divider: true,
    },
    {
      title: "Contrats d'entretiens",
      image: require("assets/img/illustrations/contrat-entretien.svg")
        .default,
    },
    {
      text: "Ajouter un contrat d'entretiens",
      link: {
        href: "#/add-maintenance-contracts",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_MAGASIN
      ],
    },
    {
      text: "Listing des contrats d'entretiens",
      link: {
        href: "#/list-maintenance-contracts",
      },
      roles: [
        Role.SUPER_ADMIN,
        Role.ADMIN,
        Role.SERVICE_DIRECTION,
        Role.SERVICE_ADMINISTRATION,
        Role.SERVICE_TECHNIQUE,
        Role.SERVICE_MAGASIN
      ],
    },
    {
      text: "Listing des contrats d'entretiens en attente de validations",
      link: {
        href: "#/list-maintenance-contracts/needs-validation",
      },
      roles: [
        Role.SUPER_ADMIN,
      ],
    },

    {
      divider: true,
    },
    {
      title: "Entreprise",
      image: require("assets/img/illustrations/company.svg")
        .default,
      roles: [
        Role.SUPER_ADMIN
      ],
    },
    {
      text: "Liste des colloborateurs",
      link: {
        href: "#/manage-personnel",
      },
      roles: [
        Role.SUPER_ADMIN
      ],
    },
    {
      text: "Ajout d'agence",
      link: {
        href: "#/add-agency",
      },
      roles: [
        Role.SUPER_ADMIN
      ],
    },
    {
      text: "Liste d'agences",
      link: {
        href: "#/list-agencies",
      },
      roles: [
        Role.SUPER_ADMIN
      ],
    },
  ],
};

export default function AuthSidebar() {
  const { user } = useContext(Appcontext);

  const generateSidebarText = () => {
    const items = textProps.items.filter((item) => {
      if (item.roles?.length > 0) {
        return item.roles
          .map((item) => item?.trim()?.toLowerCase())
          .includes(user?.department?.trim()?.toLowerCase());
      }
      return true;
    });
    return { ...textProps, items };
  };

  return <Sidebar {...generateSidebarText()} />;
}
