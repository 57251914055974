/* eslint-disable react-hooks/exhaustive-deps */
// import footeradmin from "_texts/admin/footers/footeradmin.js";
import { getNewBusiness, getNewBusinessForValidation } from "api/newBusiness";
import CardFullTable from "components/Cards/Admin/CardFullTable";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
// components
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import AppContext from "Contexts/AppContext";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import CsvDownload from "react-json-to-csv";
import { useLocation, useParams } from "react-router-dom";
// service
import { handleSetHeaderStatCardNewBusiness } from "service/newBusiness/HandleSetHeaderStatCards";
import {
  STATUSES,
  statusReverseCorres,
  typeOfBusinessReverseCorres,
} from "types/baseTypes";
import headerstatcards from "_texts/admin/headers/headerstatcards.js";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
// import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import Input from "../../components/Elements/Input";
import { Breadcrumb } from "./BreadCrumb";
import ListingActions from "../../components/Elements/ListingActions";

export default function Tables() {
  let { ko: status } = useParams();
  const { selectedAgency } = useContext(AppContext);
  const ko = status === ":ko";
  const needsValidation = status === "needs-validation";

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [listNewBooking, setListNewBooking] = useState([]);
  const [entryOfDataToSortOn, setEntryOfDataToSortOn] = useState(
    "objectifInvoiceDate"
  );
  const [typeToSort, setTypeToSort] = useState("date");
  const [isSortPositif, setIsSortPositif] = useState(true);
  const [filteredNewBusiness, setFilteredNewBusiness] = useState([]);

  const [headerstatcardsnewbusiness, setheaderstatcardsnewbusiness] = useState(
    headerstatcards
  );

  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [rerender, setRerender] = useState(false);

  const dropDownConfig = (businessId) => ({
    icon: "fas fa-edit",
    button: {
      color: "white",
      size: "sm",
      href: `#/update-new-business/${businessId}`,
    },
  });

  const getStatusColor = (status) => {
    let color = "";

    switch (status) {
      case STATUSES.CREATED:
        color = "amber";
        break;
      case STATUSES.SENT:
        color = "lightBlue";
        break;
      case STATUSES.ACCEPTED:
        color = "emerald";
        break;
      case STATUSES.REFUSED:
        color = "red";
        break;

      default:
        color = "blueGray";
        break;
    }
    return color;
  };

  const transformData = (data) => {
    const isInReview =
      !data.agencyId &&
      data.needsValidation &&
      data.creationAgency === selectedAgency &&
      !needsValidation;

    const bg =
      new Date(data.objectifDateInvoice) < new Date() && !data.invoiceDate
        ? "bg-red-200"
        : isInReview
        ? "bg-orange-200"
        : "";

    return {
      rowProps: {
        bg,
      },

      colProps: [
        !isInReview
          ? {
              dropdown: { ...dropDownConfig(data._id) },
            }
          : "EN ATTENTE DE VALIDATION ",

        data?.clientId?.typeOfClient === "Entreprise"
          ? data?.clientId?.companyName
          : `${data?.clientId?.clientLastName} ${data?.clientId?.clientFirstName}`,
        {
          color: getStatusColor(data.status),
          text: statusReverseCorres[data.status] || "",
        },
        typeOfBusinessReverseCorres[data.typeOfBusiness] || "-",
        data.responsableOfInvoice || "-",
        moment(data.date).format("DD/MM/YYYY"),
        moment(data.objectifDateInvoice).format("DD/MM/YYYY"),
        data.invoiceDate ? moment(data.invoiceDate).format("DD/MM/YYYY") : "-",
        data.amount
          ? `${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(data.amount)}`
          : "-",
        data?.clientId?.origin || "-",
        data.comment ? data.comment.slice(0, 16) : "-",
      ],
    };
  };

  const buildConsolidatedData = (data) => {
    let consolidatedData = [];

    if (needsValidation) return data;

    const finishedNewBusiness = data
      .filter((value) => value.status === STATUSES.REFUSED)
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));

    const createdNewBusinesses = data
      .filter(({ status }) => status === STATUSES.CREATED)
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));

    const sentNewBusinesses = data
      .filter(({ status }) => status === STATUSES.SENT)
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));

    if (ko) {
      consolidatedData = [...finishedNewBusiness];
    } else {
      consolidatedData = [...createdNewBusinesses, ...sentNewBusinesses];
    }

    return consolidatedData;
  };

  const handleGetNewBusiness = async () => {
    try {
      let data = [];
      if (!needsValidation) data = await getNewBusiness(selectedAgency);
      else data = await getNewBusinessForValidation();

      data = data?.filter((value) => {
        if (needsValidation) return value.needsValidation;
        if (ko) return value.status === STATUSES.REFUSED;
        return value.status < STATUSES.ACCEPTED;
      });

      const allData = buildConsolidatedData(data);

      setListNewBooking(data);

      setFilteredNewBusiness(allData.map(transformData));

      setIsLoaded(true);
      handleSetHeaderStatCardNewBusiness(data, [
        headerstatcardsnewbusiness,
        setheaderstatcardsnewbusiness,
      ]);

      setIsReady(true);
      (!isLoaded || !isReady) && setRerender(!rerender);
    } catch (err) {
      console.log("err", err);
    }
  };

  const filterNewBusiness = (query) => {
    const filterQuery = query?.toLowerCase();

    const consolidatedData = buildConsolidatedData(listNewBooking);

    const filtered = consolidatedData
      .map(transformData)
      .filter((newBusiness) => {
        let textValues = newBusiness.colProps
          // eslint-disable-next-line array-callback-return
          .map((value) => {
            if (typeof value === "string") return value;
            if (typeof value === "object" && value?.color) return value.text;
          })
          .filter((value) => typeof value === "string");

        textValues = textValues.filter((value) =>
          value?.toLowerCase().includes(filterQuery)
        );
        return textValues.length > 0;
      });

    if (!filterQuery || filterQuery.length === 0) {
      setFilteredNewBusiness(
        buildConsolidatedData(listNewBooking).map(transformData)
      );
    } else {
      setFilteredNewBusiness(filtered);
    }
  };

  useEffect(() => {
    const ascendingReturn = isSortPositif ? -1 : 1;
    const data = listNewBooking.sort((a, b) => {
      if (entryOfDataToSortOn === "clientLastName") {
        return a?.clientId?.clientFirstName?.toLowerCase() <
          b?.clientId?.clientFirstName?.toLowerCase()
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "status") {
        return a.status < b.status ? ascendingReturn : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "typeOfBusiness") {
        return a.typeOfBusiness < b.typeOfBusiness
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "responsableOfInvoice") {
        return a.responsableOfInvoice < b.responsableOfInvoice
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "date") {
        return moment(a.date).isBefore(b.date)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "objectifDateInvoice") {
        return moment(a.objectifDateInvoice).isBefore(b.objectifDateInvoice)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "invoiceDate") {
        return moment(a.invoiceDate).isBefore(b.invoiceDate)
          ? ascendingReturn
          : ascendingReturn * -1;
      }
      if (entryOfDataToSortOn === "amount") {
        return (a.amount || 0) < (b.amount || 0)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "originOfNewBusiness") {
        return a?.clientId?.origin < b?.clientId?.origin
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "comment") {
        return a.comment < b.comment ? ascendingReturn : ascendingReturn * -1;
      }
      return 1;
    });

    const transformedData = data.map(transformData);

    if (data) setFilteredNewBusiness(transformedData);
  }, [entryOfDataToSortOn, isSortPositif, typeToSort]);

  const cardfulltablenewbusiness = {
    title: "Liste des nouvelles affaires",
    color: "white",
    head: [
      {
        onClick: () => {},
        string: "",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("clientLastName");
          setIsSortPositif(!isSortPositif);
        },

        string: "Nom",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("status");
          setIsSortPositif(!isSortPositif);
        },
        string: "Statut",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("typeOfBusiness");
          setIsSortPositif(!isSortPositif);
        },
        string: "Catégorie",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("responsableOfInvoice");
          setIsSortPositif(!isSortPositif);
        },
        string: "RD",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("date");
          setIsSortPositif(!isSortPositif);
        },
        string: "Date Rdv",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("objectifDateInvoice");
          setIsSortPositif(!isSortPositif);
        },
        string: "DORD",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("invoiceDate");
          setIsSortPositif(!isSortPositif);
        },
        string: "DRD",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("amount");
          setIsSortPositif(!isSortPositif);
        },
        string: "Montant",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("originOfNewBusiness");
          setIsSortPositif(!isSortPositif);
        },
        string: "Origine",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("comment");
          setIsSortPositif(!isSortPositif);
        },
        string: "Commentaire",
      },
    ],
    body: [],
  };

  React.useEffect(() => {
    handleGetNewBusiness();
  }, [location, selectedAgency, status]);

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...headerstatcardsnewbusiness} />
        <div className="w-full h-full px-4 md:px-6 mx-auto -mt-24">
          <div className="w-full h-full flex flex-wrap">
            <ListingActions
              {...{
                fileName: "listing_new_business.csv",
                onInputSearch: filterNewBusiness,
                downloadData: listNewBooking,
                title: cardfulltablenewbusiness.title,
                location,
              }}
            />
            <div className="w-full h-full px-2">
              <CardFullTable
                {...cardfulltablenewbusiness}
                body={filteredNewBusiness}
              />
            </div>
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  );
}
