import { disableUserAccount, getAllUsers } from 'api/users';
import CardFullTable from 'components/Cards/Admin/CardFullTable';
import Input from 'components/Elements/Input';
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import React, { useEffect, useState } from 'react';

export default function ListUsers() {
  const [userQuery, setUserQuery] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [filteredTransformedUsers, setFilteredTransformedUsers] = useState([]);

  const dropDownConfig = (businessId) => ({
    icon: "fas fa-edit",
    button: {
      color: "white",
      size: "sm",
      href: `#/update-new-business/${businessId}`,
    },
  });

  const toggleUserDisableAccount = async (userPrincipalName, enabled) => {
    try {
      const response = await disableUserAccount(userPrincipalName, enabled);
      await handleGetUsers();
      console.log("res", response);
    } catch (err) {
      console.log("err", err)
    }
  }



  const transformData = (user) => {
    return {
      rowProps: {
        bg: !user.accountEnabled
          ? "bg-red-200"
          : " ",
      },
      colProps: [
        {
          toggle: {
            enabled: user.accountEnabled,
            setEnabled: () => toggleUserDisableAccount(user.userPrincipalName, !user.accountEnabled)
          },
        },
        user?.userPrincipalName,
        user?.surname || "",
        user?.givenName || "",
        user?.jobTitle || "",
        user?.officeLocation || "",

      ],
    };
  };


  const handleGetUsers = async () => {
    try {
      let users = await getAllUsers();

      setAllUsers(users);

      setFilteredTransformedUsers(users.map(transformData));


    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleGetUsers()
  }, [])


  const LIST_TABLE_HEADER = {
    title: "Liste des utilisateurs",
    color: "white",
    head: [
      {
        onClick: () => { },
        string: "Activer/Désactiver",
      },
      {

        string: "Principal Nom",
      },
      {

        string: "Nom",
      },
      {
        string: "Prénom",
      },

      {
        // onClick: () => {
        //   setTypeToSort("string");
        //   setEntryOfDataToSortOn("originOfNewBusiness");
        //   setIsSortPositif(!isSortPositif);
        // },
        string: "Poste",
      },
      {

        string: "Agence",
      },
    ],
    body: [],
  }


  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        {/* <NavbarSearchUser {...navbarsearchusersettings2} /> */}
        {/* <HeaderStatCards {...headerstatcardsnewbusiness} /> */}
        <div className="w-full h-full px-4 md:px-6 mx-auto -mt-24">
          <div className="w-full h-full hello flex flex-wrap">
            <div className="px-4 flex flex-nowrap items-center justify-between w-full">
              <Input
                {...{
                  placeholder: "Recherche",
                  type: "text",
                  onChange: (e) =>
                    setUserQuery(e.target.value),
                }}
              />
              {/* <Breadcrumb
                location={location}
                pageName={cardfulltablenewbusiness.title}
              /> */}
            </div>
            <div className="w-full h-full px-2">
              <CardFullTable
                {...LIST_TABLE_HEADER}
                body={filteredTransformedUsers}
              />
            </div>
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  )
}
