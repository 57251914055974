import { addAgency, deleteAgency, getAgencyById, updateAgency } from 'api/agency';
import { getFranchiseOwners } from 'api/users';
import Button from 'components/Elements/Button';
import Input from 'components/Elements/Input';
import Select from 'components/Elements/Select';
import HeaderStatCards from 'components/Headers/Admin/HeaderStatCards';
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import AppContext from 'Contexts/AppContext';
import React, { useContext, useEffect, useState } from 'react';
import {
  useHistory, useParams
} from "react-router-dom";
import { capitalizeFirstLetter } from 'service/functions';

export const uniqueInArr = (a) => [...new Set(a)];

export default function AddUpdateAgency() {
  const history = useHistory();
  const [agency, setAgency] = useState({
    name: "",
    phone: "",
    email: "",
    franchiseOwners: [],
    postalCodes: []
  });
  const context = useContext(AppContext)
  const [postalCode, setPostalCode] = useState("");
  const [franchiseOwners, setFranchiseOwners] = useState([]);
  const [consolidatedOwners, setConsolidatedOwners] = useState([])
  let { agencyId } = useParams();
  const isAdd = !agencyId;
  const title = isAdd ? "Ajouter Agence" : "Update Agence"

  const handleGetOwners = async () => {
    try {
      const users = await getFranchiseOwners() || [];
      setFranchiseOwners(users.map(user => user.userPrincipalName));
    } catch (err) {
      console.log("err franchise owwners", err)
    }
  }

  const handleGetAgencyById = async () => {
    try {
      const savedAgency = await getAgencyById(agencyId);
      setAgency(savedAgency);
    } catch (err) {
      console.log("err handleGetAgencyById", err)
    }
  }

  useEffect(() => {
    handleGetOwners()
    if (agencyId)
      handleGetAgencyById()
  }, [agencyId])

  useEffect(() => {
    if (consolidatedOwners.length === 0)
      setConsolidatedOwners(uniqueInArr([...franchiseOwners || [], ...agency?.franchiseOwners || []]))
  }, [franchiseOwners, agency])

  const validateAgency = () => {
    const { name = "", phone = "", email = "", franchiseOwners = [], postalCodes = [], }
      = agency || {};

    if (
      name?.trim().length < 3 ||
      phone?.trim().length < 3 ||
      email?.trim().length < 3 ||
      franchiseOwners?.length <= 0 ||
      postalCodes?.length <= 0
    ) {
      context
        ?.displayErrorMessage("Agence invalide, veuillez completer le formulaire correctement.")
      return false;
    }
    return true;

  }

  const handleDeleteAgency = async (e) => {
    e.preventDefault()

    try {
      await deleteAgency(agencyId)
      history.push("/list-agencies")
    } catch (err) {
      console.log("err delete agency", err)
    }
  }

  const handleAddUpdateAgency = async (e) => {
    e.preventDefault()
    try {
      if (!validateAgency())
        return;
      if (isAdd) {
        await addAgency(agency);
      } else {
        await updateAgency(agencyId, agency);

      }
      history.push("/list-agencies")
    } catch (err) {
      let msg = err?.response?.data?.message;

      if (msg === "RESOURCE_ALREADY_EXISTS_WITH_AGENCY_NAME") {
        context.displayErrorMessage("Une agence existe avec ce nom")
      } else {
        context.displayErrorMessage("Une erreur est survenu dans la sauvegard de l'agence")
      }

    }

  }

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        {/* <NavbarSearchUser {...navbarsearchusersettings2} /> */}
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="flex flex-wrap">
            <div className="w-full  px-4">
              <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">

                <div className="mb-3 p-6 pb-0">
                  <div className="text-center flex justify-between items-center">
                    <h6 className="text-xl font-bold mb-0">
                      {title?.toUpperCase()}
                    </h6>
                  </div>
                </div>
                <div className="flex-auto px-6 pb-6 pt-0">
                  <form >

                    <div className="flex flex-wrap -mx-4">
                      <div
                        className={`w-full px-4 lg:w-6/12 `}
                      >
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                            htmlFor="grid-password"
                          >
                            Nom
                          </label>
                          <Input {...{
                            border: "border",
                            type: "text",
                            value: agency.name,
                            onChange: (e) =>
                              setAgency((vals) => ({
                                ...vals,
                                name: capitalizeFirstLetter(e.target.value),
                              })),
                          }} />
                        </div>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                            htmlFor="grid-password"
                          >
                            Phone
                          </label>
                          <Input {...{
                            border: "border",
                            type: "text",
                            value: agency.phone,
                            onChange: (e) =>
                              setAgency((vals) => ({
                                ...vals,
                                phone: e.target.value,
                              })),
                          }} />
                        </div>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                            htmlFor="grid-password"
                          >
                            Email
                          </label>
                          <Input {...{
                            border: "border",
                            type: "email",
                            value: agency.email,
                            onChange: (e) =>
                              setAgency((vals) => ({
                                ...vals,
                                email: e.target.value,
                              })),
                          }} />
                        </div>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                            htmlFor="grid-password"
                          >
                            Franchisé(s)
                          </label>
                          <Select
                            {...{
                              border: "border",
                              size: "sm",
                              items: consolidatedOwners,
                              multi: true,
                              defaultValue: agency?.franchiseOwners?.join(" | ") || "",
                              onChange: (e = "") => {
                                if (typeof e === "string") {

                                  setAgency(vals => ({
                                    ...vals,
                                    franchiseOwners: e?.split("|")?.map(v => v.trim())
                                  })
                                  )
                                }
                              },
                            }}
                          />
                        </div>
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1"
                            htmlFor="grid-password"
                          >
                            Code postaux
                          </label>

                          <div
                            style={{
                              height: "100px",
                              overflowY: "scroll",
                              border: "1px solid black",
                              borderRadius: "5px",
                              padding: "10px",
                              margin: "10px 0",
                            }}
                          >
                            {
                              agency?.postalCodes?.map(code => {
                                return (<p key={code}>{code}</p>)
                              })
                            }
                          </div>
                          <div className="flex flex-row items-center content-center" >

                            <Input {...{
                              border: "border",
                              type: "text",
                              value: postalCode || "",
                              onChange: (e) => {
                                if (e?.target?.value?.trim()?.length <= 5)
                                  setPostalCode(e?.target?.value?.trim())
                              }
                            }} />

                            <div className="ml-3 mb-3">
                              <Button {...{
                                children: "Ajouter",
                                size: "sm",
                                color: "lightBlue",
                                onClick: (e) => {
                                  e.preventDefault()
                                  if (postalCode.trim().length > 3) {
                                    setAgency(
                                      vals => ({
                                        ...vals,
                                        postalCodes: [...vals.postalCodes || [], postalCode],
                                      }))
                                    setPostalCode("")
                                  }
                                }
                              }} />
                            </div>

                          </div>
                        </div>

                        <Button {...{
                          children: "Sauvegarder",
                          size: "sm",
                          color: "lightBlue",
                          onClick: handleAddUpdateAgency
                        }} />

                        {
                          !isAdd && (
                            <Button {...{
                              children: "Supprimer",
                              size: "sm",
                              color: "red",
                              onClick: handleDeleteAgency
                            }} />
                          )
                        }

                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
