import Incrementer from "components/Elements/Incrementer";

const Incrementers = ({
	incrementer = {},
	values,
	setValues,
	onAddNewItem,
	onRemoveNewItem,
}) => {
	const addNew = () => {
		onAddNewItem?.();
	};
	const removeNew = (idx) => {
		console.log("idx", idx);
		onRemoveNewItem?.(idx);
	};
	const hasValues =
		values?.filter((val) => typeof val === "number")?.length > 0;

	return (
		<div className="my-3">
			{values?.map((value, idx) => (
				<Incrementer
					key={idx?.toString()}
					{...{
						...incrementer,
						defaultValue: value,
						value,
						onAddNewItem: idx === values.length - 1 && hasValues && addNew,
						onRemoveNewItem:
							idx > 0 && idx === values.length - 1 && hasValues
								? () => {
										removeNew(idx);
								  }
								: undefined,
						onChange: (area) => {
							setValues(idx, area);
						},
					}}
				/>
			))}
		</div>
	);
};

export default Incrementers;
