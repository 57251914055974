import React from "react";
import ImageUpload from "./ImageUpload";
import Input from "./Input";

function Images({
	images = [],
	onAddNew,
	onRemoveNew,
	onChange,
	onRemove,
	changeButton,
	removeButton,
	onChangeText = () => {},
}) {
	return (
		<div className="flex flex-row flex-wrap">
			{images.map(({ _id, url, description = "" }) => (
				<div key={url} className="md:min-w-full" style={{ marginLeft: 20 }}>
					<ImageUpload
						{...{ onChange, onRemove, changeButton, removeButton }}
						placeholder={url}
					/>
					{url && (
						<Input
							style={{ marginTop: 10 }}
							border={"border"}
							type="textarea"
							value={description}
							onChange={(e) => {
								onChangeText(url, e.target.value);
							}}
						/>
					)}
				</div>
			))}
			<div style={{ marginLeft: 20 }}>
				<ImageUpload
					{...{
						onChange: onAddNew,
						onRemove: onRemoveNew,
						changeButton,
						removeButton,
					}}
				/>
			</div>
		</div>
	);
}

export default Images;
