import moment from "moment";
import { capitalizeFirstLetter } from "service/functions";
import {
	statusCorres,
	STATUSES,
	statusReverseCorres,
	typeOfBusinessCorres,
	typeOfBusinessReverseCorres,
	TYPE_OF_BUSINESS,
} from "types/baseTypes";

export default function GeneralForm(
	[newBusiness, setNewBusiness],
	[newStatus, setNewStatus],
	[dateOfSignature, setDateOfSignature],
	[
		nbOfDaysToAddToDateForObjectifInvoiceDate,
		setNbOfDaysToAddToDateForObjectifInvoiceDate,
	],

	handleDateChange,
	isDisabled,
	isSignable,
	{
		estimateManagers = [],
		existingClients = [],
		existingClient,
		setExistingClient,
		isAdd,
		handleSetExistingClient,
		existingClientIdx,
		agencies,
		agency,
		isAllowedShowAgencySelection,
		selectAgency,
	},
) {
	const isAccepted = newStatus === STATUSES.ACCEPTED;
	// const isSent =
	//   STATUSES[correspondingStatus] === STATUSES.SENT;
	const isCreated = newStatus === STATUSES.CREATED;
	const isRefused = newStatus === STATUSES.REFUSED;

	//   const wasAccepted =
	//   newBusiness.status === STATUSES.ACCEPTED;
	// const wasSent =
	//  newBusiness.status === STATUSES.SENT;
	// const wasCreated =
	//   newBusiness.status === STATUSES.CREATED;

	const getPlace = (place) => {
		const { address_components, formatted_address } = place;

		const postalCodeObj = address_components.find((a) => {
			return a.types.includes("postal_code");
		});

		const postalCode = postalCodeObj?.long_name;

		setNewBusiness((vals) => ({
			...vals,
			postalCode,
			clientId: {
				...vals?.clientId,
				address: formatted_address,
			},
		}));
	};

	return [
		{
			inputs: [
				isAdd && {
					label: "Nouveau client",
					width: 6,
					select: {
						border: "border",
						items: ["Oui", "Non"],
						defaultValue: existingClient ? "Oui" : "Non",
						value: existingClient ? "Oui" : "Non",
						onChange: (e) => {
							setExistingClient(e === "Oui");
							if (e === "Oui") {
								setNewBusiness((vals) => ({
									...vals,
									clientId: {
										typeOfClient: "",
										companyName: "",
										clientLastName: "",
										clientFirstName: "",
										address: "",
										origin: "",
									},
									agencyId: "",
								}));
							} else {
								handleSetExistingClient(0);
							}
						},
					},
				},
				(existingClient || existingClients?.length === 0) && {
					label: "Type de client *",
					width: 6,
					select: {
						disabled: isDisabled,
						border: !newBusiness?.clientId?.typeOfClient
							? "borderwarning"
							: "border",
						placeholder: "Type de client",
						defaultValue: newBusiness?.clientId?.typeOfClient,
						items: ["Particulier", "Entreprise"],
						value: newBusiness?.clientId?.typeOfClient,
						onChange: (e) => {
							if (typeof e === "string") {
								setNewBusiness((vals) => ({
									...vals,
									clientId: {
										...vals?.clientId,
										typeOfClient: e,
									},
								}));
							}
						},
					},
				},
				(existingClient || existingClients?.length === 0) &&
					newBusiness?.clientId?.typeOfClient === "Entreprise" && {
						label: `Nom de l'entreprise *`,
						width: 6,
						input: {
							disabled: isDisabled,
							border: !newBusiness?.clientId?.companyName
								? "borderwarning"
								: "border",
							placeholder: "Entreprise",
							type: "text",
							defaultValue: newBusiness?.clientId?.companyName,
							value: newBusiness?.clientId?.companyName,
							onChange: (e) => {
								setNewBusiness((vals) => ({
									...vals,
									clientId: {
										...vals?.clientId,
										companyName: e.target.value?.toUpperCase(),
									},
								}));
							},
						},
					},
				(existingClient || existingClients?.length === 0) && {
					label: `Nom du contact *`,
					width: 6,
					input: {
						required: true,
						disabled: isDisabled,
						border: !newBusiness?.clientId?.clientLastName
							? "borderwarning"
							: "border",

						placeholder: "Nom",
						type: "text",
						defaultValue: newBusiness?.clientId?.clientLastName,
						value: newBusiness?.clientId?.clientLastName,
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								clientId: {
									...vals?.clientId,
									clientLastName: e.target.value?.toUpperCase(),
								},
							}));
						},
					},
				},
				(existingClient || existingClients?.length === 0) && {
					label: `Prénom du contact *`,
					width: 6,
					input: {
						required: true,
						disabled: isDisabled,
						border: !newBusiness?.clientId?.clientFirstName
							? "borderwarning"
							: "border",
						placeholder: "Prénom",
						type: "text",
						defaultValue: newBusiness?.clientId?.clientFirstName,
						value: newBusiness?.clientId?.clientFirstName,
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								clientId: {
									...vals?.clientId,
									clientFirstName: capitalizeFirstLetter(e.target.value),
								},
							}));
						},
					},
				},
				!existingClient &&
					existingClients?.length > 0 && {
						label: `Choix du client *`,
						width: 6,
						selectSearch: {
							search: true,
							options: existingClients,
							autoComplete: "on",
							placeholder: "",
							required: true,
							disabled: isDisabled,
							value: existingClientIdx,
							filterOptions: () => {
								return (value) => {
									if (value.length === 0) return existingClients;
									return existingClients?.filter((c) =>
										c?.name?.toLowerCase()?.includes(value?.toLowerCase()),
									);
								};
							},
							onChange: (idx) => {
								handleSetExistingClient(idx);
							},
						},
					},
				{
					label: `Adresse *`,
					width: 12,
					addressAutoComplete: {
						disabled: isDisabled,
						defaultValue: newBusiness?.clientId?.address,
						value: newBusiness?.clientId?.address,
						language: "fr",
						types: ["geocode"],
						componentRestrictions: { country: "fr" },
						style: {
							width: "100%",
							border: "1px solid black",
							borderColor: !newBusiness?.clientId?.address ? "red" : "black",
							borderRadius: "5px",
							padding: "10px",
						},
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								clientId: {
									...vals?.clientId,
									address: e.target.value,
								},
							}));
						},
						onPlaceSelected: (e) => {
							getPlace(e);
						},
					},
				},
				isAllowedShowAgencySelection && {
					label: `Agence`,
					width: 6,
					select: {
						border: !newBusiness.agencyId ? "borderwarning" : "border",
						items: agencies,
						defaultValue: agency,
						value: agency,
						onChange: (e) => {
							if (typeof e === "string") selectAgency(e);
						},
					},
				},

				{
					label: `Catégorie *`,
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							newBusiness.typeOfBusiness === undefined
								? "borderwarning"
								: "border",
						placeholder: "Catégorie",
						defaultValue:
							typeOfBusinessReverseCorres[newBusiness.typeOfBusiness],
						items: Object.keys(typeOfBusinessCorres),
						value: typeOfBusinessReverseCorres[newBusiness.typeOfBusiness],
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								typeOfBusiness: TYPE_OF_BUSINESS[typeOfBusinessCorres[e]],
							}));
							e === typeOfBusinessReverseCorres[TYPE_OF_BUSINESS.SITE] &&
								setNbOfDaysToAddToDateForObjectifInvoiceDate(4);
						},
					},
				},
				{
					label: "Origine de l'affaire *",
					width: 6,
					select: {
						disabled: isDisabled,
						border: !newBusiness?.clientId?.origin ? "borderwarning" : "border",
						placeholder: "Origine de l'affaire",
						defaultValue: newBusiness?.clientId?.origin,
						items: [
							"Connaissance",
							"Déja client",
							"Salon",
							"Publicités",
							"Recommandation du client",
							"Recommandation d'autres professionnels",
							"Club affaire",
							"Technicien",
						],
						value: newBusiness?.clientId?.origin,
						onChange: (e) => {
							if (typeof e === "string") {
								setNewBusiness((vals) => ({
									...vals,
									clientId: {
										...vals?.clientId,
										origin: e,
									},
								}));
							}
						},
					},
				},
			],
		},
		{
			inputs: [
				{
					label: `Date du Rdv *`,
					width: 6,
					input: {
						disabled: isDisabled,
						border: !newBusiness.date ? "borderwarning" : "border",
						placeholder: "",
						type: "date",
						defaultValue: newBusiness.date,
						value: newBusiness.date,
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								date: e.target.value,
							}));
							handleDateChange(e.target.value);
						},
					},
				},
				{
					label: `Responsable devis *`,
					width: 6,
					select: {
						border: !newBusiness.responsableOfInvoice
							? "borderwarning"
							: "border",
						placeholder: "",
						items: estimateManagers.map((value) => value.userPrincipalName),
						value: newBusiness.responsableOfInvoice,
						defaultValue: newBusiness.responsableOfInvoice,
						onChange: (e) => {
							if (typeof e === "string") {
								setNewBusiness((vals) => ({
									...vals,
									responsableOfInvoice: e,
								}));
							}
						},
					},
				},

				{
					label: `Date objective remise du devis *`,
					width: 6,
					input: {
						disabled: isDisabled,
						border: !newBusiness.objectifDateInvoice
							? "borderwarning"
							: "border",
						placeholder: "",
						type: "date",
						defaultValue: newBusiness.objectifDateInvoice,
						value: newBusiness.objectifDateInvoice,
						onChange: (e) =>
							setNewBusiness((vals) => ({
								...vals,
								objectifDateInvoice: e.target.value,
							})),
					},
				},
				{
					label: `Montant HT € *`,
					width: 6,
					input: {
						disabled: newBusiness.status >= STATUSES.ACCEPTED,
						required: !isCreated,
						border:
							(!newBusiness.amount || newBusiness.amount <= 0) && !isCreated
								? "borderwarning"
								: "border",
						placeholder: "",
						type: "number",
						defaultValue: newBusiness.amount,
						value: newBusiness.amount,
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								amount: !isNaN(e.target.value) && e.target.value,
							}));
						},
					},
				},
			],
		},
		{
			title: "",
			inputs: [
				{
					label: `Date de remise réelle du devis *`,
					width: 6,
					input: {
						disabled: isDisabled && isRefused && newBusiness?.invoiceDate,
						border:
							!newBusiness.invoiceDate && !isCreated
								? "borderwarning"
								: "border",
						placeholder: "",
						type: "date",
						defaultValue: newBusiness.invoiceDate,
						value: newBusiness.invoiceDate,
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								invoiceDate: e.target.value,
							}));
						},
					},
				},
				{
					label: "Statut",
					width: 6,
					select: {
						placeholder: "",
						defaultValue: statusReverseCorres[newStatus],
						items: Object.keys(statusCorres).filter((value) => {
							const statusNb = newBusiness.status || STATUSES.CREATED;

							return STATUSES[statusCorres[value]] >= statusNb;
						}),
						value: statusReverseCorres[newStatus],
						onChange: (e) => {
							setNewStatus(STATUSES[statusCorres[e]]);
						},
					},
				},
				isAccepted && {
					label: `Date de signature *`,
					width: 6,
					input: {
						border: !newBusiness?.dateOfSignature ? "borderwarning" : "border",
						placeholder: "",
						type: "date",
						defaultValue: newBusiness?.dateOfSignature
							? moment(newBusiness?.dateOfSignature).format("YYYY-MM-DD")
							: "",
						value: newBusiness?.dateOfSignature
							? moment(newBusiness?.dateOfSignature).format("YYYY-MM-DD")
							: "",
						onChange: (e) => {
							setNewBusiness((vals) => ({
								...vals,
								dateOfSignature: e.target.value,
							}));
							setDateOfSignature(e.target.value);
						},
					},
				},

				{
					label: `Remarques *`,
					width: 12,
					input: {
						border:
							!newBusiness.comment && isRefused ? "borderwarning" : "border",
						placeholder: "Remarques",
						type: "textarea",
						defaultValue: newBusiness.comment,
						rows: 2,
						value: newBusiness.comment,
						onChange: (e) =>
							setNewBusiness((vals) => ({
								...vals,
								comment: e.target.value,
							})),
					},
				},
			],
		},
	];
}
