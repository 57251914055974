import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge.js";
import BathroomPlumbingTechnicalReportForm from "forms/newBusiness/BathroomPlumbingTechnicalReportForm";
import ClimatisationHeatTechnicalReportForm from "forms/newBusiness/ClimatisationHeatTechnicalReportForm";
import VentilationTechnicalReport from "forms/newBusiness/VentilationTechnicalReportForm";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { updateTechnicalReport } from "../../api/technicalReport";
import { TECHNICAL_REPORT_TYPE } from "../../types/technicalReportTypes";

function ListTechnicalReportItem({
	onGoingTechnicalReport = {},
	disabled = false,
}) {
	const history = useHistory();
	const [report, setReport] = useState(onGoingTechnicalReport);
	const { technicalReportType } = onGoingTechnicalReport;
	const [
		hasOtherWaterAccesPointsBool,
		setHasOtherWaterAccesPointsBool,
	] = useState(false);

	const buildTechnicalReportTypeByType = () => {
		if (technicalReportType === TECHNICAL_REPORT_TYPE.VENTILATION) {
			return VentilationTechnicalReport(
				report,
				setReport,
				[hasOtherWaterAccesPointsBool, setHasOtherWaterAccesPointsBool],
				disabled,
			);
		} else if (
			technicalReportType === TECHNICAL_REPORT_TYPE.CLIMATISATION ||
			technicalReportType === TECHNICAL_REPORT_TYPE.HEAT
		) {
			return ClimatisationHeatTechnicalReportForm(
				report,
				setReport,
				disabled,
				technicalReportType === TECHNICAL_REPORT_TYPE.HEAT ? "HEAT" : "CLIM",
			);
		} else if (
			technicalReportType === TECHNICAL_REPORT_TYPE.BATHROOM ||
			technicalReportType === TECHNICAL_REPORT_TYPE.PLUMBING
		) {
			return BathroomPlumbingTechnicalReportForm(report, setReport, disabled);
		}
	};

	const handleUpdateOfTechnicalReport = async () => {
		try {
			const { _id: technicalReportId } = onGoingTechnicalReport;
			await updateTechnicalReport(technicalReportId, report);
			history.push("/");
		} catch (err) {
			console.log(err);
		}
	};

	const buildFormForTechnicalReport = () => {
		return {
			button: {
				children: "Sauvegarder",
				size: "sm",
				color: "lightBlue",
				onClick: handleUpdateOfTechnicalReport,
			},

			forms: buildTechnicalReportTypeByType(),
		};
	};

	return (
		<div
			style={{
				width: "1024px",
				margin: "auto",
				maxWidth: "100%",
			}}
		>
			<CardSettingsLarge {...buildFormForTechnicalReport()} />
		</div>
	);
}

export default ListTechnicalReportItem;
