import axios from "axios";

const { SERVER_URL } = window._env_;


export const getAllMaintenanceContracts = async (agency) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/get-maintenance-contracts`, { params: { agency } });

  return result.data;
}

export const getAllMaintenanceContractsForValidation = async () => {
  const result = await axios.get(`${SERVER_URL}/api/v1/get-maintenance-contracts-for-validation`);

  return result.data;
}

export const getMaintenanceContractsById = async (id) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/get-maintenance-contract/${id}`);

  return result.data;
}

export const addMaintenanceContract = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-maintenance-contract`, data);

  return result.data;
}

export const updateMaintenanceContract = async (id, data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/update-maintenance-contract/${id}`, data);

  return result.data;
}

export const deleteMaintenanceContract = async (id) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/delete-maintenance-contract/${id}`);

  return result.data;
}
