/* eslint-disable react-hooks/exhaustive-deps */
import CardFullTable from "components/Cards/Admin/CardFullTable";
import ListingActions from "components/Elements/ListingActions";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
// components
import CsvDownload from "react-json-to-csv";
import { useLocation, useParams } from "react-router-dom";
import { handleSetHeaderStatCards } from "service/interventions/HandleSetHeaderStatCards";
import { Role } from "utils/common";
import headerstatcards from "_texts/admin/headers/headerstatcards.js";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
import { getInterventions } from "../../api/interventions";
// import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import Input from "../../components/Elements/Input";
import Appcontext from "../../Contexts/AppContext";
// import footeradmin from "_texts/admin/footers/footeradmin.js";
import { siteStatusReverseCorres, SITE_STATUSES } from "../../types/baseTypes";
import { Breadcrumb } from "./BreadCrumb";

export default function ListingSitesOrTS() {
  const { user, selectedAgency } = useContext(Appcontext);
  const [isTechnicalPersonnel] = useState(
    user?.department?.toLowerCase() === Role.SERVICE_TECHNIQUE.toLowerCase()
  );
  const location = useLocation();
  let { advStatus } = useParams();

  const [entryOfDataToSortOn, setEntryOfDataToSortOn] = useState(
    "objectifInvoiceDate"
  );
  const [typeToSort, setTypeToSort] = useState("date");
  const [isSortPositif, setIsSortPositif] = useState(true);

  const [listInterventions, setListInterventions] = useState([]);

  const [filteredInterventions, setFilteredInterventions] = useState([]);
  const [
    headerstatcardsinterventions,
    setheaderstatcardsinterventions,
  ] = useState(headerstatcards);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const TABLE_CONFIG = {
    title:
      advStatus && advStatus === ":finished"
        ? "Dépannages terminées"
        : "Dépannages en cours",

    color: "white",
    head: [
      {
        onClick: () => {},
        string: "",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("clientLastName");
          setIsSortPositif(!isSortPositif);
        },
        string: "Nom",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("siteStatus");
          setIsSortPositif(!isSortPositif);
        },
        string: "Statut",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("bt");
          setIsSortPositif(!isSortPositif);
        },
        string: "Ref",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("siteManager");
          setIsSortPositif(!isSortPositif);
        },
        string: "RC",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("technicians");
          setIsSortPositif(!isSortPositif);
        },
        string: "Techniciens",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("materialOrderDate");
          setIsSortPositif(!isSortPositif);
        },
        string: "Commande",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("deliveryDate");
          setIsSortPositif(!isSortPositif);
        },
        string: "Livraison",
      },

      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("objectifDateStart");
          setIsSortPositif(!isSortPositif);
        },
        string: "Début",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("objectifDateEnd");
          setIsSortPositif(!isSortPositif);
        },
        string: "Fin",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("supplier");
          setIsSortPositif(!isSortPositif);
        },
        string: "Fournisseur",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("problem");
          setIsSortPositif(!isSortPositif);
        },
        string: "Problèmes",
      },
      !isTechnicalPersonnel && {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("amount");
          setIsSortPositif(!isSortPositif);
        },
        string: "Montant",
      },
    ],
  };

  const dropDownConfig = (interventionId) => {
    const suffix = isTechnicalPersonnel ? "/disabled" : "";
    return {
      icon:
        advStatus && advStatus === ":finished" ? "fas fa-eye" : "fas fa-edit",
      button: {
        color: "white",
        size: "sm",
        href: `#/update-intervention/${interventionId}${suffix}`,
      },
    };
  };

  const getSiteStatusColor = (siteStatus) => {
    let color = "";

    switch (siteStatus) {
      case SITE_STATUSES.TO_DO:
        color = "red";
        break;
      case SITE_STATUSES.PURCHASED:
        color = "pink";
        break;
      case SITE_STATUSES.DELIVERED:
        color = "teal";
        break;
      case SITE_STATUSES.PLANIFIED:
        color = "lightBlue";
        break;
      case SITE_STATUSES.FINISHED:
        color = "emerald";
        break;
      case SITE_STATUSES.TO_BE_INVOICED:
        color = "purple";
        break;
      case SITE_STATUSES.NOT_FINISHED_TO_PLANIFY:
        color = "amber";
        break;
      default:
        color = "blueGray";
        break;
    }
    return color;
  };

  const transformData = (data) => {
    return {
      rowProps: {
        bg: "",
      },

      colProps: [
        {
          dropdown: {
            ...dropDownConfig(data._id),
          },
        },
        data?.clientId?.typeOfClient === "Entreprise"
          ? data?.clientId?.companyName
          : `${data?.clientId?.clientLastName} ${data?.clientId?.clientFirstName}`,
        {
          color: getSiteStatusColor(data.siteStatus || SITE_STATUSES.TO_DO),
          text:
            siteStatusReverseCorres[data.siteStatus] ||
            siteStatusReverseCorres[SITE_STATUSES.TO_DO],
        },
        data.bt || "-",
        data?.siteManager || "-",
        data.technicians || "-",
        data.materialOrderDate
          ? moment(data.materialOrderDate).format("DD/MM/YYYY")
          : "-",
        data.deliveryDate
          ? moment(data.deliveryDate).format("DD/MM/YYYY")
          : "-",
        data.objectifDateStart
          ? moment(data.objectifDateStart).format("DD/MM/YYYY")
          : "-",
        data.objectifDateEnd
          ? moment(data.objectifDateEnd).format("DD/MM/YYYY")
          : "-",
        data.supplier || "-",
        data.problem || "-",
        data.amount && !isTechnicalPersonnel
          ? `${new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
            }).format(data.amount)}`
          : "-",
      ],
    };
  };

  const buildConsolidatedData = (data) => {
    const finishedSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.FINISHED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);

    const todoSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.TO_DO)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const purchasedSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.PURCHASED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const deliveredSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.DELIVERED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const plannedSites = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.PLANIFIED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const plannedSitesMaterialToOrder = data
      .filter(
        ({ siteStatus }) =>
          siteStatus === SITE_STATUSES.PLANIFIED_MATERIAL_TO_ORDER
      )
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const plannedSitesMaterialOrdered = data
      .filter(
        ({ siteStatus }) =>
          siteStatus === SITE_STATUSES.PLANIFIED_MATERIAL_ORDERED
      )
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const toBeInvoiced = data
      .filter(({ siteStatus }) => siteStatus === SITE_STATUSES.TO_BE_INVOICED)
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);
    const notFinishedToPlanify = data
      .filter(
        ({ siteStatus }) => siteStatus === SITE_STATUSES.NOT_FINISHED_TO_PLANIFY
      )
      .sort((a, b) => ((a.bt || "") < (b.bt || "") ? -1 : 1))
      .map(transformData);

    let consolidatedData = [];

    if (advStatus && advStatus === ":finished") {
      consolidatedData = [...finishedSites];
    } else {
      consolidatedData = [
        ...todoSites,
        ...purchasedSites,
        ...deliveredSites,
        ...notFinishedToPlanify,
        ...plannedSitesMaterialToOrder,
        ...plannedSitesMaterialOrdered,
        ...plannedSites,
        ...toBeInvoiced,
      ];
    }

    return consolidatedData;
  };

  const handleGetInterventions = async () => {
    try {
      let data = await getInterventions(selectedAgency);

      setListInterventions(data);
      const allData = buildConsolidatedData(data);

      // const transformedData = data.map(transformData);
      setFilteredInterventions(allData);
      // if (!!query) filterInterventions(query);

      handleSetHeaderStatCards(data, advStatus, [
        headerstatcardsinterventions,
        setheaderstatcardsinterventions,
      ]);
    } catch (err) {
      console.log("err", err);
    }
  };

  const filterInterventions = (query) => {
    const filterQuery = query?.toLowerCase();

    const filtered = buildConsolidatedData(listInterventions).filter(
      (intervention) => {
        let textValues = intervention.colProps
          // eslint-disable-next-line array-callback-return
          .map((value) => {
            if (typeof value === "string") return value;
            if (typeof value === "object" && value?.color) return value.text;
          })
          .filter((value) => typeof value === "string");

        textValues = textValues.filter((value) =>
          value?.toLowerCase().includes(filterQuery)
        );
        return textValues.length > 0;
      }
    );

    if (!filterQuery || filterQuery.length === 0) {
      setFilteredInterventions(buildConsolidatedData(listInterventions));
    } else {
      setFilteredInterventions(filtered);
    }
  };

  useEffect(() => {
    const ascendingReturn = isSortPositif ? -1 : 1;
    const data = filteredInterventions.sort((a, b) => {
      if (entryOfDataToSortOn === "clientLastName") {
        return a?.clientId?.clientFirstName?.toLowerCase() <
          b?.clientId?.clientFirstName?.toLowerCase()
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "siteStatus") {
        return a.siteStatus < b.siteStatus
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "bt") {
        return a.bt < b.bt ? ascendingReturn : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "siteManager") {
        return a.siteManager < b.siteManager
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "technicians") {
        return a.technicians < b.technicians
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "materialOrderDate") {
        return moment(a.materialOrderDate).isBefore(b.materialOrderDate)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "deliveryDate") {
        return moment(a.deliveryDate).isBefore(b.deliveryDate)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "objectifDateStart") {
        return moment(a.objectifDateStart).isBefore(b.objectifDateStart)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "objectifDateEnd") {
        return moment(a.objectifDateEnd).isBefore(b.objectifDateEnd)
          ? ascendingReturn
          : ascendingReturn * -1;
      }
      if (entryOfDataToSortOn === "amount") {
        return (a.amount || 0) < (b.amount || 0)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "problem") {
        return a.problem < b.problem ? ascendingReturn : ascendingReturn * -1;
      }
      return 1;
    });

    // const transformedData = data
    //   .map(transformData)

    if (data) setFilteredInterventions(data);
  }, [entryOfDataToSortOn, isSortPositif, typeToSort]);

  React.useEffect(() => {
    handleGetInterventions();
  }, [advStatus, selectedAgency]);

  return (
    <>
      <AuthSidebar />
      {/* 2xl:ml-64 */}
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards
          {...{
            ...headerstatcardsinterventions,
            isAllowedToView: !isTechnicalPersonnel,
          }}
        />
        <div className="w-full h-full px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="w-full flex flex-wrap">
            <ListingActions
              {...{
                fileName: "listing_depnnages.csv",
                onInputSearch: filterInterventions,
                downloadData: listInterventions,
                title: TABLE_CONFIG.title,
                location,
              }}
            />
            <div className="w-full px-4">
              <CardFullTable {...TABLE_CONFIG} body={filteredInterventions} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
