
const textProps = {
    logo:{
        src:require("assets/img/brand/Logo_AG+.svg").default,
        alt: "AG+ energies",
        text:"APPLI AG+ ENERGIES"
    },
    title: "Connectez-vous à l'aide de vos identifiants Office 365",

    button: {
      fullWidth: true,
      color: "accentBlue",
      children: "CONNEXION",

    },
  };
  export default textProps;
