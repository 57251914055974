import { uploadImage } from "../api/technicalReport";

export const imageHandler = async (file) => {
	// const file = event.target.files[0];
	const formData = new FormData();
	formData.append("image", file);

	if (file) {
		const urls = await uploadImage(formData);
		return urls;
	}
};

export const Role = {
	SUPER_ADMIN: "SUPER_ADMIN",
	ADMIN: "ADMIN",
	SERVICE_DIRECTION: "SERVICE_DIRECTION",
	SERVICE_ADMINISTRATION: "SERVICE_ADMINISTRATION",
	SERVICE_COMMERCIAL: "SERVICE_COMMERCIAL",
	SERVICE_MAGASIN: "SERVICE_MAGASIN",
	SERVICE_TECHNIQUE: "SERVICE_TECHNIQUE",
};

export const JOB_TITLES = {
	FRANCHISER: "FRANCHISER",
	FRANCHISE_OWNERS: "FRANCHISE_OWNERS",
	DIRECTEUR_AGENCE: "DIRECTEUR_AGENCE",
	DIRECTEUR_ADJOINT: "DIRECTEUR_ADJOINT",
	ASSITANTE_DIRECTION: "ASSITANTE_DIRECTION",
	ASSISTANTE_GESTION: "ASSISTANTE_GESTION",
	CHARGE_AFFAIRES: "CHARGE_AFFAIRES",
	MAGASINIER: "MAGASINIER",
	TECHNICAL: "TECHNICAL",
};

export const getPostalCode = (address = "") => {
	const regex = /^(?:[0-8]\d|9[0-8])\d{3}$/;
	let addressParts = address
		.split(" ")
		.filter((p) => typeof +p === "number" && !isNaN(+p));
	addressParts = addressParts.filter((h) => regex.exec(h));

	return addressParts[0] || "";
};
