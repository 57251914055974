import FooterAdmin from "components/Footers/Admin/FooterAdmin";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards";
import AuthSidebar from "components/Sidebar/AuthSidebar";
import React, { useContext } from "react";
import Input from "../../components/Elements/Input";
import AppContext from "../../Contexts/AppContext";
import footeradmin from "../../_texts/admin/footers/footeradmin";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";

export default function Profile() {
  const { user = {} } = useContext(AppContext);

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="flex flex-wrap">
            <div className="w-full  px-4">
              <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">
                <div className="flex-auto px-6 pb-6 pt-0">
                  <form>
                    <div className="flex flex-wrap -mx-4">
                      <div className="relative w-full mb-3 flex flex-col justify-center items-center my-8">
                        <div className={`w-full px-4 lg:w-6/12 `}>
                          <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                            Prénom
                          </label>
                          <Input
                            {...{
                              label: ``,
                              disabled: true,
                              border: "border",
                              placeholder: "Nom",
                              type: "text",
                              value: user.givenName,
                            }}
                          />
                        </div>
                        <div className={`w-full px-4 lg:w-6/12 `}>
                          <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                            Nom
                          </label>
                          <Input
                            {...{
                              disabled: true,
                              border: "border",
                              placeholder: "Nom",
                              type: "text",
                              value: user?.surname,
                            }}
                          />
                        </div>
                        <div className={`w-full px-4 lg:w-6/12 `}>
                          <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                            Nom utilisateur
                          </label>
                          <Input
                            {...{
                              disabled: true,
                              border: "border",
                              placeholder: "Nom",
                              type: "text",
                              value: user?.displayName,
                            }}
                          />
                        </div>
                        <div className={`w-full px-4 lg:w-6/12 `}>
                          <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                            Poste
                          </label>
                          <Input
                            {...{
                              disabled: true,
                              border: "border",
                              type: "text",
                              value: user?.jobTitle,
                            }}
                          />
                        </div>
                        <div className={`w-full px-4 lg:w-6/12 `}>
                          <label className="block uppercase text-blueGray-500 text-xs font-bold mb-2 ml-1">
                            Agence
                          </label>
                          <Input
                            {...{
                              disabled: true,
                              border: "border",
                              type: "text",
                              value: user?.officeLocation,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <FooterAdmin {...footeradmin} />
        </div>
      </div>
    </>

    // <div className="relative 2xl:ml-64 bg-blueGray-100">
    //   <div className="px-4 md:px-6 mx-auto w-full -mt-24">
    //     <div className="flex flex-wrap">
    //       <div className="w-full  px-4">

    //         <div className="relative flex flex-col w-full mb-6 shadow-lg rounded-lg bg-white">

    //           <div className="flex-auto px-6 pb-6 pt-0">
    //             <form>

    //               <div className="flex flex-wrap -mx-4">
    //                 <div className="relative w-full mb-3">
    //                   <div
    //                     className={`w-full px-4 lg:w-6/12 `}
    //                   >
    //                     <Input {...{
    //                       label: `Nom du contact *`,
    //                       width: 6,
    //                       input: {
    //                         required: true,
    //                         disabled: true,
    //                         border: "border",
    //                         placeholder: "Nom",
    //                         type: "text",
    //                         defaultValue: "newBusiness.clientLastName",
    //                         value: " newBusiness.clientLastName",
    //                       },
    //                     }} />
    //                   </div>

    //                 </div>

    //               </div>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}
