export const TECHNICAL_REPORT_TYPE = {
	HEAT: 0,
	CLIMATISATION: 5,
	BATHROOM: 10,
	VENTILATION: 15,
	PLUMBING: 20,
};

export const TECHNICAL_REPORT_TYPE_CORRESPONDANCE = {
	0: "Chauffage",
	5: "Climatisation",
	10: "Sanitaire",
	15: "Ventilation",
	20: "Plomberie",
};
export const TECHNICAL_REPORT_TYPE_REVERSE_CORRESPONDANCE = {
	Chauffage: 0,
	Climatisation: 5,
	Sanitaire: 10,
	Ventilation: 15,
	Plomberie: 20,
};

export const HOUSE_SIZE = {
	T1: 0,
	T2: 5,
	T3: 10,
	T4: 15,
	T5: 20,
	T6: 25,
};
export const houseSizeCorres = {
	"T1": "T1",
	"T2": "T2",
	"T3": "T3",
	"T4": "T4",
	"T5": "T5",
	"T6 et +": "T6",
};
export const houseSizeReverseCorres = {
	0: "T1",
	5: "T2",
	10: "T3",
	15: "T4",
	20: "T5",
	25: "T6 et +",
};

export const NB_OF_FLOORS = {
	EXACT1: 0,
	EXACT2: 5,
	SUP2: 10,
};
export const nbOfFloorsCorres = {
	"1": "EXACT1",
	"2": "EXACT2",
	"> 2 (Plan obligatoire)": "SUP2",
};
export const nbOfFloorsReverseCorres = {
	0: "1",
	5: "2",
	10: "> 2 (Plan obligatoire)",
};

export const AREA = {
	INF100: 0,
	INF150: 5,
	INF200: 10,
	SUP200: 15,
};
export const areaCorres = {
	"< 100 m²": "INF100",
	"100 / 150 m²": "INF150",
	"150 / 200 m²": "INF200",
	"> 200 m² (plan obligatoire)": "SUP200",
};
export const areaReverseCorres = {
	0: "< 100 m²",
	5: "100 / 150 m²",
	10: "150 / 200 m²",
	15: "> 200 m² (plan obligatoire)",
};

export const VENTILATION_TYPE = {
	DOUBLE_FLUX: 0,
	HYGRO_A: 5,
	HYGRO_B: 10,
	SIMPLE_FLUX: 15,
	EXTRACTEUR: 20,
};
export const typeCorres = {
	"Double flux": "DOUBLE_FLUX",
	"Hygro A": "HYGRO_A",
	"Hygro B": "HYGRO_B",
	"Simple flux": "SIMPLE_FLUX",
	"Extracteur": "EXTRACTEUR",
};
export const typeReverseCorres = {
	0: "Double flux",
	5: "Hygro A",
	10: "Hygro B",
	15: "Simple flux",
	20: "Extracteur",
};

export const DOUBLE_FLUX_TYPE = {
	RADIO: 0,
	FILAIRE: 5,
};
export const doubleFluxTypeCorres = {
	Radio: "RADIO",
	Filaire: "FILAIRE",
};
export const doubleFluxTypeReverseCorres = {
	0: "Radio",
	5: "Filaire",
};

export const HYGRO_TYPE = {
	CONSUMPTION: 0,
	STANDARD: 5,
	LOW_CONSUMPTION: 10,
};
export const hygroTypeCorres = {
	"Standard": "STANDARD",
	"Basse consommation": "LOW_CONSUMPTION",
};
export const hygroTypeReverseCorres = {
	0: "Standard",
	5: "Basse consommation",
};

export const CAISSON_TYPE = {
	STANDARD: 0,
	LOW_CONSUMPTION: 5,
};
export const caissonTypeCorres = {
	"Standard": "STANDARD",
	"Basse consommation": "LOW_CONSUMPTION",
};
export const caissonTypeReverseCorres = {
	0: "Standard",
	5: "Basse consommation",
};

export const BOUCHES_TYPE = {
	BATTERY: 0,
	ELECTRIC: 5,
	MANUEL: 10,
};
export const boucheTypeCorres = {
	Pile: "BATTERY",
	Electriques: "ELECTRIC",
	Manuelle: "MANUEL",
};
export const boucheTypeReverseCorres = {
	0: "Pile",
	5: "Electriques",
	10: "Manuelle",
};

export const AIR_ENTRY_TYPE = {
	HYGRO: 0,
	ACOUSTIC: 5,
};
export const airEntryPointCorres = {
	Hygro: "HYGRO",
	Accoustique: "ACOUSTIC",
};
export const airEntryPointReverseCorres = {
	0: "Hygro",
	5: "Accoustique",
};

export const ROOF_TYPE = {
	PLACO: 0,
	HOURDI: 5,
	PLAFONETTE: 10,
	LOST_SPACE: 15,
	PREPARED: 20,
};
export const roofTypeCorres = {
	"Placo": "PLACO",
	"Hourdi": "HOURDI",
	"Plafonette": "PLAFONETTE",
	"Comble perdu": "LOST_SPACE",
	"Aménagé": "PREPARED",
};
export const roofTypeReverseCorres = {
	0: "Placo",
	5: "Hourdi",
	10: "Plafonette",
	15: "Comble perdu",
	20: "Aménagé",
};

export const TYPE_OF_WALLS = {
	BRIQUE: 0,
	PLACO: 5,
	PIERRE: 10,
};
export const typeOfWallsCorres = {
	Brique: "BRIQUE",
	Placo: "PLACO",
	Pierre: "PIERRE",
};
export const typeOfWallsReverseCorres = {
	0: "Brique",
	5: "Placo",
	10: "Pierre",
};

export const TYPE_OF_WALLS_FOR_BATHROOM_FURNITURE = {
	BRIQUE: 0,
	PLACO: 5,
	CONCRETE: 10,
};
export const typeOfWallsForBathroomFurnitureCorres = {
	Brique: "BRIQUE",
	Placo: "PLACO",
	Béton: "CONCRETE",
};
export const typeOfWallsForBathroomFurnitureReverseCorres = {
	0: "Brique",
	5: "Placo",
	10: "Béton",
};

export const TYPE_OF_WALLS_FAIENCE = {
	PLATRE: 0,
	PLACO: 5,
};
export const typeOfWallsFaienceCorres = {
	Plâtre: "PLATRE",
	Placo: "PLACO",
};
export const typeOfWallsFaienceReverseCorres = {
	0: "Plâtre",
	5: "Placo",
};

export const WALLS_ISOLATION = {
	AIR: 0,
	GLASS_WOOL: 5,
	POLYSTYRENE: 10,
	INEXISTANT: 15,
};
export const wallsIsolationCorres = {
	"Air": "AIR",
	"Laine de verre": "GLASS_WOOL",
	"Polystyrenne": "POLYSTYRENE",
	"Inexistant": "INEXISTANT",
};
export const wallsIsolationReverseCorres = {
	0: "Air",
	5: "Laine de verre",
	10: "Polystyrenne",
	15: "Inexistant",
};

export const BUILD_PERIOD = {
	B4_1988: 0,
	B4_2000: 5,
	AFTER_2000: 10,
};
export const buildPeriodCorres = {
	"Avant 1988": "B4_1988",
	"Entre 1989 et 2000": "B4_2000",
	"Après 2000": "AFTER_2000",
};
export const buildPeriodReverseCorres = {
	0: "Avant 1988",
	5: "Entre 1989 et 2000",
	10: "Après 2000",
};

export const ROOF_ISOLATION = {
	BLOWED: 0,
	UNROLLED: 5,
};
export const roofIsolationCorres = {
	Soufflés: "BLOWED",
	Déroulés: "UNROLLED",
};
export const roofIsolationReverseCorres = {
	0: "Soufflés",
	5: "Déroulés",
};

export const ELECTRICITY_TYPE = {
	MONO: 0,
	TRI: 5,
};
export const electricityTypeCorres = {
	Monophasée: "MONO",
	Triphasée: "TRI",
};
export const electricityTypeReverseCorres = {
	0: "Monophasée",
	5: "Triphasée",
};

export const PART_OF_HOUSE = {
	ALL: 0,
	PART: 5,
	NONE: 10,
};
export const partOfHouseCorres = {
	"Tout le logement": "ALL",
	"Une partie du logement": "PART",
	"Non": "NONE",
};
export const partOfHouseReverseCorres = {
	0: "Tout le logement",
	5: "Une partie du logement",
	10: "Non",
};

export const HEAT_TYPE = {
	PAC_AIR_WATER: 0,
	FUEL: 5,
	GAZ: 10,
	ELEC: 15,
	// CLIM: 20,
};
export const heatTypeCorres = {
	"PAC air eau": "PAC_AIR_WATER",
	"Chaudière fioul": "FUEL",
	"Chaudière gaz": "GAZ",
	"Radiateur électrique": "ELEC",
	// "Climatisation": "CLIM",
};
export const heatTypeReverseCorres = {
	0: "PAC air eau",
	5: "Chaudière fioul",
	10: "Chaudière gaz",
	15: "Radiateurs électriques",
	// 20: "Climatisation",
};

export const NEW_HEAT_TYPE = {
	PAC: 0,
	LOW_CONSO: 5,
	PAC_HYBRIDE: 10,
	ELEC: 15,
	// CLIM: 20,
};
export const newHeatTypeCorres = {
	"PAC": "PAC",
	"Chaudière condensation": "LOW_CONSO",

	"PAC hybride": "PAC_HYBRIDE",
	"Radiateurs électriques": "ELEC",
	// "Climatisation": "CLIM",
};
export const newHeatTypeReverseCorres = {
	0: "PAC",
	5: "Chaudière condensation",
	10: "PAC hybride",
	15: "Radiateurs électriques",
	// 20: "Climatisation",
};

export const THERMOSTAT_TYPE = {
	RADIO: 0,
	FILAIRE: 5,
};
export const thermostatTypeCorres = {
	Radio: "RADIO",
	Filaire: "FILAIRE",
};
export const thermostatTypeReverseCorres = {
	0: "Radio",
	5: "Filaire",
};

export const EMETER_TYPE = {
	GROUND: 0,
	CEILING: 5,
	HYDRO: 10,
	DYNAMIC: 15,
};
export const emeterTypeCorres = {
	"plancher chauffant / rafraîchissant": "GROUND",
	"plafond rayonnant": "CEILING",
	"radiateurs hydrauliques": "HYDRO",
	"radiateurs dynamiques": "DYNAMIC",
};
export const emeterTypeReverseCorres = {
	0: "plancher chauffant / rafraîchissant",
	5: "plafond rayonnant",
	10: "radiateurs hydrauliques",
	15: "radiateurs dynamiques",
};

export const HYDRO_HEAT_TYPE = {
	STEEL: 0,
	ALU: 5,
	IRON: 10,
};
export const hydroHeatTypeCorres = {
	Acier: "STEEL",
	Alu: "ALU",
	Fonte: "IRON",
};
export const hydroHeatTypeReverseCorres = {
	0: "Acier",
	5: "Alu",
	10: "Fonte",
};

export const SMOKE_SYS_TYPE = {
	NONE: 0,
	CHIMNEY: 5,
	SUCKER: 10,
};
export const smokeSysTypeCorres = {
	Sans: "NONE",
	Cheminée: "CHIMNEY",
	Ventouse: "SUCKER",
};
export const smokeSysTypeReverseCorres = {
	0: "Sans",
	5: "Cheminée",
	10: "Ventouse",
};

export const WIRE_CLIM_SYS_TYPE = {
	SPLIT: 0,
	SHEATHABLE: 5,
};
export const wireClimSysTypeCorres = {
	Split: "SPLIT",
	Gainable: "SHEATHABLE",
};
export const wireClimSysTypeReverseCorres = {
	0: "Split",
	5: "Gainable",
};

export const NEW_PAC_ACTION = {
	REPLACE: 0,
	RELEVE: 5,
};
export const newPacActionCorres = {
	Remplacement: "REPLACE",
	Relève: "RELEVE",
};
export const newPacActionReverseCorres = {
	0: "Remplacement",
	5: "Relève",
};

export const NEW_LOW_CONSO_HEAT_TYPE = {
	FUEL: 0,
	GAZ: 5,
};
export const newLowConsoHeatTypeCorres = {
	Fioul: "FUEL",
	Gaz: "GAZ",
};
export const newLowConsoHeatTypeReverseCorres = {
	0: "Fioul",
	5: "Gaz",
};

export const NEW_CLIM_ACTION = {
	RESHAPE: 0,
	REGULATION: 5,
	// MOUTH: 10,
};
export const newClimActionCorres = {
	Reprise: "RESHAPE",
	Régulation: "REGULATION",
	// Bouche: "MOUTH",
};
export const newClimActionReverseCorres = {
	0: "Reprise",
	5: "Régulation",
	// 10: "Bouche",
};

export const NEW_CLIM_ACTION_REPRISE = {
	CORNER_DAY_CORNER_NIGHT: 0,
	ROOM_BY_ROOM: 5,
};
export const newClimActionRepriseCorres = {
	"Coin jour / Coin nuit": "CORNER_DAY_CORNER_NIGHT",
	"Pièce par pièce": "ROOM_BY_ROOM",
};
export const newClimActionRepriseReverseCorres = {
	0: "Coin jour / Coin nuit",
	5: "Pièce par pièce",
};

export const NEW_CLIM_ACTION_CHOICE = {
	CENTRALISED: 0,
	ROOM_BY_ROOM: 5,
};
export const newClimActionChoiceCorres = {
	"Centralisé": "CENTRALISED",
	"Pièce par pièce": "ROOM_BY_ROOM",
};
export const newClimActionChoiceReverseCorres = {
	0: "Centralisé",
	5: "Pièce par pièce",
};

export const NEW_CLIM_MOUTH_TYPE = {
	ROUND: 0,
	RECT: 5,
	DECO: 10,
};
export const newClimMouthTypeCorres = {
	Ronde: "ROUND",
	Rectangulaire: "RECT",
	Déco: "DECO",
};
export const newClimMouthTypeReverseCorres = {
	0: "Ronde",
	5: "Rectangulaire",
	10: "Déco",
};

export const ELEC_HEAT_REGULATION_TYPE = {
	WITH_PILOTE: 0,
	WITHOUT_PILOTE: 5,
};
export const elecHeatRegulationTypeCorres = {
	"Avec gestionnaire fil pilote": "WITH_PILOTE",
	"Sans gestionnaire fil pilote": "WITHOUT_PILOTE",
};
export const elecHeatRegulationTypeReverseCorres = {
	0: "Avec gestionnaire fil pilote",
	5: "Sans gestionnaire fil pilote",
};

export const HOT_WATER_DEPORTED_TYPE = {
	ELECTRIC: 0,
	THERMODYNAMIC: 5,
	SOLAR: 10,
};
export const hotWaterDeportedTypeCorres = {
	Électrique: "ELECTRIC",
	Thermodynamique: "THERMODYNAMIC",
	Solaire: "SOLAR",
};
export const hotWaterDeportedTypeReverseCorres = {
	0: "Électrique",
	5: "Thermodynamique",
	10: "Solaire",
};

export const SHOWER_TYPE = {
	PMR: 0,
	TO_RECESS: 5,
	TO_POSE: 10,
	BOX: 15,
};
export const showerTypeCorres = {
	"PMR": "PMR",
	"A encastrer": "TO_RECESS",
	"A poser": "TO_POSE",
	"Cabine": "BOX",
};
export const showerTypeReverseCorres = {
	0: "PMR",
	5: "A encastrer",
	10: "A poser",
	15: "Cabine",
};

export const TYPE_OF_SHOWER_WALL = {
	FIXED: 0,
	SWIVEL: 5,
	ANGLE: 10,
	SLIDE: 15,
};
export const typeOfShowerWallCorres = {
	Fixe: "FIXED",
	Pivotante: "SWIVEL",
	Angle: "ANGLE",
	Coulissante: "SLIDE",
};
export const typeOfShowerWallReverseCorres = {
	0: "Fixe",
	5: "Pivotante",
	10: "Angle",
	15: "Coulissante",
};
