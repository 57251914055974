/* eslint-disable react-hooks/exhaustive-deps */
import {
  getNewBusinessById
} from "api/newBusiness";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import {
  useParams
} from "react-router-dom";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import HeaderStatCards from "../../components/Headers/Admin/HeaderStatCards";
import AppContext from "../../Contexts/AppContext";
import ListTechnicalReportItem from './ListTechnicalReportItem';





function ListTechnicalReports() {
  let { businessId, disabled } = useParams();
  const [newBusiness, setNewBusiness] = useState({});

  const {
    displayErrorMessage,
  } = useContext(AppContext);

  const handleGetBusinessById = async () => {
    try {
      const data = await getNewBusinessById(businessId);

      setNewBusiness({
        ...data,
        date: moment(data.date).format("YYYY-MM-DD"),
        objectifDateInvoice: moment(
          data.objectifDateInvoice,
        ).format("YYYY-MM-DD"),
        invoiceDate:
          (data.invoiceDate || data.dateOfSignature) &&
          moment(data.invoiceDate).format("YYYY-MM-DD"),
      });


    } catch (err) {
      console.log("err", err);
      displayErrorMessage(
        "Impossible de récuperer les information liés à cette affaire.",
      );
    }
  };

  useEffect(() => {
    handleGetBusinessById();
  }, [businessId]);


  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="flex flex-wrap">
            {
              newBusiness
                ?.technicalReports
                ?.map(report => (
                  <div key={report?._id} className="w-full  px-4">
                    <ListTechnicalReportItem disabled={!!disabled} onGoingTechnicalReport={report} />
                  </div>
                ))
            }

          </div>
          <FooterAdmin {...footeradmin} />
        </div>
      </div>
    </>
  )
}

export default ListTechnicalReports
