import {
  addConstructionSite,
  addTroubleShoot, deleteOrder, getOrderById, updateOrder
} from "api/orders";
import { getAllSiteManagers } from "api/users";
import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge.js";
// import CardDashboardProfile from "components/Cards/Admin/CardDashboardProfile.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
// components
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import moment from "moment";
import React, {
  useContext, useEffect, useState
} from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  advancementStatusCorres, advancementStatusReverseCorres, ADVANCEMENT_STATUSES,
  SITE_STATUSES, typeOfBusinessReverseCorres
} from "types/baseTypes";
// import headerstatcards from "_texts/admin/headers/headerstatcards.js";
// import cardsettingslarge from "_texts/admin/cards/cardsettingslarge.js";
// import carddashboardprofile from "_texts/admin/cards/carddashboardprofile.js";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import AppContext from "../../Contexts/AppContext";




export default function UpdateOrder() {
  const [
    newAdvancementStatus,
    setNewAdvancementStatus,

  ] = useState(ADVANCEMENT_STATUSES.TO_START);
  let history = useHistory();

  const location = useLocation();
  let { orderId } = useParams();
  const {
    user,
    selectedAgency,
    isAdmin,
    isSuperAdmin,
    displayErrorMessage,
    displaySuccessMessage,
  } = useContext(AppContext);
  const [order, setOrder] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [siteManagers, setSiteManagers] = useState([]);

  const isAllowedDelete = isAdmin || isSuperAdmin;


  const handleGetSiteManagers = async () => {
    try {
      const users = await getAllSiteManagers(selectedAgency);
      setSiteManagers(users);
    } catch (err) {
      console.log("err site users", err)
    }
  }

  useEffect(() => {
    handleGetSiteManagers()
  }, [])

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleGetOrderById = async () => {
    try {
      const data = await getOrderById(orderId);

      setOrder({
        ...data,
        date: moment(data.date).format("YYYY-MM-DD"),
        objectifDateInvoice: moment(
          data.objectifDateInvoice,
        ).format("YYYY-MM-DD"),
        invoiceDate: moment(data.invoiceDate).format(
          "YYYY-MM-DD",
        ),
        dateCompleteSite:
          data.dateCompleteSite &&
          moment(data.dateCompleteSite).format(
            "YYYY-MM-DD",
          ),
        dateOfSignature: moment(
          data.dateOfSignature,
        ).format("YYYY-MM-DD"),
        advancementStatus:
          data.advancementStatus ||
          ADVANCEMENT_STATUSES.TO_START,
        siteStatus: SITE_STATUSES.TO_DO,
      });
      setNewAdvancementStatus(data.advancementStatus);
      setIsLoaded(true);
    } catch (err) {
      displayErrorMessage(
        "Impossible de récuperer les information liés à cette commande.",
      );
    }
  };

  useEffect(() => {
    handleGetOrderById();
  }, []);

  const isRunning =
    newAdvancementStatus === ADVANCEMENT_STATUSES.RUNNING;

  const isSuspended =
    newAdvancementStatus === ADVANCEMENT_STATUSES.PAUSED;

  const wasRunning =
    order.advancementStatus ===
    ADVANCEMENT_STATUSES.RUNNING;
  const wasSuspended =
    order.advancementStatus === ADVANCEMENT_STATUSES.PAUSED;
  const isButtonDisabled =
    (!order.siteManager && isRunning) ||
    (isSuspended && wasSuspended);
  const wasOver =
    order.advancementStatus ===
    ADVANCEMENT_STATUSES.FINISHED ||
    order.advancementStatus ===
    ADVANCEMENT_STATUSES.REFUSED;

  const validateUpdateOrder = (msg, display = true) => {
    if (isRunning && !order.siteManager) {
      display
        ? displayErrorMessage(msg)
        : console.log("validateUpdateOrder", false);
      return false;
    }

    return true;
  };
  const validateAddSiteOrTS = (msg, display = true) => {
    if (!order.siteManager) {
      display
        ? displayErrorMessage(msg)
        : console.log("validateAddSiteOrTS", false);
      return false;
    }

    return true;
  };

  const handleUpdateOrder = async () => {
    try {
      if (isRunning && !(wasRunning || wasSuspended)) {
        const consolidatedOrder = {
          siteStatus: order.siteStatus,
          siteManager: order.siteManager,
          advancementStatus: newAdvancementStatus,
          orderId: order._id,
          clientId: order.clientId,
          agencyId: order.agencyId
        };
        if (order.amount >= 1000) {

          const msg =
            "Veuillez completer tous les champs obligatoires avant de créer ce chantier.";
          if (!validateAddSiteOrTS(msg)) return;

          await addConstructionSite(consolidatedOrder);
        } else if (order.amount < 1000) {

          const msg =
            "Veuillez completer tous les champs obligatoires avant de créer ce dépannage.";
          if (!validateAddSiteOrTS(msg)) return;

          await addTroubleShoot(consolidatedOrder);
        }

      }

      const msg =
        "Veuillez completer tous les champs obligatoires avant de modifier cette commande.";
      if (!validateUpdateOrder(msg)) return;
      const consolidatedOrder = {
        ...order,
        advancementStatus: newAdvancementStatus,
      };
      await updateOrder(orderId, consolidatedOrder);

      displaySuccessMessage();

      isRunning && !(wasRunning || wasSuspended)
        ? order.amount < 1000
          ? history.push(
            "/list-sites-or-trouble-shoots/:trouble-shoots",
          )
          : history.push(
            "/list-sites-or-trouble-shoots/:sites",
          )
        : history.push("/list-orders/:running");
    } catch (err) {
      displayErrorMessage();
    }
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleDateChange = (date) => {
    const objectifDateInvoice = moment(date)
      .add(2, "days")
      .format("YYYY-MM-DD");
    setOrder((vals) => ({
      ...vals,
      date,
      objectifDateInvoice,
    }));
  };

  const handleDeleteOrder = async () => {
    try {
      await deleteOrder(orderId)
      history.push("/list-orders/:running")
    } catch (err) {
      console.log("err delting new business", err)
    }
  }

  const buildForm = () => {
    const buttonTitle =
      isRunning && !(wasRunning || wasSuspended)
        ? order.amount >= 1000
          ? "Ajouter un chantier"
          : "Ajouter un dépannage"
        : "Modifier";

    const title = !wasOver
      ? "Éditer la commande"
      : "Infos de la commande";

    const deleteButton = (isAllowedDelete && orderId)
      ? {
        children: "SUPPRIMER",
        size: "sm",
        color: "red",
        onClick: handleDeleteOrder
      }
      : null;


    const technicalReportButton = {
      children: "Relevé technique",
      size: "sm",
      color: "lightBlue",
      onClick: () => {
        const suffix = order.advancementStatus >= ADVANCEMENT_STATUSES.RUNNING
          ? "/disabled"
          : ""
        history.push(`/list-technical-reports/${order?.newBusinessId}${suffix}`)
      },
    }

    const siteSupervisionButton = {
      children: "Suivi de chantier",
      size: "sm",
      color: "lightBlue",
      onClick: () => {
        const suffix = order.advancementStatus >= ADVANCEMENT_STATUSES.RUNNING
          ? "/disabled"
          : ""
        history.push({
          pathname: `/list-site-supervisions`,
          state: {
            type: "ORDERS",
            id: orderId,
            siteSupervisions: order?.siteSupervisions || []
          }
        })
      },
    }

    return {
      title,
      deleteButton,
      button: {
        children: buttonTitle,
        disabled: isButtonDisabled || wasOver,
        size: "sm",
        color: !(isButtonDisabled || wasOver)
          ? "lightBlue"
          : "darkGrey",
        onClick: handleUpdateOrder,
      },
      button2: technicalReportButton,
      button3: siteSupervisionButton,
      forms: [
        {
          title: "",
          inputs: [
            {
              disabled: wasOver,
              label: "Etat",
              width: 6,
              select: {
                disabled: wasOver,
                placeholder: "",
                items: isSuspended
                  ? [
                    advancementStatusReverseCorres[
                    ADVANCEMENT_STATUSES.PAUSED
                    ],
                    advancementStatusReverseCorres[
                    ADVANCEMENT_STATUSES.RUNNING
                    ],
                  ]
                  : !(wasSuspended || wasRunning)
                    ? [
                      advancementStatusReverseCorres[
                      ADVANCEMENT_STATUSES.TO_START
                      ],
                      advancementStatusReverseCorres[
                      ADVANCEMENT_STATUSES.RUNNING
                      ],
                    ]
                    : [
                      advancementStatusReverseCorres[
                      ADVANCEMENT_STATUSES.RUNNING
                      ],
                      advancementStatusReverseCorres[
                      ADVANCEMENT_STATUSES.PAUSED
                      ],
                    ],
                defaultValue:
                  advancementStatusReverseCorres[
                  newAdvancementStatus
                  ],
                value:
                  advancementStatusReverseCorres[
                  newAdvancementStatus
                  ],
                onChange: (e) => {
                  setNewAdvancementStatus(
                    ADVANCEMENT_STATUSES[
                    advancementStatusCorres[e]
                    ],
                  );
                },
              },
            },

            {
              label: `Responsable chantier ${!order.siteManager && isRunning ? "*" : ""
                }`,

              width: 6,
              select: {
                disabled:
                  (wasRunning && !isRunning) || wasOver,
                border:
                  !order.siteManager && isRunning
                    ? "borderwarning"
                    : "border",
                placeholder: "",
                items: siteManagers.map((value) => value.userPrincipalName),
                value: order.siteManager,
                defaultValue: order.siteManager,
                onChange: (e) => {
                  if (typeof e === "string") {
                    setOrder((vals) => ({
                      ...vals,
                      siteManager: e
                    }));
                  }
                },
              },
            },

            {
              label: "Remarques",
              width: 12,
              input: {
                disabled: wasOver,
                placeholder: "",
                type: "textarea",
                defaultValue: order.comment,
                rows: 2,
                value: order.comment,
                onChange: (e) =>
                  setOrder((vals) => ({
                    ...vals,
                    comment: e.target.value,
                  })),
              },
            },
            {
              label: "Type de client",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                value: order?.clientId?.typeOfClient,

              },
            },
            {
              label: "Date fin chantier",
              width: 6,
              input: {
                placeholder: "",
                disabled: true,
                type: "date",
                defaultValue: "",
                value: order.dateCompleteSite,

              },
            },

            order.typeOfClient === "Entreprise" && {
              label: "Nom de l'entreprise*",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "text",
                defaultValue: "",
                value: order?.clientId?.companyName,

              },
            },
            {
              label: "Nom du contact*",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "text",
                defaultValue: "",
                value: order?.clientId?.clientLastName,

              },
            },
            {
              label: "Prénom du contact*",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "text",
                defaultValue: "",
                value: order?.clientId?.clientFirstName,

              },
            },

            {
              label: "Catégorie",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                value: typeOfBusinessReverseCorres[order.typeOfBusiness],

              },
            },
            {
              label: "Origine de l'affaire",
              width: 6,
              background: "grey",
              input: {
                disabled: true,
                placeholder: "",
                value: order?.clientId?.origin,

              },
            },
          ],
        },

        {
          title: "",
          inputs: [
            {
              label: "Date du Rdv",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "date",
                // defaultValue: "",
                value: order.date,
                onChange: (e) =>
                  handleDateChange(e.target.value),
              },
            },
            {
              label: "Responsable devis",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "text",
                // defaultValue: "",
                value: order.responsableOfInvoice,
                onChange: (e) =>
                  setOrder((vals) => ({
                    ...vals,
                    responsableOfInvoice: e.target.value,
                  })),
              },
            },
            {
              label: "Date objective remise du devis",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "date",
                // defaultValue: "",
                value: order.objectifDateInvoice,
                onChange: (e) =>
                  setOrder((vals) => ({
                    ...vals,
                    objectifDateInvoice: e.target.value,
                  })),
              },
            },
            {
              label: "Date de remise réelle du devis",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "date",
                // defaultValue: "",
                value: order.invoiceDate,
                onChange: (e) =>
                  setOrder((vals) => ({
                    ...vals,
                    invoiceDate: e.target.value,
                  })),
              },
            },
          ],
        },
        {
          title: "",
          inputs: [
            {
              label: "Montant HT €",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "number",
                defaultValue: "",
                value: order.amount,
                onChange: (e) =>
                  setOrder((vals) => ({
                    ...vals,
                    amount: e.target.value,
                  })),
              },
            },

            {
              label: "Status",
              width: 6,
              select: {
                placeholder: "",
                disabled: true,
                items: ["Devis accepté"],
                defaultValue: "Devis Accepté",
                value: "Devis Accepté",
              },
            },
            {
              label: "Date de signature*",
              width: 6,
              input: {
                disabled: true,
                placeholder: "",
                type: "date",
                defaultValue: order.dateOfSignature,
                value: order.dateOfSignature,
                onChange: (e) =>
                  setOrder((vals) => ({
                    ...vals,
                    dateOfSignature: e.target.value,
                  })),
              },
            },
          ],
        },
      ],
    };
  };

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="flex flex-wrap">
            <div className="w-full  px-4">
              {isLoaded ? (
                <CardSettingsLarge {...buildForm()} />
              ) : (
                <h1>...Loading</h1>
              )}
            </div>
            {/* <div className="w-full xl:w-4/12 px-4">
              <CardDashboardProfile {...carddashboardprofile} />
            </div> */}
          </div>
          <FooterAdmin {...footeradmin} />
        </div>
      </div>
    </>
  );
}
