import Button from "components/Elements/Button";
import {
  Link,
  //   useParams,
  useHistory,
} from "react-router-dom";

export const Breadcrumb = ({ location, pageName, color, ...rest }) => {
  const separator = " >>> ";
  const history = useHistory();

  if (!!!color) {
    color = "white";
  }

  const colors = {
    lightBlue: " text-blueGray",
    white: " text-white",
  };

  return (
    <div
      className={
        "breadCrumb flex flex-nowrap  z-1 items-center content-center" +
        colors[color]
      }
    >
      <div className="px-4">
        <Button
          onClick={() => history.goBack()}
          size="lg"
          color={color}
          children={<i className="fas fa-undo"></i>}
          roundedFull={true}
        />
      </div>

      <div style={{ fontSize: "0.8em" }}>
        <Link className="px-2" to="/">
          Dashboard
        </Link>
        <span>{separator}</span>
        <Link className="px-2" to={location.pathname}>
          {pageName}
        </Link>
      </div>
    </div>
  );
};
