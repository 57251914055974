import { getAllAgencies } from 'api/agency';
import CardFullTable from 'components/Cards/Admin/CardFullTable';
import Input from 'components/Elements/Input';
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import React, { useEffect, useState } from 'react';

export default function ListAgencies() {
  const [agencyQuery, setAgencyQuery] = useState("")
  const [agencies, setAgencies] = useState([]);
  const [filterdTransformedAgencies, setFilterdTransformedAgencies] = useState([]);

  const dropDownConfig = (id) => ({
    icon: "fas fa-edit",
    button: {
      color: "white",
      size: "sm",
      href: `#/add-agency/${id}`,
    },
  });

  const transformData = (agency) => {
    return {
      rowProps: {
        bg: " ",
      },
      colProps: [
        {
          dropdown: { ...dropDownConfig(agency?._id) },
        },
        agency?.name || "-",
        agency?.phone || "-",
        agency?.email || "-",
        agency?.franchiseOwners?.join(" - ") || "",

      ],
    };
  };

  const handleGetAgencies = async () => {

    try {
      let allAgencies = await getAllAgencies();

      allAgencies = allAgencies?.sort((a, b) => a?.name < b?.name ? -1 : 1)

      setAgencies(allAgencies);

      setFilterdTransformedAgencies(allAgencies.map(transformData));


    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleGetAgencies()
  }, [])

  const LIST_TABLE_HEADER = {
    title: "Liste des agences",
    color: "white",
    head: [
      {
        onClick: () => { },
        string: "Editer",
      },
      {

        string: "Nom",
      },
      {
        string: "Tél",
      },

      {
        // onClick: () => {
        //   setTypeToSort("string");
        //   setEntryOfDataToSortOn("originOfNewBusiness");
        //   setIsSortPositif(!isSortPositif);
        // },
        string: "Email",
      },
      {

        string: "Franchisé(s)",
      },
    ],
    body: [],
  }


  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        {/* <NavbarSearchUser {...navbarsearchusersettings2} /> */}
        {/* <HeaderStatCards {...headerstatcardsnewbusiness} /> */}
        <div className="w-full h-full px-4 md:px-6 mx-auto -mt-24">
          <div className="w-full h-full hello flex flex-wrap">
            <div className="px-4 flex flex-nowrap items-center justify-between w-full">
              <Input
                {...{
                  placeholder: "Recherche",
                  type: "text",
                  onChange: (e) =>
                    setAgencyQuery(e.target.value),
                }}
              />
              {/* <Breadcrumb
                location={location}
                pageName={cardfulltablenewbusiness.title}
              /> */}
            </div>
            <div className="w-full h-full px-2">
              <CardFullTable
                {...LIST_TABLE_HEADER}
                body={filterdTransformedAgencies}
              />
            </div>
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  )
}
