import axios from "axios";

const MicrosoftUrl = "https://graph.microsoft.com/v1.0";
const select =
  "$select=department,businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName";

export const getOneUser = async (accessToken) => {
  const result = await axios.get(`${MicrosoftUrl}/me?${select}`, {
    headers: { Authorization: `Bearer  ${accessToken}` },
  });
  return result.data;
};

export const getAllUsers = async (accessToken) => {
  const result = await axios.get(`${MicrosoftUrl}/users`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return result.data;
};

export const getAllUsersWithDepartment = async (accessToken) => {
  const result = await axios.get(`${MicrosoftUrl}/users`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return result.data;
};
