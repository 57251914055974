/* eslint-disable react-hooks/exhaustive-deps */
// src/views/admin/AddUpdateNewBusiness.js

import { getAllAgencies } from "api/agency";
import {
	addNewBusiness,
	clearTechnicalReports,
	deleteNewBusiness,
	getClients,
	getNewBusinessById,
	updateNewBusiness,
} from "api/newBusiness";
import { addOrder } from "api/orders";
import { updateTechnicalReport, uploadImage } from "api/technicalReport";
import { getAllEstimateManagers } from "api/users";
import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import BathroomPlumbingTechnicalReportForm from "forms/newBusiness/BathroomPlumbingTechnicalReportForm";
import ClimatisationHeatTechnicalReportForm from "forms/newBusiness/ClimatisationHeatTechnicalReportForm";
import GeneralForm from "forms/newBusiness/GeneralForm";
import VentilationTechnicalReport from "forms/newBusiness/VentilationTechnicalReportForm";
import SiteForm from "forms/site/SiteForm";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	ADVANCEMENT_STATUSES,
	STATUSES,
	TYPE_OF_BUSINESS,
} from "types/baseTypes";
import { TECHNICAL_REPORT_TYPE } from "types/technicalReportTypes";
import { getPostalCode } from "utils/common";
import footeradmin from "_texts/admin/footers/footeradmin.js";
// components
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
import AppContext from "../../Contexts/AppContext";

export default function AddUpdateNewBusiness() {
	const [isGeneral, setIsGeneral] = useState(true);
	let location = useLocation();
	const history = useHistory();
	const [newStatus, setNewStatus] = useState(STATUSES.CREATED);
	const [isAdd] = useState(location.pathname === "/add-new-business");
	let { businessId } = useParams();
	const [existingClient, setExistingClient] = useState(isAdd ? false : true);
	const [initClients, setInitClients] = useState([]);
	const [existingClients, setExistingClients] = useState([]);
	const [existingClientIdx, setExistingClientIdx] = useState(0);
	const {
		user,
		selectedAgency,
		isAdmin,
		isSuperAdmin,
		displayErrorMessage,
		displaySuccessMessage,
	} = useContext(AppContext);
	const [agencies, setAgencies] = useState([]);
	const [chosenAgency, setChosenAgency] = useState("");
	const [initialAgencies, setInitialAgencies] = useState([]);
	const [isLoaded, setIsLoaded] = useState(false);
	const [newBusiness, setNewBusiness] = useState({
		status: STATUSES.CREATED,
		clientId: {},
	});
	const [
		hasOtherWaterAccesPointsBool,
		setHasOtherWaterAccesPointsBool,
	] = useState(false);

	const [dateOfSignature, setDateOfSignature] = useState("");

	const [showFormsTechnicalReports, setShowFormsTechnicalReports] = useState(
		[],
	);
	const [onGoingTechnicalReport, setOnGoingTechnicalReport] = useState({});
	const [initialBusinessType, setInitialBusinessType] = useState(null);
	const [technicalReports, setTechnicalReports] = useState([]);

	const [
		nbOfDaysToAddToDateForObjectifInvoiceDate,
		setNbOfDaysToAddToDateForObjectifInvoiceDate,
	] = useState(2);
	const [estimateManagers, setEstimateManagers] = useState([]);

	const userAgency = selectedAgency;

	const isAllowedDelete = isAdmin || isSuperAdmin;

	const isAccepted = newStatus === STATUSES.ACCEPTED;

	const isRefused = newStatus === STATUSES.REFUSED;

	const wasSent = newBusiness.status >= STATUSES.SENT;

	const isAllowedShowAgencySelection = !isAdd && isSuperAdmin;

	// useEffect(() => {
	//   setIsAdd(location.pathname === "/add-new-business")
	//   if (location.pathname === "/add-new-business" && Object.keys(newBusiness).length > 0) {
	//     setNewBusiness({});
	//     setOnGoingTechnicalReport({})
	//     // window.location.reload()
	//   }
	//   setForm(buildForm());
	// }, [location.pathname, newBusiness])

	useEffect(() => {
		handleGetExisingClients();
	}, [selectedAgency]);

	const handleGetExisingClients = async () => {
		try {
			let data = (await getClients(selectedAgency)) || [];
			setInitClients(data);

			if (isAdd) {
				const first = data[0];
				setNewBusiness((vals) => ({ ...vals, clientId: { ...first } }));
			}

			data = data.map((d, idx) => ({
				name: `${d.typeOfClient} | ${
					d.typeOfClient === "Entreprise"
						? d.companyName
						: `${d.clientLastName} ${d.clientFirstName}`
				}`,
				value: idx,
			}));
			setExistingClients(data);
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleSetExistingClient = (idx) => {
		setNewBusiness((vals) => ({
			...vals,
			clientId: {
				typeOfClient: "",
				companyName: "",
				clientLastName: "",
				clientFirstName: "",
				address: "",
				origin: "",
			},
			agencyId: "",
		}));

		const data = initClients[idx] || {};
		setExistingClientIdx(idx);

		setNewBusiness((vals) => ({ ...vals, clientId: { ...data } }));
	};

	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);

	const handleGetAllAgencies = async () => {
		try {
			const data = await getAllAgencies();
			setInitialAgencies(data);
			setAgencies(data.map((d) => d.name));
		} catch (err) {
			console.log("err", err);
		}
	};

	const handleAgencySelect = (sAgency) => {
		const agencyFound = initialAgencies.find((a) =>
			a.name.toLowerCase().includes(sAgency.toLowerCase()),
		);

		if (agencyFound) {
			setNewBusiness((vals) => ({ ...vals, agencyId: agencyFound?._id }));

			setChosenAgency(sAgency);
		}
	};

	useEffect(() => {
		handleGetAllAgencies();
	}, []);

	const handleGetEstimateManagers = async () => {
		try {
			const users = await getAllEstimateManagers(userAgency);
			setEstimateManagers(users);
		} catch (err) {
			console.log("err estimate users", err);
		}
	};

	useEffect(() => {
		handleGetEstimateManagers();
	}, []);

	const handleUpdateTypeOfBusiness = () => {
		const confirmed = window.confirm(
			"Vous venez de changer le type d'affaire, le relevé technique associé sera supprimé, voulez vous continuer?",
		);

		if (confirmed) {
			clearTechnicalReports(businessId);
		}

		return confirmed;
	};

	const handleGetBusinessById = async () => {
		try {
			const data = await getNewBusinessById(businessId);

			setNewBusiness({
				...data,
				date: moment(data.date).format("YYYY-MM-DD"),
				objectifDateInvoice: moment(data.objectifDateInvoice).format(
					"YYYY-MM-DD",
				),
				invoiceDate:
					(data.invoiceDate || data.dateOfSignature) &&
					moment(data.invoiceDate).format("YYYY-MM-DD"),
			});

			setNewStatus(data.status ? data.status : STATUSES.CREATED);

			setOnGoingTechnicalReport(data.technicalReports[0] || {});
			setInitialBusinessType(data?.typeOfBusiness);
			setDateOfSignature(data?.dateOfSignature || "");

			setIsLoaded(true);
		} catch (err) {
			displayErrorMessage(
				"Impossible de récuperer les information liés à cette affaire.",
			);
		}
	};

	const getTechnicalReportType = () => {
		switch (newBusiness.typeOfBusiness) {
			case TYPE_OF_BUSINESS.HEAT:
				return TECHNICAL_REPORT_TYPE.HEAT;
			case TYPE_OF_BUSINESS.VENTILATION:
				return TECHNICAL_REPORT_TYPE.VENTILATION;
			case TYPE_OF_BUSINESS.CLIMATISATION:
				return TECHNICAL_REPORT_TYPE.CLIMATISATION;
			case TYPE_OF_BUSINESS.PLUMBING:
				return TECHNICAL_REPORT_TYPE.PLUMBING;
			case TYPE_OF_BUSINESS.BATHROOM:
				return TECHNICAL_REPORT_TYPE.BATHROOM;
			case TYPE_OF_BUSINESS.SITE:
				return undefined;
			case TYPE_OF_BUSINESS.CONTRACT:
				return TECHNICAL_REPORT_TYPE.VENTILATION;

			default:
				return TECHNICAL_REPORT_TYPE.VENTILATION;
		}
	};

	useEffect(() => {
		const technicalReportType = getTechnicalReportType();
		if (typeof technicalReportType !== "undefined")
			setOnGoingTechnicalReport((vals) => ({ ...vals, technicalReportType }));
		if (newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.SITE && isAdd)
			setOnGoingTechnicalReport((vals) => ({}));
	}, [newBusiness.typeOfBusiness]);

	useEffect(() => {
		if (!isAdd && businessId) {
			handleGetBusinessById();
		}
	}, [businessId]);

	useEffect(() => {
		const agencyFound = initialAgencies.find(
			(a) => a._id === newBusiness?.agencyId,
		);

		setChosenAgency(agencyFound?.name);
	}, [newBusiness, initialAgencies]);

	const validateAddUpdateNewBusiness = (msg, display = true) => {
		const {
			clientId,
			typeOfBusiness,
			date,
			objectifDateInvoice,
			responsableOfInvoice,
			amount,
			invoiceDate,
			comment,
		} = newBusiness;
		const {
			companyName,
			typeOfClient,
			clientFirstName,
			clientLastName,
			origin,
		} = clientId;

		if (
			!!(
				newStatus >= STATUSES.ACCEPTED &&
				date &&
				newBusiness?.dateOfSignature &&
				moment(newBusiness?.dateOfSignature).isBefore(date, "day")
			)
		) {
			if (display)
				displayErrorMessage(
					"La date de rdv doit etre avant ou à la date de signature.",
				);

			return false;
		}

		if (
			!typeOfClient ||
			!clientFirstName ||
			!clientLastName ||
			typeOfBusiness === undefined ||
			!origin ||
			!date ||
			!objectifDateInvoice ||
			!responsableOfInvoice ||
			(newStatus !== STATUSES.CREATED && (!amount || amount <= 0)) ||
			(typeOfClient === "Entreprise" && !companyName) ||
			(newStatus === STATUSES.REFUSED && !comment) ||
			(newStatus !== STATUSES.CREATED && !invoiceDate)
		) {
			if (display) displayErrorMessage(msg);

			return false;
		}
		return true;
	};

	const imageHandler = async (event) => {
		const file = event.target.files[0];
		const formData = new FormData();
		formData.append("image", file);

		if (file) {
			const url = await uploadImage(formData);
			return url;
		}
	};

	const validateAddOrder = (msg, display = true) => {
		if (!validateAddUpdateNewBusiness(msg, display)) {
			return false;
		} else if (!newBusiness?.dateOfSignature) {
			if (display) displayErrorMessage(msg);

			return false;
		}
		return true;
	};

	const handleAddNewBusiness = async () => {
		const consolidatedNewBusiness = {
			...newBusiness,
			status: newStatus,
		};

		try {
			if (
				!isAdd &&
				newBusiness?.dateOfSignature &&
				!newBusiness.needsValidation
			) {
				const msg =
					"Veuillez completer tous les champs obligatoires avant d'ajouter une nouvelle commande.";

				if (!validateAddOrder(msg)) return;
				// if (
				//   !handleValidateTechnicalReports(
				//     newBusiness,
				//     [technicalReports, setTechnicalReports],
				//     displayErrorMessage,
				//     showFormsTechnicalReports,
				//   )
				// )
				//   return;

				await addOrder({
					...consolidatedNewBusiness,
					advancementStatus: ADVANCEMENT_STATUSES.TO_START,
					technicalReports: technicalReports,
				});

				await updateNewBusiness(newBusiness._id, consolidatedNewBusiness);
			} else if (isAdd) {
				const msg =
					"Veuillez completer tous les champs obligatoires avant d'ajouter une nouvelle affaire.";

				if (!validateAddUpdateNewBusiness(msg)) return;
				// if (
				//   !handleValidateTechnicalReports(
				//     newBusiness,
				//     [technicalReports, setTechnicalReports],
				//     displayErrorMessage,
				//     showFormsTechnicalReports,
				//   )
				// )
				//   return;

				if (!consolidatedNewBusiness.postalCode) {
					const postalCode = getPostalCode(newBusiness?.clientId?.address);
					consolidatedNewBusiness.postalCode = postalCode;
				}

				const newB = await addNewBusiness({
					...consolidatedNewBusiness,
					userAgency,
					technicalReport: onGoingTechnicalReport,
				});

				if (newBusiness?.dateOfSignature) {
					await addOrder({
						...newB,
						newBusinessId: newB._id,
						advancementStatus: ADVANCEMENT_STATUSES.TO_START,
					});
				}

				if (newStatus === STATUSES.REFUSED) {
					await addOrder({
						...newB,
						dateOfSignature: new Date(),
						newBusinessId: newB._id,
						advancementStatus: newStatus,
					});
				}
			} else {
				const msg =
					"Veuillez completer tous les champs obligatoires avant de modifier cette affaire.";

				if (!validateAddUpdateNewBusiness(msg)) return;

				let updatedNewBusiness = consolidatedNewBusiness;

				if (newBusiness?.typeOfBusiness !== initialBusinessType) {
					const confirmed = handleUpdateTypeOfBusiness();

					updatedNewBusiness = {
						...updatedNewBusiness,
						typeOfBusiness: confirmed
							? newBusiness?.typeOfBusiness
							: initialBusinessType,
					};
				}
				// if (
				//   !handleValidateTechnicalReports(
				//     newBusiness,
				//     [technicalReports, setTechnicalReports],
				//     displayErrorMessage,
				//     showFormsTechnicalReports,
				//   )
				// )
				//   return;

				// const superConsolidatedNewBusiness = {
				//   ...consolidatedNewBusiness,
				//   technicalReports: technicalReports,
				// };

				await updateNewBusiness(newBusiness._id, updatedNewBusiness);

				if (onGoingTechnicalReport._id) {
					await updateTechnicalReport(
						onGoingTechnicalReport._id,
						onGoingTechnicalReport,
					);
				}
			}

			// if (
			//   consolidatedNewBusiness.technicalReports
			//     ?.length === 0 &&
			//   Object.keys(technicalReports).length > 0
			// ) {
			//   // create technicalReport
			//   await createTechnicalReport(
			//     newBusiness._id,
			//     technicalReports,
			//   );
			// } else if (
			//   consolidatedNewBusiness.technicalReports?.length >
			//   0 &&
			//   Object.keys(technicalReports).length > 0
			// ) {
			//   const [
			//     technical,
			//   ] = consolidatedNewBusiness.technicalReports;
			//   // update technical report
			//   await updateTechnicalReport(
			//     technical._id,
			//     technicalReports,
			//   );
			// }

			if (newStatus === "Devis refusé") {
				await addOrder({
					...consolidatedNewBusiness,
					dateOfSignature: new Date(),
					newBusinessId: newBusiness._id,
					advancementStatus: newStatus,
				});
			}

			displaySuccessMessage();
			if (!isAdd && newBusiness.needsValidation) {
				return history.push("/list-new-business");
			}
			newBusiness?.dateOfSignature
				? history.push("/list-orders/:running")
				: newStatus === STATUSES.REFUSED
				? history.push("/list-new-business/:ko")
				: history.push("/list-new-business");
		} catch (err) {
			let msg = "Une erreur est survenue.";

			if (
				err?.response?.data?.message ===
				"ORDER_ALREADY_EXISTS_WITH_NEW_BUSINESS_ID"
			)
				msg = "Une commande à deja été crée pour cette affaire.";

			displayErrorMessage(msg);
		}
	};

	const handleDateChange = (date) => {
		const objectifDateInvoice = moment(date)
			.add(nbOfDaysToAddToDateForObjectifInvoiceDate, "days")
			.format("YYYY-MM-DD");
		setNewBusiness((vals) => ({
			...vals,
			date,
			objectifDateInvoice,
		}));
	};

	const handleChangeView = () => {
		if (newBusiness.typeOfBusiness !== TYPE_OF_BUSINESS.CONTRACT)
			setIsGeneral(!isGeneral);

		const existingTechnicalReports = newBusiness.technicalReports;

		// if (
		//   existingTechnicalReports &&
		//   Object.keys(existingTechnicalReports).length === 5
		// ) {
		//   setTechnicalReports({
		//     ...existingTechnicalReports,
		//   });
		// }
		if (showFormsTechnicalReports.length === 0) {
			if (newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.SITE) {
				const show = [];
				for (let key of Object.keys(technicalReports)) {
					if (technicalReports[key].length > 1)
						show.push(TYPE_OF_BUSINESS[key]);
				}
				setShowFormsTechnicalReports(show);
			}
		}

		if (newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.VENTILATION) {
			setHasOtherWaterAccesPointsBool(
				!!existingTechnicalReports?.VENTILATION?.hasOtherWaterAccesPoints,
			);
		}
	};

	const handleDeleteNewBusiness = async () => {
		try {
			await deleteNewBusiness(businessId);
			history.push("/list-new-business");
		} catch (err) {
			console.log("err delting new business", err);
			displayErrorMessage();
		}
	};

	const handleValidationOfBusiness = async () => {
		try {
			if (!newBusiness?.agencyId || !newBusiness?.clientId?.address) {
				displayErrorMessage("Veuillez compléter le formulaire");
				return;
			}

			await updateNewBusiness(newBusiness._id, {
				...newBusiness,
				needsValidation: false,
			});

			if (newBusiness.status >= STATUSES.ACCEPTED) {
				const consolidatedNewBusiness = {
					...newBusiness,
					status: newStatus,
				};

				await addOrder({
					...consolidatedNewBusiness,
					// dateOfSignature,
					advancementStatus: ADVANCEMENT_STATUSES.TO_START,
					technicalReports: technicalReports,
				});
			}
			history.push("/list-new-business");
		} catch (err) {
			console.log("err", err);
			displayErrorMessage();
		}
	};

	const buildForm = () => {
		const buttonTitle =
			!isAdd && newBusiness.needsValidation
				? "Modifer"
				: newStatus === STATUSES.ACCEPTED
				? "Ajouter une commande"
				: isAdd
				? "Ajouter"
				: "Modifier";

		const buttonTitle2 = "Général";

		const buttonTitle3 = "Relevé technique";
		const buttonTitle4 = "Ajouter un relevé technique";

		const title = isAccepted
			? "AJOUTER UNE COMMANDE"
			: isRefused
			? "Ajouter une affaire KO"
			: isAdd
			? "ajouter une affaire"
			: "éditer l'affaire";
		const isSignable = validateAddUpdateNewBusiness("", false);

		const isDisabled =
			newStatus === STATUSES.REFUSED ||
			(wasSent && validateAddUpdateNewBusiness("", false));

		const isButtonDisabled = isAccepted
			? !validateAddOrder("", false)
			: !validateAddUpdateNewBusiness("", false);

		const isButton2Disabled = isGeneral;
		const isButton3Disabled = false;

		// const isButton3Disabled =
		//   !isGeneral || isButtonDisabled;

		const button3 =
			newBusiness?.technicalReports?.length > 0 && !isAdd
				? {
						children: buttonTitle3,
						disabled: isButton3Disabled,
						size: "sm",
						color: !isButton3Disabled ? "lightBlue" : "darkGrey",
						onClick: () =>
							history.push(`/list-technical-reports/${businessId}`),
				  }
				: null;

		const isAllowedToAddMultipleTechReports =
			newBusiness?.typeOfBusiness === TYPE_OF_BUSINESS.SITE ||
			(newBusiness?.typeOfBusiness !== TYPE_OF_BUSINESS.SITE &&
				newBusiness?.technicalReports?.length === 0);

		const button4 =
			!isAdd && isAllowedToAddMultipleTechReports
				? {
						children: buttonTitle4,
						disabled: isButton3Disabled,
						size: "sm",
						color: !isButton3Disabled ? "lightBlue" : "darkGrey",
						onClick: () =>
							history.push(`/add-new-technical-report/${businessId}`),
				  }
				: null;

		const validateButton =
			!isAdd && newBusiness.needsValidation
				? {
						children: "VALIDER",
						size: "sm",
						color: "orange",
						onClick: handleValidationOfBusiness,
				  }
				: null;

		const deleteButton =
			isAllowedDelete && businessId
				? {
						children: "SUPPRIMER",
						size: "sm",
						color: "red",
						onClick: handleDeleteNewBusiness,
				  }
				: null;

		return {
			title,
			deleteButton,
			button: {
				children: buttonTitle,
				// disabled: isDisabled,
				size: "sm",
				color: !isButtonDisabled ? "lightBlue" : "darkGrey",
				onClick: handleAddNewBusiness,
			},

			button2: {
				children: buttonTitle2,
				disabled: isButton2Disabled,
				size: "sm",
				color: !isButton2Disabled ? "lightBlue" : "darkGrey",
				onClick: handleChangeView,
			},
			button3,
			button4,
			button5: validateButton,

			forms: isGeneral
				? GeneralForm(
						[newBusiness, setNewBusiness],
						[newStatus, setNewStatus],
						[dateOfSignature, setDateOfSignature],
						[
							nbOfDaysToAddToDateForObjectifInvoiceDate,
							setNbOfDaysToAddToDateForObjectifInvoiceDate,
						],
						handleDateChange,
						isDisabled,
						isSignable,
						{
							estimateManagers,
							existingClients,
							existingClient,
							setExistingClient,
							isAdd,
							handleSetExistingClient,
							existingClientIdx,
							agencies,
							agency: chosenAgency,
							selectAgency: handleAgencySelect,
							isAllowedShowAgencySelection,
						},
				  )
				: newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.VENTILATION
				? VentilationTechnicalReport(
						[onGoingTechnicalReport, setOnGoingTechnicalReport],
						[hasOtherWaterAccesPointsBool, setHasOtherWaterAccesPointsBool],
						imageHandler,
				  )
				: newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.CLIMATISATION ||
				  newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.HEAT
				? ClimatisationHeatTechnicalReportForm(
						[technicalReports, setTechnicalReports],
						imageHandler,
						false,
						newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.HEAT
							? "HEAT"
							: "CLIM",
				  )
				: newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.BATHROOM ||
				  newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.PLUMBING
				? BathroomPlumbingTechnicalReportForm(
						[technicalReports, setTechnicalReports],
						imageHandler,
				  )
				: newBusiness?.typeOfBusiness === TYPE_OF_BUSINESS.SITE
				? SiteForm([showFormsTechnicalReports, setShowFormsTechnicalReports])
				: [],
		};
	};

	const buildFormSite = (typeOfBusiness) => {
		const buildTitle = () => {
			const title = "Relevé technique";
			let suffix = "";
			switch (newBusiness?.typeOfBusiness) {
				case TYPE_OF_BUSINESS.VENTILATION:
					suffix = "ventilation";
					break;
				case TYPE_OF_BUSINESS.CLIMATISATION:
					suffix = "climatisation";
					break;
				case TYPE_OF_BUSINESS.HEAT:
					suffix = "chauffage";
					break;
				case TYPE_OF_BUSINESS.BATHROOM:
					suffix = "sanitaire";
					break;
				case TYPE_OF_BUSINESS.PLUMBING:
					suffix = "plomberie";
					break;

				default:
					break;
			}

			return `${title} ${suffix}`;
		};
		const title = buildTitle();

		return {
			button: null,
			button2: null,
			button3: null,
			title,

			forms:
				typeOfBusiness === TYPE_OF_BUSINESS.VENTILATION
					? VentilationTechnicalReport(
							[technicalReports, setTechnicalReports],
							[hasOtherWaterAccesPointsBool, setHasOtherWaterAccesPointsBool],
							imageHandler,
					  )
					: typeOfBusiness === TYPE_OF_BUSINESS.CLIMATISATION ||
					  typeOfBusiness === TYPE_OF_BUSINESS.HEAT
					? ClimatisationHeatTechnicalReportForm(
							[technicalReports, setTechnicalReports],
							imageHandler,
							typeOfBusiness,
							typeOfBusiness === TYPE_OF_BUSINESS.HEAT ? "HEAT" : "CLIM",
					  )
					: (typeOfBusiness === TYPE_OF_BUSINESS.BATHROOM ||
							typeOfBusiness === TYPE_OF_BUSINESS.PLUMBING) &&
					  BathroomPlumbingTechnicalReportForm(
							[technicalReports, setTechnicalReports],
							imageHandler,
							typeOfBusiness,
					  ),
		};
	};

	return (
		<>
			<AuthSidebar />
			<div className="relative 2xl:ml-64 bg-blueGray-100">
				<NavbarSearchUser {...navbarsearchusersettings2} />
				<HeaderStatCards {...{ color: "accentBlue" }} />
				<div className="px-4 md:px-6 mx-auto w-full -mt-24">
					<div className="flex flex-wrap">
						<div className="w-full  px-4">
							{(isAdd || isLoaded) &&
							!!!(
								!isGeneral &&
								newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.SITE
							) ? (
								<CardSettingsLarge {...buildForm()} />
							) : null}
						</div>
						{!isGeneral &&
						newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.SITE &&
						showFormsTechnicalReports?.length
							? showFormsTechnicalReports
									.filter((elt) => elt !== -1)
									.map((typeOfBusiness) => (
										<>
											<CardSettingsLarge {...buildFormSite(typeOfBusiness)} />
										</>
									))
							: null}
						{!isGeneral &&
						newBusiness.typeOfBusiness === TYPE_OF_BUSINESS.SITE ? (
							<CardSettingsLarge {...buildForm()} />
						) : null}
						{/* <div className="w-full xl:w-4/12 px-4">
              <CardDashboardProfile {...carddashboardprofile} />
            </div> */}
					</div>
					<FooterAdmin {...footeradmin} />
				</div>
			</div>
		</>
	);
}
