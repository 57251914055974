import React from "react";
import PropTypes from "prop-types";

export default function CardStatsMini({
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescripiron,
  statIconName,
  statIconColor,
}) {
  


  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white rounded-lg mb-6 md:mb-0 shadow-lg">
        <div className="flex-auto p-2">
          
          <div className="flex flex-nowrap">
            <div className="relative w-full pr-1 max-w-full  flex-grow flex-1">
              <h5 className="text-blueGray-400 whitespace-nowrap uppercase font-bold text-xs">
                {statSubtitle}
              </h5>
              <span className="font-bold   text-xs">{statTitle}</span>
            </div>
            <div className="relative w-auto pl-2 flex-initial">
              <div
                className={
                  "text-white p-3 text-center inline-flex items-center justify-center w-5 h-5 shadow-lg rounded-full " +
                  statIconColor
                }
              >
                <i className={statIconName}></i>
              </div>
            </div>
          </div>
          {statPercent !== ""  ? (<p className="text-xs text-blueGray-500 mt-4">
            <span className={statPercentColor + " mr-2"}>
              <i
                className={
                  statArrow === "up"
                    ? "fas fa-arrow-up"
                    : statArrow === "down"
                    ? "fas fa-arrow-down"
                    : ""
                }
              ></i>{" "}
              {statPercent}%
            </span>
            <span className="whitespace-nowrap">{statDescripiron}</span>
          </p>): (<div className="h-8"></div>)}
          
        </div>
      </div>
    </>
  );
}

CardStatsMini.defaultProps = {
  statArrow: "up",
};

CardStatsMini.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statArrow: PropTypes.oneOf(["up", "down"]),
  statPercent: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
