import axios from "axios";

const { SERVER_URL } = window._env_;
//
// const SERVER_URL = "https://ag-energie-admin-backend.int.at-digital.fr";

export const STATUSES = {
  CONTRACT: 10,
  CREATED: 20,
  INVOICE: 30,
  SENT: 40,
  ACCEPTED: 50,
  REFUSED: 60,
};
export const ADVANCEMENT_STATUSES = {
  TO_START: 10,
  RUNNING: 20,
  PAUSED: 30,
  REFUSED: 40,
  FINISHED: 50,
};

export const SITE_STATUSES = {
  TO_DO: 10,
  PURCHASED: 20,
  DELIVERED: 30,
  PLANIFIED: 40,
  PAUSED: 50,
  FINISHED: 60,
};

export const STATUS_CORRESPONDING = {
  Contrat: "CONTRACT",
  Créé: "CREATED",
  Devis: "INVOICE",
  "Devis envoyé": "SENT",
  "Devis accepté": "ACCEPTED",
  "Devis refusé": "REFUSED",
  "A démarrer": "TO_START",
  "En cours": "RUNNING",
  Suspendu: "PAUSED",
  Terminé: "FINISHED",
  "A planifier / A commander": "TO_DO",
  "A planifer / Matériel commandé": "PURCHASED",
  "A planifier / Matériel reçu": "DELIVERED",
  "Planifié / Reçu": "PLANIFIED",
};

export const getOrders = async (agency) => {
  const result = await axios.get(
    `${SERVER_URL}/api/v1/getOrders`,
    { params: { agency } }
  );
  return result.data;
};

export const addOrder = async (order) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/addOrder`,
    order,
  );

  return result.data;
};

export const getOrderById = async (orderId) => {
  const result = await axios.get(
    `${SERVER_URL}/api/v1/getOrders/${orderId}`,
  );
  return result.data;
};

export const deleteOrder = async (orderId) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/deleteOrder/${orderId}`,
  );
  return result.data;
};

export const updateOrder = async (orderId, updates) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/updateOrder/${orderId}`,
    updates,
  );
  return result.data;
};

export const addConstructionSite = async (
  constructionSite,
) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/addConstructionSite`,
    constructionSite,
  );
  return result.data;
};

export const addTroubleShoot = async (troubleshoot) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/addTroubleShoot`,
    troubleshoot,
  );
  return result.data;
};

export const getConstructionSites = async (agency) => {
  const result = await axios.get(
    `${SERVER_URL}/api/v1/getConstructionSites`,
    { params: { agency } }
  );
  return result.data;
};

export const getConstructionSiteById = async (
  constructionSiteId,
) => {
  const result = await axios.get(
    `${SERVER_URL}/api/v1/getConstructionSites/${constructionSiteId}`,
  );
  return result.data;
};

export const deleteConstructionSite = async (
  constructionSiteId,
) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/deleteConstructionSite/${constructionSiteId}`,
  );
  return result.data;
};

export const deleteTroubleShoot = async (
  constructionSiteId,
) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/deleteTroubleShoot/${constructionSiteId}`,
  );
  return result.data;
};

export const getTroubleShoots = async (agency) => {
  const result = await axios.get(
    `${SERVER_URL}/api/v1/getTroubleShoots`,
    { params: { agency } }
  );
  return result.data;
};
export const getTroubleShootById = async (
  troubleShootId,
) => {
  const result = await axios.get(
    `${SERVER_URL}/api/v1/getTroubleShoots/${troubleShootId}`,
  );
  return result.data;
};
export const updateTroubleShoot = async (
  troubleShootId,
  updates,
) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/updateTroubleShoot/${troubleShootId}`,
    updates,
  );
  return result.data;
};

export const updateConstructionSite = async (
  constructionSiteId,
  updates,
) => {
  const result = await axios.post(
    `${SERVER_URL}/api/v1/updateConstructionSite/${constructionSiteId}`,
    updates,
  );
  return result.data;
};
