/* eslint-disable jsx-a11y/anchor-has-content */
import PropTypes from "prop-types";
import React from "react";
// components
import Button from "../Elements/Button.js";


export default function DropdownButton({ text, icon, button }) {

  const btnDropdownRef = React.createRef();


  return (
    <>
      <div className="relative">
        <Button
          ref={btnDropdownRef}
          href={button.href}
          {...button}
        >



          {icon ? <i className={icon + (text ? " mr-2" : "")}></i> : null}
          {text}

        </Button>

      </div>
    </>
  );
}

DropdownButton.defaultProps = {
  items: [],
};
DropdownButton.propTypes = {
  text: PropTypes.string,
  // if you want an icon to the left of the text
  icon: PropTypes.string,
  // Array of properties to pass to the link object
  // if you pass a prop named to, the link will be
  // generated using Link from react-router-dom
  // props that can be passed to the Button component
  button: PropTypes.object,
};
