import React, { useContext } from "react";
import MicrosoftLogin from "react-microsoft-login";
import { getOneUser } from "../../../api/microsoft";
import AppContext from '../../../Contexts/AppContext';

export default function MicrosoftLoginCard({ onClick }) {
  const context = useContext(AppContext);


  const loginHandler = async (err, data, msal) => {
    try {
      if (!err && data) {
        const user = await getOneUser(data.accessToken);

        context.setLogoutHandler(() => () => {
          msal?.logout()
          context.setUser({})
        });

        onClick(user);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <MicrosoftLogin
        clientId={"4f4abaa7-b228-4ad3-9a92-56255296bc56"}
        authCallback={loginHandler}
        graphScopes={["user.read", "User.ReadBasic.All"]}
        tenantUrl="https://login.microsoftonline.com/a69b9c8e-be10-4e6d-9947-469cb91943de"
        useLocalStorageCache
        redirectUri={window.location.origin}
      // forceRedirectStrategy
      />
    </div>
  );
}
