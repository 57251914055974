import React, { useContext, useEffect } from 'react';
import {
  useHistory
} from "react-router-dom";
import AppContext from '../../Contexts/AppContext';

export default function Logout() {
  const context = useContext(AppContext);
  const history = useHistory();

  useEffect(() => {
    context.logoutHandler();
    history.push("/login")
  }, [])

  return (
    <div>
      <p>Déconnexion en cours</p>
    </div>
  )
}
