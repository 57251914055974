import axios from 'axios';

const { SERVER_URL } = window._env_;

// const SERVER_URL = "https://ag-energie-admin-backend.int.at-digital.fr";

export const getInterventions = async (agency) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getInterventions`, { params: { agency } });

  return result.data;
}

export const addIntervention = async (intervention) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/addIntervention`, intervention);

  return result.data;
}

export const getInterventionById = async (interventionId) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getInterventionById/${interventionId}`);
  return result.data;
}

export const deleteInterventionById = async (interventionId) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/deleteIntervention/${interventionId}`);
  return result.data;
}

export const updateIntervention = async (interventionId, updates) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/updateIntervention/${interventionId}`, updates);
  return result.data;
}

