import {
  getAllMaintenanceContracts,
  getAllMaintenanceContractsForValidation,
} from "api/maintenanceContract";
import CardFullTable from "components/Cards/Admin/CardFullTable";
import Input from "components/Elements/Input";
import ListingActions from "components/Elements/ListingActions";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from "components/Sidebar/AuthSidebar";
import AppContext from "Contexts/AppContext";
import React, { useContext, useEffect, useState } from "react";
import CsvDownload from "react-json-to-csv";
import { useHistory, useParams, useLocation } from "react-router";
import {
  MAINTENANCE_CONTRACTS_STATUS,
  MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE,
  typeOfBusinessReverseCorres,
} from "types/baseTypes";
import { Role } from "utils/common";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";

export default function ListMaintenanceContracts() {
  const [query, setQuery] = useState("");
  const location = useLocation();

  const [
    unTransformedMaintenanceContracts,
    setUnTransformedMaintenanceContracts,
  ] = useState([]);
  const [maintenanceContracts, setMaintenanceContracts] = useState([]);
  const [entryOfDataToSortOn, setEntryOfDataToSortOn] = useState("status");
  const [typeToSort, setTypeToSort] = useState("date");
  const [isSortPositif, setIsSortPositif] = useState(true);
  const history = useHistory();
  const { type } = useParams();
  const { user, selectedAgency } = useContext(AppContext);
  const [isTechnicalPersonnel] = useState(
    user?.department?.trim()?.toLowerCase() ===
      Role.SERVICE_TECHNIQUE.trim().toLowerCase()
  );
  const needsValidation = type === "needs-validation";

  const dropDownConfig = (id) => {
    const redirectPermissions = [
      Role.SUPER_ADMIN,
      Role.ADMIN,
      Role.SERVICE_DIRECTION,
      Role.SERVICE_ADMINISTRATION,
    ];
    const userRole = user?.department?.trim()?.toLowerCase();
    const suffix = redirectPermissions
      .map((p) => p.toLowerCase())
      .includes(userRole)
      ? ""
      : "/disabled";

    return {
      icon: "fas fa-edit",
      button: {
        color: "white",
        size: "sm",
        href: `#/add-maintenance-contracts/${id}${suffix}`,
      },
    };
  };

  const getStatusColor = (status) => {
    let color = "";

    switch (status) {
      case MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.CREATED:
        color = "amber";
        break;
      case MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.SENT:
        color = "lightBlue";
        break;
      case MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.ACCEPTED:
        color = "emerald";
        break;
      case MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.NOT_FINISHED_TO_PLANIFY:
        color = "orange";
        break;
      case MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.REFUSED:
        color = "red";
        break;
      case MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.TO_BE_INVOICED:
        color = "purple";
        break;

      default:
        color = "blueGray";
        break;
    }
    return color;
  };

  const transformData = (maintenanceContract) => {
    const isInReview =
      !maintenanceContract.agencyId &&
      maintenanceContract.needsValidation &&
      maintenanceContract.creationAgency === selectedAgency &&
      !needsValidation;

    return {
      rowProps: {
        bg: isInReview ? "bg-orange-200" : " ",
      },
      colProps: [
        !isInReview
          ? {
              dropdown: { ...dropDownConfig(maintenanceContract?._id) },
            }
          : "EN ATTENTE DE VALIDATION",
        maintenanceContract?.clientId?.typeOfClient === "Entreprise"
          ? maintenanceContract?.clientId?.companyName
          : `${maintenanceContract?.clientId?.clientLastName} ${maintenanceContract?.clientId?.clientFirstName}`,
        {
          color: getStatusColor(maintenanceContract.status),
          text: MAINTENANCE_CONTRACTS_STATUS[maintenanceContract.status] || "",
        },
        typeOfBusinessReverseCorres[maintenanceContract.typeOfBusiness] || "-",
        !isTechnicalPersonnel && maintenanceContract.amountHT,
        !isTechnicalPersonnel && maintenanceContract.amountTTC,
        maintenanceContract.comment,
      ],
    };
  };

  const handleGetMaintenanceContracts = async () => {
    try {
      let data = [];

      if (!needsValidation)
        data = await getAllMaintenanceContracts(selectedAgency);
      else data = await getAllMaintenanceContractsForValidation();

      setUnTransformedMaintenanceContracts(data);

      const sortedData = buildConsolidatedData(data);

      setMaintenanceContracts(sortedData.map(transformData));
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleGetMaintenanceContracts();
  }, [needsValidation, selectedAgency]);

  const buildConsolidatedData = (data) => {
    let consolidatedData = [];

    if (needsValidation) return data;

    const notStatus = data.filter(
      (value) => typeof value.status === "undefined"
    );

    const finished = data.filter(
      (value) =>
        value.status === MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.REFUSED
    );

    const created = data.filter(
      ({ status }) =>
        status === MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.CREATED
    );

    const sent = data.filter(
      ({ status }) => status === MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.SENT
    );

    const accepted = data.filter(
      ({ status }) =>
        status === MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.ACCEPTED
    );
    const notFinishedToPlanify = data.filter(
      ({ status }) =>
        status ===
        MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.NOT_FINISHED_TO_PLANIFY
    );
    const toBeInvoiced = data.filter(
      ({ status }) =>
        status === MAINTENANCE_CONTRACT_STATUS_CORRESPONDANCE.TO_BE_INVOICED
    );

    consolidatedData = [
      ...notStatus,
      ...created,
      ...accepted,
      ...sent,
      ...notFinishedToPlanify,
      ...toBeInvoiced,
      ...finished,
    ];

    return consolidatedData;
  };

  useEffect(() => {
    const ascendingReturn = isSortPositif ? -1 : 1;
    const data = unTransformedMaintenanceContracts.sort((a, b) => {
      if (entryOfDataToSortOn === "name") {
        const aName =
          a?.clientId?.typeOfClient === "Entreprise"
            ? a?.clientId?.companyName
            : `${a?.clientId?.clientLastName} ${a?.clientId?.clientFirstName}`;

        const bName =
          b?.clientId?.typeOfClient === "Entreprise"
            ? b?.clientId?.companyName
            : `${b?.clientId?.clientLastName} ${b?.clientId?.clientFirstName}`;

        return aName?.toLowerCase() < bName?.toLowerCase()
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "status") {
        if (typeof a.status === "undefined" || typeof b.status === "undefined")
          return -1;
        return a.status < b.status ? ascendingReturn : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "typeOfBusiness") {
        return a.typeOfBusiness < b.typeOfBusiness
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "amountHT") {
        return (a.amountHT || 0) < (b.amountHT || 0)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "amountTTC") {
        return (a.amountTTC || 0) < (b.amountTTC || 0)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "comment") {
        return a.comment < b.comment ? ascendingReturn : ascendingReturn * -1;
      }
      return 1;
    });

    const transformedData = data.map(transformData);

    if (data) setMaintenanceContracts(transformedData);
  }, [entryOfDataToSortOn, isSortPositif, typeToSort]);

  const filterByQuery = (query) => {
    const filterQuery = query?.toLowerCase();

    const consolidatedData = buildConsolidatedData(
      unTransformedMaintenanceContracts
    );

    const filtered = consolidatedData.map(transformData).filter((data) => {
      let textValues = data.colProps
        // eslint-disable-next-line array-callback-return
        .map((value) => {
          if (typeof value === "string") return value;
          if (typeof value === "object" && value?.color) return value.text;
        })
        .filter((value) => typeof value === "string");

      textValues = textValues.filter((value) =>
        value?.toLowerCase().includes(filterQuery)
      );
      return textValues.length > 0;
    });

    if (!filterQuery || filterQuery.length === 0) {
      setMaintenanceContracts(
        buildConsolidatedData(unTransformedMaintenanceContracts).map(
          transformData
        )
      );
    } else {
      setMaintenanceContracts(filtered);
    }
  };

  const LIST_TABLE_HEADER = {
    title: "Liste des contrats d'entretiens",
    color: "white",
    head: [
      {
        onClick: () => {},
        string: "Editer",
      },

      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("name");
          setIsSortPositif(!isSortPositif);
        },
        string: "Nom",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("status");
          setIsSortPositif(!isSortPositif);
        },
        string: "Statut",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("typeOfBusiness");
          setIsSortPositif(!isSortPositif);
        },
        string: "Catégorie",
      },
      !isTechnicalPersonnel && {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("amountHT");
          setIsSortPositif(!isSortPositif);
        },
        string: "Montant HT €",
      },
      !isTechnicalPersonnel && {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("amountTTC");
          setIsSortPositif(!isSortPositif);
        },
        string: "Montant TTC €",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("comment");
          setIsSortPositif(!isSortPositif);
        },
        string: "Commentaire",
      },
    ],
    // body: siteSupervisions.map(transformData),
  };

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="w-full h-full px-4 md:px-6 mx-auto -mt-24">
          <div className="w-full h-full flex flex-wrap">
            <ListingActions
              {...{
                filename: "listing_contrat_entretiens.csv",
                onInputSearch: filterByQuery,
                downloadData: unTransformedMaintenanceContracts,
                title: "Contrat Entretiens",
                location,
              }}
            />

            <div className="w-full h-full px-2">
              <CardFullTable
                {...LIST_TABLE_HEADER}
                body={maintenanceContracts}
              />
            </div>
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  );
}
