// src/forms/site/SiteForm.js

import {
  TYPE_OF_BUSINESS,
  typeOfBusinessCorres,
  typeOfBusinessReverseCorres,
} from "types/baseTypes";


export default function SiteForm(
  [showFormsTechnicalReports, setShowFormsTechnicalReports],
) {

  return [
    {
      titles: ["Ajouter un relevé technique"],
      inputs: [
        {
          label: "",
          width: 3,
        },
        {
          label: "Catégorie",
          width: 3,
          select: {

            placeholder: "Ajouter un relevé technique",
            items: Object.keys(typeOfBusinessCorres)
              ?.filter(
                (elt) =>
                  TYPE_OF_BUSINESS[
                  typeOfBusinessCorres?.[elt]
                  ] <= TYPE_OF_BUSINESS?.BATHROOM,
              )
              .filter((elt) => !showFormsTechnicalReports.includes(TYPE_OF_BUSINESS[typeOfBusinessCorres[elt]]))

              .concat("Aucun"),
            value:
              showFormsTechnicalReports.length &&
              typeOfBusinessReverseCorres[
              showFormsTechnicalReports.length - 1
              ],
            onChange: (e) => {
              let index;
              e !== "Aucun"
                ? (index =
                  TYPE_OF_BUSINESS[
                  typeOfBusinessCorres[e]
                  ])
                : (index = -1);

              setShowFormsTechnicalReports([
                ...showFormsTechnicalReports,
                index,
              ]);

              console.log(
                "showFormsTechnicalReports",
                showFormsTechnicalReports,
              );
            },
          },
        },
      ],
    },
  ]
}
