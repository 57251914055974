import {
	showerTypeCorres,
	showerTypeReverseCorres,
	SHOWER_TYPE,
	typeOfShowerWallCorres,
	typeOfShowerWallReverseCorres,
	typeOfWallsFaienceCorres,
	typeOfWallsFaienceReverseCorres,
	typeOfWallsForBathroomFurnitureCorres,
	typeOfWallsForBathroomFurnitureReverseCorres,
	TYPE_OF_SHOWER_WALL,
	TYPE_OF_WALLS_FAIENCE,
	TYPE_OF_WALLS_FOR_BATHROOM_FURNITURE,
} from "types/technicalReportTypes";
import { imageHandler } from "../../utils/common";

export default function BathroomPlumbingTechnicalReportForm(
	onGoingTechnicalReport,
	setOnGoingTechnicalReport,
	isDisabled = false,
) {
	const handleAddNewImage = (imageUrl) => {
		setOnGoingTechnicalReport((vals) => {
			const images = vals?.images || [];
			return {
				...vals,
				images: [...images, { url: imageUrl }],
			};
		});
	};

	const handleRemoveOfNewImage = (imageUrl) => {
		setOnGoingTechnicalReport((vals) => {
			return {
				...vals,
				images: vals?.images?.filter(({ url }) => url !== imageUrl),
			};
		});
	};

	const handleChangeOfImage = (existingImageUrl, newImageUrl) => {
		setOnGoingTechnicalReport((vals) => {
			return {
				...vals,
				images: vals?.images?.map((image) => {
					const { url, ...args } = image;
					if (url === existingImageUrl) return { ...args, url: newImageUrl };
					return image;
				}),
			};
		});
	};

	const onChangeText = (existingImageUrl, text) => {
		setOnGoingTechnicalReport((vals) => {
			return {
				...vals,
				images: vals?.images?.map((image) => {
					const { url } = image;
					if (url === existingImageUrl) return { ...image, description: text };
					return image;
				}),
			};
		});
	};

	return [
		// {
		//   title: `Relevé technique ${typeOfBusinessReverseCorres[typeOfBusiness]}`,
		//   inputs: [],
		// },

		{
			title: "Dimensions de la pièce *",
			inputs: [
				{
					label: "Longueur salle de bain ( m ) *",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.lengthBathroom === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 100,
						changeValue: 0.1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.lengthBathroom,
						value: onGoingTechnicalReport.lengthBathroom,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								lengthBathroom: e,
							}));
						},
					},
				},
				{
					label: "largeur salle de bain ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.widthBathroom === undefined
								? "borderwarning"
								: "border",
						min: 0,
						max: 100,
						changeValue: 0.1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.widthBathroom,
						value: onGoingTechnicalReport.widthBathroom,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								widthBathroom: e,
							}));
						},
					},
				},
				{
					label: "hauteur salle de bain ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.heightBathroom === undefined
								? "borderwarning"
								: "border",
						//
						min: 0,
						max: 10,
						changeValue: 0.1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.heightBathroom,
						value: onGoingTechnicalReport.heightBathroom,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								heightBathroom: e,
							}));
						},
					},
				},
			],
		},
		{
			title: "Reprise de l'alimentation eau froide et eau chaude",

			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isRepriseWaterAlimentation === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isRepriseWaterAlimentation === undefined
								? false
								: onGoingTechnicalReport.isRepriseWaterAlimentation,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isRepriseWaterAlimentation:
									onGoingTechnicalReport.isRepriseWaterAlimentation ===
									undefined
										? true
										: !!!onGoingTechnicalReport.isRepriseWaterAlimentation,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isRepriseWaterAlimentation === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isRepriseWaterAlimentation === undefined
								? false
								: !!!onGoingTechnicalReport.isRepriseWaterAlimentation,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isRepriseWaterAlimentation:
									onGoingTechnicalReport.isRepriseWaterAlimentation ===
									undefined
										? false
										: !!!onGoingTechnicalReport.isRepriseWaterAlimentation,
							}));
						},
					},
				},

				onGoingTechnicalReport?.isRepriseWaterAlimentation === true && {
					label: "Diamètre du tuyeau existant ( mm )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.tubeWaterAlimentationDiametre === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 1000,
						changeValue: 1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.tubeWaterAlimentationDiametre,
						value: onGoingTechnicalReport.tubeWaterAlimentationDiametre,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								tubeWaterAlimentationDiametre: e,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isRepriseWaterAlimentation === true && {
			titles: ["Types d'arrivée eau froide eau chaude ( choix multiple )"],
			inputs: [
				{
					label: "Cuivre",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasMultilayerInWaterEntry &&
							!!!onGoingTechnicalReport.hasPerInWaterEntry &&
							!!!onGoingTechnicalReport.hasIronInWaterEntry &&
							!!!onGoingTechnicalReport.hasBrassInWaterEntry
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.hasBrassInWaterEntry === undefined
								? false
								: onGoingTechnicalReport.hasBrassInWaterEntry,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasBrassInWaterEntry:
									onGoingTechnicalReport.hasBrassInWaterEntry === undefined ||
									onGoingTechnicalReport.hasBrassInWaterEntry === null
										? true
										: !onGoingTechnicalReport.hasBrassInWaterEntry,
							}));
						},
					},
				},
				{
					label: "Fer",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasMultilayerInWaterEntry &&
							!!!onGoingTechnicalReport.hasPerInWaterEntry &&
							!!!onGoingTechnicalReport.hasIronInWaterEntry &&
							!!!onGoingTechnicalReport.hasBrassInWaterEntry
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.hasIronInWaterEntry === undefined
								? false
								: onGoingTechnicalReport.hasIronInWaterEntry,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasIronInWaterEntry:
									onGoingTechnicalReport.hasIronInWaterEntry === undefined ||
									onGoingTechnicalReport.hasIronInWaterEntry === null
										? true
										: !onGoingTechnicalReport.hasIronInWaterEntry,
							}));
						},
					},
				},
				{
					label: "Per",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasMultilayerInWaterEntry &&
							!!!onGoingTechnicalReport.hasPerInWaterEntry &&
							!!!onGoingTechnicalReport.hasIronInWaterEntry &&
							!!!onGoingTechnicalReport.hasBrassInWaterEntry
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.hasPerInWaterEntry === undefined
								? false
								: onGoingTechnicalReport.hasPerInWaterEntry,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasPerInWaterEntry:
									onGoingTechnicalReport.hasPerInWaterEntry === undefined ||
									onGoingTechnicalReport.hasPerInWaterEntry === null
										? true
										: !onGoingTechnicalReport.hasPerInWaterEntry,
							}));
						},
					},
				},
				{
					label: "Multicouche",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasMultilayerInWaterEntry &&
							!!!onGoingTechnicalReport.hasPerInWaterEntry &&
							!!!onGoingTechnicalReport.hasIronInWaterEntry &&
							!!!onGoingTechnicalReport.hasBrassInWaterEntry
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.hasMultilayerInWaterEntry === undefined
								? false
								: onGoingTechnicalReport.hasMultilayerInWaterEntry,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasMultilayerInWaterEntry:
									onGoingTechnicalReport.hasMultilayerInWaterEntry ===
										undefined ||
									onGoingTechnicalReport.hasMultilayerInWaterEntry === null
										? true
										: !onGoingTechnicalReport.hasMultilayerInWaterEntry,
							}));
						},
					},
				},
			],
		},

		{
			title: "Reprise évacuation des eaux usées",
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isRepriseWaterEvacuation === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isRepriseWaterEvacuation === undefined
								? false
								: onGoingTechnicalReport.isRepriseWaterEvacuation,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isRepriseWaterEvacuation:
									onGoingTechnicalReport.isRepriseWaterEvacuation === undefined
										? true
										: !!!onGoingTechnicalReport.isRepriseWaterEvacuation,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isRepriseWaterEvacuation === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isRepriseWaterEvacuation === undefined
								? false
								: !!!onGoingTechnicalReport.isRepriseWaterEvacuation,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isRepriseWaterEvacuation:
									onGoingTechnicalReport.isRepriseWaterEvacuation === undefined
										? false
										: !!!onGoingTechnicalReport.isRepriseWaterEvacuation,
							}));
						},
					},
				},

				onGoingTechnicalReport?.isRepriseWaterEvacuation === true && {
					label: "Diamètre du tuyeau  ( mm )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.tubeWaterEvacuationDiametre === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 1000,
						changeValue: 1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.tubeWaterEvacuationDiametre,
						value: onGoingTechnicalReport.tubeWaterEvacuationDiametre,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								tubeWaterEvacuationDiametre: e,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isRepriseWaterEvacuation === true && {
			titles: ["Type d'évacuation"],
			inputs: [
				{
					label: "Cuivre",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasPvcInWaterExit &&
							!!!onGoingTechnicalReport.hasIronInWaterExit &&
							!!!onGoingTechnicalReport.hasBrassInWaterExit
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.hasBrassInWaterExit === undefined
								? false
								: onGoingTechnicalReport.hasBrassInWaterExit,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasBrassInWaterExit:
									onGoingTechnicalReport.hasBrassInWaterExit === undefined ||
									onGoingTechnicalReport.hasBrassInWaterExit === null
										? true
										: !onGoingTechnicalReport.hasBrassInWaterExit,
							}));
						},
					},
				},
				{
					label: "Fer",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasPvcInWaterExit &&
							!!!onGoingTechnicalReport.hasIronInWaterExit &&
							!!!onGoingTechnicalReport.hasBrassInWaterExit
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.hasIronInWaterExit === undefined
								? false
								: onGoingTechnicalReport.hasIronInWaterExit,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasIronInWaterExit:
									onGoingTechnicalReport.hasIronInWaterExit === undefined ||
									onGoingTechnicalReport.hasIronInWaterExit === null
										? true
										: !onGoingTechnicalReport.hasIronInWaterExit,
							}));
						},
					},
				},
				{
					label: "PVC",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							!!!onGoingTechnicalReport.hasPvcInWaterExit &&
							!!!onGoingTechnicalReport.hasIronInWaterExit &&
							!!!onGoingTechnicalReport.hasBrassInWaterExit
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.hasPvcInWaterExit === undefined
								? false
								: onGoingTechnicalReport.hasPvcInWaterExit,

						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								hasPvcInWaterExit:
									onGoingTechnicalReport.hasPvcInWaterExit === undefined ||
									onGoingTechnicalReport.hasPvcInWaterExit === null
										? true
										: !onGoingTechnicalReport.hasPvcInWaterExit,
							}));
						},
					},
				},
			],
		},
		{
			title: "Dépose de la faïence",
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaienceDepose === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaienceDepose === undefined
								? false
								: onGoingTechnicalReport.isFaienceDepose,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaienceDepose:
									onGoingTechnicalReport.isFaienceDepose === undefined
										? true
										: !onGoingTechnicalReport.isFaienceDepose,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaienceDepose === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaienceDepose === undefined
								? false
								: !onGoingTechnicalReport.isFaienceDepose,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaienceDepose:
									onGoingTechnicalReport.isFaienceDepose === undefined
										? false
										: !onGoingTechnicalReport.isFaienceDepose,
							}));
						},
					},
				},

				onGoingTechnicalReport?.isFaienceDepose === true && {
					label: "Surface ( m² )",
					width: 6,
					incrementer: {
						// disabled: false,
						border: !!!onGoingTechnicalReport.faienceDeposeArea
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.faienceDeposeArea,
						value: onGoingTechnicalReport?.faienceDeposeArea,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceDeposeArea: e,
							}));
						},
					},
				},

				onGoingTechnicalReport?.isFaienceDepose === true && {
					label: "Type de murs",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.typeOfWallsFaience === undefined
								? "borderwarning"
								: "border",
						placeholder: "Type de murs",
						defaultValue:
							typeOfWallsFaienceReverseCorres[
								onGoingTechnicalReport.typeOfWallsFaience
							],
						items: Object.keys(typeOfWallsFaienceCorres),
						value:
							typeOfWallsFaienceReverseCorres[
								onGoingTechnicalReport.typeOfWallsFaience
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								typeOfWallsFaience:
									TYPE_OF_WALLS_FAIENCE[typeOfWallsFaienceCorres[e]],
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport.isFaienceDepose === true && {
			title: "dimension de la reprise",
			inputs: [
				{
					label: "Longueur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.faienceDeposeLength
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.faienceDeposeLength,
						value: onGoingTechnicalReport?.faienceDeposeLength,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceDeposeLength: e,
							}));
						},
					},
				},
				{
					label: "Largeur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.faienceDeposeWidth
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.faienceDeposeWidth,
						value: onGoingTechnicalReport?.faienceDeposeWidth,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceDeposeWidth: e,
							}));
						},
					},
				},
				{
					label: "Hauteur ( mm )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.faienceDeposeHeight
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.faienceDeposeHeight,
						value: onGoingTechnicalReport?.faienceDeposeHeight,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceDeposeHeight: e,
							}));
						},
					},
				},
			],
		},

		onGoingTechnicalReport.isFaienceDepose === true && {
			title: "Refaïencer les murs",
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isReFaienceWalls === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isReFaienceWalls === undefined
								? false
								: onGoingTechnicalReport.isReFaienceWalls,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isReFaienceWalls:
									onGoingTechnicalReport.isReFaienceWalls === undefined
										? true
										: !onGoingTechnicalReport.isReFaienceWalls,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isReFaienceWalls === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isReFaienceWalls === undefined
								? false
								: !onGoingTechnicalReport.isReFaienceWalls,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isReFaienceWalls:
									onGoingTechnicalReport.isReFaienceWalls === undefined
										? false
										: !onGoingTechnicalReport.isReFaienceWalls,
							}));
						},
					},
				},
				onGoingTechnicalReport.isReFaienceWalls === true && {
					label: "Hauteur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.faienceWallHeight
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 10,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.faienceWallHeight,
						value: onGoingTechnicalReport?.faienceWallHeight,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceWallHeight: e,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isReFaienceWalls === true && {
					label: "Largeur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.faienceWallWidth
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.faienceWallWidth,
						value: onGoingTechnicalReport?.faienceWallWidth,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceWallWidth: e,
							}));
						},
					},
				},
			],
		},

		onGoingTechnicalReport?.isFaienceDepose === true && {
			title: "Reprise du décalage de la faïence",
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaienceDecalageReprise === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaienceDecalageReprise === undefined
								? false
								: onGoingTechnicalReport.isFaienceDecalageReprise,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaienceDecalageReprise:
									onGoingTechnicalReport.isFaienceDecalageReprise === undefined
										? true
										: !onGoingTechnicalReport.isFaienceDecalageReprise,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaienceDecalageReprise === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaienceDecalageReprise === undefined
								? false
								: !onGoingTechnicalReport.isFaienceDecalageReprise,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaienceDecalageReprise:
									onGoingTechnicalReport.isFaienceDecalageReprise === undefined
										? false
										: !onGoingTechnicalReport.isFaienceDecalageReprise,
							}));
						},
					},
				},

				onGoingTechnicalReport?.isFaienceDecalageReprise === true && {
					label: "épaisseur ( mm )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.faienceDecalageHeight
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 1,
						defaultValue: onGoingTechnicalReport?.faienceDecalageHeight,
						value: onGoingTechnicalReport?.faienceDecalageHeight,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceDecalageHeight: e,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isFaienceDecalageReprise === true && {
					label: "Longueur( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.faienceDecalageLength
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.faienceDecalageLength,
						value: onGoingTechnicalReport?.faienceDecalageLength,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								faienceDecalageLength: e,
							}));
						},
					},
				},
			],
		},

		{
			title: "Souhaits du client",
			inputs: [],
		},
		{
			titles: ["Remplacer meubles SDB"],
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isReplaceBathroomFurniture === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isReplaceBathroomFurniture === undefined
								? false
								: onGoingTechnicalReport.isReplaceBathroomFurniture,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isReplaceBathroomFurniture:
									onGoingTechnicalReport.isReplaceBathroomFurniture ===
									undefined
										? true
										: !onGoingTechnicalReport.isReplaceBathroomFurniture,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isReplaceBathroomFurniture === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isReplaceBathroomFurniture === undefined
								? false
								: !onGoingTechnicalReport.isReplaceBathroomFurniture,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isReplaceBathroomFurniture:
									onGoingTechnicalReport.isReplaceBathroomFurniture ===
									undefined
										? false
										: !onGoingTechnicalReport.isReplaceBathroomFurniture,
							}));
						},
					},
				},
				onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
					label: "Simple",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathroomFurnitureSimple === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathroomFurnitureSimple === undefined
								? false
								: onGoingTechnicalReport.isBathroomFurnitureSimple,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathroomFurnitureSimple:
									onGoingTechnicalReport.isBathroomFurnitureSimple === undefined
										? true
										: !onGoingTechnicalReport.isBathroomFurnitureSimple,
							}));
						},
					},
				},
				onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
					label: "Double",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathroomFurnitureSimple === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathroomFurnitureSimple === undefined
								? false
								: !onGoingTechnicalReport.isBathroomFurnitureSimple,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathroomFurnitureSimple:
									onGoingTechnicalReport.isBathroomFurnitureSimple === undefined
										? false
										: !onGoingTechnicalReport.isBathroomFurnitureSimple,
							}));
						},
					},
				},
				onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
					label: "Tiroir",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathroomFurnitureWithDrawer ===
								undefined &&
							onGoingTechnicalReport.isBathroomFurnitureWithDoor === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathroomFurnitureWithDrawer === undefined
								? false
								: onGoingTechnicalReport.isBathroomFurnitureWithDrawer,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathroomFurnitureWithDrawer:
									onGoingTechnicalReport.isBathroomFurnitureWithDrawer ===
									undefined
										? true
										: !onGoingTechnicalReport.isBathroomFurnitureWithDrawer,
							}));
						},
					},
				},
				onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
					label: "Porte",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathroomFurnitureWithDrawer ===
								undefined &&
							onGoingTechnicalReport.isBathroomFurnitureWithDoor === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathroomFurnitureWithDoor === undefined
								? false
								: onGoingTechnicalReport.isBathroomFurnitureWithDoor,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathroomFurnitureWithDoor:
									onGoingTechnicalReport.isBathroomFurnitureWithDoor ===
									undefined
										? true
										: !onGoingTechnicalReport.isBathroomFurnitureWithDoor,
							}));
						},
					},
				},
				onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
					label: "Suspendu",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathroomFurnitureSuspended === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathroomFurnitureSuspended === undefined
								? false
								: onGoingTechnicalReport.isBathroomFurnitureSuspended,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathroomFurnitureSuspended:
									onGoingTechnicalReport.isBathroomFurnitureSuspended ===
									undefined
										? true
										: !onGoingTechnicalReport.isBathroomFurnitureSuspended,
							}));
						},
					},
				},
				onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
					label: "Non suspendu",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathroomFurnitureSuspended === undefined
								? "borderwarning"
								: "border",
						defaultValue:
							onGoingTechnicalReport.isBathroomFurnitureSuspended === undefined
								? false
								: !onGoingTechnicalReport.isBathroomFurnitureSuspended,
						checked:
							onGoingTechnicalReport.isBathroomFurnitureSuspended === undefined
								? false
								: !onGoingTechnicalReport.isBathroomFurnitureSuspended,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathroomFurnitureSuspended:
									onGoingTechnicalReport.isBathroomFurnitureSuspended ===
									undefined
										? false
										: !onGoingTechnicalReport.isBathroomFurnitureSuspended,
							}));
						},
					},
				},
				onGoingTechnicalReport.isBathroomFurnitureSuspended === true &&
					onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
						label: "Type de murs pour suspendre le meuble",
						width: 6,
						select: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.typeOfWallsForBathroomFurniture ===
								undefined
									? "borderwarning"
									: "border",
							placeholder: "Type de murs",
							defaultValue:
								typeOfWallsForBathroomFurnitureReverseCorres[
									onGoingTechnicalReport.typeOfWallsForBathroomFurniture
								],
							items: Object.keys(typeOfWallsForBathroomFurnitureCorres),
							value:
								typeOfWallsForBathroomFurnitureReverseCorres[
									onGoingTechnicalReport.typeOfWallsForBathroomFurniture
								],
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									typeOfWallsForBathroomFurniture:
										TYPE_OF_WALLS_FOR_BATHROOM_FURNITURE[
											typeOfWallsForBathroomFurnitureCorres[e]
										],
								}));
							},
						},
					},
				onGoingTechnicalReport.isBathroomFurnitureSuspended === true &&
					onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
						label: "Avec Pied",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet ===
								undefined
									? "borderwarning"
									: "border",
							checked:
								onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet ===
								undefined
									? false
									: onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									isBathroomFurnitureSuspendedWithFeet:
										onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet ===
										undefined
											? true
											: !onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet,
								}));
							},
						},
					},
				onGoingTechnicalReport.isBathroomFurnitureSuspended === true &&
					onGoingTechnicalReport.isReplaceBathroomFurniture === true && {
						label: "Sans Pied",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							checked:
								onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet ===
								undefined
									? false
									: !onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									isBathroomFurnitureSuspendedWithFeet:
										onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet ===
										undefined
											? false
											: !onGoingTechnicalReport.isBathroomFurnitureSuspendedWithFeet,
								}));
							},
						},
					},
			],
		},
		onGoingTechnicalReport?.isReplaceBathroomFurniture === true && {
			title: "Dimension du meuble de SDB",
			inputs: [
				{
					label: "Largeur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.bathroomFurnitureWidth
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.bathroomFurnitureWidth,
						value: onGoingTechnicalReport?.bathroomFurnitureWidth,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								bathroomFurnitureWidth: e,
							}));
						},
					},
				},
				{
					label: "Profondeur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.bathroomFurnitureDepth
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.bathroomFurnitureDepth,
						value: onGoingTechnicalReport?.bathroomFurnitureDepth,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								bathroomFurnitureDepth: e,
							}));
						},
					},
				},
			],
		},

		{
			titles: ["Le client souhaite refaire la douche"],
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isDoShower === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isDoShower === undefined
								? false
								: onGoingTechnicalReport.isDoShower,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isDoShower:
									onGoingTechnicalReport.isDoShower === undefined
										? true
										: !!!onGoingTechnicalReport.isDoShower,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isDoShower === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isDoShower === undefined
								? false
								: !!!onGoingTechnicalReport.isDoShower,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isDoShower:
									onGoingTechnicalReport.isDoShower === undefined
										? false
										: !!!onGoingTechnicalReport.isDoShower,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isDoShower === true && {
			title: "",
			inputs: [
				{
					label: "Type de Douche",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.showerType === undefined
								? "borderwarning"
								: "border",
						placeholder: "Type de murs",
						defaultValue:
							showerTypeReverseCorres[onGoingTechnicalReport.showerType],
						items: Object.keys(showerTypeCorres),
						value: showerTypeReverseCorres[onGoingTechnicalReport.showerType],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								showerType: SHOWER_TYPE[showerTypeCorres[e]],
							}));
						},
					},
				},
				onGoingTechnicalReport.isDoShower === true &&
					onGoingTechnicalReport.showerType !== SHOWER_TYPE.PMR && {
						label: "Longueur douche ( m )",
						width: 6,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.lengthShower === undefined
									? "borderwarning"
									: "border",

							min: 0,
							max: 5,
							changeValue: 0.1,
							color: "lightBlue",
							defaultValue: onGoingTechnicalReport.lengthShower,
							value: onGoingTechnicalReport.lengthShower,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									lengthShower: e,
								}));
							},
						},
					},
				onGoingTechnicalReport.isDoShower === true &&
					onGoingTechnicalReport.showerType !== SHOWER_TYPE.PMR && {
						label: "largeur douche ( m )",
						width: 6,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.widthShower === undefined
									? "borderwarning"
									: "border",

							min: 0,
							max: 5,
							changeValue: 0.1,
							color: "lightBlue",
							defaultValue: onGoingTechnicalReport.widthShower,
							value: onGoingTechnicalReport.widthShower,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									widthShower: e,
								}));
							},
						},
					},
				(onGoingTechnicalReport.showerType === SHOWER_TYPE.TO_RECESS ||
					onGoingTechnicalReport.showerType === SHOWER_TYPE.TO_POSE) && {
					label: "Type de paroi",
					width: 6,
					select: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.typeOfShowerWall === undefined
								? "borderwarning"
								: "border",
						placeholder: "Type de murs",
						defaultValue:
							typeOfShowerWallReverseCorres[
								onGoingTechnicalReport.typeOfShowerWall
							],
						items: Object.keys(typeOfShowerWallCorres),
						value:
							typeOfShowerWallReverseCorres[
								onGoingTechnicalReport.typeOfShowerWall
							],
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								typeOfShowerWall:
									TYPE_OF_SHOWER_WALL[typeOfShowerWallCorres[e]],
							}));
						},
					},
				},
				(onGoingTechnicalReport.showerType === SHOWER_TYPE.TO_RECESS ||
					onGoingTechnicalReport.showerType === SHOWER_TYPE.TO_POSE) && {
					label: "Longueur paroi douche ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.showerWallLength
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.showerWallLength,
						value: onGoingTechnicalReport?.showerWallLength,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								showerWallLength: e,
							}));
						},
					},
				},
				(onGoingTechnicalReport.showerType === SHOWER_TYPE.TO_RECESS ||
					onGoingTechnicalReport.showerType === SHOWER_TYPE.TO_POSE) && {
					label: "Largeur paroi douche ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border: !!!onGoingTechnicalReport.showerWallWidth
							? "borderwarning"
							: "border",
						color: "lightBlue",
						min: 0,
						max: 100,
						changeValue: 0.1,
						defaultValue: onGoingTechnicalReport?.showerWallWidth,
						value: onGoingTechnicalReport?.showerWallWidth,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								showerWallWidth: e,
							}));
						},
					},
				},
				{
					label: "Robinetterie petit Côté",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaucetShowerOntheSmallSide === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaucetShowerOntheSmallSide === undefined
								? false
								: onGoingTechnicalReport.isFaucetShowerOntheSmallSide,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaucetShowerOntheSmallSide:
									onGoingTechnicalReport.isFaucetShowerOntheSmallSide ===
									undefined
										? true
										: !!!onGoingTechnicalReport.isFaucetShowerOntheSmallSide,
							}));
						},
					},
				},
				{
					label: "Grand côté",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaucetShowerOntheSmallSide === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaucetShowerOntheSmallSide === undefined
								? false
								: !!!onGoingTechnicalReport.isFaucetShowerOntheSmallSide,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaucetShowerOntheSmallSide:
									onGoingTechnicalReport.isFaucetShowerOntheSmallSide ===
									undefined
										? false
										: !!!onGoingTechnicalReport.isFaucetShowerOntheSmallSide,
							}));
						},
					},
				},
				{
					label: "Colonne de douche",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isShowerColonne === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isShowerColonne === undefined
								? false
								: onGoingTechnicalReport.isShowerColonne,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isShowerColonne:
									onGoingTechnicalReport.isShowerColonne === undefined
										? true
										: !!!onGoingTechnicalReport.isShowerColonne,
							}));
						},
					},
				},
				{
					label: "Barre de douche ",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isShowerColonne === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isShowerColonne === undefined
								? false
								: !!!onGoingTechnicalReport.isShowerColonne,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isShowerColonne:
									onGoingTechnicalReport.isShowerColonne === undefined
										? false
										: !!!onGoingTechnicalReport.isShowerColonne,
							}));
						},
					},
				},
				{
					label: "Sortie bonde horizontale",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isShowerBondExitHorizontal === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isShowerBondExitHorizontal === undefined
								? false
								: onGoingTechnicalReport.isShowerBondExitHorizontal,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isShowerBondExitHorizontal:
									onGoingTechnicalReport.isShowerBondExitHorizontal ===
									undefined
										? true
										: !!!onGoingTechnicalReport.isShowerBondExitHorizontal,
							}));
						},
					},
				},
				{
					label: "Sortie bonde verticale",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isShowerBondExitHorizontal === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isShowerBondExitHorizontal === undefined
								? false
								: !!!onGoingTechnicalReport.isShowerBondExitHorizontal,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isShowerBondExitHorizontal:
									onGoingTechnicalReport.isShowerBondExitHorizontal ===
									undefined
										? false
										: !!!onGoingTechnicalReport.isShowerBondExitHorizontal,
							}));
						},
					},
				},
			],
		},

		{
			title: "Le client souhaite refaire la baignoire",
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isDoBathtub === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isDoBathtub === undefined
								? false
								: onGoingTechnicalReport.isDoBathtub,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isDoBathtub:
									onGoingTechnicalReport.isDoBathtub === undefined
										? true
										: !!!onGoingTechnicalReport.isDoBathtub,
							}));
						},
					},
				},
				{
					label: "non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isDoBathtub === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isDoBathtub === undefined
								? false
								: !!!onGoingTechnicalReport.isDoBathtub,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isDoBathtub:
									onGoingTechnicalReport.isDoBathtub === undefined
										? false
										: !!!onGoingTechnicalReport.isDoBathtub,
							}));
						},
					},
				},
			],
		},

		onGoingTechnicalReport.isDoBathtub === true && {
			titles: ["", ""],
			inputs: [
				{
					label: "PMR",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isPMRBathtub === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.S === undefined
								? false
								: onGoingTechnicalReport.isPMRBathtub,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isPMRBathtub:
									onGoingTechnicalReport.isPMRBathtub === undefined
										? true
										: !!!onGoingTechnicalReport.isPMRBathtub,
							}));
						},
					},
				},
				{
					label: "Autre",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isPMRBathtub === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isPMRBathtub === undefined
								? false
								: !!!onGoingTechnicalReport.isPMRBathtub,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isPMRBathtub:
									onGoingTechnicalReport.isPMRBathtub === undefined
										? false
										: !!!onGoingTechnicalReport.isPMRBathtub,
							}));
						},
					},
				},
				{
					label: "Longueur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.bathtubeLength === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 5,
						changeValue: 0.1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.bathtubeLength,
						value: onGoingTechnicalReport.bathtubeLength,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								bathtubeLength: e,
							}));
						},
					},
				},
				{
					label: "Largeur ( m )",
					width: 6,
					incrementer: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.bathtubeWidth === undefined
								? "borderwarning"
								: "border",

						min: 0,
						max: 5,
						changeValue: 0.1,
						color: "lightBlue",
						defaultValue: onGoingTechnicalReport.bathtubeWidth,
						value: onGoingTechnicalReport.bathtubeWidth,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								bathtubeWidth: e,
							}));
						},
					},
				},
				{
					label: "Avec tablier",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubwithApron === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubwithApron === undefined
								? false
								: onGoingTechnicalReport.isBathtubwithApron,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubwithApron:
									onGoingTechnicalReport.isBathtubwithApron === undefined
										? true
										: !!!onGoingTechnicalReport.isBathtubwithApron,
							}));
						},
					},
				},
				{
					label: "Sans tablier",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubwithApron === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubwithApron === undefined
								? false
								: !!!onGoingTechnicalReport.isBathtubwithApron,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubwithApron:
									onGoingTechnicalReport.isBathtubwithApron === undefined
										? false
										: !!!onGoingTechnicalReport.isBathtubwithApron,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isDoBathtub === true &&
			onGoingTechnicalReport?.isBathtubwithApron === true && {
				titles: ["Tablier à carreler"],
				inputs: [
					{
						label: "Oui",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.isBathtubwithApronToTile === undefined
									? "borderwarning"
									: "border",
							checked:
								onGoingTechnicalReport.isBathtubwithApronToTile === undefined
									? false
									: onGoingTechnicalReport.isBathtubwithApronToTile,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									isBathtubwithApronToTile:
										onGoingTechnicalReport.isBathtubwithApronToTile ===
										undefined
											? true
											: !!!onGoingTechnicalReport.isBathtubwithApronToTile,
								}));
							},
						},
					},
					{
						label: "Non",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.isBathtubwithApronToTile === undefined
									? "borderwarning"
									: "border",
							checked:
								onGoingTechnicalReport.isBathtubwithApronToTile === undefined
									? false
									: !!!onGoingTechnicalReport.isBathtubwithApronToTile,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									isBathtubwithApronToTile:
										onGoingTechnicalReport.isBathtubwithApronToTile ===
										undefined
											? false
											: !!!onGoingTechnicalReport.isBathtubwithApronToTile,
								}));
							},
						},
					},
				],
			},
		onGoingTechnicalReport?.isDoBathtub === true && {
			titles: ["Paroi"],
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubwithWall === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubwithWall === undefined
								? false
								: onGoingTechnicalReport.isBathtubwithWall,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubwithWall:
									onGoingTechnicalReport.isBathtubwithWall === undefined
										? true
										: !!!onGoingTechnicalReport.isBathtubwithWall,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubwithWall === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubwithWall === undefined
								? false
								: !!!onGoingTechnicalReport.isBathtubwithWall,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubwithWall:
									onGoingTechnicalReport.isBathtubwithWall === undefined
										? false
										: !!!onGoingTechnicalReport.isBathtubwithWall,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport.isDoBathtub === true &&
			onGoingTechnicalReport.isBathtubwithWall === true && {
				titles: ["Dimensions paroi"],
				inputs: [
					{
						label: "Longueur ( m )",
						width: 6,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.bathtubeWallLength === undefined
									? "borderwarning"
									: "border",

							min: 0,
							max: 5,
							changeValue: 0.1,
							color: "lightBlue",
							defaultValue: onGoingTechnicalReport.bathtubeWallLength,
							value: onGoingTechnicalReport.bathtubeWallLength,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									bathtubeWallLength: e,
								}));
							},
						},
					},
					{
						label: "Largeur ( m )",
						width: 6,
						incrementer: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.bathtubeWallWidth === undefined
									? "borderwarning"
									: "border",

							min: 0,
							max: 5,
							changeValue: 0.1,
							color: "lightBlue",
							defaultValue: onGoingTechnicalReport.bathtubeWallWidth,
							value: onGoingTechnicalReport.bathtubeWallWidth,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									bathtubeWallWidth: e,
								}));
							},
						},
					},
				],
			},
		onGoingTechnicalReport.isDoBathtub === true && {
			titles: ["Position robinetterie", "Encastrement"],
			inputs: [
				{
					label: "Petit Côté",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide ===
							undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide ===
							undefined
								? false
								: onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaucetBathtubeOntheSmallSide:
									onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide ===
									undefined
										? true
										: !!!onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide,
							}));
						},
					},
				},
				{
					label: "Grand côté",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide ===
							undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide ===
							undefined
								? false
								: !!!onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isFaucetBathtubeOntheSmallSide:
									onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide ===
									undefined
										? false
										: !!!onGoingTechnicalReport.isFaucetBathtubeOntheSmallSide,
							}));
						},
					},
				},
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubeRecessed === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubeRecessed === undefined
								? false
								: onGoingTechnicalReport.isBathtubeRecessed,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubeRecessed:
									onGoingTechnicalReport.isBathtubeRecessed === undefined
										? true
										: !!!onGoingTechnicalReport.isBathtubeRecessed,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubeRecessed === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubeRecessed === undefined
								? false
								: !!!onGoingTechnicalReport.isBathtubeRecessed,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubeRecessed:
									onGoingTechnicalReport.isBathtubeRecessed === undefined
										? false
										: !!!onGoingTechnicalReport.isBathtubeRecessed,
							}));
						},
					},
				},
				{
					label: "Sortie Bonde Horizontale",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubeBondExitHorizontal === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubeBondExitHorizontal === undefined
								? false
								: onGoingTechnicalReport.isBathtubeBondExitHorizontal,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubeBondExitHorizontal:
									onGoingTechnicalReport.isBathtubeBondExitHorizontal ===
									undefined
										? true
										: !!!onGoingTechnicalReport.isBathtubeBondExitHorizontal,
							}));
						},
					},
				},
				{
					label: "Sortie bonde verticale",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isBathtubeBondExitHorizontal === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isBathtubeBondExitHorizontal === undefined
								? false
								: !!!onGoingTechnicalReport.isBathtubeBondExitHorizontal,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isBathtubeBondExitHorizontal:
									onGoingTechnicalReport.isBathtubeBondExitHorizontal ===
									undefined
										? false
										: !!!onGoingTechnicalReport.isBathtubeBondExitHorizontal,
							}));
						},
					},
				},
			],
		},
		{
			title: "Le client souhaite refaire les WC",
			inputs: [
				{
					label: "Oui",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isDoWC === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isDoWC === undefined
								? false
								: onGoingTechnicalReport.isDoWC,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isDoWC:
									onGoingTechnicalReport.isDoWC === undefined
										? true
										: !!!onGoingTechnicalReport.isDoWC,
							}));
						},
					},
				},
				{
					label: "Non",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isDoWC === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isDoWC === undefined
								? false
								: !!!onGoingTechnicalReport.isDoWC,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isDoWC:
									onGoingTechnicalReport.isDoWC === undefined
										? false
										: !!!onGoingTechnicalReport.isDoWC,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isDoWC === true && {
					label: "PMR",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isPMRWC === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isPMRWC === undefined
								? false
								: onGoingTechnicalReport.isPMRWC,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isPMRWC:
									onGoingTechnicalReport.isPMRWC === undefined
										? true
										: !!!onGoingTechnicalReport.isPMRWC,
							}));
						},
					},
				},
				onGoingTechnicalReport?.isDoWC === true && {
					label: "Autre",
					width: 3,
					checkbox: {
						disabled: isDisabled,
						border:
							onGoingTechnicalReport.isPMRWC === undefined
								? "borderwarning"
								: "border",
						checked:
							onGoingTechnicalReport.isPMRWC === undefined
								? false
								: !!!onGoingTechnicalReport.isPMRWC,
						onChange: (e) => {
							setOnGoingTechnicalReport((vals) => ({
								...vals,
								isPMRWC:
									onGoingTechnicalReport.isPMRWC === undefined
										? false
										: !!!onGoingTechnicalReport.isPMRWC,
							}));
						},
					},
				},
			],
		},
		onGoingTechnicalReport?.isDoWC === true &&
			onGoingTechnicalReport.isPMRWC === false && {
				inputs: [
					{
						label: "Au sol à poser",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.isWCOnTheGround === undefined
									? "borderwarning"
									: "border",
							checked:
								onGoingTechnicalReport.isWCOnTheGround === undefined
									? false
									: onGoingTechnicalReport.isWCOnTheGround,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									isWCOnTheGround:
										onGoingTechnicalReport.isWCOnTheGround === undefined
											? true
											: !!!onGoingTechnicalReport.isWCOnTheGround,
								}));
							},
						},
					},
					{
						label: "Suspendu",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							border:
								onGoingTechnicalReport.isWCOnTheGround === undefined
									? "borderwarning"
									: "border",
							checked:
								onGoingTechnicalReport.isWCOnTheGround === undefined
									? false
									: !!!onGoingTechnicalReport.isWCOnTheGround,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									isWCOnTheGround:
										onGoingTechnicalReport.isWCOnTheGround === undefined
											? false
											: !!!onGoingTechnicalReport.isWCOnTheGround,
								}));
							},
						},
					},
					{
						label: "Sanibroyeur",
						width: 3,
						checkbox: {
							disabled: isDisabled,
							checked:
								onGoingTechnicalReport.isWCSanibroyer === undefined
									? false
									: onGoingTechnicalReport.isWCSanibroyer,
							onChange: (e) => {
								setOnGoingTechnicalReport((vals) => ({
									...vals,
									isWCSanibroyer:
										onGoingTechnicalReport.isWCSanibroyer === undefined
											? true
											: !!!onGoingTechnicalReport.isWCSanibroyer,
								}));
							},
						},
					},
				],
			},

		{
			inputs: [
				{
					label: "Ajouter une image",
					width: 3,
					images: {
						images: onGoingTechnicalReport.images,
						onChange: async (existingImageUrl, file) => {
							try {
								const url = await imageHandler(file);

								handleChangeOfImage(existingImageUrl, url);
							} catch (err) {}
						},
						onRemove: (existingImageUrl) => {
							handleRemoveOfNewImage(existingImageUrl);
						},
						onAddNew: async (_placeholder, file) => {
							try {
								const url = await imageHandler(file);

								handleAddNewImage(url);
							} catch (err) {}
						},
						onRemoveNew: (existingImageUrl) => {
							handleRemoveOfNewImage(existingImageUrl);
						},
						onChangeText,
						changeButton: {
							disabled: isDisabled,
							children: "Ajouter/changer",
							size: "sm",
							color: "lightBlue",
							onClick: () => alert("add/change"),
						},
						removeButton: {
							disabled: isDisabled,
							children: "Supprimer",
							size: "sm",
							color: "lightBlue",
							onClick: () => alert("remove"),
						},
					},
				},
			],
		},
	];
}
