import PropTypes from "prop-types";
import React from "react";
// components
import Button from "../../components/Elements/Button.js";
import Input from "../../components/Elements/Input.js";

export default function Incrementer({
	defaultValue,
	size,
	color,
	onChange,
	onMinus,
	onPlus,
	max,
	min,
	changeValue,
	disabled,
	onAddNewItem,
	onRemoveNewItem,
	border,
}) {
	const [inputValue, setInputValue] = React.useState(defaultValue);
	const decrement = (e) => {
		e.preventDefault();
		e.stopPropagation();

		onChange(
			parseFloat(inputValue) - changeValue < min
				? min
				: changeValue % 1 === 0
				? parseInt(inputValue) - changeValue
				: Math.round((parseFloat(inputValue) - changeValue) * 100) / 100,
		);
		onMinus(
			parseFloat(inputValue) - changeValue < min
				? min
				: changeValue % 1 === 0
				? parseInt(inputValue) - changeValue
				: Math.round((parseFloat(inputValue) - changeValue) * 100) / 100,
		);
		setInputValue(
			parseFloat(inputValue) - changeValue < min
				? min
				: changeValue % 1 === 0
				? parseInt(inputValue) - changeValue
				: Math.round((parseFloat(inputValue) - changeValue) * 100) / 100,
		);
	};

	const increment = (e) => {
		e.preventDefault();
		e.stopPropagation();

		onChange(
			parseFloat(inputValue) + changeValue > max
				? max
				: changeValue % 1 === 0
				? parseInt(inputValue) + changeValue
				: Math.round((parseFloat(inputValue) + changeValue) * 100) / 100,
		);
		onPlus(
			parseFloat(inputValue) + changeValue > max
				? max
				: changeValue % 1 === 0
				? parseInt(inputValue) + changeValue
				: Math.round((parseFloat(inputValue) + changeValue) * 100) / 100,
		);
		setInputValue(
			parseFloat(inputValue) + changeValue > max
				? max
				: changeValue % 1 === 0
				? parseInt(inputValue) + changeValue
				: Math.round((parseFloat(inputValue) + changeValue) * 100) / 100,
		);
	};

	const manually = (e) => {
		const manualInputValue =
			typeof parseFloat(e.target.value) === "number" &&
			e.target.value <= max &&
			e.target.value >= min
				? e.target.value
				: inputValue;

		setInputValue(manualInputValue);

		onChange(
			parseFloat(manualInputValue) > max
				? max
				: parseFloat(manualInputValue) < min
				? min
				: changeValue % 1 === 0
				? parseInt(manualInputValue)
				: Math.round(manualInputValue * 100) / 100,
		);
	};

	return (
		<>
			<div className="my-3 relative inline-flex flex-row w-full items-stretch flex-1 flex-wrap">
				<div className="inline-flex flex-row">
					<div className="mr-2">
						<Button
							disabled={disabled}
							color={color}
							size={size}
							onClick={decrement}
						>
							<i className="fas fa-minus"></i>
						</Button>
					</div>
					<div className="mr-2">
						<Input
							disabled={disabled}
							value={inputValue}
							onChange={manually}
							type="text"
							border={border}
						/>
					</div>
					<div>
						<Button
							disabled={disabled}
							color={color}
							size={size}
							onClick={increment}
						>
							<i className="fas fa-plus"></i>
						</Button>
					</div>
				</div>

				<div className="inline-flex flex-row">
					{onRemoveNewItem && (
						<div className="mt-3 lg:mt-0 sm:ml-2 lg:ml-0  mr-4">
							<Button
								disabled={disabled}
								color="red"
								size={size}
								onClick={onRemoveNewItem}
							>
								<i className="fas fa-minus"></i>
							</Button>
						</div>
					)}
					{onAddNewItem && (
						<div className="sm:ml-2   mt-3 lg:mt-0">
							<Button
								disabled={disabled}
								color={color}
								size={size}
								onClick={onAddNewItem}
							>
								<i className="fas fa-plus"></i>
							</Button>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

Incrementer.defaultProps = {
	onChange: () => {},
	onMinus: () => {},
	onPlus: () => {},
	color: "orange",
	defaultValue: 0,
	min: -100,
	max: 100,
	changeValue: 1,
	size: "regular",
};

Incrementer.propTypes = {
	defaultValue: PropTypes.number,
	// the number with which the increment should increase
	// and the decrement should decrease
	changeValue: PropTypes.number,
	// what is the last number you can decrement to
	min: PropTypes.number,
	// what is the last number you can increment to
	max: PropTypes.number,
	size: PropTypes.oneOf(["sm", "lg", "regular"]),
	color: PropTypes.oneOf([
		"facebook",
		"twitter",
		"instagram",
		"github",
		"pinterest",
		"youtube",
		"vimeo",
		"slack",
		"dribbble",
		"reddit",
		"tumblr",
		"linkedin",
		"white",
		"light",
		"dark",
		"blueGray",
		"red",
		"orange",
		"amber",
		"emerald",
		"teal",
		"lightBlue",
		"indigo",
		"purple",
		"pink",
		"accentBlue",
	]),
	// this function will be called for both
	// increment and decrement actions
	// Example of usage: onChange={(value) => console.log(value)}
	// the above example will show you the new value
	onChange: PropTypes.func,
	// this function will be called for
	// the decrement action
	// Example of usage: onMinus={(value) => console.log(value)}
	// the above example will show you the new value
	onMinus: PropTypes.func,
	// this function will be called for
	// the increment action
	// Example of usage: onMinus={(value) => console.log(value)}
	// the above example will show you the new value
	onPlus: PropTypes.func,
	border: PropTypes.string,
};
