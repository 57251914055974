import Input from "../../components/Elements/Input";
import { Breadcrumb } from "../../views/admin/BreadCrumb";
import CsvDownload from "react-json-to-csv";

const ListingActions = ({
  fileName,
  onInputSearch,
  downloadData,
  location,
  title,
}) => {
  return (
    <div
      style={{ marginBottom: "5%" }}
      className="px-4 flex flex-nowrap items-center justify-between w-full"
    >
      <div className="flex flex-row">
        <Input
          className="searchButton"
          {...{
            placeholder: "Recherche",
            type: "text",
            onChange: (e) => onInputSearch(e.target.value),
          }}
        />
        {downloadData && (
          <CsvDownload
            data={downloadData}
            filename={fileName}
            className="ml-4 csvExport"
            style={{
              background: "none",
              borderRadius: "10px",
              display: "inline-block",
              fontSize: "12px",
              fontWeight: "bold",
              padding: "0px 20px",
              textDecoration: "none",
              color: "white",
              zIndex: 1,
              width: "120px",
              border: " 2px solid white",
            }}
          >
            Exporter
          </CsvDownload>
        )}
      </div>
      <Breadcrumb location={location} pageName={title} />
    </div>
  );
};

export default ListingActions;
