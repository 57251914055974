/* eslint-disable react-hooks/exhaustive-deps */
import { getInterventions } from "api/interventions";
// import AppContext from "Contexts/AppContext";
import { getNewBusiness } from "api/newBusiness";
import { getOrders } from "api/orders";
import CardChartJS from "components/Cards/Admin/CardChartJS.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
// components
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import AppContext from "Contexts/AppContext";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { handleSetCardChartJS } from "service/dashboard/HandleSetCardChartJS";
import { handleSetHeaderStatCards } from "service/dashboard/HanldeSetHeaderStatCards";
import { ADVANCEMENT_STATUSES } from "types/baseTypes";
import cardchartjsdashboard1 from "_texts/admin/cards/cardchartjsdashboard1";
import cardchartjsdashboard2 from "_texts/admin/cards/cardchartjsdashboard2";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import headerstatcards from "_texts/admin/headers/headerstatcards.js";
// texts as props
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";


const Dashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const [
    cardchartjsdashboardturnover,
    setCardchartjsdashboardturnover,
  ] = useState(cardchartjsdashboard1);
  const [
    cardchartjsdashboardtotalorders,
    setCardchartjsdashboardtotalorders,
  ] = useState(cardchartjsdashboard2);
  const { selectedAgency } = useContext(AppContext)

  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const [rerender, setRerender] = useState(false);

  const [headerstatcardsdashboard, setheaderstatcardsdashboard] = useState(
    headerstatcards
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (!isLoggedIn) {
      history.replace("/login");
    }
  }, []);

  const handleFilterOrdersNotRefused = (data) => {
    if (data && data.length) {
      return data.filter(
        (data) => data.advancementStatus !== ADVANCEMENT_STATUSES.REFUSED
      );
    } else return {};
  };

  const handleGetData = async () => {
    try {
      let orders = await getOrders(selectedAgency);

      const newBusiness = await getNewBusiness(selectedAgency);
      const interventions = await getInterventions(selectedAgency);
      if (orders.length) {
        orders = handleFilterOrdersNotRefused(orders);
      } else {
        console.log("NO ORDERS");
      }

      handleSetHeaderStatCards(orders, newBusiness, interventions, [
        headerstatcardsdashboard,
        setheaderstatcardsdashboard,
      ]);
      handleSetCardChartJS(
        orders,
        [cardchartjsdashboardtotalorders, setCardchartjsdashboardtotalorders],
        [cardchartjsdashboardturnover, setCardchartjsdashboardturnover]
      );

      setIsReady(true);
      setIsLoaded(true);
      (!isLoaded || !isReady) && setRerender(!rerender);
    } catch (err) {
      console.log("err", err);
    }
  };

  useEffect(() => {
    handleGetData();
  }, [location, rerender, selectedAgency]);

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        {isReady ? (
          <HeaderStatCards {...headerstatcardsdashboard} />
        ) : (
          <h1> </h1>
        )}

        <div className="px-2 md:px-2 mx-auto w-full -mt-24">
          {isLoaded ? (
            <div className="flex flex-wrap">
              <div className="w-full md:w-8/12 px-2">
                <CardChartJS {...cardchartjsdashboardturnover} />
              </div>
              <div className="w-full md:w-4/12 px-2">
                <CardChartJS {...cardchartjsdashboardtotalorders} />
              </div>
            </div>
          ) : (
            <h1> </h1>
          )}

          <FooterAdmin {...footeradmin} />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
