import {
  cumulAmount, handleFilterOrdersOfLastMonthUntilDDay,
  handleFilterOrdersOfLastYearUntilMMonth, handleFilterOrdersOfMonth,
  handleFilterOrdersOfYear, handleFilterSites,
  handleFilterTroubleShoots
} from "service/functions";

export const handleSetHeaderStatCards = (
  orders,
  newBusiness,
  interventions,
  [headerstatcardsdashboard, setheaderstatcardsdashboard],
) => {
  setheaderstatcardsdashboard({
    color: "accentBlue",
    cards: [
      {
        statSubtitle: "NOUVELLES AFFAIRES",
        statTitle: `${newBusiness.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(cumulAmount(newBusiness))}`,
        statArrow:
          cumulAmount(
            handleFilterOrdersOfMonth(newBusiness),
          ) +
            cumulAmount(handleFilterOrdersOfMonth(orders)) >
            cumulAmount(
              handleFilterOrdersOfLastMonthUntilDDay(
                newBusiness,
              ),
            ) +
            cumulAmount(
              handleFilterOrdersOfLastMonthUntilDDay(
                orders,
              ),
            )
            ? "up"
            : "down",
        statPercent:
          cumulAmount(
            handleFilterOrdersOfLastMonthUntilDDay(
              newBusiness,
            ),
          ) +
            cumulAmount(
              handleFilterOrdersOfLastMonthUntilDDay(
                orders,
              ),
            ) >
            0
            ? `${(
              (100 *
                (cumulAmount(
                  handleFilterOrdersOfMonth(newBusiness),
                ) +
                  cumulAmount(
                    handleFilterOrdersOfMonth(orders),
                  ))) /
              (cumulAmount(
                handleFilterOrdersOfLastMonthUntilDDay(
                  newBusiness,
                ),
              ) +
                cumulAmount(
                  handleFilterOrdersOfLastMonthUntilDDay(
                    orders,
                  ),
                )) -
              100
            ).toFixed(2)}`
            : "",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Depuis le mois dernier",
        statIconName: "far fa-chart-bar",
        statIconColor: "bg-red-500",
      },
      {
        statSubtitle: "CHANTIERS / DEPANNAGES",
        statTitle: `${handleFilterSites(orders).length} : ${handleFilterSites(orders).length
            ? new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(
              cumulAmount(
                handleFilterOrdersOfYear(
                  handleFilterSites(orders),
                ),
              ),
            )
            : "0 €"
          } / ${handleFilterTroubleShoots(orders).length} : ${handleFilterTroubleShoots(orders).length
            ? new Intl.NumberFormat("fr-FR", {
              style: "currency",
              currency: "EUR",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(
              cumulAmount(
                handleFilterOrdersOfYear(
                  handleFilterTroubleShoots(orders),
                ),
              ),
            )
            : "0 €"
          }`,
        statArrow:
          cumulAmount(
            handleFilterOrdersOfLastMonthUntilDDay(
              handleFilterTroubleShoots(orders),
            ),
          ) > 0 &&
            cumulAmount(
              handleFilterOrdersOfMonth(
                handleFilterTroubleShoots(orders),
              ),
            ) > 0
            ? cumulAmount(
              handleFilterOrdersOfMonth(
                handleFilterSites(orders),
              ),
            ) /
              cumulAmount(
                handleFilterOrdersOfMonth(
                  handleFilterTroubleShoots(orders),
                ),
              ) <
              cumulAmount(
                handleFilterOrdersOfLastMonthUntilDDay(
                  handleFilterSites(orders),
                ),
              ) /
              cumulAmount(
                handleFilterOrdersOfLastMonthUntilDDay(
                  handleFilterTroubleShoots(orders),
                ),
              )
              ? "down"
              : "up"
            : "up",

        statPercent:
          cumulAmount(
            handleFilterOrdersOfLastMonthUntilDDay(
              handleFilterTroubleShoots(orders),
            ),
          ) > 0 &&
            cumulAmount(
              handleFilterOrdersOfMonth(
                handleFilterTroubleShoots(orders),
              ),
            ) > 0
            ? `
               ${(
              cumulAmount(
                handleFilterOrdersOfMonth(
                  handleFilterSites(orders),
                ),
              ) /
              cumulAmount(
                handleFilterOrdersOfMonth(
                  handleFilterTroubleShoots(orders),
                ),
              )
            ).toFixed(2) -
            (
              cumulAmount(
                handleFilterOrdersOfLastMonthUntilDDay(
                  handleFilterSites(orders),
                ),
              ) /
              cumulAmount(
                handleFilterOrdersOfLastMonthUntilDDay(
                  handleFilterTroubleShoots(orders),
                ),
              )
            ).toFixed(2)
            }
                `
            : "",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Depuis le mois dernier",
        statIconName: "fas fa-users",
        statIconColor: "bg-pink-500",
      },
      {
        statSubtitle: "INTERVENTIONS",
        statTitle: `${interventions.length
          } / ${new Intl.NumberFormat("fr-FR", {
            style: "currency",
            currency: "EUR",
            maximumFractionDigits: 0,
            minimumFractionDigits: 0,
          }).format(cumulAmount(interventions))}`,
        statArrow:
          cumulAmount(
            handleFilterOrdersOfMonth(interventions),
          ) >
            cumulAmount(
              handleFilterOrdersOfLastMonthUntilDDay(
                interventions,
              ),
            )
            ? "up"
            : "down",
        statPercent:
          cumulAmount(
            handleFilterOrdersOfLastMonthUntilDDay(
              interventions,
            ),
          ) > 0
            ? `${(
              (100 *
                cumulAmount(
                  handleFilterOrdersOfMonth(
                    interventions,
                  ),
                )) /
              cumulAmount(
                handleFilterOrdersOfLastMonthUntilDDay(
                  interventions,
                ),
              ) -
              100
            ).toFixed(2)}`
            : "",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Depuis le mois dernier",
        statIconName: "fas fa-chart-pie",
        statIconColor: "bg-agOrange-regular",
      },
      {
        statSubtitle: "CHIFFRE D'AFFAIRES",
        statTitle: new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        }).format(
          cumulAmount(handleFilterOrdersOfYear(orders)),
        ),
        statArrow:
          cumulAmount(handleFilterOrdersOfYear(orders)) <
            cumulAmount(
              handleFilterOrdersOfLastYearUntilMMonth(orders),
            )
            ? "down"
            : "up",
        statPercent:
          cumulAmount(
            handleFilterOrdersOfLastYearUntilMMonth(orders),
          ) > 0
            ? (cumulAmount(
              handleFilterOrdersOfYear(orders),
            ) /
              cumulAmount(
                handleFilterOrdersOfLastYearUntilMMonth(
                  orders,
                ),
              )) *
            100 -
            100
            : "",
        statPercentColor: "text-emerald-500",
        statDescripiron: "Depuis le début de l'année",
        statIconName: "fas fa-euro-sign",
        statIconColor: "bg-lightBlue-500",
      },
    ],
  });
};

export default handleSetHeaderStatCards;
