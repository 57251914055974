import axios from 'axios';

const { SERVER_URL } = window._env_;

// const SERVER_URL = "https://ag-energie-admin-backend.int.at-digital.fr";

export const getNewBusiness = async (agency) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getNewBusiness`, { params: { agency } });
  return result.data;
}

export const getNewBusinessForValidation = async () => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getNewBusinessForValidation`);
  return result.data;
}

export const addNewBusiness = async (newBusiness) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/addNewBusiness`, newBusiness);

  return result.data;
}

export const getNewBusinessById = async (businessId) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/getNewBusiness/${businessId}`);
  return result.data;
}

export const deleteNewBusiness = async (businessId) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/deleteNewBusiness/${businessId}`);
  return result.data;
}

export const updateNewBusiness = async (businessId, updates) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/updateNewBusiness/${businessId}`, updates);
  return result.data;
}

export const clearTechnicalReports = async (businessId) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/clearTechnicalReports/${businessId}`);
  return result.data;
}

export const getClients = async (agency) => {
  const result = await axios.get(`${SERVER_URL}/api/v1/get-clients/${agency}`);
  return result.data;
}


export const addNewBusinessFromConstructionSite = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-new-business-from-construction-site`, data);

  return result.data;
}
export const addNewBusinessFromTroubleShoot = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-new-business-from-trouble-shoot`, data);

  return result.data;
}
export const addNewBusinessFromIntervention = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-new-business-from-intervention`, data);

  return result.data;
}
export const addNewBusinessFromMaintenanceContract = async (data) => {
  const result = await axios.post(`${SERVER_URL}/api/v1/add-new-business-from-maintenance-contract`, data);

  return result.data;
}
