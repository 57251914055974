import { GoogleApiWrapper } from 'google-maps-react';
import PropTypes from 'prop-types';
import React from 'react';

const { GOOGLE_MAPS_API_KEY } = window._env_;

class ReactGoogleAutocomplete extends React.Component {
  static propTypes = {
    onPlaceSelected: PropTypes.func,
    types: PropTypes.array,
    componentRestrictions: PropTypes.object,
    bounds: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.autocomplete = null;
    this.event = null;
  }

  componentDidMount() {
    const { types = ['(cities)', '(geocode)'], componentRestrictions, bounds, } = this.props;
    const config = {
      types,
      bounds,
    };

    if (componentRestrictions) {
      config.componentRestrictions = componentRestrictions;
    }

    this.disableAutofill();

    // eslint-disable-next-line no-undef
    this.autocomplete = new window.google.maps.places.Autocomplete(this.refs.input, config);

    this.event = this.autocomplete.addListener('place_changed', this.onSelected.bind(this));

  }

  disableAutofill() {
    // Autofill workaround adapted from https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/49161445#49161445
    if (window.MutationObserver) {
      const observerHack = new MutationObserver(() => {
        observerHack.disconnect();
        if (this.refs && this.refs.input) {
          this.refs.input.autocomplete = 'disable-autofill';
        }
      });
      observerHack.observe(this.refs.input, {
        attributes: true,
        attributeFilter: ['autocomplete'],
      });
    }
  }

  // componentWillUnmount() {
  //   this.event.remove();
  // }

  onSelected() {
    if (this.props.onPlaceSelected) {
      const place = this.autocomplete.getPlace()
      this.props.onPlaceSelected(place);
    }
  }


  render() {
    const { onPlaceSelected, types, componentRestrictions, bounds, ...rest } = this.props;

    return (


      <input required
        ref="input"
        placeholder="Votre adresse"
        {...rest}
      />
    );
  }
}


export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY

})(ReactGoogleAutocomplete)