import CardFullTable from 'components/Cards/Admin/CardFullTable';
import Button from 'components/Elements/Button';
import HeaderStatCards from 'components/Headers/Admin/HeaderStatCards';
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from 'components/Sidebar/AuthSidebar';
import AppContext from 'Contexts/AppContext';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { Role } from 'utils/common';
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";




export default function ListSupervisions() {
  const [query, setQuery] = useState("");
  const { user } = useContext(AppContext)
  const [filteredSupervisions, setFilteredSupervisions] = useState([]);
  const history = useHistory();
  const params = useParams();
  const { state: { siteSupervisions = [], type = "", id = "" } = {} } = useLocation()

  const ADD_PERMISSIONS = [
    Role.SUPER_ADMIN,
    Role.ADMIN,
    Role.SERVICE_DIRECTION,
    Role.SERVICE_ADMINISTRATION,
  ]

  const isAllowedToAdd = ADD_PERMISSIONS
    .map(p => p.toLowerCase())
    .includes(user?.department?.toLowerCase())

  useEffect(() => {
    if (!id) history.goBack();
  }, [])

  const dropDownConfig = (id) => ({
    icon: "fas fa-edit",
    button: {
      color: "white",
      size: "sm",
      href: `#/add-site-supervisions/${id}`,
    },
  });


  const transformData = (siteSupervision) => {
    return {
      rowProps: {
        bg: " ",
      },
      colProps: [
        {
          dropdown: { ...dropDownConfig(siteSupervision?._id) },
        },
        siteSupervision?.name?.slice(0, 200) || "-",
        `${siteSupervision?.images?.length || ""}`,

      ],
    };
  };


  const LIST_TABLE_HEADER = {
    title: "Liste des suivi chantiers",
    color: "white",
    head: [
      {
        onClick: () => { },
        string: "Editer",
      },
      {

        string: "Nom",
      },
      {
        string: "nombre d'images",
      },

    ],
    body: siteSupervisions.map(transformData),
  }

  const handleAddSiteSupervision = () => {
    history.push({
      pathname: `/add-site-supervisions`,
      state: {
        type,
        id,
      }
    })
  }

  return (
    <>

      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...{ color: "accentBlue" }} />
        <div className="w-full h-full px-4 md:px-6 mx-auto -mt-24">
          <div className="w-full h-full hello flex flex-wrap">
            <div className="px-4 flex flex-nowrap items-center justify-between w-full">
              <div className="flex">

                {/* <Input
                  {...{
                    placeholder: "Recherche",
                    type: "text",
                    onChange: (e) =>
                      setQuery(e.target.value),
                  }}
                /> */}
                {
                  isAllowedToAdd &&
                  <Button {...{
                    style: {
                      zIndex: 1,
                      marginLeft: "10px"
                    },
                    children: "Ajouter un suivi de chantier",
                    size: "sm",
                    color: "lightBlue",
                    onClick: handleAddSiteSupervision
                  }} />
                }

              </div>

              {/* <Breadcrumb
                location={location}
                pageName={cardfulltablenewbusiness.title}
              /> */}
            </div>
            <div className="w-full h-full px-2">
              <CardFullTable
                {...LIST_TABLE_HEADER}
              // body={filteredSupervisions}
              />
            </div>
          </div>
          {/* <FooterAdmin {...footeradmin} /> */}
        </div>
      </div>
    </>
  )
}
