import dropdownuser from "_texts/misc/dropdowns/dropdownuser.js";

const textProps = {
  brand: {
    // href: "",
    // children: "Nouvelle affaire",
  },
  input: {
    placeholder: "Search here",
    border: "borderless",
    type: "text",
  },
  dropdown: { ...dropdownuser },
};
export default textProps;
