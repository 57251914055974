import CardSettingsLarge from "components/Cards/Admin/CardSettingsLarge.js";
import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import BathroomPlumbingTechnicalReportForm from "forms/newBusiness/BathroomPlumbingTechnicalReportForm";
import ClimatisationHeatTechnicalReportForm from "forms/newBusiness/ClimatisationHeatTechnicalReportForm";
import VentilationTechnicalReport from "forms/newBusiness/VentilationTechnicalReportForm";
import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import footeradmin from "_texts/admin/footers/footeradmin.js";
import { createTechnicalReport } from "../../api/technicalReport";
import HeaderStatCards from "../../components/Headers/Admin/HeaderStatCards";
import {
	TECHNICAL_REPORT_TYPE,
	TECHNICAL_REPORT_TYPE_CORRESPONDANCE,
	TECHNICAL_REPORT_TYPE_REVERSE_CORRESPONDANCE,
} from "../../types/technicalReportTypes";

function AddTechnicalReport({ existingTechnicalReportTypes = [] }) {
	const history = useHistory();
	let { businessId } = useParams();
	const [technicalReportType, setTechnicalReportType] = useState(null);
	const [onGoingTechnicalReport, setOnGoingTechnicalReport] = useState({});
	const [
		hasOtherWaterAccesPointsBool,
		setHasOtherWaterAccesPointsBool,
	] = useState(false);

	const buildForm = () => {
		return {
			button: null,
			forms: [
				{
					titles: ["Ajouter un relevé technique"],
					inputs: [
						{
							label: "Catégorie",
							width: 3,
							select: {
								placeholder: "Ajouter un relevé technique",
								items: Object.values(
									TECHNICAL_REPORT_TYPE_CORRESPONDANCE,
								).filter(
									(elt) =>
										!existingTechnicalReportTypes.includes(
											TECHNICAL_REPORT_TYPE[
												TECHNICAL_REPORT_TYPE_CORRESPONDANCE[elt]
											],
										),
								),

								// value:
								//   showFormsTechnicalReports.length &&
								//   typeOfBusinessReverseCorres[
								//   showFormsTechnicalReports.length - 1
								//   ],
								onChange: (e) => {
									setTechnicalReportType(
										TECHNICAL_REPORT_TYPE_REVERSE_CORRESPONDANCE[e],
									);
								},
							},
						},
					],
				},
			],
		};
	};

	const handleAddNewTechnicalReport = async () => {
		const consolidatedTechnicalReport = {
			...onGoingTechnicalReport,
			technicalReportType,
		};
		try {
			await createTechnicalReport(businessId, consolidatedTechnicalReport);
			history.push("/list-new-business");
		} catch (err) {
			console.log("err", err);
		}
	};

	const buildTechnicalReportTypeByType = () => {
		const technicalReportTypeIdentifier =
			TECHNICAL_REPORT_TYPE.HEAT === technicalReportType ? "HEAT" : "CLIM";
		if (technicalReportType === TECHNICAL_REPORT_TYPE.VENTILATION) {
			return VentilationTechnicalReport(
				onGoingTechnicalReport,
				setOnGoingTechnicalReport,
				[hasOtherWaterAccesPointsBool, setHasOtherWaterAccesPointsBool],
			);
		} else if (
			technicalReportType === TECHNICAL_REPORT_TYPE.CLIMATISATION ||
			technicalReportType === TECHNICAL_REPORT_TYPE.HEAT
		) {
			return ClimatisationHeatTechnicalReportForm(
				onGoingTechnicalReport,
				setOnGoingTechnicalReport,
				technicalReportTypeIdentifier,
			);
		} else if (
			technicalReportType === TECHNICAL_REPORT_TYPE.BATHROOM ||
			technicalReportType === TECHNICAL_REPORT_TYPE.PLUMBING
		) {
			return BathroomPlumbingTechnicalReportForm(
				onGoingTechnicalReport,
				setOnGoingTechnicalReport,
			);
		}
	};

	const buildFormForTechnicalReport = () => {
		return {
			title: "Ajouter un nouveau relevé technique",
			button: {
				children: "Ajouter",
				size: "sm",
				color: "lightBlue",
				onClick: handleAddNewTechnicalReport,
			},

			forms: buildTechnicalReportTypeByType(),
		};
	};

	return (
		<>
			<AuthSidebar />
			<div className="relative 2xl:ml-64 bg-blueGray-100">
				<HeaderStatCards {...{ color: "accentBlue" }} />
				<div className="px-4 md:px-6 mx-auto w-full -mt-24">
					<div className="flex flex-wrap">
						<div className="w-full  px-4">
							<CardSettingsLarge {...buildForm()} />
						</div>
						{technicalReportType !== null && (
							<div className="w-full  px-4">
								<CardSettingsLarge {...buildFormForTechnicalReport()} />
							</div>
						)}
					</div>
					<FooterAdmin {...footeradmin} />
				</div>
			</div>
		</>
	);
}

export default AddTechnicalReport;
