import {
  siteStatusCorres,
  siteStatusReverseCorres, SITE_STATUSES, typeOfBusinessCorres,
  typeOfBusinessReverseCorres, TYPE_OF_BUSINESS
} from "types/baseTypes";

export default function GeneralForm(
  [intervention, setIntervention],
  [newSiteStatus, setNewSiteStatus],
  capitalizeFirstLetter,
  isAdd,
  {
    technicalUsers,
    siteManagers,
    isDisabled,
    existingClients,
    existingClient,
    existingClientIdx,
    setExistingClient,
    handleSetExistingClient,
    agencies,
    agency,
    isAllowedShowAgencySelection,
    selectAgency
  }
) {
  // const isStarting = newSiteStatus === SITE_STATUSES.TO_DO;
  // const isPurchased = newSiteStatus === SITE_STATUSES.PURCHASED;
  // const isDelivered = newSiteStatus === SITE_STATUSES.DELIVERED;

  // const isOk = newSiteStatus === SITE_STATUSES.PLANIFIED;

  const isFinished = newSiteStatus === SITE_STATUSES.FINISHED;

  // const wasStarting = intervention.siteStatus === SITE_STATUSES.TO_DO;

  // const wasPurchased = intervention.siteStatus === SITE_STATUSES.PURCHASED;

  // const wasDelivered =
  // SITE_STATUSES[intervention.siteStatus] === SITE_STATUSES.DELIVERED;

  // const wasOk = intervention.siteStatus === SITE_STATUSES.PLANIFIED;

  // const wasFinished = intervention.siteStatus === SITE_STATUSES.FINISHED;

  // const isBtWarning =
  //   !intervention.bt ||
  //   isNaN(intervention.bt) ||
  //   intervention.bt.toString().length !== 4;

  const getPlace = (place) => {
    const { address_components, formatted_address } = place;

    const postalCodeObj = address_components.find(a => {
      return a.types.includes("postal_code");
    })

    const postalCode = postalCodeObj?.long_name;

    setIntervention(vals => ({
      ...vals,
      postalCode,
      clientId: {
        ...vals?.clientId,
        address: formatted_address
      }
    }));

  }

  return [
    {
      title: "Rensignements basiques",
      inputs: [
        {
          label: `Ref (4 chiffres)*`,
          width: 6,
          input: {
            disabled: isDisabled,
            border:
              !intervention.bt ||
                isNaN(intervention.bt) ||
                intervention.bt.toString().length !== 4
                ? "borderwarning"
                : "border",
            placeholder: "",
            type: "text",
            defaultValue: intervention.bt,
            value: intervention.bt,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                bt: e.target.value.toString().slice(0, 4),
              })),
          },
        },
        isAdd && {
          label: "Nouveau client",
          width: 6,
          select: {
            border: "border",
            items: ["Oui", "Non"],
            defaultValue: existingClient ? "Oui" : "Non",
            value: existingClient ? "Oui" : "Non",
            onChange: (e) => {
              setExistingClient(e === "Oui")
              if (e === "Oui") {
                setIntervention(vals => ({
                  ...vals,
                  clientId: {
                    typeOfClient: "",
                    companyName: "",
                    clientLastName: "",
                    clientFirstName: "",
                    address: "",
                    origin: ""
                  },
                  agencyId: "",
                }))
              }
              else {
                handleSetExistingClient(0)
              }
            },
          },
        },
        (existingClient || existingClients?.length === 0) && {
          label: "Type de client *",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              intervention?.clientId?.typeOfClient === undefined
                ? "borderwarning"
                : "border",
            placeholder: "",
            defaultValue: intervention?.clientId?.typeOfClient,
            items: ["Particulier", "Entreprise"],
            value: intervention?.clientId?.typeOfClient,
            onChange: (e) => {
              setIntervention((vals) => ({
                ...vals,
                clientId: {
                  ...vals?.clientId,
                  typeOfClient: e,
                }
              }));
            },
          },
        },

        ((existingClient || existingClients?.length === 0) &&
          intervention?.clientId?.typeOfClient === "Entreprise") && {
          label: `Nom de l'entreprise *`,
          width: 6,
          input: {
            disabled: isDisabled,
            border: !intervention?.clientId?.companyName ? "borderwarning" : "border",
            placeholder: "",
            type: "text",
            defaultValue: intervention?.clientId?.companyName,
            value: intervention?.clientId?.companyName,
            onChange: (e) => {
              setIntervention((vals) => ({
                ...vals,
                clientId: {
                  ...vals?.clientId,
                  companyName: e.target.value?.toUpperCase(),
                }
              }));
            },
          },
        },
        (existingClient || existingClients?.length === 0) && {
          label: `Nom du contact *`,
          width: 6,
          input: {
            required: true,
            disabled: isDisabled,
            border: !intervention?.clientId?.clientLastName ? "borderwarning" : "border",
            placeholder: "",
            type: "text",
            defaultValue: intervention?.clientId?.clientLastName,
            value: intervention?.clientId?.clientLastName,
            onChange: (e) => {
              setIntervention((vals) => ({
                ...vals,
                clientId: {
                  ...vals?.clientId,
                  clientLastName: e.target.value?.toUpperCase(),
                }
              }));
            },
          },
        },
        (existingClient || existingClients?.length === 0) && {
          label: `Prénom du contact *`,
          width: 6,
          input: {
            required: true,
            disabled: isDisabled,
            border: !intervention?.clientId?.clientFirstName ? "borderwarning" : "border",
            placeholder: "",
            type: "text",
            defaultValue: intervention?.clientId?.clientFirstName,
            value: intervention?.clientId?.clientFirstName,
            onChange: (e) => {
              setIntervention((vals) => ({
                ...vals,
                clientId: {
                  ...vals?.clientId,
                  clientFirstName: capitalizeFirstLetter(e.target.value),
                }
              }));
            },
          },
        },

        (!existingClient && existingClients?.length > 0) && {
          label: `Choix du client *`,
          width: 6,
          selectSearch: {
            search: true,
            options: existingClients,
            autoComplete: "on",
            placeholder: "",
            required: true,
            disabled: isDisabled,
            value: existingClientIdx,
            filterOptions: () => {
              return (value) => {
                if (value.length === 0) return existingClients;
                return existingClients?.filter(c => c?.name?.toLowerCase()?.includes(value?.toLowerCase()))
              }
            },
            onChange: (idx) => {
              handleSetExistingClient(idx)

            },
          },
        },

        {
          label: `Catégorie *`,
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              intervention.typeOfBusiness === undefined
                ? "borderwarning"
                : "border",
            placeholder: "Catégorie",
            defaultValue:
              typeOfBusinessReverseCorres[intervention.typeOfBusiness],
            items: Object.keys(typeOfBusinessCorres),
            value: typeOfBusinessReverseCorres[intervention.typeOfBusiness],
            onChange: (e) => {
              setIntervention((vals) => ({
                ...vals,
                typeOfBusiness: TYPE_OF_BUSINESS[typeOfBusinessCorres[e]],
              }));
            },
          },
        },
        {
          label: `Adresse *`,
          width: 12,
          addressAutoComplete: {
            disabled: isDisabled,
            defaultValue: intervention?.clientId?.address,
            language: "fr",
            types: ['geocode'],
            componentRestrictions: { country: "fr" },
            style: {
              width: "100%",
              "border": "1px solid black",
              borderColor: !intervention?.clientId?.address ? "red" : "black",
              borderRadius: "5px",
              padding: "10px",
            },
            onChange: (e) => {
              setIntervention(vals => ({
                ...vals,
                clientId: {
                  ...vals?.clientId,
                  address: e.target.value
                }
              }))
            },
            onPlaceSelected: (e) => {

              getPlace(e);
            }

          },
        },
        (isAllowedShowAgencySelection) && {
          label: `Agence`,
          width: 6,
          select: {
            border:
              !intervention.agencyId ? "borderwarning" : "border",
            items: agencies,
            defaultValue: agency,
            value: agency,
            onChange: selectAgency,
          },
        },

        !isDisabled && {
          label: "Origine du dépannage *",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              intervention?.clientId?.origin === undefined
                ? "borderwarning"
                : "border",
            placeholder: "Origine de l'affaire",
            defaultValue: intervention?.clientId?.origin,
            items: [
              "Connaissance",
              "Déja client",
              "Salon",
              "Publicités",
              "Recommandation du client",
              "Recommandation d'autres professionnels",
              "Club affaire",
              "Technicien",
            ],
            value: intervention?.clientId?.origin,
            onChange: (e) => {
              if (typeof e === "string") {
                setIntervention((vals) => ({
                  ...vals,
                  clientId: {
                    ...vals?.clientId,
                    origin: e,
                  }
                }));
              }
            },
          },
        },
      ],
    },
    {
      title: "",
      inputs: [
        !isDisabled && {
          label: `Montant HT € `,
          width: 6,
          input: {
            disabled: isDisabled,
            border:
              (!intervention?.amount || intervention?.amount <= 0) && isFinished
                ? "borderwarning"
                : "border",
            placeholder: "",
            type: "number",
            defaultValue: intervention.amount,
            value: intervention.amount,
            onChange: (e) => {
              setIntervention((vals) => ({
                ...vals,
                amount: +e.target.value,
              }));
            },
          },
        },
        // {
        //   label: "Responsable chantier",
        //   width: 6,
        //   select: {
        //     disabled: isDisabled,
        //     border: "border",
        //     placeholder: "",
        //     items: siteManagers,
        //     defaultValue: intervention.siteManager,
        //     value: intervention.siteManager,
        //     onChange: (e) => {
        //       if (typeof e === "string") {
        //         setIntervention((vals) => ({
        //           ...vals,
        //           siteManager: e
        //         }))
        //       }
        //     }
        //   },


        // },
      ],
    },

    {
      title: "Avancement du dépannage",
      inputs: [
        {
          label: "Statut",
          width: 6,
          select: {
            disabled: isDisabled,
            placeholder: "",
            items: Object.keys(siteStatusCorres).filter((value) => {
              return true;
              // const siteStatusNb =
              //   intervention.siteStatus || SITE_STATUSES.TO_DO;

              // return SITE_STATUSES[siteStatusCorres[value]] >= siteStatusNb;
            }),
            defaultValue: siteStatusReverseCorres[newSiteStatus],
            value: siteStatusReverseCorres[newSiteStatus],
            onChange: (e) =>
              setNewSiteStatus(SITE_STATUSES[siteStatusCorres[e]]),
          },
        },

        {
          label: `Date de Commande`,
          width: 6,
          input: {
            disabled: isDisabled,
            border: "border",
            placeholder: "",
            type: "date",
            defaultValue: intervention.materialOrderDate,
            value: intervention.materialOrderDate,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                materialOrderDate: e.target.value,
              })),
          },
        },
        {
          label: `Fournisseur`,
          width: 6,
          input: {
            disabled: isDisabled,
            placeholder: "Fournisseur",
            border: "border",
            type: "text",
            defaultValue: intervention.supplier,
            value: intervention.supplier,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                supplier: e.target.value,
              })),
          },
        },
        {
          label: `Livraison`,
          width: 6,
          input: {
            disabled: isDisabled,
            border: "border",
            placeholder: "",
            type: "date",
            defaultValue: intervention.deliveryDate,
            value: intervention.deliveryDate,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                deliveryDate: e.target.value,
              })),
          },
        },
        {
          label: `Planification début du dépannage`,
          width: 6,
          input: {
            disabled: isDisabled,
            border:
              (!intervention.objectifDateStart) && isFinished
                ? "borderwarning"
                : "border",
            placeholder: "",
            type: "date",
            defaultValue: intervention.objectifDateStart,
            value: intervention.objectifDateStart,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                objectifDateStart: e.target.value,
              })),
          },
        },
        {
          label: `Planification fin du dépannage`,
          width: 6,
          input: {
            disabled: isDisabled,
            border:
              (!intervention.objectifDateEnd) && isFinished
                ? "borderwarning"
                : "border",
            placeholder: "",
            type: "date",
            defaultValue: intervention.objectifDateEnd,
            value: intervention.objectifDateEnd,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                objectifDateEnd: e.target.value,
              })),
          },
        },
        {
          label: `Techniciens`,
          width: 6,
          select: {
            multi: true,
            disabled: isDisabled,
            border:
              (intervention?.technicians?.trim()?.length <= 0) && isFinished
                ? "borderwarning"
                : "border",
            placeholder: "",
            items: technicalUsers,
            defaultValue: intervention.technicians,
            value: intervention.technicians,
            onChange: (e) => {
              if (typeof e === "string") {
                setIntervention((vals) => ({
                  ...vals,
                  technicians: e,
                }))
              }
            }
          },

          // input: {
          //   border: "border",
          //   placeholder: "Techniciens",
          //   type: "text",
          //   defaultValue: intervention.technicians,
          //   value: intervention.technicians,
          //   onChange: (e) =>
          //     setIntervention((vals) => ({
          //       ...vals,
          //       technicians: e.target.value,
          //     })),
          // },
        },
        isFinished &&
        {
          label: `Date fin du dépannage`,
          width: 6,
          input: {
            disabled: isDisabled,
            placeholder: "",
            border:
              (!intervention.dateCompleteSite) && isFinished
                ? "borderwarning"
                : "border",
            type: "date",
            defaultValue: intervention.dateCompleteSite,
            value: intervention.dateCompleteSite,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                dateCompleteSite: e.target.value,
              })),
          },
        },
        {
          label: "Problèmes",
          width: 6,
          input: {
            disabled: isDisabled,
            placeholder: "Problèmes",
            type: "textarea",
            defaultValue: intervention.problem,
            value: intervention.problem,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                problem: e.target.value,
              })),
          },
        },

        {
          label: `Remarques`,
          width: 12,
          input: {
            disabled: isDisabled,
            placeholder: "Remarques",
            type: "textarea",
            defaultValue: intervention.comment,
            rows: 2,
            value: intervention.comment,
            onChange: (e) =>
              setIntervention((vals) => ({
                ...vals,
                comment: e.target.value,
              })),
          },
        },
      ],
    },
  ];
}