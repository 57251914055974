// src/forms/newBusiness/VentilationTechnicalReportForm.js

import {
  airEntryPointCorres,
  airEntryPointReverseCorres, AIR_ENTRY_TYPE, AREA,
  areaCorres,
  areaReverseCorres, BOUCHES_TYPE,
  boucheTypeCorres,
  boucheTypeReverseCorres, caissonTypeCorres,
  caissonTypeReverseCorres, CAISSON_TYPE, doubleFluxTypeCorres,
  doubleFluxTypeReverseCorres, DOUBLE_FLUX_TYPE, houseSizeCorres,
  houseSizeReverseCorres, HOUSE_SIZE, hygroTypeCorres,
  hygroTypeReverseCorres, HYGRO_TYPE, nbOfFloorsCorres,
  nbOfFloorsReverseCorres, NB_OF_FLOORS, roofTypeCorres,
  roofTypeReverseCorres, ROOF_TYPE, typeCorres,
  typeReverseCorres, VENTILATION_TYPE
} from "types/technicalReportTypes";
import { imageHandler } from '../../utils/common';

export default function VentilationTechnicalReport(
  onGoingTechnicalReport,
  setOnGoingTechnicalReport,
  [
    hasOtherWaterAccesPointsBool,
    setHasOtherWaterAccesPointsBool,
  ],
  isDisabled = false
) {


  const handleAddNewImage = (imageUrl) => {
    setOnGoingTechnicalReport(vals => {
      const images = vals?.images || []
      return {
        ...vals,
        images: [...images, { url: imageUrl }]
      }
    })
  }


  const handleRemoveOfNewImage = (imageUrl) => {
    setOnGoingTechnicalReport(vals => {
      return {
        ...vals,
        images: vals?.images?.filter(({ url }) => url !== imageUrl)
      }
    })
  }


  const handleChangeOfImage = (existingImageUrl, newImageUrl) => {
    setOnGoingTechnicalReport(vals => {
      return {
        ...vals,
        images: vals?.images?.map(image => {
          const { url, } = image;
          if (url === existingImageUrl)
            return { ...image, url: newImageUrl }
          return image;
        })
      }
    })
  }


  const onChangeText = (existingImageUrl, text) => {
    setOnGoingTechnicalReport(vals => {
      return {
        ...vals,
        images: vals?.images?.map(image => {
          const { url, } = image;
          if (url === existingImageUrl)
            return { ...image, description: text }
          return image;
        })
      }
    })
  }


  return [
    {
      title: "Type de construction",
      inputs: [
        {
          label: "Neuf",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport
                ?.isNewProperty === undefined
                ? "borderwarning"
                : "border",
            checked:
              !!onGoingTechnicalReport
                .isNewProperty,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                isNewProperty:
                  onGoingTechnicalReport
                    .isNewProperty === undefined
                    ? true
                    : !onGoingTechnicalReport
                      .isNewProperty,
              }));
            },
          },
        },
        {
          label: "Existant",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport
                ?.isNewProperty === undefined
                ? "borderwarning"
                : "border",
            placeholder: "",
            // defaultValue:
            //   onGoingTechnicalReport.isNewProperty ===
            //     undefined
            //     ? false
            //     : !onGoingTechnicalReport.isNewProperty,
            checked:
              onGoingTechnicalReport
                .isNewProperty === undefined
                ? false
                : !onGoingTechnicalReport
                  .isNewProperty,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                isNewProperty:
                  onGoingTechnicalReport
                    .isNewProperty === undefined
                    ? false
                    : !onGoingTechnicalReport
                      .isNewProperty,
              }));
            },
          },
        },
        {
          label: "Taille du logement",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.houseSize ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Taille du logement",
            defaultValue:
              houseSizeReverseCorres[
              onGoingTechnicalReport.houseSize
              ],
            items: Object.keys(houseSizeCorres),
            value:
              houseSizeReverseCorres[
              onGoingTechnicalReport.houseSize
              ],
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                houseSize: HOUSE_SIZE[houseSizeCorres[e]],
              }));
            },
          },
        },
      ],
    },
    {
      title: "Type de Logement",
      inputs: [
        {
          label: "Appartement",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport?.isHouse ===
                undefined
                ? "borderwarning"
                : "border",
            checked:
              onGoingTechnicalReport.isHouse ===
                undefined ||
                onGoingTechnicalReport.isHouse === null
                ? false
                : !onGoingTechnicalReport.isHouse,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                isHouse:
                  !onGoingTechnicalReport.isHouse
                    ? false
                    : !onGoingTechnicalReport
                      .isHouse,
              }));
            },
          },
        },
        {
          label: "Maison",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport?.isHouse ===
                undefined
                ? "borderwarning"
                : "border",
            defaultValue:
              onGoingTechnicalReport.isHouse ===
                undefined
                ? false
                : onGoingTechnicalReport.isHouse,
            checked:
              onGoingTechnicalReport.isHouse,

            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                isHouse:
                  !onGoingTechnicalReport.isHouse
                    ? true
                    : !onGoingTechnicalReport.isHouse,
              }));
            },
          },
        },
        {
          label: "Surface",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.area ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Surface",
            defaultValue:
              areaReverseCorres[
              onGoingTechnicalReport.area
              ],
            items: [
              "< 100 m²",
              "100 / 150 m²",
              "150 / 200 m²",
              "> 200 m² (plan obligatoire)",
            ],
            value:
              areaReverseCorres[
              onGoingTechnicalReport.area
              ],
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                area: AREA[areaCorres[e]],
              }));
            },
          },
        },
      ],
    },
    {
      title: "",
      inputs: [
        onGoingTechnicalReport?.isHouse === false && {
          label: "Niveau (étage)",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.nbOfFloors ===
                undefined
                ? "borderwarning"
                : "border",
            items: Object.keys(nbOfFloorsCorres),
            placeholder: "Nombre d'étages",
            defaultValue:
              nbOfFloorsReverseCorres[
              onGoingTechnicalReport.nbOfFloors
              ],
            value:
              nbOfFloorsReverseCorres[
              onGoingTechnicalReport.nbOfFloors
              ],

            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                nbOfFloors:
                  NB_OF_FLOORS[nbOfFloorsCorres[e]],
              }));
            },
          },
        },
        {
          label: "Nombre de salles de bain",
          width: 6,
          incrementer: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.nbOfBathrooms ===
                undefined
                ? "borderwarning"
                : "border",
            min: 0,
            max: 16,
            color: "lightBlue",
            placeholder: "Nombre de salle de bains",
            defaultValue:
              onGoingTechnicalReport.nbOfBathrooms,
            value:
              onGoingTechnicalReport.nbOfBathrooms,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                nbOfBathrooms: e,
              }));
            },
          },
        },
        {
          label: "Type de plafond",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.roofType ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Type de Plafond",
            defaultValue:
              roofTypeReverseCorres[
              onGoingTechnicalReport.roofType
              ],

            items: ["Placo", "Hourdi", "Plafonette"],
            value:
              roofTypeReverseCorres[
              onGoingTechnicalReport.roofType
              ],
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                roofType: ROOF_TYPE[roofTypeCorres[e]],
              }));
            },
          },
        },

        {
          label: "Nombre de toilettes",
          width: 6,
          incrementer: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.nbOfToilets ===
                undefined
                ? "borderwarning"
                : "border",
            min: 0,
            max: 16,
            color: "lightBlue",
            placeholder: "Nombre de toilettes",
            defaultValue:
              onGoingTechnicalReport.nbOfToilets,
            value: onGoingTechnicalReport.nbOfToilets,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                nbOfToilets: e,
              }));
            },
          },
        },
      ],
    },
    {
      title: "Autres Points d'eau",
      width: 6,
      inputs: [
        {
          label: "Buanderie",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            checked:
              onGoingTechnicalReport
                .hasLaundryRoom === undefined
                ? false
                : onGoingTechnicalReport
                  .hasLaundryRoom,

            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                hasLaundryRoom:
                  !onGoingTechnicalReport.hasLaundryRoom
                    ? true
                    : !onGoingTechnicalReport.hasLaundryRoom,
              }));
            },
          },
        },
        {
          label: "Salle d'eau",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            checked:
              onGoingTechnicalReport.hasBathroom ===
                undefined
                ? false
                : onGoingTechnicalReport.hasBathroom,

            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                hasBathroom:
                  onGoingTechnicalReport
                    .hasBathroom === undefined ||
                    onGoingTechnicalReport
                      .hasBathroom === null
                    ? true
                    : !onGoingTechnicalReport
                      .hasBathroom,
              }));
            },
          },
        },
        {
          label: "Autres",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            checked: hasOtherWaterAccesPointsBool,
            onChange: (e) => {
              setHasOtherWaterAccesPointsBool(
                !!!hasOtherWaterAccesPointsBool,
              );
            },
          },
        },

        hasOtherWaterAccesPointsBool === true && {
          label: "Point d'eau",
          width: 3,
          input: {
            disabled: isDisabled,
            border: !onGoingTechnicalReport
              ?.hasOtherWaterAccesPoints
              ? "borderwarning"
              : "border",
            placeholder: "Point d'eau",
            type: "text",
            defaultValue:
              onGoingTechnicalReport
                ?.hasOtherWaterAccesPoints,
            value:
              onGoingTechnicalReport
                ?.hasOtherWaterAccesPoints,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                hasOtherWaterAccesPoints: e.target.value,
              }));
            },
          },
        },
      ],
    },
    {
      title: "Choix VMC",
      width: 6,
      inputs: [
        {
          label: "Type de ventilation",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.type ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Type de ventilation",
            defaultValue:
              typeReverseCorres[
              onGoingTechnicalReport.type
              ],
            items: [
              "Double flux",
              "Hygro A",
              "Hygro B",
              "Simple flux",
              "Extracteur"

            ],
            value:
              typeReverseCorres[
              onGoingTechnicalReport.type
              ],
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                type: VENTILATION_TYPE[typeCorres[e]],
              }));
            },
          },
        },
        onGoingTechnicalReport.type ===
        VENTILATION_TYPE.DOUBLE_FLUX && {
          label: "Type de double flux",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport
                .doubleFluxType === undefined
                ? "borderwarning"
                : "border",
            placeholder: "Double flux",
            defaultValue:
              doubleFluxTypeReverseCorres[
              onGoingTechnicalReport.doubleFluxType
              ],
            items: ["Radio", "Filaire"],
            value:
              doubleFluxTypeReverseCorres[
              onGoingTechnicalReport.doubleFluxType
              ],
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                doubleFluxType:
                  DOUBLE_FLUX_TYPE[
                  doubleFluxTypeCorres[e]
                  ],
              }));
            },
          },
        },

        (onGoingTechnicalReport.type ===
          VENTILATION_TYPE.HYGRO_A ||
          onGoingTechnicalReport.type ===
          VENTILATION_TYPE.HYGRO_B) && {
          label: "Type de hygro",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.hygroType ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Type de hygro",
            defaultValue:
              hygroTypeReverseCorres[
              onGoingTechnicalReport.hygroType
              ],
            items: Object.keys(hygroTypeCorres),
            value:
              hygroTypeReverseCorres[
              onGoingTechnicalReport.hygroType
              ],
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                hygroType: HYGRO_TYPE[hygroTypeCorres[e]],
              }));
            },
          },
        },
        {
          label: "Type de caisson",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.caissonType ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Type de caisson",
            defaultValue:
              caissonTypeReverseCorres[
              onGoingTechnicalReport.caissonType
              ],
            items: ["Standard", "Basse consommation"],
            value:
              caissonTypeReverseCorres[
              onGoingTechnicalReport.caissonType
              ],

            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                caissonType:
                  CAISSON_TYPE[caissonTypeCorres[e]],
              }));
            },
          },
        },
        {
          label: "Type de bouche",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.boucheType ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Type de bouche",
            defaultValue:
              boucheTypeReverseCorres[
              onGoingTechnicalReport.boucheType
              ],
            items: ["Pile", "Electriques", "Manuelle"],
            value:
              boucheTypeReverseCorres[
              onGoingTechnicalReport.boucheType
              ],

            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                boucheType:
                  BOUCHES_TYPE[boucheTypeCorres[e]],
              }));
            },
          },
        },
        {
          label: "Type d'entrée d'air",
          width: 6,
          select: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport.airEntryPoint ===
                undefined
                ? "borderwarning"
                : "border",
            placeholder: "Type d'entrée d'air",
            defaultValue:
              airEntryPointReverseCorres[
              onGoingTechnicalReport.airEntryPoint
              ],
            items: ["Hygro", "Accoustique"],
            value:
              airEntryPointReverseCorres[
              onGoingTechnicalReport.airEntryPoint
              ],
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                airEntryPoint:
                  AIR_ENTRY_TYPE[airEntryPointCorres[e]],
              }));
            },
          },
        },
        {
          label: "Entrées d'air à créer",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport
                .existingAirEntries === undefined
                ? "borderwarning"
                : "border",
            placeholder: "",
            checked:
              onGoingTechnicalReport
                .existingAirEntries === false,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                existingAirEntries:
                  onGoingTechnicalReport
                    .existingAirEntries === undefined
                    ? (onGoingTechnicalReport.existingAirEntries = false)
                    : !!!onGoingTechnicalReport
                      .existingAirEntries,
              }));
            },
          },
        },
        {
          label: "Entrées d'air présentes",
          width: 3,
          checkbox: {
            disabled: isDisabled,
            border:
              onGoingTechnicalReport
                .existingAirEntries === undefined
                ? "borderwarning"
                : "border",
            checked:
              onGoingTechnicalReport
                .existingAirEntries,
            onChange: (e) => {
              setOnGoingTechnicalReport((vals) => ({
                ...vals,
                existingAirEntries: !!!onGoingTechnicalReport.existingAirEntries,
              }));
            },
          },
        },

      ],
    },
    {
      width: 6,
      inputs: [
        {
          label: "Ajouter une image",
          width: 5,
          images: {
            images: onGoingTechnicalReport.images,
            onChange: async (existingImageUrl, file) => {
              try {
                const url = await imageHandler(file);

                handleChangeOfImage(existingImageUrl, url);

              } catch (err) {

              }
            },
            onRemove: (existingImageUrl) => {
              handleRemoveOfNewImage(existingImageUrl)
            },
            onAddNew: async (_placeholder, file) => {
              try {
                const url = await imageHandler(file);

                handleAddNewImage(url);

              } catch (err) {

              }
            },
            onRemoveNew: (existingImageUrl) => {
              handleRemoveOfNewImage(existingImageUrl)
            },
            onChangeText,
            changeButton: {
              disabled: isDisabled,
              children: "Ajouter/changer",
              size: "sm",
              color: "lightBlue",
              onClick: () => alert("add/change"),
            },
            removeButton: {
              disabled: isDisabled,
              children: "Supprimer",
              size: "sm",
              color: "lightBlue",
              onClick: () => alert("remove"),
            }

          },
        },
      ]
    }
  ];
}
