/* eslint-disable react-hooks/exhaustive-deps */
import CardFullTable from "components/Cards/Admin/CardFullTable.js";
import ListingActions from "components/Elements/ListingActions";
import HeaderStatCards from "components/Headers/Admin/HeaderStatCards.js";
import NavbarSearchUser from "components/Navbars/NavbarSearchUser.js";
import AuthSidebar from "components/Sidebar/AuthSidebar.js";
import AppContext from "Contexts/AppContext";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
// components
import CsvDownload from "react-json-to-csv";
import { useLocation, useParams } from "react-router-dom";
// service
import { handleSetHeaderStatCardsOrder } from "service/orders/HandleSetHeaderStatCardsOrder";
import {
  advancementStatusReverseCorres,
  ADVANCEMENT_STATUSES,
  typeOfBusinessReverseCorres,
} from "types/baseTypes";
import headerstatcards from "_texts/admin/headers/headerstatcards.js";
import navbarsearchusersettings2 from "_texts/admin/navbars/navbarsearchusersettings2.js";
// import cardfulltableorders from "_texts/admin/cards/cardfulltableorders.js";
// import footeradmin from "_texts/admin/footers/footeradmin.js";
import { getOrders, SITE_STATUSES } from "../../api/orders";
// import FooterAdmin from "components/Footers/Admin/FooterAdmin.js";
import Input from "../../components/Elements/Input";
import { Breadcrumb } from "./BreadCrumb";

export default function ListingOrders() {
  const location = useLocation();
  const { selectedAgency } = useContext(AppContext);
  const [listOrders, setListOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [headerstatcardsorders, setheaderstatcardsorders] = useState(
    headerstatcards
  );
  const { advStatus } = useParams();
  const isOver = advStatus === ":ko" || advStatus === ":finished";
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const [entryOfDataToSortOn, setEntryOfDataToSortOn] = useState(
    "dateOfSignature"
  );
  const [typeToSort, setTypeToSort] = useState("date");
  const [isSortPositif, setIsSortPositif] = useState(true);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [rerender, setRerender] = useState(false);
  const [filteredOrdersData, setFilteredOrdersData] = useState([]);

  const cardfulltableorders = {
    title:
      advStatus === ":finished"
        ? "Liste commande terminées"
        : advStatus === ":ko"
        ? "Liste affaires refusées"
        : "Liste des commandes acceptées",
    color: "white",
    head: [
      {
        onClick: () => {},
        string: "",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("clientLastName");
          setIsSortPositif(!isSortPositif);
        },
        string: "Nom",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("advancementStatus");
          setIsSortPositif(!isSortPositif);
        },
        string: "Etat",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("typeOfBusiness");
          setIsSortPositif(!isSortPositif);
        },
        string: "Catégorie",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("siteManager");
          setIsSortPositif(!isSortPositif);
        },
        string: "RC",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("dateOfSignature");
          setIsSortPositif(!isSortPositif);
        },
        string: "Signature",
      },
      {
        onClick: () => {
          setTypeToSort("number");
          setEntryOfDataToSortOn("amount");
          setIsSortPositif(!isSortPositif);
        },
        string: "Montant",
      },
      {
        onClick: () => {
          setTypeToSort("date");
          setEntryOfDataToSortOn("dateCompleteSite");
          setIsSortPositif(!isSortPositif);
        },
        string: "FC",
      },
      {
        onClick: () => {
          setTypeToSort("string");
          setEntryOfDataToSortOn("comment");
          setIsSortPositif(!isSortPositif);
        },
        string: "Infos",
      },
    ],
    body: [],
  };
  const dropDownConfig = (orderId) => ({
    icon: isOver ? "fas fa-eye" : "fas fa-edit",
    button: {
      color: "white",
      size: "sm",
      href: `#/update-order/${orderId}`,
    },
  });

  const getAdvancementStatusColor = (advancementStatus) => {
    let color = "";

    switch (advancementStatus) {
      case ADVANCEMENT_STATUSES.TO_START:
        color = "amber";
        break;
      case ADVANCEMENT_STATUSES.RUNNING:
        color = "lightBlue";
        break;
      case ADVANCEMENT_STATUSES.FINISHED:
        color = "emerald";
        break;
      case ADVANCEMENT_STATUSES.REFUSED:
        color = "red";
        break;

      default:
        color = "blueGray";
        break;
    }
    return color;
  };

  const transformData = (data) => {
    return {
      rowProps: {
        bg:
          data.advancementStatus === ADVANCEMENT_STATUSES.PAUSED
            ? "bg-blueGray-200"
            : " ",
      },
      colProps: [
        {
          dropdown: { ...dropDownConfig(data._id) },
        },
        data?.clientId?.typeOfClient === "Entreprise"
          ? data?.clientId?.companyName
          : `${data?.clientId?.clientLastName} ${data?.clientId?.clientFirstName}`,
        {
          color: getAdvancementStatusColor(
            data.advancementStatus || ADVANCEMENT_STATUSES.TO_START
          ),
          text:
            advancementStatusReverseCorres[data.advancementStatus] ||
            advancementStatusReverseCorres[ADVANCEMENT_STATUSES.TO_START],
        },
        typeOfBusinessReverseCorres[data.typeOfBusiness] || "-",
        data.siteManager || "-",
        moment(data.dateOfSignature).format("DD/MM/YYYY"),
        `${new Intl.NumberFormat("fr-FR", {
          style: "currency",
          currency: "EUR",
        }).format(data.amount)}` || "-",

        moment(data.dateCompleteSite).format("DD/MM/YYYY") || "-",
        data.comment ? data.comment.slice(0, 16) : "-",
      ],
    };
  };

  const buildConsolidatedData = (data) => {
    const toStartOrders = data
      ?.filter(
        ({ advancementStatus }) =>
          advancementStatus === ADVANCEMENT_STATUSES.TO_START
      )
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
    const runningOrders = data
      ?.filter(
        ({ advancementStatus }) =>
          advancementStatus === ADVANCEMENT_STATUSES.RUNNING
      )
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
    const pausedOrders = data
      ?.filter(
        ({ advancementStatus }) =>
          advancementStatus === ADVANCEMENT_STATUSES.PAUSED
      )
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
    const refusedOrders = data
      ?.filter(
        ({ advancementStatus }) =>
          advancementStatus === ADVANCEMENT_STATUSES.REFUSED
      )
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));
    const finishedOrders = data
      ?.filter(
        ({ advancementStatus, siteStatus }) =>
          advancementStatus === ADVANCEMENT_STATUSES.FINISHED ||
          siteStatus === SITE_STATUSES.FINISHED
      )
      .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1));

    let consolidatedData = [];

    if (advStatus === ":finished") {
      consolidatedData = finishedOrders;
    } else if (advStatus === ":ko") {
      consolidatedData = refusedOrders;
    } else {
      consolidatedData = [...toStartOrders, ...runningOrders, ...pausedOrders];
    }

    return consolidatedData;
  };

  const handleGetOrders = async () => {
    try {
      let data = await getOrders(selectedAgency);

      const consolidatedData = buildConsolidatedData(data);
      const allData = consolidatedData;

      setListOrders(data);
      setFilteredOrdersData(consolidatedData);
      setFilteredOrders(allData);

      setIsLoaded(true);

      handleSetHeaderStatCardsOrder(data, advStatus, [
        headerstatcardsorders,
        setheaderstatcardsorders,
      ]);

      setIsReady(true);
      (!isLoaded || !isReady) && setRerender(!rerender);
    } catch (err) {
      console.log("err", err);
    }
  };

  const filterOrders = (query) => {
    const filterQuery = query?.toLowerCase();

    const filtered = buildConsolidatedData(listOrders).filter((orders) => {
      return (
        orders?.clientId?.clientFirstName
          ?.toLowerCase()
          ?.includes(filterQuery) ||
        orders?.clientId?.clientLastName
          ?.toLowerCase()
          ?.includes(filterQuery) ||
        orders?.status?.toString().includes(filterQuery) ||
        orders?.clientId?.typeOfClient?.toString().includes(filterQuery)
      );
    });

    if (!filterQuery || filterQuery.length === 0) {
      setFilteredOrders(buildConsolidatedData(listOrders));
    } else {
      setFilteredOrders(filtered);
    }
  };

  useEffect(() => {
    const ascendingReturn = isSortPositif ? -1 : 1;
    const data = filteredOrdersData.sort((a, b) => {
      if (entryOfDataToSortOn === "clientLastName") {
        return a?.clientId?.clientFirstName?.toLowerCase() <
          b?.clientId?.clientFirstName?.toLowerCase()
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "advancementStatus") {
        return a.advancementStatus < b.advancementStatus
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "typeOfBusiness") {
        return typeOfBusinessReverseCorres[a.typeOfBusiness] <
          typeOfBusinessReverseCorres[b.typeOfBusiness]
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "siteManager") {
        return a.siteManager?.trim() < b.siteManager?.trim()
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "dateOfSignature") {
        return moment(a.dateOfSignature).isBefore(b.dateOfSignature)
          ? ascendingReturn
          : ascendingReturn * -1;
      }
      if (entryOfDataToSortOn === "amount") {
        return (a.amount || 0) < (b.amount || 0)
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "originOfNewBusiness") {
        return a?.clientId?.origin < b?.clientId?.origin
          ? ascendingReturn
          : ascendingReturn * -1;
      } else if (entryOfDataToSortOn === "comment") {
        return a.comment?.trim() < b.comment?.trim()
          ? ascendingReturn
          : ascendingReturn * -1;
      }
      return 1;
    });

    // const transformedData = data
    //   .map(transformData)

    if (data) setFilteredOrders(data);
  }, [entryOfDataToSortOn, isSortPositif, typeToSort]);

  React.useEffect(() => {
    handleGetOrders();
  }, [advStatus, location, selectedAgency]);

  return (
    <>
      <AuthSidebar />
      <div className="relative 2xl:ml-64 bg-blueGray-100 h-full">
        <NavbarSearchUser {...navbarsearchusersettings2} />
        <HeaderStatCards {...headerstatcardsorders} />
        <div className="w-full px-4 md:px-6 mx-auto w-full -mt-24">
          <div className="w-full flex flex-wrap">
            <ListingActions
              {...{
                fileName: "listing_commandes.csv",
                onInputSearch: filterOrders,
                downloadData: listOrders,
                title: cardfulltableorders.title,
                location,
              }}
            />
            <div className="w-full px-4">
              <CardFullTable
                {...cardfulltableorders}
                body={filteredOrders.map(transformData)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
